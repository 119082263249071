/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { clearError } from "../../actions/clearError";
import { notifySuccess } from "../../components/alert";
import {
  getOwnerProfileAction,
  updateOwnerProfileAction,
} from "../../actions/pms/ownerProfile";
import { UPDATE_PROFILE_RESET } from "../../actions/types/ownerAuth";
import PageLoader from "../../components/loader/PageLoader";
import { isObjEmpty } from "../../utils";
import { GENDERS } from "../../constants";
import { useTranslation } from "react-i18next";
import { authenticateHotelOwner } from "../../actions/pms/ownerAuth";
import NewProfile from "./NewProfile";
import ProfileHeader from "./ProfileHeader";
import EmployeeInformation from "./EmployeeInformation";
import LeaveManagement from "./LeaveManagement";
import ShiftManagement from "./ShiftManagement";
import SalaryManagement from "./SalaryManagement";

const Profile = (props) => {
  const [index, setIndex] = useState(1);
  const { hotel } = useSelector((state) => state.Hotel);

  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState(GENDERS.MALE);
  const [profileEdit, setProfileEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  //add new state

  useEffect(() => {
    if (props?.newProfile && props?.newProfile?.isupdated) {
      notifySuccess(t("967"));
      props?.getProfile();
      console.log(props?.newProfile);
      props?.reset();
      setProfileEdit(false);
    }
  }, [props.newProfile]);

  //set previous data from get profile
  useEffect(() => {
    if (
      props?.profile &&
      props?.profile?.profile &&
      !isObjEmpty(props?.profile?.profile)
    ) {
      setAddress(props?.profile?.profile?.address);
      setGender(props?.profile?.profile?.gender);
      setPhone(props?.profile?.profile?.mobile);
    }

    if (!isObjEmpty(props?.owner?.owner)) {
      const first = props?.owner?.owner?.name?.split("_")[0];

      const last = props?.owner?.owner?.name?.replace(first + "_", "");
      setFirstName(first);
      setLastName(last);
      setEmail(props?.owner?.owner?.email);
    }

    setLoading(false);
  }, [props.profile, props?.owner]);

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="page-container">
          <div className="mb-2 text-[30px] leading-[38px] font-bold tracking-[-0.8px]">
            {t("968")}
          </div>

          <NewProfile />
          <div className=" border border-[#E5E9EB] rounded-[10px] bg-white mt-4 p-6">
            <ProfileHeader index={index} setIndex={setIndex} />
            {index === 1 ? <EmployeeInformation hotel={hotel} /> : null}
            {index === 2 ? <LeaveManagement hotel={hotel} /> : null}
            {index === 3 ? <ShiftManagement hotel={hotel} /> : null}
            {index === 4 ? <SalaryManagement hotel={hotel} /> : null}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  newProfile: state.UpdateProfile,
  profile: state.OwnerProfile,
  owner: state.Authenticate,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data) => dispatch(updateOwnerProfileAction(data)),
  getProfile: () => {
    dispatch(getOwnerProfileAction());
    dispatch(authenticateHotelOwner());
  },
  clear: () => dispatch(clearError()),
  reset: () => dispatch({ type: UPDATE_PROFILE_RESET }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
