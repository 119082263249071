import React, { useState } from "react";
// import { BsThreeDotsVertical } from "react-icons/bs";
import { MdSwapCalls } from "react-icons/md";
import CustomeTable from "../../components/CustomeTable";
import CreateShiftSwap from "../../components/modals/CreateShiftSwap";

const ShiftManagement = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenCreateSwap, setIsOpenCreateSwap] = useState(false);
  const tableData = [
    {
      id: "01",
      date: "29/11/2023",
      employeeName: "Rajeeve Mehta",
      shift1: "09:00-11:00am",
      shift2: "09:00-11:00am",
      shift3: "09:00-11:00am",
    },
    {
      id: "02",
      date: "29/11/2023",
      employeeName: "Rajeeve Mehta",
      shift1: "09:00-11:00am",
      shift2: "09:00-11:00am",
      shift3: "09:00-11:00am",
    },
    {
      id: "03",
      date: "29/11/2023",
      employeeName: "Rajeeve Mehta",
      shift1: "09:00-11:00am",
      shift2: "09:00-11:00am",
      shift3: "09:00-11:00am",
    },
    {
      id: "04",
      date: "29/11/2023",
      employeeName: "Rajeeve Mehta",
      shift1: "09:00-11:00am",
      shift2: "09:00-11:00am",
      shift3: "09:00-11:00am",
    },
    {
      id: "05",
      date: "29/11/2023",
      employeeName: "Rajeeve Mehta",
      shift1: "09:00-11:00am",
      shift2: "09:00-11:00am",
      shift3: "09:00-11:00am",
    },
    {
      id: "06",
      date: "29/11/2023",
      employeeName: "Rajeeve Mehta",
      shift1: "09:00-11:00am",
      shift2: "09:00-11:00am",
      shift3: "09:00-11:00am",
    },
    {
      id: "07",
      date: "29/11/2023",
      employeeName: "Rajeeve Mehta",
      shift1: "09:00-11:00am",
      shift2: "09:00-11:00am",
      shift3: "09:00-11:00am",
    },
    {
      id: "08",
      date: "29/11/2023",
      employeeName: "Rajeeve Mehta",
      shift1: "09:00-11:00am",
      shift2: "09:00-11:00am",
      shift3: "09:00-11:00am",
    },
  ];

  // const viewShiftManagementAction = () => {
  //   return (
  //     <button
  //       type="button"
  //       className="h-[25px] w-[25px] flex items-center justify-center rounded-full border border-[#E5E9EB] bg-white hover:bg-[#E5E9EB] mt-[5px]"
  //     >
  //       <BsThreeDotsVertical />
  //     </button>
  //   );
  // };
  const columnDefs = [
    // {
    //   headerName: "Actions",
    //   field: "id",
    //   cellRenderer: viewShiftManagementAction,
    // },
    {
      headerName: "Date",
      field: "date",
      filter: true,
    },
    {
      headerName: "Employee Name",
      field: "employeeName",
      filter: true,
    },
    {
      headerName: "Shift 1",
      field: "shift1",
      filter: true,
    },
    {
      headerName: "Shift 2",
      field: "shift2",
      filter: true,
    },
    {
      headerName: "Shift 3",
      field: "shift3",
      filter: true,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between my-4">
        <div className="text-[22px] leading-[27.72px] tracking-[-0.8px] text-[#07090D] font-semibold">
          Worker
        </div>
        <button
          className="h-10 w-[133px] px-3 rounded-md flex items-center gap-3 bg-base-primary hover:bg-base-primary_hover duration-300 ease-[0.3s]"
          onClick={() => setIsOpenCreateSwap(true)}
        >
          <div className="size-[19px] flex items-center justify-center text-base-primary bg-white rounded-full">
            <MdSwapCalls />
          </div>
          <div className="text-[16px] leading-5 font-medium text-white">
            Swap Shift
          </div>
        </button>
        {isOpenCreateSwap ? (
          <CreateShiftSwap close={() => setIsOpenCreateSwap(false)} />
        ) : null}
      </div>
      <CustomeTable
        size="h-[60vh]"
        tableName={"Shifts List Table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default ShiftManagement;
