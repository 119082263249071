import React, { useState } from "react";
import { OutlineButton } from "../../components";
import AddButton from "../../components/buttons/AddButton";
import AllRoomType from "./AllRoomType";

import ConnectedRooms from "./ConnectedRooms";
import AddRoomType from "./AddRoomType";

const AllRooms = () => {
  const [isAddRoomType, setIsAddRoomType] = useState(false);
  const [isConnectedRooms, setIsConnectedRooms] = useState(false);
  const handleConnectedRooms = () => {
    setIsConnectedRooms(true);
    setIsAddRoomType(false);
  };
  const handleAddRoomType = () => {
    setIsAddRoomType(true);
    setIsConnectedRooms(false);
  };
  return (
    <div>
      <div className="flex items-center justify-end">
        <div className="flex items-center gap-4">
          <OutlineButton
            text={"Connected Room"}
            onClick={handleConnectedRooms}
          />
          <AddButton text={"Add Room Type"} onClick={handleAddRoomType} />
        </div>
      </div>
      {isAddRoomType ? (
        <AddRoomType close={() => setIsAddRoomType(false)} />
      ) : null}
      {isConnectedRooms ? (
        <ConnectedRooms close={() => setIsConnectedRooms(false)} />
      ) : null}
      <div className="mt-4">
        <AllRoomType />
      </div>
    </div>
  );
};

export default AllRooms;
