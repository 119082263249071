import React from "react";
import { useDispatch } from "react-redux";
import { deleteRoomAction } from "../../actions/pms/roomdelete";
import { DeleteButton, EditButton } from "../../components";

const RoomAction = ({ roomId, hotelId }) => {
  const dispatch = useDispatch();

  const deleteConfirm = () => {
    dispatch(deleteRoomAction(roomId, hotelId));
  };

  const cancelDelete = () => {};

  return (
    <div className="flex items-center h-full gap-4">
     <EditButton onClick={""}/>
     <DeleteButton onClick={deleteConfirm}/>
    </div>
  );
};

export default RoomAction;
