import React from "react";

const SubmitButton = ({
  onClick = () => {},
  type = "submit",
  className = "h-10 w-fit text-sm ",
  loading = false,
  text,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`${className} flex items-center justify-center px-6 rounded-md bg-base-primary hover:bg-base-primary_hover shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] font-medium text-[#F6F8F9] transition-all duration-300 ease-[0.3s] capitalize`}
    >
      <div>{text}</div>
      <div className="absolute left-1/2 -translate-x-1/2">
        <div
          className={`${
            loading ? "" : "hidden"
          }  h-5 w-5 border-[2px] rounded-full border-white border-t-[#ffffff23] animate-spin`}
        ></div>
      </div>
    </button>
  );
};

export default SubmitButton;
