import React from "react";
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next";
import CustomModal from "../CustomModal";
import CancelButton from "../buttons/CancelButton";
import SubmitButton from "../buttons/SubmitButton";

export default function SaveOrNot({ formSubmit, open, close }) {
  const { t } = useTranslation();
  //agree to delete
  const agree = () => {
    formSubmit();
    close();
    registerEvent("agree to delete", "delete or not delete modal");
  };

  //disagree to delete
  const disagree = () => {
    close();
    registerEvent("disagree to delete", "delete or not delete modal");
  };

  return (
    <>
      {open ? (
        <CustomModal close={close}>
          <div className="w-80">
            <h2 className="text-[22px] leading-[27.72px] font-semibold tracking-[-0.8px] text-[#07090D]">
              Please Confirm Changes
            </h2>
            <p className="text-[14px] leading-[26px] font-medium text-[#6E7C87]">
              {t("1117")}
            </p>
            <div className="flex items-center justify-between mt-6">
              <CancelButton onClick={disagree} />
              <SubmitButton
                type="button"
                text={t("87")}
                sizing={"w-20 h-[37px]"}
                onClick={agree}
              />
            </div>
          </div>
        </CustomModal>
      ) : null}
    </>
  );
}
