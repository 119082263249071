import React, { useState } from "react";
import Seperator from "../../components/layouts/Seperator";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import IngredientsFor from "./IngredientsFor";
import SelectBox from "../../components/layouts/SelectBox";
import ShiftButton from "../../components/buttons/ShiftButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import SubmitButton from "../../components/buttons/SubmitButton";

const MapIngredients = (props) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  // const navigate = useNavigate();

  //set kitchen data and redirect to another page
  // const setKitchenAndRedirect = (link) => {
  //   if (link) {
  //     navigate(link);
  //   }
  // };

  const data = {
    heading: t("1091"),
    seperator: [
      {
        text: "Kitchen Management",
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      {
        link: ROUTES.KITCHEN_MENU,
        text: t("Menu"),
      },
      {
        text: t("1085"),
      },
    ],
  };
  const row = [
    {
      name: "Salt",
      type: "Grocery",
      availableQuantity: "5KG",
      shelfLife: "6 Months",
      purchaseFrequency: "Weekly",
    },
    {
      name: "Salt",
      type: "Grocery",
      availableQuantity: "5KG",
      shelfLife: "6 Months",
      purchaseFrequency: "Weekly",
    },
    {
      name: "Salt",
      type: "Grocery",
      availableQuantity: "5KG",
      shelfLife: "6 Months",
      purchaseFrequency: "Weekly",
    },
    {
      name: "Salt",
      type: "Grocery",
      availableQuantity: "5KG",
      shelfLife: "6 Months",
      purchaseFrequency: "Weekly",
    },
    {
      name: "Salt",
      type: "Grocery",
      availableQuantity: "5KG",
      shelfLife: "6 Months",
      purchaseFrequency: "Weekly",
    },
    {
      name: "Salt",
      type: "Grocery",
      availableQuantity: "5KG",
      shelfLife: "6 Months",
      purchaseFrequency: "Weekly",
    },
  ];
  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      checkboxSelection: true,
    },
    {
      headerName: "Type",
      field: "type",
    },
    {
      headerName: "Available Quantity",
      field: "availableQuantity",
    },
    {
      headerName: "Shelf Life",
      field: "shelfLife",
    },
    {
      headerName: "Purchase Frequency",
      field: "purchaseFrequency",
    },
  ];
  return (
    <div className="page-container">
      <div className="flex justify-between items-end">
        <Seperator data={data} />
      </div>
      <div className="w-full border border-[#D0D5DD] rounded-[10px] bg-white my-6 p-6">
        <div className="flex items-center gap-4">
          <div className="text-[26px] leading-[32.72px] text-[#07090D] font-bold">
            Inventory
          </div>
          <div className="h-12 w-[276px] flex items-center border border-[#E5E9EB] rounded-lg text-[16px] leading-6 font-normal shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] px-4">
            Department: kitchen
          </div>
          <div className="h-12 w-[428px] flex items-center border border-[#E5E9EB] rounded-lg text-[16px] leading-6 font-normal shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] px-4">
            Sub Kitchen Inventory Type: Food Ingrediants
          </div>
        </div>
        <div className="mt-6">
          <div className="grid grid-cols-10 w-full">
            {/* Left side */}
            <div className="col-span-4">
              <SelectBox
                tableSize="h-[60vh]"
                label={"List of All Ingrediants"}
                heading={"Ingrediants"}
                btnName={"Add Ingrediants"}
                columnDefs={columnDefs}
                rowData={row}
                setSelectedRows={setSelectedRows}
              />
            </div>

            {/* Buttons for add and remove */}
            <div className="col-span-2 flex flex-col items-center justify-center gap-6">
              <ShiftButton
                name={"Add"}
                icon={<AiOutlineDoubleRight size={18} />}
              />
              <ShiftButton
                name={"Remove"}
                icon={<AiOutlineDoubleLeft size={18} />}
              />
            </div>

            {/* Right side */}
            <div className="col-span-4 w-full">
              <SelectBox
                tableSize="h-[60vh]"
                label={"Ingrediants For Chicken Wings"}
                heading={"Selected Ingrediants"}
                showBtn={false}
                columnDefs={columnDefs}
                rowData={row}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end mt-6 mb-3">
          <SubmitButton
            type="button"
            text={"Save"}
            className="h-12 w-[192px]"
          />
        </div>
      </div>
      <IngredientsFor />
    </div>
  );
};

export default MapIngredients;
