import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { USER_LOGOUT } from "../actions/types/ownerAuth";
import {
  ROUTES,
} from "../constants";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { owner } = useSelector((state) => state.Authenticate);
  //redirect to signup the user
  const cleanLocalStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: USER_LOGOUT });
  };

  return (
    <>
      <div className="relative flex flex-col">
        <div className="min-h-[95vh] relative w-full">
          <header className="sticky top-0 left-0 w-full flex justify-between items-center z-50 max-w-7xl mx-auto px-4 py-2 sm:px-8 md:px-12 2xl:px-0 bg-transparent ">
            <Link to={ROUTES.LANDING_PAGE}>
              <h1 className="text-3xl text-black font-extrabold py-2">
                {t("783")}
              </h1>
            </Link>
            <div className="flex">
              <Link to={owner ? ROUTES.DASHBOARD : ROUTES.LOGIN}>
                <div className="mr-4 sm:mr-8 text-base-primary p-2 font-semibold">
                  {t("220")}
                </div>
              </Link>
              <Link to={ROUTES.REGISTER} onClick={cleanLocalStorage}>
                <div className="text-base-primary p-2 font-semibold">
                  {t("784")}
                </div>
              </Link>
            </div>
          </header>
          <main className="relative w-full flex justify-center flex-col items-start px-4 md:mt-12 max-w-7xl mx-auto sm:px-8 md:px-12 2xl:px-0 min-h z-20 pb-8">
            <h1 className="text-3xl md:text-4xl font-semibold">
              {t("785")}
              <br></br>
              {t("786")}
              <br></br>
              {t("787")}
              <br></br>
              {t("788")}
            </h1>
            <p className="md:text-xl my-8">
              {t("789")}
              <br></br>
              {t("790")}
              <br></br>
              {t("791")}
            </p>
            <div className="flex flex-wrap">
              <Link to={ROUTES.REGISTER} onClick={cleanLocalStorage}>
                <div className="py-3 px-4 rounded-3xl bg-base-primary text-[#FFFFFF] hover:shadow">
                  {t("792")}
                </div>
              </Link>
            </div>
          </main>

          {/* waves */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="absolute bottom-0 right-0 w-[105%] bg-transparent opacity-100 text-[#FFFFFF] z-10"
          >
            <path
              fill="#fff"
              fillOpacity={1}
              d="M0,256L40,234.7C80,213,160,171,240,138.7C320,107,400,85,480,96C560,107,640,149,720,176C800,203,880,213,960,218.7C1040,224,1120,224,1200,202.7C1280,181,1360,139,1400,117.3L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
    </>
  );
};
export default LandingPage;
