import React, { useState } from "react";
import { CustomeTable } from "../../components";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import AddSpa from "./AddSpa";
import { registerEvent } from "../../logs";
import SubmitButton from "../../components/buttons/SubmitButton";

const SpaConfig = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenSpa, setIsOpenSpa] = useState(false);

  const addSpaModal = () => {
    setIsOpenSpa(true);
    registerEvent("click add spa button", "all spa page");
  };

  //close add Laundry modal
  const close = () => {
    setIsOpenSpa(false);
    registerEvent("close add Laundry item component", "add Laundry page");
  };
  const tableData = [
    {
      id: "1",
      image: "",
      serviceName: "Massage Therapy",
      description:
        "Massage therapy is the practice of kneading or manipulating a person's muscles and other soft-tissue in order to improve their wellbeing or health.",
      price: "$5",
    },
    {
      id: "2",
      image: "",
      serviceName: "Massage Therapy",
      description:
        "Massage therapy is the practice of kneading or manipulating a person's muscles and other soft-tissue in order to improve their wellbeing or health.",
      price: "#5",
    },
    {
      image: "",
      serviceName: "Massage Therapy",
      description:
        "Massage therapy is the practice of kneading or manipulating a person's muscles and other soft-tissue in order to improve their wellbeing or health.",
      price: "$6",
    },
    {
      image: "",
      serviceName: "Massage Therapy",
      description:
        "Massage therapy is the practice of kneading or manipulating a person's muscles and other soft-tissue in order to improve their wellbeing or health.",
      price: "$8",
    },
    {
      image: "",
      serviceName: "Massage Therapy",
      description:
        "Massage therapy is the practice of kneading or manipulating a person's muscles and other soft-tissue in order to improve their wellbeing or health.",
      price: "#4",
    },
    {
      image: "",
      serviceName: "Massage Therapy",
      description:
        "Massage therapy is the practice of kneading or manipulating a person's muscles and other soft-tissue in order to improve their wellbeing or health.",
      price: "$8",
    },
  ];

  // show image
  const showItemImage = () => {
    return (
      <div className="flex items-center justify-start">
        <img
          src={`https://w7.pngwing.com/pngs/972/557/png-transparent-blue-dress-shirt-t-shirt-dress-shirt-dress-shirt-tshirt-blue-image-file-formats-thumbnail.png`}
          alt=""
          className="h-9 w-10 rounded-lg bg-[#00000099] my-[2px]"
        />
      </div>
    );
  };
  function setItemPrice({ value }) {
    return (
      <div className="h-full flex items-center">
        {/* <input
          type="text"
          className="h-8 w-full accent-current rounded-md placeholder:flex placeholder:items-center placeholder:justify-center px-2"
          placeholder="price"
        /> */}
        <div className="w-12 h-[30px] flex items-center justify-center border border-base-primary rounded bg-[#F6F6F6] text-base-primary">
          {value}
        </div>
      </div>
    );
  }
  const handleActions = () => {
    return (
      <div className="h-full flex items-center gap-4">
        <EditButton />
        <DeleteButton />
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Image",
      field: "image",
      minWidth: 100,
      filter: true,
      cellRenderer: showItemImage,
    },
    {
      headerName: "Service Name",
      field: "serviceName",
      filter: true,
    },
    {
      headerName: "Discription",
      field: "description",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
      cellRenderer: setItemPrice,
    },
    {
      headerName: "Actions",
      field: "",
      minWidth: 180,
      cellRenderer: handleActions,
    },
  ];
  return (
    <>
      {isOpenSpa ? <AddSpa closeAddSpa={close} /> : null}
      <div className="border border-[#E4E4E7] rounded-[10px] bg-white p-6 mt-6">
        <div className="flex items-center justify-between">
          <h2 className="text-[30px] leading-[37.8px] font-bold text-[#07090D] tracking-[-1.5%]">
            Spa Configuration
          </h2>
          <div className="flex items-center gap-4">
            {/* <CancelButton onClick={closeLaundryConfig} /> */}
            <SubmitButton
              type="button"
              text={"Add Spa"}
              onClick={addSpaModal}
              sizing={"h-10 w-[119px]"}
            />
          </div>
        </div>
        {/* <h2 className="text-[18px] leading-5 font-medium text-[#344054] my-4">
          Create Spa Price List
        </h2> */}
        <div className="mt-6">
          <CustomeTable
            tableName={"Create Spa Price List"}
            columnDefs={columnDefs}
            rowData={tableData}
            setSelectedRows={setSelectedRows}
          />
        </div>
      </div>
    </>
  );
};

export default SpaConfig;
