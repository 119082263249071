import React from "react";
import GotoReport from "./GotoReport";
import { useTranslation } from "react-i18next";
import { SelectDate } from "../../components";

const DashBoardHead = (props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col sm:flex-row justify-between mb-6 gap-4">
      <div>
        <h2 className="font-bold tracking-[-0.8px] text-[#18181B] leading-[38px] text-xl sm:text-2xl md:text-3xl">
          {t("2")}
        </h2>
        <p className="text-[#00000099] font-normal text-base md:text-[20px] leading-[25px] tracking-[-0.8px]">
          {t("3")}
        </p>
      </div>
      <div className="flex gap-4 items-end">
        <SelectDate  />
        {/* <div className="relative w-[308px] h-10 flex flex-row justify-center items-center gap-3 border border-[#E4E4E7] rounded-md box-border bg-white text-[#18181B] p-3">
          <input
            value={props.date}
            onChange={(e) => props.setDate(e.target.value)}
            type={"date"}
            className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
          />
          <input
            value={props.date}
            onChange={(e) => props.setDate(e.target.value)}
            type={"date"}
            className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
          />
        </div> */}
        <GotoReport />
      </div>
    </div>
  );
};

export default DashBoardHead;
