import React from "react";
import PropertyDetails from "./property-details/PropertyDetails";
import TaxCategories from "./taxes/TaxCategories";
import SettingsHeader from "./Tabs";
import WifiConfig from "./wifi/WifiConfig";
import { useSelector } from "react-redux";
import PageLoader from "../../components/loader/PageLoader";
import PropertyInfo from "./property-details/PropertyInfo";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Seperator from "../../components/layouts/Seperator";
import EmployeePermissions from "./employepermission";
import PaymentModes from "./payment-modes";
import BankAccountConfig from "./bank-account";
import AutoSyncBookingConfig from "./booking-config/AutoSyncBookingConfig";
import Employees from "./employepermission/Employees";
import CorporateAccount from "./bank-account/CorporateAccount";
import LocalTravelAgent from "./travel-agent";

const Settings = () => {
  const { t } = useTranslation();
  const { index } = useParams();
  const { hotel } = useSelector((state) => state.Hotel);

  return (
    <div className="page-container">
      <Seperator
        data={{
          heading: t("48"),
          seperator: [
            {
              text: t("344"),
            },
            {
              text: t("48"),
            },
          ],
        }}
      />

      <SettingsHeader params={index} />
      {hotel && hotel?._id ? (
        <>
          {+index === 1 ? <PropertyDetails hotel={hotel} /> : null}
          {+index === 2 ? <PropertyInfo hotel={hotel} /> : null}
          {+index === 3 ? <Employees hotel={hotel} /> : null}
          {/* {+index === 3 ? <ContactInfo hotel={hotel} /> : null} */}
          {+index === 4 ? <PaymentModes hotel={hotel} /> : null}
          {+index === 5 ? <BankAccountConfig hotel={hotel} /> : null}
          {+index === 6 ? <AutoSyncBookingConfig hotel={hotel} /> : null}
          {+index === 7 ? <CorporateAccount hotel={hotel} /> : null}
          {/* {+index === 7 ? <BookingSourceConfig hotel={hotel} /> : null} */}
          {+index === 8 ? <LocalTravelAgent hotel={hotel} /> : null}
          {+index === 9 ? <TaxCategories hotel={hotel} /> : null}
          {+index === 10 ? <WifiConfig hotel={hotel} /> : null}
          {+index === 11 ? <EmployeePermissions hotel={hotel} /> : null}
          {/* {+index === 11 ? <LoginCredentials hotel={hotel} /> : null} */}
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default Settings;
