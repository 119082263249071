/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { addRoomAction, updateRoomAction } from "../../actions/pms/room";
import { connect } from "react-redux";
import AddRoomTypeCheckBox from "./RoomTypeCheckBox";
import { BED_TYPES, LENGTH_UNITS} from "../../constants";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { getAllAssetAction } from "../../actions/pms/assetget";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import RoomTypeRoomNumbers from "./RoomTypeRoomNumbers";
import RoomTypeRoomNumber from "./RoomTypeRoomNumber";
import RoomTypeBedTypes from "./RoomTypeBedTypes";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import { notifyError, notifySuccess } from "../../components/alert";
import { ADD_ROOM_RESET, UPDATE_ROOM_RESET } from "../../actions/types/room";
import { clearError } from "../../actions/clearError";
import { getAllRoomAction } from "../../actions/pms/roomget";
import FormSubmitButton from "../../components/buttons";
import UnitsMeassureInputfield from "../../components/layouts/UnitsMeassureInputfield";
import ToolTip from "../../components/ToolTip";
import CloseButton from "../../components/buttons/CloseButton";
import { OutlineButton } from "../../components";
import { IoSearchOutline } from "react-icons/io5";
import SelectTax from "../../components/modals/SelectTax";

const AddRoomType = (props) => {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isWithBalcony, setIsWithBalcony] = useState(false);
  const [isRoomWithAC, setIsRoomWithAC] = useState(false);
  const [smokingAllow, setSmokingAllow] = useState(false);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(1);
  const [bedDetails, setBedDetails] = useState([
    { bedNumber: 1, details: Object.keys(BED_TYPES)[0] },
  ]);

  const [hotelRooms, setHotelRooms] = useState([]);
  const [assets, setAssets] = useState([]);
  const [ourAssets, setOurAssets] = useState([]);

  //states for images
  const [images, setImages] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);
  // Add new state
  const [roomTypeName, setRoomTypeName] = useState("");
  const [numberOfRooms, setNumberOfRooms] = useState("");
  const [numberOfBeds, setNumberOfBeds] = useState(1);
  const [roomTariff, setRoomTariff] = useState("");
  const [roomLength, setRoomLength] = useState();
  const [roomBreadth, setRoomBreadth] = useState();
  // const [addRoomAmenities, setAddRoomAmenities] = useState("");
  const [selectRoomLength, setSelectRoomLength] = useState([]);
  const [selectRoomBreadth, setSelectRoomBreadth] = useState([]);
  const [miniBarConfigValue, setMiniBarConfigValue] = useState([]);
  // new 4-9-2024
  const [allBedSizes, setAllBedSizes] = useState([]);
  const [allTypeBedSizes, setAllTypeBedSizes] = useState([]);
  const [bedNo, setBedNo] = useState([]);
  const [minRoomTariff, setMinRoomTariff] = useState("");
  const [maxRoomTariff, setMaxRoomTariff] = useState("");
  const [roomMinibarList, setRoomMinibarList] = useState([]);
  const [addTax, setAddTax] = useState([]);
  const [isViewTax, setIsViewTax] = useState(false);
  //assets
  useEffect(() => {
    if (
      props?.assets &&
      props?.assets?.assets &&
      props?.assets?.assets?.length &&
      !props?.assets?.loading
    ) {
      setOurAssets(props?.assets?.assets);
    }
  }, [props?.assets]);

  useEffect(() => {
    if (props?.hotel && props?.hotel?.hotel && props?.hotel?.hotel?._id)
      props.getAsset(props?.hotel?.hotel?._id);
  }, []);

  const saveRoomDetails = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const closeComponent = () => props.close();

  const sumbitFromDate = async () => {
    const formData = {
      hotelId: props?.hotel?.hotel?._id,
      roomType: roomTypeName,
      isRoomWithAC,
      isWithBalcony,
      isSmokingRoom: smokingAllow,
      adults,
      children,
      net: roomTariff,
      roomLength,
      roomBreadth,
      bedDetails,
      hotelRooms,
      images,
      assets,
      ourAssets,
    };
    if (isUpdating)
      props.updateRoom({ roomId: props?.updateData?._id, ...formData });
    else props.addRoom(formData);
  };

  //props isupdating
  useEffect(() => {
    if (props?.updateData && props?.updateData?._id) {
      setIsUpdating(true);
      setIsRoomWithAC(props?.updateData?.isRoomWithAC);
      setSmokingAllow(props?.updateData?.isSmokingRoom);
      setIsWithBalcony(props?.updateData?.isWithBalcony);
      setRoomTypeName(props?.updateData?.roomType);
      setAdults(props?.updateData?.adults);
      setChildren(props?.updateData?.children);
      setRoomTariff(props?.updateData?.net);
      setRoomLength(props?.updateData?.roomLength);
      setRoomBreadth(props?.updateData?.roomBreadth);
      setBedDetails(props?.updateData?.bedDetails);
      setHotelRooms(props?.updateData?.hotelRooms);
      setNumberOfRooms(props?.updateData?.hotelRooms?.length);
      setAssets(props?.updateData?.assetsConsumptionInBooking);
      setImages(props?.updateData?.images);
    }
  }, [props?.updateData]);

  useEffect(() => {
    if (props?.update?.isupdated || props?.room?.isadded) {
      setIsUpdating(false);
      setIsRoomWithAC(false);
      setSmokingAllow(false);
      setIsWithBalcony(false);
      setRoomTypeName("");
      setAdults(0);
      setChildren(0);
      setRoomTariff(0);
      setRoomLength(0);
      setRoomBreadth(0);
      setBedDetails(0);
      setHotelRooms([]);
      setNumberOfRooms(0);
      setAssets("");
      setImages("");
    }
    if (props?.room?.isadded) {
      props?.getRooms(props?.hotel?.hotel?._id);
      notifySuccess(t("586"));
      props?.addreset();
      closeComponent();
    }

    if (props.update?.isupdated) {
      props?.getRooms(props?.hotel?.hotel?._id);
      notifySuccess(t("588"));
      props?.updatereset();
      closeComponent();
    }

    if (props?.room?.error || props?.update?.error) {
      notifyError(t("589"));
      props?.clear();
    }
  }, [props?.room, props.update]);

  // const handleRoomTypeBeds = (index) => {};

  const CHOSE_BEDS_SIZES = {
    ALL_BEDS_DIFFRENT_SIZE: "ALL BEDS DIFFRENT SIZE",
    ALL_BEDS_SAME_SIZE: "ALL BEDS SAME SIZE",
  };
  const getBedSizes = (e) => {
    const allBedSizes = e.target.value;
    const allTypeBedSizes = e.target.value;
    const bedNumber = e.target.value;
    setAllBedSizes(allBedSizes);
    setAllTypeBedSizes(allTypeBedSizes);
    setBedNo(bedNumber);
  };
  console.log("all bed sizes", bedNo, allBedSizes, allTypeBedSizes);
  return (
    <div className="relative border border-[#E5E9EB] rounded-lg bg-white overflow-auto mx-auto mt-6 px-8 py-6">
      <div className="text-center font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
        {t("69")}
      </div>
      <div className="absolute right-7 top-8">
        <CloseButton onClick={closeComponent} />
      </div>

      <div className="text-[20px] leading-5 font-bold mt-6">{t("515")}</div>
      <form onSubmit={saveRoomDetails}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-4">
          <Inputfield
            label={t("88")}
            message={t("590")}
            placeholder={t("591")}
            value={roomTypeName}
            onChange={(e) => setRoomTypeName(e.target.value)}
            required={true}
            minLength={3}
            maxLength={100}
          />

          <RoomTypeRoomNumbers
            hotelRooms={hotelRooms}
            setHotelRooms={setHotelRooms}
            value={numberOfRooms}
            setValue={setNumberOfRooms}
          />
          {[...Array(+numberOfRooms)]?.map((ro, index) => (
            <RoomTypeRoomNumber
              key={index}
              hotelRooms={hotelRooms}
              setHotelRooms={setHotelRooms}
              index={index}
            />
          ))}

          <RoomTypeBedTypes
            value={numberOfBeds}
            setValue={setNumberOfBeds}
            setBedType={setBedDetails}
            options={BED_TYPES}
          />

          <div className="">
            {[...Array(+numberOfBeds)].map((bd, index) => (
              <button
                key={bd}
                type="button"
                onClick={getBedSizes}
                className="capitalize text-sm font-medium w-fit h-fit mb-1 py-0 first:pl-0 px-2  border-r border-[#D0D5DD] focus:text-base-primary active:text-base-primary"
              >{`Bed ${index + 1}`}</button>
            ))}
            <div className="flex items-center w-full leading-6 font-normal text-[#667085]">
              <select
                value={allBedSizes}
                onChange={getBedSizes}
                className="w-[45%] text-[10px] border-r-0 rounded-r-none px-[6px]"
              >
                {Object.keys(CHOSE_BEDS_SIZES).map((item) => {
                  return (
                    <option value={item} key={item} className="capitalize ">
                      {CHOSE_BEDS_SIZES[item].replace("_", " ")}
                    </option>
                  );
                })}
              </select>
              <select
                value={allTypeBedSizes}
                onChange={getBedSizes}
                className="w-[55%] rounded-l-none px-[6px]"
              >
                {Object.keys(BED_TYPES).map((item) => {
                  return (
                    <option value={item} key={item} className="capitalize ">
                      {BED_TYPES[item].replace("_", " ")}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {/* {[...Array(+numberOfBeds)].map((bd, index) => (
            <RoomTypeBeds
              key={index}
              options={BED_TYPES}
              value={bedDetails}
              setValue={setBedDetails}
              index={index}
            />
          ))} */}

          <div className="grid grid-cols-1 items-end w-full">
            <div className="flex items-center justify-between">
              <label>{t("597")}</label>
              <ToolTip Message={t("598")} />
            </div>
            <div className="flex items-center w-full">
              <input
                type="text"
                min={0}
                max={10000}
                value={minRoomTariff}
                placeholder="Min Room Tariff"
                className="rounded-r-none border-r-0"
                onChange={(e) => setMinRoomTariff(e.target.value)}
              />
              <input
                type="text"
                min={0}
                max={10000}
                value={maxRoomTariff}
                className="rounded-l-none"
                placeholder="Min Room Tariff"
                onChange={(e) => setMaxRoomTariff(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="text-[20px] leading-5 font-bold">{t("518")}</div>
        <div className="flex items-center gap-4 my-4">
          <AddRoomTypeCheckBox
            label={t("85")}
            value={isRoomWithAC}
            setValue={setIsRoomWithAC}
          />
          <AddRoomTypeCheckBox
            label={t("86")}
            value={isWithBalcony}
            setValue={setIsWithBalcony}
          />
          <AddRoomTypeCheckBox
            label={t("519")}
            value={smokingAllow}
            setValue={setSmokingAllow}
          />
        </div>

        <div className="text-[20px] leading-5 font-bold">{t("124")}</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-4">
          <UnitsMeassureInputfield
            label={t("97")}
            message={t("600")}
            placeholder={t("601")}
            value={roomLength}
            onChange={(e) => setRoomLength(e.target.value)}
            min={1}
            max={100}
            required={true}
            options={LENGTH_UNITS}
            selectValue={selectRoomLength}
            onChangeSelect={(e) => setSelectRoomLength(e.target.value)}
          />
          <UnitsMeassureInputfield
            label={t("99")}
            message={t("100")}
            placeholder={t("602")}
            value={roomBreadth}
            onChange={(e) => setRoomBreadth(e.target.value)}
            min={1}
            max={100}
            required={true}
            options={LENGTH_UNITS}
            selectValue={selectRoomBreadth}
            onChangeSelect={(e) => setSelectRoomBreadth(e.target.value)}
          />
        </div>
        <div className="text-[20px] leading-5 font-bold">{t("125")}</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-4">
          <Inputfield
            type="number"
            label={t("603")}
            message={t("604")}
            placeholder={t("605")}
            value={adults}
            onChange={(e) => setAdults(e.target.value)}
            min={0}
            max={4}
            required={true}
          />
          <Inputfield
            type="number"
            label={t("606")}
            message={t("607")}
            placeholder={t("608")}
            value={children}
            onChange={(e) => setChildren(e.target.value)}
            min={1}
            max={6}
            required={true}
          />
        </div>
        <div className="text-[20px] leading-5 font-bold">{t("126")}</div>
        <div className="grid grid-cols-3 items-end gap-6 mt-4">
          {/* <div className="">
            <div className="flex items-center justify-between">
              <label>Amenities</label>
              <ToolTip
                Message={"select amenities you have to add your hotel..."}
              />
            </div>
            <button
              type="button"
              className="h-12 w-full border border-[#D0D5DD] rounded-lg shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] text-base text-[#667085] flex items-center pl-4"
              onClick={() => setIsMultipleSelectModal(true)}
            >
              Add Room Amenities
            </button>
          </div> */}

          <SelectFromObject
            label={t("MiniBar Configuration")}
            value={miniBarConfigValue}
            setValue={setMiniBarConfigValue}
            toolTipMessage={t("110")}
            options={{}}
          />
          {/* <button
            type="button"
            className="h-10 w-fit px-4 flex items-center justify-center gap-3 rounded-md bg-base-primary hover:bg-base-primary_hover shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] text-sm font-medium text-[#F6F8F9] transition-all duration-[0.3s] ease-[0s] capitalize"
            onClick={() => setIsMultipleSelectModal(true)}
          >
            <GoMultiSelect size={24} className="font-bold" />
            <div className="">Select Amenites</div>
          </button> */}
          <OutlineButton
            sizing={"h-12 w-[241px]"}
            text={"View Room Amenities List"}
          />
        </div>
        {/* {isMultipleSelectModal ? (
          <SelectAmenities
            options={ROOM_AMENITIES}
            selectedItems={selectedAmenities}
            setSelectedItems={setSelectedAmenities}
            open={isMultipleSelectModal}
            close={() => setIsMultipleSelectModal(false)}
          />
        ) : null}

        <div className="relative w-full flex flex-wrap items-start gap-4 my-4 pt-12 overflow-y-auto dropdown-scroll">
         
          {selectedAmenities?.map((item) => {
            return <>
              <div className={`absolute top-2 text-[20px] leading-5 font-bold`}>
              Selected Amenities
            </div>
              <span
                className="cursor-pointer select-none h-12 px-4 flex items-center border border-[#D0D5DD] rounded-lg"
                key={item}
              >
                {ROOM_AMENITIES[item].replace("", " ")}
              </span>
              </>
          })}
        </div> */}
        <div className="text-[20px] leading-5 font-bold my-4">
          {"Room Mini Bar List"}
        </div>
        <div className="grid grid-cols-3 items-end gap-6">
          <SelectFromObject
            label={t("Select Room Mini Bar List")}
            value={roomMinibarList}
            setValue={setRoomMinibarList}
            toolTipMessage={t("110")}
            options={{}}
          />
          <OutlineButton
            sizing={"h-12 w-[241px]"}
            text={"View Room Mini Bar List"}
          />
        </div>
        <div className="text-[20px] leading-5 font-bold my-4">{"Add Tax"}</div>
        <div className="grid grid-cols-3 items-end gap-6">
          <div className="relative">
            <Inputfield
              label={"Add Tax"}
              value={addTax}
              onChange={(e) => setAddTax(e.target.value)}
              className={"pl-12"}
            />
            <IoSearchOutline size={24} className="absolute top-8 left-4" />
          </div>
          <OutlineButton
            sizing={"h-12 w-[241px]"}
            text={"View Tax"}
            onClick={() => setIsViewTax(true)}
          />
        </div>
        {isViewTax ? <SelectTax close={() => setIsViewTax(false)} /> : null}
        <div className=" flex w-full justify-end my-6">
          <FormSubmitButton
            loading={props?.room?.loading || props?.update?.loading}
            text={t("69")}
          />
        </div>
      </form>

      {open ? (
        <SaveOrNot
          close={() => setOpen(false)}
          isopen={open}
          formSubmit={sumbitFromDate}
        />
      ) : null}
      {props?.delete && props?.delete?.loading ? (
        <BackGroundLoadSpinner />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  room: state.AddRoom,
  update: state.UpdateRoom,
  delete: state.DeleteRoom,
  hotel: state.Hotel,
  assets: state.AllAsset,
});

const mapDispatchToProps = (dispatch) => ({
  addRoom: (data) => dispatch(addRoomAction(data)),
  updateRoom: (data) => dispatch(updateRoomAction(data)),
  getAsset: (hotelId) => dispatch(getAllAssetAction(hotelId)),
  addreset: () => dispatch({ type: ADD_ROOM_RESET }),
  updatereset: () => dispatch({ type: UPDATE_ROOM_RESET }),
  clear: () => dispatch(clearError()),
  getRooms: (hotelId) => dispatch(getAllRoomAction(hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRoomType);
