export const ADD_HOTEL_REQUEST = "ADD_HOTEL_REQUEST";
export const ADD_HOTEL_SUCCESS = "ADD_HOTEL_SUCCESS";
export const ADD_HOTEL_FAIL = "ADD_HOTEL_FAIL";
export const ADD_HOTEL_RESET = "ADD_HOTEL_RESET";

export const UPDATE_HOTEL_REQUEST = "UPDATE_HOTEL_REQUEST";
export const UPDATE_HOTEL_SUCCESS = "UPDATE_HOTEL_SUCCESS";
export const UPDATE_HOTEL_FAIL = "UPDATE_HOTEL_FAIL";
export const UPDATE_HOTEL_RESET = "UPDATE_HOTEL_RESET";

export const GET_HOTEL_REQUEST = "GET_HOTEL_REQUEST";
export const GET_HOTEL_SUCCESS = "GET_HOTEL_SUCCESS";
export const GET_HOTEL_FAIL = "GET_HOTEL_FAIL";

export const GET_HOTELS_REQUEST = "GET_HOTELS_REQUEST";
export const GET_HOTELS_SUCCESS = "GET_HOTELS_SUCCESS";
export const GET_HOTELS_FAIL = "GET_HOTELS_FAIL";

//verify property
export const VERIFY_PROPERTY_REQUEST = "VERIFY_PROPERTY_REQUEST";
export const VERIFY_PROPERTY_SUCCESS = "VERIFY_PROPERTY_SUCCESS";
export const VERIFY_PROPERTY_FAIL = "VERIFY_PROPERTY_FAIL";
export const VERIFY_PROPERTY_RESET = "VERIFY_PROPERTY_RESET";

export const GET_HOTEL_CONFIG_REQUEST = "GET_HOTEL_CONFIG_REQUEST";
export const GET_HOTEL_CONFIG_SUCCESS = "GET_HOTEL_CONFIG_SUCCESS";
export const GET_HOTEL_CONFIG_FAIL = "GET_HOTEL_CONFIG_FAIL";