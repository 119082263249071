/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { clearError } from "../../actions/clearError";
import {
  addEmployeeAction,
  getAllHotelEmployeeAction,
} from "../../actions/pms/employee";
import { connect, useSelector } from "react-redux";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import { DAYS_NAME, DEPARTMENTS,  GENDERS, POSITIONS } from "../../constants";
import SelectFromArray from "../../components/layouts/SelectFromArray";
import PhoneWithCode from "../../components/layouts/PhoneWithCode";
import { getToday, getYearAgoToday } from "../../utils/dateAndTime";
import { notifyError, notifySuccess } from "../../components/alert";
import {
  ADD_EMPLOYEE_RESET,
  UPDATE_EMPLOYEE_RESET,
} from "../../actions/types/employee";
import { updateHotelEmployee } from "../../actions/pms/employeeProfile";
import StateNameSelector from "../../components/layouts/StateNameSelector";
import CountryNameSelector from "../../components/layouts/CountryNameSelector";
import OutlineButton from "../../components/buttons/OutlineButton";
import AddToExistingRole from "./AddToExistingRole";
import CloseButton from "../../components/buttons/CloseButton";
import { GoCopy } from "react-icons/go";
import SubmitButton from "../../components/buttons/SubmitButton";
import CreateNewRoles from "./CreateNewRoles";
import { HiOutlinePlusSm } from "react-icons/hi";
import MultipleSelectTabView from "../../components/layouts/MultipleSelectTabView";

const AddEmployee = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isAddExistingRole, setIsAddExistingRole] = useState(true);
  const [isCreateNewRole, setIsCreateNewRole] = useState(false);
  const { hotel } = useSelector((state) => state.Hotel);

  //add new states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState(GENDERS.MALE);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [countryIsoCode, setCountryIsoCode] = useState("");
  const [state, setState] = useState("");
  const [stateIsoCode, setStateIsoCode] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [designation, setDesignation] = useState(POSITIONS.ACCOUNTING_MANAGER);
  const [department, setDepartment] = useState(DEPARTMENTS.ACCOUNTING_MANAGER);
  const [reportingTo, setReportingTo] = useState("62f49e1ec9782c752d510059");
  const [joiningDate, setJoiningDate] = useState("");
  const [rotationalShift, setRotationalShift] = useState("yes");
  const [cookSpecialties, setCookSpecialties] = useState("");
  // const [shiftType, setShiftType] = useState("");
  const [roasterManager, setRoasterManager] = useState("");
  const [selectDutyDays, setSelectDutyDays] = useState([]);
  const [selectDutyTimeStart, setSelectDutyTimeStart] = useState("");
  const [selectDutyTimeEnd, setSelectDutyTimeEnd] = useState("");
  // const [createMoreShifts, setCreateMoreShifts] = useState([]);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  // new field
  const [employeeType, setEmployeeType] = useState([]);
  const [shiftType, setShiftType] = useState([]);
  const [addShiftsRoaster, setAddShiftsRoaster] = useState([0]);
  const [dutyDays, setDutyDays] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  console.log("check value of rotational Shift : ", rotationalShift);

  const handleSubmit = () => {
    const formData = {
      hotelId: hotel?._id,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      phone,
      email,
      country,
      state,
      pinCode,
      streetAddress,
      designation,
      department,
      reportingTo,
      joiningDate,
      rotationalShift,
      cookSpecialties,

      // shiftType,
      roasterManager,
      selectDutyDays,
      selectDutyTimeStart,
      selectDutyTimeEnd,
      userName,
      userPassword,
      stateIsoCode,
      // new field
      employeeType,
      // password,
      // shiftEndTime,
      // shiftStartTime,
    };

    if (props?.updateData?.employeeId) {
      props?.updateEmployee({
        ...formData,
        employeeId: props?.updateData?.employeeId,
      });
    } else props.addEmployee(formData);
  };

  useEffect(() => {
    if (props?.updateData?.employeeId) {
      setFirstName(props?.updateData?.firstName);
      setLastName(props?.updateData?.lastName);
      setGender(props?.updateData?.gender);
      setDateOfBirth(props?.updateData?.dateOfBirth?.slice(0, 10));
      setPhone(props?.updateData?.phone);
      setEmail(props?.updateData?.email);
      setCountry(props?.updateData?.country);
      setState(props?.updateData?.state);
      setPinCode(props?.updateData?.pinCode);
      setStreetAddress(props?.updateData?.streetAddress);
      setDesignation(props?.updateData?.designation);
      setDepartment(props?.updateData?.department);
      setReportingTo(props?.updateData?.reportingTo);
      setJoiningDate(props?.updateData?.joiningDate?.slice(0, 10));
      setRotationalShift(props?.updateData?.rotationalShift);
      // setShiftType(props?.updateData?.shiftType);
      setRoasterManager(props?.updateData?.roasterManager);
      setSelectDutyDays(props?.updateData?.selectDutyDays);
      setSelectDutyTimeStart(props?.updateData?.selectDutyTimeStart);
      setSelectDutyTimeEnd(props?.updateData?.selectDutyTimeEnd);
      setUserName(props?.updateData?.userName);
      setUserPassword(props?.updateData?.userPassword);
      // setShiftStartTime(props?.updateData?.shiftStartTime);
      // setShiftEndTime(props?.updateData?.shiftEndTime);
      // setPassword(props?.updateData?.password);

      // new field
      setEmployeeType(props?.updateData?.employeeType);
    }
  }, [props?.updateData]);

  const handleClickOpen = (e) => {
    e.preventDefault();
    registerEvent("add employee button click", "add employee");
    setOpen(true);
  };

  useEffect(() => {
    if (props?.employee?.isadded || props?.employee?.isupdated) {
      setFirstName("");
      setLastName("");
      setGender("");
      setDateOfBirth("");
      setPhone("");
      setEmail("");
      setCountry("");
      setState("");
      setPinCode("");
      setStreetAddress("");
      setDesignation("");
      setDepartment("");
      setReportingTo("");
      setJoiningDate("");
      setRotationalShift("");
      setCookSpecialties("");
      // setShiftType("");
      setRoasterManager("");
      setSelectDutyDays("");
      setSelectDutyTimeStart("");
      setSelectDutyTimeEnd("");
      setUserName("");
      setUserPassword("");
      // setShiftStartTime("");
      // setShiftEndTime("");
      // setPassword("");

      // new field
      setEmployeeType("");
      props?.close();
    }

    if (props?.employee?.isadded) {
      notifySuccess(t("648"));
      props?.getEmployees(hotel?._id);
      props?.reset();
    }
    if (props?.employee?.isupdated) {
      notifySuccess(t("649"));
      props?.getEmployees(hotel?._id);
      props?.reset();
    }

    if (props?.employee?.error || props?.update?.error) {
      notifyError(t("650"));
      props?.clear();
    }
  }, [props?.employee, props?.update]);

  const handleAddExistingRole = () => {
    setIsAddExistingRole(true);
    setIsCreateNewRole(false);
  };
  const handleCreateNewRole = () => {
    setIsCreateNewRole(true);
    setIsAddExistingRole(false);
  };
  const handleShiftType = () => {
    setAddShiftsRoaster([...addShiftsRoaster, addShiftsRoaster]);
  };
  return (
    <>
      <SaveOrNot
        formSubmit={handleSubmit}
        isopen={open}
        close={() => setOpen(false)}
      />
      <div className="p-8 pt-4 mx-auto flex flex-col border rounded-lg bg-white relative">
        <div className="flex items-center justify-between w-full mb-4">
          <div className="text-[26px] text-[#07090D] leading-[32.76px] font-bold tracking-[-1.5%]">
            {props?.updateData?.employeeId ? t("651") : t("200")}
          </div>
          <CloseButton onClick={props?.close} />
        </div>

        <form className="w-full pt-4" onSubmit={handleClickOpen}>
          <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 grid-w-full">
            <SelectFromArray
              label={t("Employee Type")}
              options={["New"]}
              value={employeeType}
              onChange={(e) => setEmployeeType(e?.target?.value)}
            />
            <Inputfield
              label={`${t("First Name")} *`}
              placeholder={t("First Name")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required={true}
              minLength={3}
              maxLength={30}
            />
            <Inputfield
              label={`${t("Last Name")} *`}
              placeholder={t("Last Name")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required={true}
              minLength={3}
              maxLength={30}
            />
            <Inputfield
              label={`${t("654")} *`}
              placeholder={t("655")}
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              type="date"
              required={true}
              max={getYearAgoToday(18)}
            />
            <SelectFromObject
              label={`${t("168")} *`}
              options={GENDERS}
              value={gender}
              setValue={setGender}
            />
            <PhoneWithCode
              label={`${t("527")} *`}
              phone={phone}
              setPhone={setPhone}
            />
            <Inputfield
              label={t("416")}
              placeholder={t("656")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required={true}
            />
            {/* new fields */}
            <CountryNameSelector
              country={country}
              setCountry={setCountry}
              setCountryIsoCode={setCountryIsoCode}
            />
            <StateNameSelector
              state={state}
              setState={setState}
              setStateIsoCode={setStateIsoCode}
              countryCode={countryIsoCode}
            />
            <Inputfield
              label={"Pin Code"}
              placeholder={t("Code")}
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              minLength={5}
              maxLength={100}
              required={true}
            />
            <Inputfield
              label={t("Street Address")}
              placeholder={t("Street Address")}
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
              minLength={10}
              maxLength={100}
              required={true}
            />
            <SelectFromObject
              label={t("658")}
              options={POSITIONS}
              value={designation}
              setValue={setDesignation}
            />
            <SelectFromObject
              label={t("659")}
              options={DEPARTMENTS}
              value={department}
              setValue={setDepartment}
            />

            <SelectFromObject
              label={t("660")}
              options={{}}
              value={reportingTo}
              setValue={setReportingTo}
            />
            <Inputfield
              label={t("661")}
              type="date"
              value={joiningDate}
              onChange={(e) => setJoiningDate(e.target.value)}
              required={true}
              min={getToday()}
            />
            {rotationalShift === "no" ? (
              <SelectFromObject
                label={`${t("Shift Type")} *`}
                options={{ roasterSystem: "Roaster System" }}
                value={shiftType}
                setValue={setShiftType}
              />
            ) : null}
            <SelectFromObject
              label={`${t("Rotational Shift")} *`}
              options={{ no: "No", yes: "Yes" }}
              value={rotationalShift}
              setValue={setRotationalShift}
            />
            <SelectFromObject
              label={`${t("Roaster Manager")} *`}
              options={{}}
              value={roasterManager}
              setValue={setRoasterManager}
              required={true}
            />
            {/* <Inputfield
              label={"Cook Specialties"}
              placeholder={t("Search")}
              value={cookSpecialties}
              onChange={(e) => setCookSpecialties(e.target.value)}
            /> */}
          </div>
          {/* access control */}
          {rotationalShift === "yes" ? (
            <div className="">
              <div className="text-[18px] leading-6 font-medium my-4">
                Access Control
              </div>
              <div className="flex items-center gap-6">
                <OutlineButton
                  text={"Add to Existing Role"}
                  active={isAddExistingRole}
                  onClick={handleAddExistingRole}
                />
                <OutlineButton
                  text={"Create New Role"}
                  active={isCreateNewRole}
                  onClick={handleCreateNewRole}
                />
              </div>
              <div className="my-4">
                {isAddExistingRole ? <AddToExistingRole /> : null}
                {isCreateNewRole ? <CreateNewRoles /> : null}
              </div>

              {/* Employee Login Credentials */}
              <div className="text-[18px] leading-6 font-medium my-4">
                Employee Login Credentials
              </div>
              <div className="grid grid-cols-3 gap-6 items-end my-4">
                <Inputfield
                  label={t("Username")}
                  placeholder={t("firstname")}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required={true}
                />
                <div className="relative">
                  <Inputfield
                    label={t("Password")}
                    type={"password"}
                    placeholder={t("userpassword")}
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    required={true}
                    icon={<GoCopy size={22} />}
                  />
                </div>

                <button
                  type="button"
                  className="h-10 w-[199px] border border-base-primary rounded-md bg-white text-sm text-[#07090D] font-medium hover:bg-base-primary hover:text-white transition-all duration-300 ease-[0s] mb-[5px] ml-6"
                >
                  {t("Generate Login Credentials")}
                </button>
              </div>
            </div>
          ) : null}

          {rotationalShift === "no" ? (
            <div className="">
              <div className="text-[18px] leading-6 font-medium text-[#07090D] my-4">
                Fixed Shift Roaster
              </div>
              <div className="bg-[#F6F6F6] rounded-[5px] p-4">
                <div className="">
                  {addShiftsRoaster?.map((d) => {
                    return (
                      <div className="grid grid-cols-3 gap-6" key={d}>
                        <MultipleSelectTabView
                          label={`Select Duty Days *`}
                          value={dutyDays}
                          setValue={setDutyDays}
                          options={DAYS_NAME}
                          required={true}
                        />
                        <div className="">
                          <label htmlFor="dutyTime">Select Duty Time *</label>
                          <div className="relative  h-12 flex flex-row justify-center items-center gap-3 border border-[#D0D5DD] rounded-md box-border bg-white text-[#18181B] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] p-1">
                            <input
                              name="dutyTime"
                              value={startTime}
                              onChange={(e) => setStartTime(e.target.value)}
                              type={"time"}
                              className="h-11 w-full bg-white shadow-none rounded font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px] border-none focus:border-0"
                              required
                            />
                            <div className="text-[16px] leading-6 text-[#6E7C87]">
                              To
                            </div>
                            <input
                              name="dutyTime"
                              value={endTime}
                              onChange={(e) => setEndTime(e.target.value)}
                              type={"time"}
                              className="h-11 w-full bg-white shadow-none rounded font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px] border-none focus:border-0"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <button
                  type="button"
                  onClick={handleShiftType}
                  className="h-[45px] w-[205px] text-[16px] leading-4 text-[#07090D] tracking-[-0.6%] rounded-md flex items-center justify-center gap-4 bg-white hover:bg-gray-600 hover:text-white duration-300 ease-[0.3s] group border border-[#D0D5DD] my-4"
                >
                  <div className="border border-[#07090D] rounded-md p-[2px] group-hover:border-white  ease-[0.3s]">
                    <HiOutlinePlusSm />
                  </div>
                  <div>Create More Shifts</div>
                </button>
              </div>
              <div className="flex flex-col gap-6 my-6">
                <div className="grid grid-cols-3 items-end gap-6">
                  <Inputfield
                    label={t("Username")}
                    placeholder={t("firstname")}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    required={true}
                    icon={<GoCopy size={22} />}
                  />
                  <button
                    type="button"
                    className="h-10 w-[199px] border border-base-primary rounded-md bg-white text-sm text-[#07090D] font-medium hover:bg-base-primary hover:text-white transition-all duration-300 ease-[0s] mb-[5px] ml-6"
                  >
                    {t("Generate Username")}
                  </button>
                </div>
                <div className="grid grid-cols-3 items-end gap-6">
                  <Inputfield
                    label={t("Password")}
                    type={"password"}
                    placeholder={t("userpassword")}
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    required={true}
                    icon={<GoCopy size={22} />}
                  />
                  <button
                    type="button"
                    className="h-10 w-[199px] border border-base-primary rounded-md bg-white text-sm text-[#07090D] font-medium hover:bg-base-primary hover:text-white transition-all duration-300 ease-[0s] mb-[5px] ml-6"
                  >
                    {t("Generate Password")}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className={`flex items-center mt-8 ${
              rotationalShift === "no" ? "justify-start" : "justify-end"
            }`}
          >
            <SubmitButton
              text={props?.updateData?.employeeId ? t("665") : t("200")}
              loading={props?.employee?.loading || props?.update?.loading}
            />
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  employee: state.AddEmployee,
  update: state.UpdateEmployeeProfile,
});

const mapDispatchToProps = (dispatch) => ({
  addEmployee: (data) => dispatch(addEmployeeAction(data)),
  updateEmployee: (data) => dispatch(updateHotelEmployee(data)),
  clear: () => dispatch(clearError()),
  reset: () =>
    dispatch({ type: ADD_EMPLOYEE_RESET }, { type: UPDATE_EMPLOYEE_RESET }),
  getEmployees: (hotelId) => dispatch(getAllHotelEmployeeAction(hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);
