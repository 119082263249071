/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AddButton from "../../../components/buttons/AddButton";
import { isObjEmpty } from "../../../utils";
import AddEmployee from "../../employee/AddEmployee";
import AllEmployee from "../../employee/AllEmployee";
import { getAllHotelEmployeeAction } from "../../../actions/pms/employee";
import { registerEvent } from "../../../logs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Employees = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [updateData, setUpdateData] = useState(null);
  const { hotel } = useSelector((state) => state.Hotel);

  const addEmployeeModal = () => {
    setOpen(true);
    registerEvent("click add employee button", "all employee page");
  };

  const close = () => {
    setOpen(false);
    setUpdateData(null);
    registerEvent("close add employee component", "add employee page");
  };

  useEffect(() => {
    if (hotel?._id) dispatch(getAllHotelEmployeeAction(hotel?._id));
  }, []);

  return (
    <div className="mt-6">
      <div className="w-full flex justify-end items-end mx-auto">
        <AddButton text={t("199")} onClick={addEmployeeModal} />
      </div>

      {(updateData && !isObjEmpty(updateData)) || open ? (
        <div className="mt-6">
          <AddEmployee close={close} updateData={updateData} />
        </div>
      ) : null}

      <div className="mt-6">
        <AllEmployee setUpdateData={setUpdateData} />
      </div>
    </div>
  );
};

export default Employees;
