import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

const FoodOrderCard = ({ orderName, Price, onClick = () => {} }) => {
  return (
    <div className="flex flex-col gap-3 bg-white py-4 border-b border-[#E1E1E1] last:border-b-0">
      <div className="flex items-start justify-between gap-6">
        <div className="text-[18px] leading-6 font-semibold text-[#07090D]">
         {orderName}
        </div>
        <button
          type="button"
          className="flex items-center justify-between bg-[#F6F6F6] rounded-full p-1 duration-300 ease-[0.3s] scale-100 hover:scale-105 hover:bg-[#FF2323] hover:text-white mt-[2px]"
        >
          <RxCross2 size={12} />
        </button>
      </div>
      <div className="flex items-center justify-between gap-6">
        <div className="text-[20px] leading-[24.53px] text-[#6E7C87]">{Price}</div>
        <div className="border border-[#E1E1E1] rounded-[5px] h-[31px] w-[93px] bg-white shadow-[0_0_5.3px_0_rgba(0,0,0,0.18)] text-[16px] leading-[22px] font-semibold text-base-primary  flex items-center justify-between py-1 px-2">
          <button
            type="button"
            className="hover:text-base-primary duration-300 ease-[0.3s] scale-100 hover:scale-150"
          >
            <FaMinus size={15} />
          </button>
          <div className="select-none">2</div>
          <button
            type="button"
            className="hover:text-base-primary duration-300 ease-[0.3s] scale-100 hover:scale-150"
          >
            <FaPlus size={15} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FoodOrderCard;
