import React, { useEffect, useState } from "react";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import { notifyError, notifySuccess } from "../../components/alert";
import {
  DEPARTMENTS,
  EXPENSE_FREQUENCY,
  EXPENSE_TYPES,
  PAYMENT_METHODS,
} from "../../constants";
import {
  addExpenseAction,
  getAllExpenseAction,
} from "../../actions/pms/expense";
import { ADD_EXPENSE_RESET } from "../../actions/types/expense";
import { clearError } from "../../actions/clearError";
import { RxCross2 } from "react-icons/rx";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import FormSubmitButton from "../../components/buttons";
import CloseButton from "../../components/buttons/CloseButton";
import InputTextArea from "../../components/layouts/InputTextArea";

const AddExpense = ({ close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Expense
  const [name, setName] = useState("");
  const [expenseType, setExpenseType] = useState(EXPENSE_TYPES.TYPE1);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [frequency, setFrequency] = useState(EXPENSE_FREQUENCY.DAILY);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [expenseDepartment, setExpenseDepartment] = useState(
    DEPARTMENTS.ACCOUNTING_MANAGER
  );
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS.CASH);
  const [paymentProvider, setPaymentProvider] = useState("");
  const [paymentApproval, setPaymentApproval] = useState("");
  const [expenseNote, setExpenseNote] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [open, setOpen] = useState(false);
  const [vendorID, setVendorID] = useState("");
  const { vendors } = useSelector((state) => state.AllVendor);
  const { isadded, error, loading } = useSelector((state) => state.AddExpense);
  const { hotel } = useSelector((state) => state.Hotel);

  useEffect(() => {
    if (vendors) console.log(vendors);
  }, [vendors]);

  //click open
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpen(false);
    const formData = {
      hotelId: hotel?._id,
      name,
      type: expenseType,
      invoiceDate,
      frequency,
      invoiceNumber,
      expenseDepartment,
      amount,
      paymentMethod,
      paymentProvider,
      paymentApproval,
      expenseNote,
      // vendorId,
    };
    dispatch(addExpenseAction(formData));
  };

  useEffect(() => {
    if (isadded) {
      notifySuccess(t("672"));
      dispatch({ type: ADD_EXPENSE_RESET });
      dispatch(getAllExpenseAction(hotel?._id));
      setName("");
      setExpenseType("");
      setInvoiceDate("");
      setFrequency("");
      setInvoiceNumber("");
      setExpenseDepartment("");
      setAmount("");
      setPaymentMethod("");
      setPaymentProvider("");
      setPaymentApproval("");
      setExpenseNote("");
      close();
    }

    if (error) {
      notifyError(t("673"));
      dispatch(clearError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isadded]);

  return (
    <>
      <SaveOrNot
        formSubmit={handleSubmit}
        isopen={open}
        close={() => setOpen(false)}
      />

      <div className="relative p-6 mt-6 w-full mx-auto flex flex-col border rounded-lg bg-white">
        <div className="w-full flex items-center justify-between mb-6">
          <div className="font-bold text-xl sm:text-2xl md:text-3xl leading-9 tracking-tighter text-center">
            {t("242")}
          </div>
          <CloseButton onClick={close} />
        </div>

        <form onSubmit={handleClickOpen}>
          <div className="w-full grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mb-6">
            <Inputfield
              label={t("467")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t("487")}
              required={true}
            />
            <SelectFromObject
              label={t("469")}
              options={EXPENSE_TYPES}
              value={expenseType}
              onChange={(e) => setExpenseType(e?.target?.value)}
            />
            <Inputfield
              label={t("1101")}
              type="date"
              value={invoiceDate}
              onChange={(e) => setInvoiceDate(e.target.value)}
              required={true}
            />
            <SelectFromObject
              label={t("473")}
              options={EXPENSE_FREQUENCY}
              value={frequency}
              onChange={(e) => setFrequency(e?.target?.value)}
            />
            <Inputfield
              label={t("475")}
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              placeholder={t("488")}
              required={true}
            />
            <SelectFromObject
              label={t("1102")}
              options={{}}
              value={expenseDepartment}
              onChange={(e) => setExpenseDepartment(e?.target?.value)}
            />
            <Inputfield
              label={t("479")}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder={t("489")}
              required={true}
              type="number"
            />
            <SelectFromObject
              label={t("481")}
              options={PAYMENT_METHODS}
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e?.target?.value)}
            />
            <SelectFromObject
              label={t("1103")}
              options={{}}
              value={paymentProvider}
              onChange={(e) => setPaymentProvider(e?.target?.value)}
            />
            <Inputfield
              label={t("483")}
              type="date"
              value={paymentDate}
              onChange={(e) => setPaymentDate(e.target.value)}
              required={true}
            />
            <Inputfield
              label={t("823")}
              placeholder={t("682")}
              value={vendorID}
              onChange={(e) => setVendorID(e.target.value)}
            />
            <SelectFromObject
              label={t("1104")}
              options={{}}
              value={paymentApproval}
              onChange={(e) => setPaymentApproval(e?.target?.value)}
            />
          </div>
          <InputTextArea
            label={t("1105")}
            placeholder="Write here"
            value={expenseNote}
            onChange={(e) => setExpenseNote(e?.target?.value)}
          />
          <div className="flex items-center justify-end mt-6">
            <FormSubmitButton text={t("242")} loading={loading} />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddExpense;
