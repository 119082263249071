import React, { useState } from "react";

const RoomCleaningTime = () => {
  const [time, setTime] = useState("10:15");

  return (
    <input
      type="time"
      className="room-cleaning-checkout pr-6 border-none focus:border-none shadow-none"
      value={time}
      onChange={(e) => setTime(e?.target?.value)}
    />
  );
};

export default RoomCleaningTime;
