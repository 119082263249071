import React from "react";

const CategoryButton = ({ name, onClick = () => {}, active }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`min-h-[54px] w-full border-2 border-[#E4E4E7] rounded-[10px] text-[20px] leading-6 text-[#313131]  flex items-center justify-start pl-8 bg-transparent duration-300 ease-[0.3s] capitalize ${
        active ? "border-base-primary bg-white" : ""
      }`}
    >
      {name}
    </button>
  );
};

export default CategoryButton;
