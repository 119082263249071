/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { getAvailableRooms } from "../../utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OccupencyIcon from "../../images/icons/OccupencyIcon.svg";
import RoomAvailableIcon from "../../images/icons/RoomAvailableIcon.svg";
import TotalBookingIcon from "../../images/icons/TotalBookingIcon.svg";
import TotalCheckInIcon from "../../images/icons/TotalCheckInIcon.svg";
import TotalCheckOutIcon from "../../images/icons/TotalCheckOutIcon.svg";
import TotalCheckInPendindIcon from "../../images/icons/TotalCheckInPendindIcon.svg";
import CalendarTickIcon from "../../images/icons/CalendarTickIcon.svg";
import AllCustomerIcon from "../../images/icons/AllCustomerIcon.svg";
import StatusCard from "../../components/layouts/StatusCard";

const PropertyStatus = ({ bookings, date }) => {
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalCheckIn, setTotalCheckIn] = useState(0);
  const [totalCheckOut, setTotalCheckOut] = useState(0);
  const [totalAvailableRooms, setTotalAvailableRooms] = useState(0);
  const [totalCheckOutPending, setTotalCheckOutPending] = useState(0);
  const { t } = useTranslation();
  const { hamburger } = useSelector((state) => state.Hamburger);

  //computing data
  const computingData = async () => {
    let books = 0;
    let checkIn = 0;
    let checkOut = 0;
    let checkOutPending = 0;

    bookings &&
      bookings.length &&
      bookings.map((booking) => {
        // bookings count
        if (booking.bookingDate) {
          let newDate = booking.bookingDate.slice(0, 10);
          if (newDate === date) {
            books += 1;
          }
        }

        //checkin count
        if (booking.checkInDate) {
          let newDate = booking.checkInDate.slice(0, 10);
          if (newDate === date) {
            checkIn += 1;
          }
        }

        //checkOut count
        if (booking.checkOutDate) {
          let newDate = booking.checkOutDate.slice(0, 10);
          if (newDate === date) {
            checkOut += 1;
          }
        }

        //checkout pending
        if (booking.checkOutDate && booking.checkInDate) {
          let newCheckIn = booking.checkInDate.slice(0, 10);
          let newCheckout = booking.checkOutDate.slice(0, 10);
          if (newCheckIn < date && newCheckout >= date) {
            checkOutPending += 1;
          }
        }
      });

    setTotalBookings(totalBookings);
    setTotalCheckIn(totalCheckIn);
    setTotalCheckOut(totalCheckOut);
    setTotalCheckOutPending(totalCheckOutPending);
    const rooms = await getAvailableRooms(date, date);
    setTotalAvailableRooms(rooms.length);
  };

  useEffect(() => {
    computingData();
  }, [bookings, date]);

  return (
    <div
      className={`${
        hamburger ? "lg:grid-cols-3" : "lg:grid-cols-4"
      } grid grid-cols-1 font-pjs sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-6`}
    >
      <StatusCard
        name={t("5")}
        icon={OccupencyIcon}
        percantage={"+36"}
        totalCounts={"12,426"}
      />
      <StatusCard
        name={t("6")}
        icon={RoomAvailableIcon}
        percantage={"-16"}
        totalCounts={"3,585"}
      />
      <StatusCard
        name={t("7")}
        icon={TotalBookingIcon}
        percantage={"+31"}
        totalCounts={"14,382"}
      />
      <StatusCard
        name={t("8")}
        icon={TotalCheckInIcon}
        percantage={"+31"}
        totalCounts={"1,426"}
      />
      <StatusCard
        name={t("9")}
        icon={TotalCheckOutIcon}
        percantage={"-31"}
        totalCounts={"2,485"}
      />
      <StatusCard
        name={t("10")}
        icon={TotalCheckInPendindIcon}
        percantage={"+43"}
        totalCounts={" 4,382"}
      />
      <StatusCard
        name={t("11")}
        icon={CalendarTickIcon}
        percantage={"-31"}
        totalCounts={"4,382"}
      />
      <StatusCard
        name={t("835")}
        icon={AllCustomerIcon}
        percantage={"-31"}
        totalCounts={"12,426"}
      />
    </div>
  );
};

export default PropertyStatus;
