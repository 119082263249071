import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useTranslation } from "react-i18next";
import ReportsIcon from "../../images/icons/ReportsIcon.svg";

const GotoReport = () => {
  const { t } = useTranslation();
  return (
    <Link
      to={ROUTES.REPORTS}
      className="flex justify-between items-center rounded-[6px] px-4 h-10 w-[110px] bg-base-primary hover:bg-base-primary_hover transition-all duration-[0.3s] ease-[0s] text-[#F6F8F9]"
    >
      <img src={ReportsIcon} alt="" />
      <div className="text-[14px] leading-[14px] font-semibold tracking-[-0.6%] ">
        {t("4")}
      </div>
    </Link>
  );
};

export default GotoReport;
