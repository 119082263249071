import React from "react";
import { connect } from "react-redux";
import { getFoodMenuAction } from "../../actions/pms/foodMenu";
import { getFoodOrdersAll } from "../../actions/pms/foodOrder";
import { CLEAR_CART } from "../../actions/types/food";
import FoodHeader from "./FoodHeader";
import { useParams } from "react-router-dom";
import FoodCategory from "./food-category";
import CreateFoodOrder from "./create-order";
import AmericanCuisine from "./food-category/AmericanCuisine";
import ThaiCuisine from "./food-category/ThaiCuisine";

const FoodOrder = (props) => {
  const { index } = useParams();

  return (
    <div className="flex">
      {/* left */}
      <div className="w-4/6 mr-[26px]">
        <FoodHeader params={index} />
        {/* content */}
        <div className="mt-4">
          {+index === 1 ? <FoodCategory /> : null}
          {+index === 2 ? <AmericanCuisine /> : null}
          {+index === 3 ? <ThaiCuisine /> : null}
        </div>
      </div>
      {/* right */}
      <div className="w-full">
        <CreateFoodOrder />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: state.FoodMenu,
  cart: state.FoodCart,
  allOrders: state.AllFoodOrder,
});

const mapDispatchToProps = (dispatch) => ({
  getMenu: (hotelId) => dispatch(getFoodMenuAction(hotelId)),
  getAllOrders: (hotelId) => dispatch(getFoodOrdersAll(hotelId)),
  clearCart: () => dispatch({ type: CLEAR_CART }),
});
export default connect(mapStateToProps, mapDispatchToProps)(FoodOrder);
