import React, { useEffect, useState } from "react";
import Seperator from "../../components/layouts/Seperator";
import { BiArrowBack } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import DetailsPrefences from "./DetailsPrefences";
import { ROUTES } from "../../constants";
// import { useDispatch } from "react-redux";
import DetailsHeading from "../../components/layouts/DetailsHeading";
import { useNavigate } from "react-router-dom";
import CustomeTable from "../../components/CustomeTable";

const CustomerDetails = () => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  // useEffect(() => {
  //   const customerId = localStorage.getItem(ID_NUMBER);
  // }, []);

  const rowData = [
    {
      _id: "cust001",
      check_in: "2022-08-11",
      check_out: "2022-08-11",
      room_type: "VIP",
      rooms: "1",
      room_number: "100",
      price: "$200",
      payment_method: "Cash",
      payment_status: "Paid",
    },
    {
      _id: "cust002",
      check_in: "2022-08-11",
      check_out: "2022-08-11",
      room_type: "Deluxe",
      rooms: "3",
      room_number: "101,102,103",
      price: "$200",
      payment_method: "Card",
      payment_status: "Half Paid",
    },
    {
      _id: "cust003",
      check_in: "2022-08-11",
      check_out: "2022-08-11",
      room_type: "Royal SuiteIP",
      rooms: "2",
      room_number: "100",
      price: "$200",
      payment_method: "Card",
      payment_status: "Unpaid",
    },
  ];

  const handlePaymentStatus = ({ value }) => {
    return (
      <div className="flex items-center justify-start text-[13px] leading-3 font-normal mt-2">
        {value === "Paid" ? (
          <div className="h-[26px] w-[68px] flex items-center justify-center rounded-[5px] bg-[#ECFDF3]  text-[#12B76A]">
            {value}
          </div>
        ) : null}
        {value === "Half Paid" ? (
          <div className="h-[26px] w-[68px] flex items-center justify-center rounded-[5px] bg-[#EEF4FF]  text-[#444CE7]">
            {value}
          </div>
        ) : null}
        {value === "Unpaid" ? (
          <div className="h-[26px] w-[68px] flex items-center justify-center rounded-[5px] bg-[#FEF3F2]  text-[#F04438]">
            {value}
          </div>
        ) : null}
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Customer ID",
      field: "_id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Check-In",
      field: "check_in",
      filter: true,
    },
    {
      headerName: "Check Out",
      field: "check_out",
      filter: true,
    },
    {
      headerName: "Room Type",
      field: "room_type",
      filter: true,
    },
    {
      headerName: "Rooms",
      field: "rooms",
      filter: true,
    },
    {
      headerName: "Room Number",
      field: "room_number",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Payment Method",
      field: "payment_method",
      filter: true,
    },

    {
      headerName: "Payment Status",
      field: "payment_status",
      cellRenderer: handlePaymentStatus,
    },
  ];
  const headingData = {
    heading: t("Guest Details"),
    seperator: [
      {
        text: t("Guest Management"),
      },
      {
        text: t("Guest"),
        link: ROUTES.CUSTOMERS,
      },
      {
        text: t("237"),
      },
    ],
  };
  const backToCustomer = () => {
    navigate(ROUTES.CUSTOMERS);
  };

  return (
    <div className="page-container">
      <div className=" flex justify-between items-end mb-6">
        <Seperator data={headingData} />
        <button
          className="h-10 w-[89px] border rounded-md bg-white flex items-center relative"
          onClick={() => backToCustomer()}
        >
          <BiArrowBack size={14} className="text-[#252C32] absolute left-4" />
          <div className="text-[14px] leading-6 font-normal pl-10">
            {t("236")}
          </div>
        </button>
      </div>
      {/*Personal Information*/}
      <div className="text-[22px] leading-[27.72px] font-bold tracking-[-0.8px] text-[#000000] mb-4">
        {t("524")}
      </div>
      <div className="h-[177px] p-8 border rounded-[10px] bg-white box-border mb-4">
        <div className="grid grid-cols-5">
          <DetailsHeading heading={t("166")} subHeading="salman" />
          <DetailsHeading heading={t("168")} subHeading="Male" />
          <DetailsHeading heading={t("169")} subHeading="+919761826682" />
          <DetailsHeading
            heading={t("416")}
            subHeading="salmanalizahid151@gmail.com"
          />
        </div>
        <div className="grid grid-cols-5 mt-6">
          <DetailsHeading heading={t("429")} subHeading="india" />
          <DetailsHeading heading={t("577")} subHeading="utter pradesh" />
          <DetailsHeading heading={t("576")} subHeading="Amroha" />
          <DetailsHeading heading={t("52")} subHeading="mohalla basawan ganj" />
        </div>
      </div>
      {/*Reservation Information*/}
      {/* <div className="text-[22px] leading-[27.72px] font-bold tracking-[-0.8px] text-[#000000] my-3">
        {t("627")}
      </div> */}

      <CustomeTable
      tableName={"Reservation Information Table"}
        setSelectedRows={setSelectedRows}
        columnDefs={columnDefs}
        rowData={rowData}
      />
      {/* Preferences */}
      <div className="text-[22px] leading-[27.72px] font-bold tracking-[-0.8px] text-[#000000] my-3">
        {t("828")}
      </div>
      <div className="h-[201px] border rounded-[10px] bg-white box-border">
        <div className="p-5 flex gap-36">
          <DetailsPrefences
            Name={t("107")}
            serviceName1={"Smoking Allowed"}
            serviceName2={"Single Bed"}
            serviceName3={"With AC"}
            serviceName4={"With Balcony"}
          />
          <DetailsPrefences
            Name={t("127")}
            serviceName1={"Wifi"}
            serviceName2={"Fridge"}
            serviceName3={"AC"}
            serviceName4={"LCDTV"}
          />
          <DetailsPrefences
            Name={t("829")}
            serviceName1={"Non Vegetarian"}
            serviceName2={"Allergic to Shellfish"}
            serviceName3={"Hot Water"}
          />
        </div>
      </div>
      {/* Guest Notes */}
      <div className=" text-[22px] leading-[27.72px] font-bold tracking-[-0.8px] text-[#000000] my-3">
        {t("830")}
      </div>
      <div className="h-[266px] border rounded-[10px] bg-white box-border">
        <div className="border-b px-4 py-2">
          <div className="text-[20px] leading-[25px] font-semibold tracking-[-0.8px] px-2 py-2">
            {t("831")}
          </div>
          <p className="text-[14px] leading-[17.64px] font-medium text-[#6E7C87] px-2 pb-3 ">
            {t("832")}
          </p>
        </div>
        <div className="text-[20px] leading-[25px] font-semibold tracking-[-0.8px] px-6 pt-4">
          {t("833")}
        </div>
        <p className="text-[14px] leading-[26px] font-medium text-[#6E7C87] px-6 py-2">
          {t("834")}
        </p>
      </div>
    </div>
  );
};
export default CustomerDetails;
