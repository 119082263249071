import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMenuItem } from "../../actions/kitchen/menu";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import { CustomeTable, DeleteButton, EditButton } from "../../components";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../components/buttons/SubmitButton";

const AllInventory = ({
  kitchen,
  setInventory,
  // hotel,
  // isadmin,
  // updateInventory,
  // isShowUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [inventoryId, setInventoryId] = useState("");
  const { inventories } = useSelector((state) => state.AllInventory);
  const { loading: delLoading } = useSelector((state) => state.DeleteMenuItem);
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  //const set modified data
  const setModifiedData = (inventories) => {
    let newData = [];
    if (inventories && Array.isArray(inventories)) {
      // eslint-disable-next-line array-callback-return
      inventories?.map((item) => {
        newData.push({
          inventoryId: item?.inventoryId,
          // name: item?.name,
          inventoryName: item?.inventoryName,
          inventoryType: item?.inventoryType,
          price: item?.price,
          storageType: item?.item?.storageType,
          currentAvailableQuantity: item?.currentAvailableQuantity,
          shelfLife: item?.shelfLife,
          purchaseFrequency: item?.purchaseFrequency,
          vendorID: item?.vendorID,
          id: item?.id,
        });
      });
      if (newData?.length) {
        setDatas(newData);
      }
    }
  };
  useEffect(() => {
    setModifiedData(inventories);
  }, [inventories]);

  // set inventory for update
  const setInventoryForUpdate = (id) => {
    // eslint-disable-next-line array-callback-return
    inventories?.map((item) => {
      if (item.id === id) {
        setInventory(item);
      }
    });
  };

  // delete inventory confirmation modal open
  const openModal = (id) => {
    if (id) {
      setOpen(true);
      setInventoryId(id);
    }
  };

  // delete confirm
  const confirmDelete = () =>
    dispatch(deleteMenuItem(kitchen._id, inventoryId));

  //delete not confirm
  const closeModal = () => {
    setOpen(false);
    setInventoryId(null);
  };
  const inventryEditBtn = (value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setInventoryForUpdate(value);
  };

  const rowData = [
    {
      inventoryId: "IN01",
      inventoryName: "Salt",
      inventoryType: "Grocery",
      price: "+2%",
      storageType: "Dry",
      currentAvailableQuantity: "2 Kg",
      shelfLife: "6 months",
      purchaseFrequency: "Weekly",
      vendorID: "V01,V02",
    },
    {
      inventoryId: "IN02",
      inventoryName: "Cheese",
      inventoryType: "Dairy",
      price: "-10%",
      storageType: "Refrigerator",
      currentAvailableQuantity: "4 Kg",
      shelfLife: "12 days",
      purchaseFrequency: "Daily",
      vendorID: "V01,V02",
    },
    {
      inventoryId: "IN03",
      inventoryName: "Butter",
      inventoryType: "Dairy",
      price: "+1%",
      storageType: "Refrigerator",
      currentAvailableQuantity: "5 Kg",
      shelfLife: "16 days",
      purchaseFrequency: "Daily",
      vendorID: "V012,V024,V079",
    },
    {
      inventoryId: "IN04",
      inventoryName: "Tomato",
      inventoryType: "Vegetable",
      price: "+10%",
      storageType: "Refrigerator",
      currentAvailableQuantity: "8 Kg",
      shelfLife: "1 week",
      purchaseFrequency: "Monthly",
      vendorID: "V031",
    },
    {
      inventoryId: "IN05",
      inventoryName: "Potato",
      inventoryType: "Vegetable",
      price: "-5%",
      storageType: "Dry",
      currentAvailableQuantity: "Out of stock",
      shelfLife: "2 Weeks",
      purchaseFrequency: "Monthly",
      vendorID: "V01,V02",
      _id: "",
    },
  ];

  // view all inventory action
  const viewAllInventoryAction = ({ value }) => {
    return (
      <div className={`h-full flex items-center gap-4`}>
        <SubmitButton
          className="h-[30px] rounded"
          text={t("Update Inventory")}
          onClick={setInventoryForUpdate}
        />
        <EditButton onClick={inventryEditBtn} />
        <DeleteButton onClick={() => openModal(value)} />
      </div>
    );
  };

  const CurrentAvailableQuant = ({ value }) => {
    return (
      <>
        {value === "Out of stock" ? (
          <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-[#FF2424] mt-2">
            {value}
          </div>
        ) : (
          <div>{value}</div>
        )}
      </>
    );
  };
  const columnDef = [
    {
      headerName: "Inventory ID",
      field: "inventoryId",
      minWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Inventory Name",
      field: "inventoryName",
      filter: true,
    },
    {
      headerName: "Inventory Type",
      field: "inventoryType",
      filter: true,
    },
    {
      headerName: "% Change In Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Storage Type",
      field: "storageType",
      filter: true,
    },
    {
      headerName: "Available Quantity",
      field: "currentAvailableQuantity",
      filter: true,
      cellRenderer: CurrentAvailableQuant,
    },
    {
      headerName: "Shelf Life",
      field: "shelfLife",
      filter: true,
    },
    {
      headerName: "Purchase Frequency",
      field: "purchaseFrequency",
      filter: true,
    },
    {
      headerName: "Vendor ID",
      field: "vendorID",
      filter: true,
    },
    {
      headerName: "Actions",
      minWidth: 380,
      cellRenderer: viewAllInventoryAction,
    },
  ];
  return (
    <>
      <div className="mt-6">
        {/* {datas.length ? ( */}
        <CustomeTable
          tableName={"Inventory List Table"}
          rowData={rowData}
          columnDefs={columnDef}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>

      <DeleteOrNot open={open} close={closeModal} formSubmit={confirmDelete} />
      {delLoading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

export default AllInventory;
