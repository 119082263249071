/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

export const useOutsideClick = (ref, close = () => {}) => {
  useEffect(() => {
    function handleClickOutside(event) {
      //if modal is open than close modal (close(false))
      if (ref.current && !ref.current.contains(event.target)) {
        close();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
