import React, { useState } from "react";
import AddButton from "../../../components/buttons/AddButton";
import AddPaymentMode from "./AddPaymentMode";
import { CustomeTable } from "../../../components";
import { TbPencil } from "react-icons/tb";

const PaymentModes = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const paymentModeAction = () => {
    return (
      <div className="h-full">
        <button
          type="button"
          className="h-8 px-4 rounded-md bg-[#FFE5E5] text-[14px] leading-6 font-normal text-[#F04438] hover:bg-[#F04438] tracking-[-0.6%] hover:text-white duration-300 ease-[0.3s]"
        >
          Remove Access
        </button>
      </div>
    );
  };

  const tableData = [
    {
      employee: [
        {
          employeeName: "Rahul-hr",
          idAndStatus: "E0123 - HR Manager",
        },
      ],
      financeAuthorization: [
        {
          toSwipeCard: "To Swipe Cards",
          toVerifyPaymentReceived: "To Verify Payment Received",
          accountNoToReceivedPayment: "Account 1- to Received Payment",
        },
      ],
    },
  ];

  const handleEmployee = ({ value }) => {
    const employee = value[0];
    return (
      <div>
        <div>{employee.employeeName}</div>
        <div>{employee.idAndStatus}</div>
      </div>
    );
  };

  const handleFinanceAuth = ({ value }) => {
    const finance = value[0];
    return (
      <div>
        <div>{finance.toSwipeCard}</div>
        <div>{finance.toVerifyPaymentReceived}</div>
        <div>{finance.accountNoToReceivedPayment}</div>
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Employee",
      field: "employee",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellRenderer: handleEmployee,
    },
    {
      headerName: "Finance Authorization",
      field: "financeAuthorization",
      filter: true,
      cellRenderer: handleFinanceAuth,
    },
    {
      headerName: "Action",
      field: "id",
      filter: true,
      cellRenderer: paymentModeAction,
    },
  ];

  return (
    <div className="my-6">
      <div className="flex items-center justify-end gap-4">
        <button
          type="button"
          className="flex items-center justify-center gap-2 h-10 w-[181px] border border-[#DDE2E4] rounded-md bg-white hover:bg-gray-300 duration-300 ease-[0.3s]"
        >
          <TbPencil size={17} />
          <div className="text-[14px] leading-6 tracking-[-0.6%] text-[#252C32] font-medium">
            Edit Payment Mode
          </div>
        </button>
        <AddButton
          text={"Add Payment Mode"}
          onClick={() => setOpenModal(true)}
        />
      </div>

      {openModal && <AddPaymentMode close={() => setOpenModal(false)} />}

      {/* Custom table */}
      <div className="mt-4">
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default PaymentModes;
