import React, { useEffect, useState } from "react";
import { TbPencil } from "react-icons/tb";
import { isObjEmpty } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { FiArrowRight } from "react-icons/fi";
import CustomeTable from "../../../components/CustomeTable";
import { DeleteButton, EditButton } from "../../../components";

const AllTaxesDataTable = (props) => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (props?.taxes && !isObjEmpty(props?.taxes?.taxes)) {
      setDatas([
        {
          name: props?.taxes?.taxes?.name,
          country: props?.taxes?.taxes?.country,
          taxCategory: props?.taxes?.taxes?.taxCategory,
          taxType: props?.taxes?.taxes?.taxType,
          minimum: props?.taxes?.taxes?.minimum,
          maximum: props?.taxes?.taxes?.maximum,
          tax: props?.taxes?.taxes?.tax,
          _id: props?.taxes?.taxes?._id,
        },
      ]);
    }
  }, [props?.taxes]);

  const gotoNextTab = () => {
    navigate(`${ROUTES.HOTEL_CONFIG}/7`);
  };
  const skipTheTab = () => {
    navigate(`${ROUTES.HOTEL_CONFIG}/7`);
  };

  const tableData = [
    {
      _id: "TX001",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
    {
      _id: "TX002",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
    {
      _id: "TX003",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
    {
      _id: "TX004",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
    {
      _id: "TX005",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
  ];
  const viewAllTaxesActions = () => {
    return (
      <div className="h-full flex gap-4 items-center">
        <EditButton />
        <DeleteButton />
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Tax ID",
      field: "_id",
      minWidth: 100,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      filter: true,
    },
    {
      headerName: "Country",
      field: "country",
      filter: true,
    },
    {
      headerName: "Tax Category",
      field: "taxCategory",
      filter: true,
    },
    {
      headerName: "Tax Type",
      field: "taxType",
      filter: true,
    },
    {
      headerName: "Minimum",
      field: "minimum",
      filter: true,
    },
    {
      headerName: "Maximum",
      field: "maximum",
      filter: true,
    },
    {
      headerName: "Tax",
      field: "tax",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 200,
      cellRenderer: viewAllTaxesActions,
    },
  ];

  return (
    <div className="mt-4">
      <CustomeTable
        tableName={"Tax List Table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      <div className="flex justify-end items-center gap-4 text-sm font-medium my-4">
        <button
          className="h-[36px] w-[58px] border border-[#E5E9EB] rounded-md bg-white hover:bg-slate-100 text-[#252C32]"
          onClick={skipTheTab}
        >
          Skip
        </button>
        <button
          className="flex items-center justify-center gap-2 h-[36px] w-[88px] rounded-md text-white bg-base-primary hover:bg-base-primary_hover drop-shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]"
          onClick={gotoNextTab}
        >
          <div>Next</div>
          <FiArrowRight size={20} />
        </button>
      </div>
    </div>
  );
};

export default AllTaxesDataTable;
