import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const MultipleSelectTabView = ({
  label,
  value,
  setValue,
  options,
  required,
}) => {
  const handleSelect = (e) => {
    const selectedValue = e.target.value;
    if (!value.includes(selectedValue)) {
      setValue([...value, selectedValue]);
    }
  };

  const removeItem = (items) => {
    setValue(value.filter((selectedItems) => selectedItems !== items));
  };

  return (
    <div>
      <label htmlFor="label">{label}</label>
      <select
        name="label"
        onChange={handleSelect}
        className="capitalize"
        required={required}
      >
        {Object.keys(options)?.map((item) => {
          return (
            <option value={options[item]} key={item}>
              {item.replace("_", " ").toLocaleLowerCase()}
            </option>
          );
        })}
      </select>
      <ul className="flex flex-wrap gap-2 mt-2">
        {value?.map((items) => (
          <li
            className="flex items-center justify-between gap-1 w-fit h-7 rounded-md border border-[#E5E9EB] bg-white text-[14px] leading-[17.64px] text-[#07090D] capitalize px-[6px]"
            key={items}
          >
            {items.replace("_", " ").toLocaleLowerCase()}
            <button
              type="button"
              onClick={() => removeItem(items)}
              className="transition-all ease-in-out-[0s] duration-300 scale-90 hover:scale-110 hover:text-[#F04438]"
            >
              <IoCloseCircleOutline size={18} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MultipleSelectTabView;
