import React, { useState } from "react";
import { CustomeTable, DeleteButton, EditButton } from "../../components";

const AllLaundry = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      _id: "001",
      roomNo: "101",
      GuestName: "Rahul",
      date: "21-06-2024",
      item: "Coat",
      attributes: "Dry Clean",
      quantity: "01",
      price: "$10",
      discount: "10%",
    },
    {
      _id: "001",
      roomNo: "101",
      GuestName: "Rahul",
      date: "21-06-2024",
      item: "Coat",
      attributes: "Dry Clean",
      quantity: "01",
      price: "$10",
      discount: "10%",
    },
    {
      _id: "001",
      roomNo: "101",
      GuestName: "Rahul",
      date: "21-06-2024",
      item: "Coat",
      attributes: "Dry Clean",
      quantity: "01",
      price: "$10",
      discount: "10%",
    },
    {
      _id: "001",
      roomNo: "101",
      GuestName: "Rahul",
      date: "21-06-2024",
      item: "Coat",
      attributes: "Dry Clean",
      quantity: "01",
      price: "$10",
      discount: "10%",
    },
    {
      _id: "001",
      roomNo: "101",
      GuestName: "Rahul",
      date: "21-06-2024",
      item: "Coat",
      attributes: "Dry Clean",
      quantity: "01",
      price: "$10",
      discount: "10%",
    },
  ];
  const AllLaundryAction = ({ value }) => {
    return (
      <div className="h-full flex items-center gap-4">
        <EditButton />
        <DeleteButton />
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Laundry ID",
      field: "_id",
      minWidth: 160,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "roomNo",
      filter: true,
    },
    {
      headerName: "Guest Name",
      field: "GuestName",
      filter: true,
    },
    {
      headerName: "Date",
      field: "date",
      filter: true,
    },
    {
      headerName: "Item",
      field: "item",
      filter: true,
    },
    {
      headerName: "Attributes",
      field: "attributes",
      filter: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Discount",
      field: "discount",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "id",
      minWidth: 200,
      cellRenderer: AllLaundryAction,
    },
  ];
  return (
    <div className="mt-6">
      <CustomeTable
        tableName={"Laundry List Table"}
        rowData={rowData}
        setSelectedRows={setSelectedRows}
        columnDefs={columnDefs}
      />
    </div>
  );
};

export default AllLaundry;
