import React from "react";
import { RiDeleteBin5Line } from "react-icons/ri";

const DeleteButton = ({
  onClick = () => {},
  disabled = true,
  text = "Delete",
  sizing = "h-[30px] w-fit",
}) => {
  return (
    <button
      className={`${sizing} pl-[3px] pr-2 border border-[#F04438] rounded-md flex items-center justify-center gap-2 duration-[0.3s] ease-[0s] bg-white text-[#F04438] hover:text-white hover:bg-[#F04438] group`}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <div className="flex items-center justify-center rounded text-white bg-[#F04438] group-hover:bg-white group-hover:text-[#F04438] p-[3px]">
        <RiDeleteBin5Line size={16} />
      </div>
      <span className="tracking-[-0.006em] text-[14px] leading-6">{text}</span>
    </button>
  );
};

export default DeleteButton;
