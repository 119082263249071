import React from "react";

const OutlineButton = ({
  text,
  onClick = () => {},
  active,
  sizing = "h-10 w-fit",
}) => {
  return (
    <button
    // active---text-base-primary bg-[#EDF6F2]
      type="button"
      className={`${
        active
          ? "text-white bg-base-primary"
          : "bg-transparent text-base-primary"
      }  px-6 border border-base-primary rounded-md text-sm font-semibold  shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)]  hover:bg-base-primary hover:text-[#F6F8F9] capitalize transition-all duration-300 ease-[0.3s] ${sizing}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default OutlineButton;
