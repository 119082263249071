import React, { useState } from "react";
import Inputfield from "../../components/layouts";
import CloseButton from "../../components/buttons/CloseButton";
import { accessDatas } from "./accessTableData";
import { CustomeTable } from "../../components";
import SubmitButton from "../../components/buttons/SubmitButton";

const CreateNewRole = (props) => {
  const [roleName, setRoleName] = useState("");
  const [manager, setManager] = useState("");

  return (
    <div className="border border-[#D0D5DD]  bg-white rounded-[10px] p-6 mt-6 ">
      <div className="flex items-center justify-between">
        <div className="text-[24px] leading-6 font-medium">
          Individual Access
        </div>
        <CloseButton onClick={props.close} />
      </div>
      <div className="grid grid-cols-2 gap-6 items-center mt-6">
        <Inputfield
          label={"Role Name"}
          placeholder={"Enter Role Name"}
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
        />
        <Inputfield
          label={"Designation"}
          placeholder={"Enter Hr Manager"}
          value={manager}
          onChange={(e) => setManager(e.target.value)}
        />
      </div>

      <div className="text-[20px] leading-6 font-medium text-[#344054] uppercase mt-8 mb-4">
        Role name - hr manger
      </div>

      <div className="w-full border border-[#D0D5DD] rounded-md bg-white flex gap-4 overflow-x-auto overflow-y-hidden scroll-horizontal p-4 ">
        {accessDatas?.map((d) => {
          return (
            <CustomeTable
              size="h-[60vh] w-[30vw]"
              key={d}
              columnDefs={d.columnDefs}
              rowData={d.items}
              pagination={false}
              showButtons={false}
            />
          );
        })}
      </div>
      <div className="flex items-center justify-end mt-6">
        <SubmitButton type="button" text={"Save as new role"} />
      </div>
    </div>
  );
};

export default CreateNewRole;
