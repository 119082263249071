
import { combineReducers } from 'redux';

import {
    checkInCodeGenerateReducer,
    checkOutGuestReducer,
} from "./pms/checkInCode";
import {
    hotelOwnerAuthReducer,
    hotelOwnerLoginSignupReducer,
} from "./pms/ownerAuth";
import {
    addHotelReducer,
    getHotelConfigurationReducer,
    getHotelReducer,
    getHotelsReducer,
    updateHotelReducer,
} from "./pms/hotel";
import {
    employeeAuthReducer,
    employeeLoginReducer,
} from "./pms/employeeAuth";
import {
    employeeShiftEndReducer,
    employeeShifts,
    employeeShiftStartReducer,
} from "./pms/employeeShift";
import {
    addEmployeeReducer,
    getAllEmployeeReducer,
    getEmployeeByIdReducer,
} from "./pms/employee";
import { getHotelGuestReducer } from "./pms/getHotelGuest";
import { changePasswordReducer } from "./pms/ownerProfile";
import {
    updateEmployeeProfileReducer,
    deleteEmployeeProfileReducer,
} from "./pms/employeeProfile";
import {
    employeeLeaveDeleteReducer,
    employeeLeaveReducer,
} from "./pms/employeeLeave";
import {
    addUpdateAmenityReducer,
    addUpdateContactReducer,
    deleteAmenityReducer,
} from "./pms/hotelProfile";
import {
    ownerProfileReducer,
    updateProfileReducer,
} from "./pms/ownerProfile";
import {
    getTransactionHistoryReducer,
    getWalletReducer,
    updateWalletReducer,
} from "./pms/wallet";
import {
    forgotPasswordReducer,
    matchOtpReducer,
    resetPasswordReducer,
} from "./pms/forgotPassword";
import {
    employeeWorkDeleteReducer,
    employeeWorkReducer,
} from "./pms/employeeWork";
import {
    cancelFoodOrderReducer,
    foodOrderReducer,
    getFoodOrderReducer,
    getFoodOrdersReducer,
} from "./pms/foodOrder";
import { getFoodMenuReducer } from "./pms/foodMenu";
import { googleAuthReducer } from "./pms/googleAuth";
import { getBookingReducer } from "./pms/bookingGet";
import {
    employeePermissionDeleteReducer,
    employeePermissionReducer,
} from "./pms/employeePermission";
import { addRoomReducer, updateRoomReducer } from "./pms/room";
import {
    deleteImagesReducer,
    uploadImagesReducer,
} from "./pms/ImageUpload";
import {
    addKitchenReducer,
    deleteKitchenReducer,
    getAllKitchensReducer,
    getKitchenReducer,
    updateKitchenReducer,
} from "./kitchen/kitchen";
import {
    addKitcheMenuItemReducer,
    deleteKitchenMenuItemReducer,
    getKitchenMenuReducer,
    updateKitchenMenuItemReducer,
} from "./kitchen/menu";
import {
    getInventoryStockByIdReducer,
    getInventoryStockReducer,
    updateInventoryStockReducer,
} from "./kitchen/inventoryStock";
import {
    createInventoryReducer,
    getInventoryItemReducer,
    getOwnerInventoryReducer,
    updateItemFromInventoryReducer,
} from "./kitchen/inventoryOwner";
import { getRoomReducer, getAllRoomReducer } from "./pms/roomget";
import { foodCartReducer } from "./pms/foodMenuCart";
import {
    deleteRoomNumberReducer,
    deleteRoomReducer,
} from "./pms/roomdelete";
import { getAllSalesReport } from "./reports/salesReport";
import { occupanciesReportReducer } from "./reports/occupancyReport";
import {
    getHotelAssetReducer,
    getHotelAssetsAllReducer,
} from "./pms/assetsget";
import {
    addHotelAssetReducer,
    deleteHotelAssetReducer,
    updateHotelAssetReducer,
} from "./pms/asset";
import { assetsReportReducer } from "./reports/assetsReportAllTime";
import { verifyEmailReducer } from "./pms/verifyEmail";
import {
    addBookingSourceReducer,
    getBookingSourceReducer,
} from "./pms/bookingSource";
import {
    addHotelWifiReducer,
    deleteHotelWifiReducer,
    getHotelWifiReducer,
    updateHotelWifiReducer,
} from "./pms/wifi";
import {
    addVendorReducer,
    getAllVendorReducer,
    getVendorByIdReducer,
} from "./pms/vendor";
import {
    deleteVendorProfileReducer,
    updateVendorProfileReducer,
} from "./pms/vendorProfile";
import {
    addBookingReducer,
    deleteBookingReducer,
    updateBookingReducer,
} from "./pms/booking";
import {
    propertyConfiguredPercentage,
    verifyPropertyReducer,
} from "./pms/verifyProperty"; 
import {
    assignHouseKeepingReducer,
    dirtyRoomsReducer,
    updateRoomStatusReducer,
} from "./pms/houseKeeping";
import {
    answereGuestQuery,
    getAllQueryReducer,
} from "./pms/guestSupport";
import {
    reSendOTPReducer,
    sendOTPReducer,
    verifyOTPReducer,
} from "./pms/verification";
import {
    approveReviewReducer,
    deleteReviewReducer,
    getAllReviewReducer,
    replyReviewReducer,
} from "./pms/reviews";
import {
    addExpenseReducer,
    getAllExpenseReducer,
} from "./pms/expense";
import { hamburgerReducer } from "./pms";



export const appReducer = combineReducers({
    //owner authentication
    Authenticate: hotelOwnerAuthReducer,
    LoginSignup: hotelOwnerLoginSignupReducer,
    OwnerProfile: ownerProfileReducer,
    UpdateProfile: updateProfileReducer,
    GoogleAuth: googleAuthReducer,
    VerifyProfile: verifyEmailReducer,

    //forgot password
    ForgotPassword: forgotPasswordReducer,
    VerifyOTP: matchOtpReducer,
    ResetPassword: resetPasswordReducer,

    //owner profile
    ChangePassword: changePasswordReducer,

    //employee auth
    EmployeeAuth: employeeAuthReducer,
    EmployeeLogin: employeeLoginReducer,

    //employee profile  //  HOTEL&KITCHEN
    AddEmployee: addEmployeeReducer,
    AllEmployee: getAllEmployeeReducer,
    Employee: getEmployeeByIdReducer,
    UpdateEmployeeProfile: updateEmployeeProfileReducer,
    DeleteEmployeeProfile: deleteEmployeeProfileReducer, 

    //employee extra-work, leave, permission
    AddLeave: employeeLeaveReducer,
    DeleteLeave: employeeLeaveDeleteReducer,
    AddExtraWork: employeeWorkReducer,
    DeleteWork: employeeWorkDeleteReducer,
    AddEmployeePermission: employeePermissionReducer,
    DeleteEmployeePermission: employeePermissionDeleteReducer,

    //employee shift
    EmployeeShiftStart: employeeShiftStartReducer,
    EmployeeShiftEnd: employeeShiftEndReducer,
    EmployeeShifts: employeeShifts,

    //guest
    GuestHotel: getHotelGuestReducer,

    //checkin checkout code
    GenerateCode: checkInCodeGenerateReducer,
    CheckOutGuest: checkOutGuestReducer,

    //hotel
    Hotel: getHotelReducer,
    AddHotel: addHotelReducer,
    AllHotel: getHotelsReducer,
    UpdateHotel: updateHotelReducer,
    HotelConfiguration: getHotelConfigurationReducer,

    Configuration: propertyConfiguredPercentage,
    // hotel amenities
    AddAmenity: addUpdateAmenityReducer,
    DeleteAmenity: deleteAmenityReducer,
    Contacts: addUpdateContactReducer,

    //verify hotel
    VerifyProperty: verifyPropertyReducer,

    //ROOM
    Room: getRoomReducer,
    AddRoom: addRoomReducer,
    AllRoom: getAllRoomReducer,
    UpdateRoom: updateRoomReducer,
    DeleteRoom: deleteRoomReducer,
    DeleteRoomNumber: deleteRoomNumberReducer,

    // BOOKING
    AddBooking: addBookingReducer,
    AllBooking: getBookingReducer,
    UpdateBooking: updateBookingReducer,
    DeleteBooking: deleteBookingReducer,

    //kitchen
    Kitchen: getKitchenReducer,
    AllKitchen: getAllKitchensReducer,
    AddKitchen: addKitchenReducer,
    UpdateKitchen: updateKitchenReducer,
    DeleteKitchen: deleteKitchenReducer,

    //menu item
    AllMenuItem: getKitchenMenuReducer,
    AddMenuItem: addKitcheMenuItemReducer,
    UpdateMenuItem: updateKitchenMenuItemReducer,
    DeleteMenuItem: deleteKitchenMenuItemReducer,

    //FOOD ORDER
    FoodMenu: getFoodMenuReducer,
    AddFoodOrder: foodOrderReducer,
    FoodOrder: getFoodOrderReducer,
    AllFoodOrder: getFoodOrdersReducer,
    CancelFoodOrder: cancelFoodOrderReducer,
    FoodCart: foodCartReducer,

    //upload image
    UploadImage: uploadImagesReducer,
    DeleteImage: deleteImagesReducer,

    //track hotel reviews
    // HotelReviews: getHotelReviewsReducer,

    //inventory stock
    AllInventoryStock: getInventoryStockReducer,
    InventoryStock: getInventoryStockByIdReducer,
    UpdateInventoryStock: updateInventoryStockReducer,

    //inventory
    Inventory: getInventoryItemReducer,
    AddInventory: createInventoryReducer,
    AllInventory: getOwnerInventoryReducer,
    UpdateInventory: updateItemFromInventoryReducer,

    //REPORTS
    SalesReport: getAllSalesReport,
    OccupanciesReport: occupanciesReportReducer,
    AssetsReport: assetsReportReducer,

    //HOTEL ASSETS
    AllAsset: getHotelAssetsAllReducer,
    Asset: getHotelAssetReducer,
    AddAsset: addHotelAssetReducer,
    UpdateAsset: updateHotelAssetReducer,
    DeleteAsset: deleteHotelAssetReducer,

    //BOOKING SOURCE
    AddBookingSource: addBookingSourceReducer,
    GetBookingSource: getBookingSourceReducer,

    //HOTEL WIFI
    AllWifi: getHotelWifiReducer,
    AddWifi: addHotelWifiReducer,
    UpdateWifi: updateHotelWifiReducer,
    DeleteWifi: deleteHotelWifiReducer,

    //vendor
    AddVendor: addVendorReducer,
    AllVendor: getAllVendorReducer,
    Vendor: getVendorByIdReducer,
    UpdateVendorProfile: updateVendorProfileReducer,
    DeleteVendorProfile: deleteVendorProfileReducer,

    //wallet
    Wallet: getWalletReducer,
    Transactions: getTransactionHistoryReducer,
    UpdateWallet: updateWalletReducer,

    //HOUSE KEEPING
    DirtyRooms: dirtyRoomsReducer,
    UpdateDirtyRoom: updateRoomStatusReducer,
    AssingHouseKeeping: assignHouseKeepingReducer,

    //GUEST SUPPORT
    AllQuery: getAllQueryReducer,
    AnsQuery: answereGuestQuery,

    //phone otp verify
    SendOTP: sendOTPReducer,
    ReSendOTP: reSendOTPReducer,
    VerifyPhoneOTP: verifyOTPReducer,

    //reviews
    AllReview: getAllReviewReducer,
    ReplyReview: replyReviewReducer,
    ApproveReview: approveReviewReducer,
    DeleteReivew: deleteReviewReducer,

    //expense
    AllExpense: getAllExpenseReducer,
    AddExpense: addExpenseReducer,
    Hamburger: hamburgerReducer,
});