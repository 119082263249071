import React from "react";
import { useState } from "react";
import { GrClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import Inputfield from "../../../components/layouts";
import { useEffect } from "react";

const TaxSlabMiddleComponent = ({
  taxRule,
  setTaxRule,
  index,
  setChanged,
  setCompCtn,
  compCtn,
  isupdate,
}) => {
  const { t } = useTranslation();
  const [price, setPrice] = useState("");
  const [percentage, setPercentage] = useState("");

  //set tax price and percentage
  const maxPrice = (e) => {
    const value = e.target.value;
    if (+value > 100000 - 2) return;

    setPrice(value);

    if (taxRule[index + 1]) {
      taxRule[index + 1].maximum = +value;
      taxRule[index + 2].minimum = +value + 1;
      setTaxRule(taxRule);
    }
    setChanged(true);
  };

  //remove this slab
  const removeSlab = () => {
    setCompCtn(compCtn - 1);
    taxRule.pop();
    setTaxRule(taxRule);
    setChanged(true);
  };

  //set tax percentage
  const taxPercentageset = (value) => {
    setPercentage(value);
    if (taxRule.length && +value <= 100) {
      taxRule[index + 1].taxPercentage = +value;
      setTaxRule(taxRule);
      setChanged(true);
    }
  };

  useEffect(() => {
    if (isupdate) {
      setPrice(taxRule[index + 1]?.maximum);
      setPercentage(taxRule[index + 1]?.taxPercentage);
    }
  }, [isupdate]);

  return (
      <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 grid-w-full">
        <Inputfield
          type="number"
          label={t("441")}
          value={+taxRule[index].maximum + 1}
          onChange={(e) => {}}
          readOnly={true}
          message={t("748")}
        />
        <Inputfield
          type="number"
          label={t("437")}
          value={price}
          onChange={(e) => maxPrice(e)}
          message={t("749")}
          required={true}
          max={100000}
          min={+taxRule[index].maximum + 1}
        />
        <Inputfield
          type="number"
          label={t("439")}
          value={percentage}
          onChange={(e) => taxPercentageset(e.target.value)}
          message={t("750")}
          min={0}
          max={100}
          required={true}
        />
        <button onClick={removeSlab} className="ml-3" type="button">
          <GrClose />
        </button>
      </div>

  );
};

export default TaxSlabMiddleComponent;
