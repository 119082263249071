import React from "react";
import { useTranslation } from "react-i18next";

const SupportUser = (props) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-start px-3 py-2 border-b-gray-300 border-b last:border-b-0 justify-between cursor-pointer hover:bg-gray-100">
      {/* profile */}
      <div className="h-10 w-10 min-w-[40px] rounded-full bg-base-rgba_primary_2 flex items-center justify-center text-base-primary text-sm font-semibold">
        {t("943")}
      </div>
      {/* mid */}
      <div className="w-[calc(100%-50px)] flex flex-col">
        <div className="flex items-center justify-between">
          <div className="text-[16px] leading-5 font-semibold tracking-[-0.6%]">
            {t("Mayme Johnston")}
          </div>
          <div className="text-[12px] leading-4 font-normal text-[#707991]">
            50 minuts ago
          </div>
        </div>
        {/* booking from => to */}
        <div className="flex items-center justify-between pt-1">
          <div className="text-[14px] leading-[18px] font-normal text-[#707991]">
            {t("counts")}
          </div>
          <div className="w-[18px] h-[18px] rounded-full bg-base-primary flex items-center justify-center text-[12px] leading-4 font-normal text-white">
            2
          </div>
        </div>

        {/* <div className="text-xs text-gray-700 font-semibold capitalize">
            {t("3 nigths")}
          </div> */}
      </div>
    </div>
  );
};

export default SupportUser;
