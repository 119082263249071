import React, { useState } from "react";
import AddAmenitiesToRoom from "./AddAmenitiesToRoom";
import {
  CustomeTable,
  DeleteButton,
  EditButton,
  OutlineButton,
} from "../../../components";
import SubmitButton from "../../../components/buttons/SubmitButton";
import RoomAmenityConfig from "./RoomAmenityConfig";

const RoomAmenities = () => {
  const [selectedRows, setSelectedRows] = useState();
  const [openAddAmenitiesToRoom, setOpenAddAmenitiesToRoom] = useState(false);
  const [roomAmenityConfig, setRoomAmenityConfig] = useState(false);
  const rowData = [
    {
      roomAmenitiesListId: "R101",
      roomAmenitiesListName: "Room Amenities List 1",
      action: ["Select Room", "Add", "View"],
    },
    {
      roomAmenitiesListId: "R102",
      roomAmenitiesListName: "Room Amenities List 1",
      action: ["View Minibar", "Add Minibar"],
    },
    {
      roomAmenitiesListId: "R103",
      roomAmenitiesListName: "Room Amenities List 1",
      action: ["View Minibar", "Add Minibar"],
    },
  ];

  const RoomAmenitiesActions = ({ value }) => {
    return (
      <div className="h-full flex items-center gap-2">
        {value?.map((v) => (
          <button
            type="button"
            key={v}
            className="h-8 px-2 rounded-md border border-[#D2D2D2] hover:bg-gray-300 duration-300 ease-[0.3s] text-[14px] leading-6 text-[#252C32]"
          >
            {v}
          </button>
        ))}
        <EditButton />
        <DeleteButton />
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Room Amenities List Id",
      field: "roomAmenitiesListId",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Amenities List Name",
      field: "roomAmenitiesListName",
      filter: true,
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 400,
      filter: true,
      cellRenderer: RoomAmenitiesActions,
    },
  ];
  const handleAddAmenitiesToRoom = () => {
    setOpenAddAmenitiesToRoom(true);
    setRoomAmenityConfig(false);
  };
  const handleAmenityConfig = () => {
    setRoomAmenityConfig(true);
    setOpenAddAmenitiesToRoom(false);
  };
  return (
    <div>
      <div className="flex items-center justify-end gap-4">
        <SubmitButton
          text={"Add Amenities To Room"}
          onClick={handleAddAmenitiesToRoom}
        />
        <OutlineButton
          sizing={"bg-white h-10"}
          text={"Room Amenities Configuration"}
          onClick={handleAmenityConfig}
        />
      </div>
      {openAddAmenitiesToRoom ? (
        <AddAmenitiesToRoom close={() => setOpenAddAmenitiesToRoom(false)} />
      ) : null}
      {roomAmenityConfig ? (
        <RoomAmenityConfig close={() => setRoomAmenityConfig(false)} />
      ) : null}
      <div className="mt-4">
        <CustomeTable
          tableName={"Room Amenities table"}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
};

export default RoomAmenities;
