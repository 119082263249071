import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { clearError } from "../../actions/clearError";
import { getAllOccupancyReport } from "../../actions/reports/occupancyReport";
import {
  occupancyReportByDate,
  occupancyReportByMonth,
  occupancyReportByYear,
} from "../../actions/reports/occupancyReportBy";
import ReportsCard from "./ReportsCard";
import totalRoomsIcon from "../../images/icons/totalRoomsIcon.svg";
import homeLockIcon from "../../images/icons/homeLockIcon.svg";
import roomkeyIcon from "../../images/icons/roomkeyIcon.svg";
import CustomeTable from "../../components/CustomeTable";
import DownloadButton from "../../components/buttons/DownloadButton";

const OccupancyReport = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    //get booking sales by date
    if (props?.fromDate && props?.toDate) {
      props?.byDateOccupancy(props?.hotel?._id, props?.fromDate, props?.toDate);
    }
    // get booking sales by month
    else if (props?.fromMonth && props?.toMonth) {
      props?.byMonthOccupancy(
        props?.hotel?._id,
        props?.fromMonth,
        props?.toMonth
      );
    }
    //get booking sales by year
    else if (props?.fromYear && props?.toYear) {
      props?.byYearOccupancy(props?.hotel?._id, props?.fromYear, props?.toYear);
    }
    //get all booking sales
    else if (!props?.fromDate && !props?.fromMonth && !props?.fromYear) {
      props?.getOccupancies(props?.hotel?._id);
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          roomNumber: d?.roomNumber,
          roomType: d?.roomType,
          guestName: d?.guestName,
          checkInDate: d?.checkInDate,
          checkOutDate: d?.checkOutDate,
          revenue: d?.revenue,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      id: "RM001",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$400",
    },
    {
      id: "RM002",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$300",
    },
    {
      id: "RM003",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$200",
    },
    {
      id: "RM004",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$1000",
    },
    {
      id: "RM005",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$500",
    },
  ];

  const columnDefs = [
    {
      headerName: "Room ID",
      field: "id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Number",
      field: "roomNumber",
      filter: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      filter: true,
    },
    {
      headerName: "Guest Name",
      field: "guestName",
      filter: true,
    },
    {
      headerName: "Check-In-Date",
      field: "checkInDate",
      filter: true,
    },
    {
      headerName: "Check-Out-Date",
      field: "checkOutDate",
      filter: true,
    },
    {
      headerName: "Revenue",
      field: "revenue",
      filter: true,
    },
  ];

  return (
    <div className="page-container">
      <div className="my-4 grid grid-cols-3 items-center gap-4">
        <ReportsCard
          icon={totalRoomsIcon}
          iconBgColor={"bg-[#FFF6ED]"}
          heading={"Total Rooms"}
          value={"1258"}
        />
        <ReportsCard
          icon={homeLockIcon}
          iconBgColor={"bg-[#F9F5FF]"}
          heading={"Total Booking"}
          value={"800"}
        />
        <ReportsCard
          icon={roomkeyIcon}
          iconBgColor={"bg-[#F9F5FF]"}
          heading={"Available Rooms"}
          value={"425"}
        />
      </div>
      {/* {datas?.datas?.length ? ( */}
      <CustomeTable
        tableName={"Room Occupancy Report table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.OccupanciesReport,
});

const mapDispatchToProps = (dispatch) => ({
  getOccupancies: (hotelId) => dispatch(getAllOccupancyReport(hotelId)),
  //get occupancy by date
  byDateOccupancy: (hotelId, fromDate, toDate) =>
    dispatch(occupancyReportByDate(hotelId, fromDate, toDate)),
  //get occupancy by month
  byMonthOccupancy: (hotelId, fromMonth, toMonth) =>
    dispatch(occupancyReportByMonth(hotelId, fromMonth, toMonth)),
  //occupancy by year
  byYearOccupancy: (hotelId, fromYear, toYear) =>
    dispatch(occupancyReportByYear(hotelId, fromYear, toYear)),
  clear: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OccupancyReport);
