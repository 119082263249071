/* eslint-disable no-unused-vars */
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import PageLoader from "../../components/loader/PageLoader";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/buttons/AddButton";
import Seperator from "../../components/layouts/Seperator";
import AllBuffet from "./AllBuffet";
import AddBuffet from "./AddBuffet";
import { ROUTES } from "../../constants";
import AllUpcommingEventBuffet from "./AllUpcommingEventBuffet";
import SpecialDateHotelGuestBuffetTable from "./SpecialDateHotelGuestBuffetTable";

const BuffetPage = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [kitchen, setKitchen] = useState(null);
  const { kitchen: storeKitchen } = useSelector((state) => state.Kitchen);
  const [index, setIndex] = useState([1]);
  //open modal
  const openModal = () => {
    setOpen(true);
    console.log(kitchen, open);
    registerEvent(t("open modal"), t("add Buffet"));
  };

  //close modal
  const closeModal = () => setOpen(false);
  const data = {
    heading: t("Kladee’s Cafe - Buffet"),
    seperator: [
      {
        text: t("Kitchen Management"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      { text: t("1001") },
    ],
  };
  const headerData = [
    {
      id: 1,
      name: "Hotel Guest Buffet Table",
    },
    {
      id: 2,
      name: "Upcomming Event Buffet Table",
    },
    {
      id: 3,
      name: "Special Date Hotel Guest Buffet",
    },
  ];
  const handleHeaderButton = (id) => {
    setIndex(id);
  };
  
  return (
    <>
      {props?.kitchen && props?.kitchen?.loading ? (
        <PageLoader />
      ) : (
        <div className="page-container p-0 mb-4">
          <div className="w-full p-4 pb-0 ">
            <div className="flex justify-between items-end">
              <Seperator data={data} />
              <div className="flex gap-2">
                <AddButton text={t("1036")} onClick={openModal} />
              </div>
            </div>
            {isObjEmpty(kitchen) && open ? (
              <AddBuffet close={closeModal} hotel={props?.hotel?.hotel} />
            ) : null}

            <div className="flex items-center gap-4 mt-12">
              {headerData?.map((header) => {
                const { id, name } = header;
                return (
                  <button
                    type="button"
                    className={`h-[59px] border rounded-lg text-[16px] leading-6  shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] px-6  ${+index === id ? "border-base-primary text-white bg-base-primary" : "border-[#D0D5DD] text-[#07090D] bg-white"}`}
                    key={id}
                    onClick={() => handleHeaderButton(id)}
                  >
                    {name}
                  </button>
                );
              })}
            </div>

            <div className="mt-6">
              {+index === 1 ? (
                <AllBuffet
                  setKitchen={setKitchen}
                  hotel={props?.hotel?.hotel}
                />
              ) : null}
              {+index === 2 ? <AllUpcommingEventBuffet /> : null}
              {+index === 3 ? <SpecialDateHotelGuestBuffetTable /> : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.Hotel,
  kitchen: state.Kitchen,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BuffetPage);
