import React, { useState } from "react";
import Packages from "./components/Packages";
import NewCustomers from "./NewCustomers";
import RecentActivity from "./components/RecentActivity";
import SalesRevenue from "./Charts/SalesRevenue";
import RoomBookingChart from "./Charts/RoomBookingChart";
import DashBoardHead from "./DashBoardHead";
import { connect, useSelector } from "react-redux";
import { getToday } from "../../utils";
import PageLoader from "../../components/loader/PageLoader";
import PropertyStatus from "./PropertyStatus";
import { useTranslation } from "react-i18next";

const Dashboard = (props) => {
  const [date, setDate] = useState(getToday());
  const { hamburger } = useSelector((state) => state.Hamburger);
  const { t } = useTranslation();

  const externalBoxStyle =
    "w-full sm:w-[50%] h-[327px] rounded-xl border border-[#E4E4E7] bg-white shadow-[0_4.04px_12.13px_0_rgba(0,0,0,0.04)]";
  return (
    <>
      {props?.bookings && props?.bookings?.loading ? (
        <PageLoader />
      ) : (
        <div className="page-container">
          <DashBoardHead date={date} setDate={setDate} />
          <PropertyStatus bookings={props?.bookings?.bookings} date={date} />
          <div
            className={`${
              hamburger ? "lg:flex-col" : "lg:flex-row"
            } w-full flex flex-col xl:flex-row gap-6`}
          >
            <div
              className={`${
                hamburger ? "w-full" : "lg:w-[70%] lg:min-w-[65%]"
              }`}
            >
              <div className="w-full h-[327px] bg-white border border-[#E4E4E7] py-4 px-6 rounded-xl overflow-x-auto">
                <div className="">
                  <div className="relative flex items-center justify-between">
                    <p className="font-bold text-sm">{t("1029")}</p>
                    <div className="flex gap-4 items-center">
                      <button className="text-[11px] font-bold px-4 border rounded-md h-[27px]">
                        {t("1030")}
                      </button>
                      <button className="text-[11px] font-bold px-4 rounded-md h-[27px]">
                        {t("1031")}
                      </button>
                      <button className="text-[11px] font-bold px-4 rounded-md h-[27px]">
                        {t("1032")}
                      </button>
                      <button className="text-[11px] font-bold px-4 rounded-md h-[27px]">
                        {t("1033")}
                      </button>
                    </div>
                    <button className="w-[86px] h-[27px] text-[10px] font-bold border rounded-md">
                      {t("1034")}
                    </button>
                  </div>
                  <SalesRevenue />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-6 mt-6">
                <div className={`${externalBoxStyle} px-8 py-5`}>
                  <NewCustomers bookings={props?.bookings?.bookings} />
                </div>
                <div className={`${externalBoxStyle} px-8 py-5`}>
                  <RecentActivity />
                </div>
              </div>
            </div>

            <div
              className={`${
                hamburger
                  ? "w-full lg:flex-col lg:w-[30%]"
                  : "lg:flex-col lg:w-[30%]"
              } flex flex-col sm:flex-row gap-6`}
            >
              <div className={`${externalBoxStyle} lg:w-full`}>
                <Packages />
              </div>
              <div className={`${externalBoxStyle} lg:w-full`}>
                <RoomBookingChart />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.AllBooking,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
