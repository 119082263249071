import React from "react";
import ToolTip from "../ToolTip";
import { TRUE_FALSE } from "../../constants";

const SelectTrueFalse = ({ label, value, setValue, tooltip }) => {
  //set value
  const selectValue = async (val) => {
    await setValue(JSON.parse(val));
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <label>{label}</label>
        <ToolTip Message={tooltip} />
      </div>
      <div className="relative flex items-center">
        <select
          className="border capitalize border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12"
          value={value}
          onChange={(e) => selectValue(e.target.value)}
        >
          {Object.keys(TRUE_FALSE)?.map((item, index) => (
            <option value={TRUE_FALSE[item]} key={index}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectTrueFalse;
