import React from "react";
import { RxCross2 } from "react-icons/rx";

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <button type="button" className="w-fit">
      <RxCross2
        size={26}
        onClick={onClick}
        className="transition-all ease-in-out-[0s] duration-300 scale-90 hover:scale-110 hover:text-[#F04438]"
      />
    </button>
  );
};

export default CloseButton;
