import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomeTable } from "../../components";

function BillingSummary() {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      roomType: "Deluxe",
      totalRooms: "1",
      roomTarrif: "2",
      totalNights: "4",
      cgst: "$200",
      sgst: "$200",
      igst: "$200",
      ut: "$200",
      vat: "$200",
      totleRoomTarrif: "$600",
    },
    {
      roomType: "Suit",
      totalRooms: "1",
      roomTarrif: "2",
      totalNights: "4",
      cgst: "$100",
      sgst: "$50",
      igst: "$50",
      ut: "$200",
      vat: "$600",
      totleRoomTarrif: "$3500",
    },
  ];

  const columnDefs = [
    {
      headerName: "",
      field: "_id",
      maxWidth: 30,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      filter: true,
    },
    {
      headerName: "Total Rooms",
      field: "totalRooms",
      filter: true,
    },
    {
      headerName: "Room Tarrif",
      field: "roomTarrif",
      filter: true,
    },
    {
      headerName: "Total Nights",
      field: "totalNights",
      filter: true,
    },
    {
      headerName: "CGST",
      field: "cgst",
      filter: true,
    },
    {
      headerName: "SGST",
      field: "sgst",
      filter: true,
    },

    {
      headerName: "IGST",
      field: "igst",
      filter: true,
    },

    {
      headerName: "UT",
      field: "ut",
      filter: true,
    },
    {
      headerName: "VAT",
      field: "vat",
      filter: true,
    },
    {
      headerName: "Totle Room Tarrif",
      field: "totleRoomTarrif",
      filter: true,
    },
  ];
  return (
    <div className="mt-4 mb-6">
      <CustomeTable
        tableName={t("147")}
        columnDefs={columnDefs}
        rowData={rowData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
}

export default BillingSummary;
