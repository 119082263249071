import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { ROLE } from '../constants'
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ permissions }) => {

    const { owner } = useSelector((state) => state.Authenticate);
    const { employee } = useSelector((state) => state.EmployeeAuth)

    
  return <Outlet />;
};

export default ProtectedRoute;
