/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addAssetAction, updateAssetAction } from "../../actions/pms/asset";
import { registerEvent } from "../../logs";
import { THRESHOLD_CONSUME_IN } from "../../constants"; 
import { SaveOrNot } from "../../components";
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import { notifyError, notifySuccess } from "../../components/alert";
import {
  ADD_HOTEL_ASSET_RESET,
  UPDATE_HOTEL_ASSET_RESET,
} from "../../actions/types/asset";
import { clearError } from "../../actions/clearError"; 
import SelectFromObject from "../../components/layouts/SelectFromObject";
import FormSubmitButton from "../../components/buttons";

const UpdateAssets = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [pricePerItem, setPricePerItem] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [thresholdValue, setThresholdValue] = useState("");
  const [consumeIn, setConsumeIn] = useState();
  // const [consumptionRate, setConsumptionRate] = useState("ROOM_BOOKING");
  const [vendorId, setVendorId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [vendorID, setVendorID] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const submitTheData = async () => {
    const data = {
      hotelId: props?.hotel?._id,
      name,
      brand,
      description,
      pricePerItem,
      availableQuantity,
      thresholdValue,
      consumeIn,
      vendorId: vendorId ? vendorId : null,
      position: t("568"),
    };

    if (props?.asset?._id) {
      await props.updateNow({ ...data, assetId: props?.asset?._id });
      registerEvent("click update asset", "asset page", data);
    } else {
      await props.addNow(data);
      registerEvent("click add asset", "asset page", data);
    }
  };

  const closeModal = () => setOpenModal(false);

  useEffect(() => {
    if (props?.asset && !isObjEmpty(props?.asset)) {
      setName(props?.asset?.name);
      setBrand(props?.asset?.brand);
      setDescription(props?.asset?.description);
      setPricePerItem(props?.asset?.pricePerItem);
      setAvailableQuantity(props?.asset?.availableQuantity);
      setThresholdValue(props?.asset?.thresholdValue);
      setConsumeIn(props?.asset?.consumeIn);
      setVendorId(props?.asset?.vendorId);
    }
  }, [props?.asset]);

  useEffect(() => {
    if (props?.add?.added || props?.add?.updated) {
      setName("");
      setBrand("");
      setDescription("");
      setPricePerItem("");
      setAvailableQuantity("");
      setThresholdValue("");
      setConsumeIn("");
      setVendorId("");
      props?.close();
    }
    if (props?.add?.added) {
      notifySuccess(t("674"));
      props?.reset();
    }

    if (props?.update?.updated) {
      notifySuccess(t("675"));
      props?.reset();
    }
    if (props?.update?.error || props?.add?.error) {
      notifyError(t("676"));
      props?.clear();
    }
  }, [props?.update, props?.add]);

  return (
    <>
      <div className="mt-6">
        <div className="w-full max-w-7xl mx-auto bg-white rounded-lg border px-6 pt-6 pb-8  relative">
          <div className="flex items-center justify-between">
            {!props.asset ? (
              <div className="font-bold text-[30px] leading-[37.8px] text-black tracking-[-1.5%]">
                {t("326")}
              </div>
            ) : (
              <div className="font-bold text-[30px] leading-[37.8px] text-black tracking-[-1.5%]">
                {t("328")}
              </div>
            )}
            {/* <button className="-mr-1" onClick={props?.close}>
              <RxCross2
                size={26}
                className="hover:rotate-90 duration-500 hover:text-[#F04438]"
              />
            </button> */}
          </div>

          <form className="w-full relative mt-6" onSubmit={handleSubmit}>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6 lg:gap-x-8 gap-6 gap-y-4">
              <Inputfield
                label={t("166")}
                placeholder={t("677")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                minLength={3}
                maxLength={30}
                required={true}
              />
              <Inputfield
                label={t("1016")}
                placeholder={t("678")}
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                minLength={3}
                maxLength={30}
              />

              <Inputfield
                label={t("1017")}
                placeholder={t("489")}
                value={pricePerItem}
                onChange={(e) => setPricePerItem(e.target.value)}
                type="number"
                min={0}
                max={30000}
                required={true}
              />

              <Inputfield
                label={t("1018")}
                placeholder={t("679")}
                value={availableQuantity}
                onChange={(e) => setAvailableQuantity(e.target.value)}
                type="number"
                min={0}
                max={30000}
                required={true}
              />
              <Inputfield
                label={t("342")}
                placeholder={t("680")}
                value={thresholdValue}
                onChange={(e) => setThresholdValue(e.target.value)}
                type="number"
                min={0}
                max={30000}
                required={true}
              />
              <SelectFromObject
                label={t("334")}
                options={THRESHOLD_CONSUME_IN}
                value={consumeIn}
                setValue={setConsumeIn}
              />
            </div>
            <div className="grid grid-cols-1 my-4">
              <label className="text-sm font-medium text-[#344054]">
                {t("1020")}
              </label>
              <textarea
                type="text"
                className="h-[78px] pt-2 resize-none overflow-y-auto scroll-barguest"
                placeholder={t("681")}
                minLength={20}
                maxLength={500}
                value={description}
                onChange={(e) => setDescription(e?.target?.value)}
              ></textarea>
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6 lg:gap-x-8 gap-6 gap-y-4">
              <Inputfield
                label={t("485")}
                placeholder={t("682")}
                value={vendorID}
                onChange={(e) => setVendorID(e.target.value)}
              />
            </div>
            <div className="flex justify-end mt-6">
              <FormSubmitButton 
                loading={props?.add?.loading || props?.update?.loading}
                text={!props.asset ? t("327") : t("328")}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
      <SaveOrNot
        isopen={openModal}
        close={closeModal}
        formSubmit={submitTheData}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  add: state.AddAsset,
  update: state.UpdateAsset,
});

const mapDispatchToProps = (dispatch) => ({
  addNow: (data) => dispatch(addAssetAction(data)),
  updateNow: (data) => dispatch(updateAssetAction(data)),
  reset: () =>
    dispatch(
      { type: ADD_HOTEL_ASSET_RESET },
      { type: UPDATE_HOTEL_ASSET_RESET }
    ),
  clear: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAssets);
