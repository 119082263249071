import React, { useState } from "react";
import Inputfield from "../../components/layouts";
import RoleName from "./RoleName";
import { CustomeTable } from "../../components";
import SubmitButton from "../../components/buttons/SubmitButton";

const CreateNewRoles = () => {
  const [roleName, setRoleName] = useState([]);
  const [manager, setManager] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      allHotels: "All Access",
      reservations: "All Access",
      customers: "All Access",
      houseKeeping: "All Access",
      rooms: "All Access",
      booking: "All Access",
      kitchen: "All Access",
      employee: "All Access",
      expense: "All Access",
      foodOrder: "All Access",
      reports: "All Access",
    },
    {
      allHotels: "Micro Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      allHotels: "Micro Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      allHotels: "Micro Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      allHotels: "Micro Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      allHotels: "Micro Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      allHotels: "Micro Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
  ];
  const ViewSelectChecked = ({ value }) => {
    const handleValue = (e) => {
      setIsChecked(e.target.checked?._id);
      if (isChecked && e._id) {
        return value;
      }
    };

    return (
      <div className="h-full flex items-center gap-2">
        <input
          type="checkbox"
          checked={isChecked?._id}
          onChange={handleValue}
          className="h-[15.63px] w-4 border border-[#D0D5DD] rounded accent-current"
        />
        <p>{value}</p>
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "All Hotels",
      field: "allHotels",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Reservations",
      field: "reservations",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Customers",
      field: "customers",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "House Keeping",
      field: "houseKeeping",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Rooms",
      field: "rooms",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Booking",
      field: "booking",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Kitchen",
      field: "kitchen",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Employee",
      field: "employee",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Expense",
      field: "expense",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Food Order",
      field: "foodOrder",
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Reports",
      field: "reports",
      cellRenderer: ViewSelectChecked,
    },
  ];
  return (
    <div>
      <div className="grid grid-cols-2 gap-6 items-center my-6">
        <Inputfield
          placeholder={"Enter Role Name"}
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
        />
        <Inputfield
          placeholder={"Enter Hr Manager"}
          value={manager}
          onChange={(e) => setManager(e.target.value)}
        />
      </div>

      <div className="w-full">
        <CustomeTable
          size={"h-[70vh]"}
          showButtons={false}
          columnDefs={columnDefs}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
        />
      </div>
      <div className="flex items-center justify-end mt-6">
        <SubmitButton type="button" text={"Save as new Role"}/>
      </div>
    </div>
  );
};

export default CreateNewRoles;
