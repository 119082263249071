import React, { useState } from "react";
// import AllKitchen from "./AllKitchen";
import { connect, useSelector } from "react-redux";
import { getKitchenByHotelIdAction } from "../../actions/kitchen/kitchen";
import AddKitchen from "./AddKitchen";

const HotelOwnKitchen = (props) => {
  const [kitchen, setKitchen] = useState(null);
  const { hotel } = useSelector((state) => state.Hotel);
  return (
    <div>
      <AddKitchen
        close={props.closeHotelOwnKitchenModal}
        hotel={props?.hotel?.hotel}
      />
      {/* <div className="mt-4">
        <AllKitchen setKitchen={setKitchen} hotel={props?.hotel?.hotel} />
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.Hotel,
  kitchen: state.Kitchen,
});

const mapDispatchToProps = (dispatch) => ({
  getKitchen: (id) => dispatch(getKitchenByHotelIdAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HotelOwnKitchen);
