import React from "react";
import { useTranslation } from "react-i18next";
import { IoBackspaceOutline } from "react-icons/io5";

const BackButton = ({ onClick = () => {} }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="h-10 px-4 flex justify-center gap-2 items-center border border-[#F04438 rounded-md bg-[#FEF3F2] hover:bg-[#F04438] hover:text-white text-[#F04438] text-[16px] leading-6 font-medium tracking-[-0.6%] duration-300 ease-[0.3s]"
      onClick={onClick}
    >
      <IoBackspaceOutline size={24} />
      {t("Back")}
    </button>
  );
};

export default BackButton;
