import React, { useState } from "react";
import { CloseButton } from "../../../components";
import Inputfield from "../../../components/layouts";
import SelectFromObject from "../../../components/layouts/SelectFromObject";
import SubmitButton from "../../../components/buttons/SubmitButton";

const MinibarItems = (props) => {
  const [foodAndBevrageName, setFoodAndBevrageName] =
    useState("Red Bull (250ml)");
  const [fAndBType, setFAndBType] = useState([]);
  const [dietaryPreference, setDietaryPreference] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [price, setPrice] = useState("$10");

  return (
    <div className="relative w-full bg-white border border-[#D0D5DD] rounded-[10px] mt-4 p-6">
      <div className="text-center text-[26px] leading-[32.76px] font-bold text-[#07090D]">
        Minibar Item List
      </div>
      <div className="absolute right-6 top-[30px]">
        <CloseButton onClick={props.close} />
      </div>

      <div className="grid grid-cols-3 gap-6 px-14 my-6">
        <Inputfield
          label={"Food & Beverages Name"}
          value={foodAndBevrageName}
          onChange={(e) => setFoodAndBevrageName(e.target.value)}
          placeholder={"Enter Food And Beverages Name"}
        />
        <SelectFromObject
          label={"F&B Type"}
          options={{
            snacks: "snacks",
          }}
          value={fAndBType}
          setValue={setFAndBType}
        />
        <SelectFromObject
          label={"Dietary Preference"}
          options={{
            veg: "Veg",
          }}
          value={dietaryPreference}
          setValue={setDietaryPreference}
        />
        <SelectFromObject
          label={"Quantity"}
          options={{
            ml: "ML",
          }}
          value={quantity}
          setValue={setQuantity}
        />
        <Inputfield
          label={"Price to customer"}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder={"Enter price"}
        />
      </div>
      <div className="flex items-center justify-end">
        <SubmitButton text={"Add Minibar"} />
      </div>
    </div>
  );
};

export default MinibarItems;
