import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { ROUTES } from "../../constants";
import TabConfigButton from "../../components/buttons/TabConfigButton";

const SettingsHeader = ({ params }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  // Define the slides
  const slides = [
    { name: t("459"), index: 1 },
    { name: t("Property Info"), index: 2 },
    { name: t("Add Employee"), index: 3 },
    { name: t("Payment Modes"), index: 4 },
    { name: t("Bank Account"), index: 5 },
    { name: t("Auto Sync Booking"), index: 6 },
    { name: t("Corporate Account"), index: 7 },
    { name: t("Local Travel Agent"), index: 8 },
    // { name: t("463"), index: 7 },
    { name: t("464"), index: 9 },
    { name: t("Wifi Config"), index: 10 },
    { name: t("Employee Permission"), index: 11 },
  ];

  const totalVisible = 6;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - totalVisible ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - totalVisible : prevIndex - 1
    );
  };

  return (
    <div className="relative w-full my-4 px-10">
      {/* Previous button */}
      <button
        type="button"
        onClick={prevSlide}
        className="absolute left-0 top-[10px] flex items-center justify-center size-7 border-[0.9px] border-[#D0D5DD] rounded-[3.6px] bg-white shadow-[0_3.6px_18px_0_rgba(0,0,0,0.06)] text-[#8A8A8A] transition-all duration-[0.3s] ease-[0s] hover:opacity-50 z-20"
      >
        <IoIosArrowBack size={22} />
      </button>

      <div className="overflow-hidden">
        <div
          className="transition-transform duration-500 flex items-center justify-between gap-4 px-1"
          style={{
            transform: `translateX(-${currentIndex * (100 / totalVisible)}%)`,
          }}
        >
          {slides.map((slide) => (
            <TabConfigButton
              name={slide.name}
              index={slide.index}
              params={params}
              routes={ROUTES.HOTEL_CONFIG}
              key={slide.index}
            />
          ))}
        </div>
      </div>

      {/* Forward button */}
      <button
        type="button"
        onClick={nextSlide}
        className="absolute right-0 top-[10px] flex items-center justify-center size-7 border-[0.9px] border-[#D0D5DD] rounded-[3.6px] bg-white shadow-[0_3.6px_18px_0_rgba(0,0,0,0.06)] text-[#8A8A8A] transition-all duration-[0.3s] ease-[0s] hover:opacity-50 z-20"
      >
        <IoIosArrowForward size={22} />
      </button>
    </div>
  );
};

export default SettingsHeader;
