import React, { useState } from "react";
import SelectBox from "../layouts/SelectBox";
import CustomModal from "../CustomModal";
import Inputfield from "../layouts";
import { IoSearchOutline } from "react-icons/io5";
import ShiftButton from "../buttons/ShiftButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import SubmitButton from "../buttons/SubmitButton";

const SelectTax = ({ close = () => {} }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const leftSideRow = [
    {
      taxName: "Food GST",
      taxCalculationOn: "Meal Plan",
      taxType: "Percentage",
      taxOnItem: "Food Items",
      min: "0",
      max: "7500",
      tax: "5%",
    },
  ];
  const rightSideRow = [
    {
      taxName: "Food GST",
      taxCalculationOn: "Meal Plan",
      taxType: "Percentage",
      taxOnItem: "Food Items",
      min: "0",
      max: "7500",
      tax: "5%",
    },
  ];
  const columnDefs = [
    {
      headerName: "Tax Name",
      field: "taxName",
      checkboxSelection: true,
    },
    {
      headerName: "Tax Calculation On",
      field: "taxCalculationOn",
    },
    {
      headerName: "Tax Type",
      field: "taxType",
    },
    {
      headerName: "Tax On Item",
      field: "taxOnItem",
    },
    {
      headerName: "Min",
      field: "min",
    },
    {
      headerName: "Max",
      field: "max",
    },
    {
      headerName: "Tax",
      field: "tax",
    },
  ];
  return (
    <CustomModal close={close} heading={"Select Tax"}>
      <div className="h-[71vh] w-[80vw] capitalize">
        <div className="grid grid-cols-10 w-full">
          {/* Left side */}
          <div className="col-span-4">
            <SelectBox
              label={"Available Listed Taxes"}
              heading={"Select Taxes"}
              btnName={"Add Tax"}
              columnDefs={columnDefs}
              rowData={leftSideRow}
              setSelectedRows={setSelectedRows}
            />
          </div>

          {/* Buttons for add and remove */}
          <div className="col-span-2 flex flex-col items-center justify-center gap-6">
            <ShiftButton
              name={"Add"}
              icon={<AiOutlineDoubleRight size={18} />}
            />
            <ShiftButton
              name={"Remove"}
              icon={<AiOutlineDoubleLeft size={18} />}
            />
          </div>

          {/* Right side */}
          <div className="col-span-4 w-full">
            <SelectBox
              label={"Selected Taxes"}
              heading={"Selected Taxes"}
              showBtn={false}
              columnDefs={columnDefs}
              rowData={rightSideRow}
            />
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <SubmitButton sizing={"h-12 w-[183px]"} text={"Add"} />
        </div>
      </div>
    </CustomModal>
  );
};

export default SelectTax;
