import React from "react";
import CustomModal from "../CustomModal";
import SubmitButton from "../buttons/SubmitButton";

const QrPrints = ({ close = () => {} }) => {
  return (
    <CustomModal close={close} heading={"Genrate Qr Code"}>
      <div className="flex items-center justify-between text-[24px] leading-[30.24px] font-semibold tracking-[-1.5%] text-[#07090D] pt-2">
        <h2>Hotal Name</h2>
        <h2>Room#102</h2>
      </div>
      <div className="w-full mt-6">
        {/* qrcode */}
        <div className="flex items-center justify-center">
          <div className="border border-[#E5E9EB] p-3 rounded shadow-base">
            <img
              src={`https://w7.pngwing.com/pngs/144/829/png-transparent-qr-code-information-qr-code-android-qrcode-text-rectangle-monochrome-thumbnail.png`}
              alt="Qr code imgage"
              className="h-[200px] w-[200px]"
            />
            <div className="text-2xl font-bold tracking-[-1.5%] text-[#07090D] flex justify-center mt-2">
              Scane Me
            </div>
          </div>
        </div>
        {/* list */}
        <div className="flex items-start justify-between text-lg font-bold tracking-[-1.5%] text-[#07090D] gap-20 mt-6 ">
          <ul className="list-disc list-inside marker:text-base-primary">
            <li>For Food Menu</li>
            <li>To Order Food</li>
            <li>For Room Service</li>
            <li>To Connect Wifi</li>
          </ul>
          <ul className="list-disc marker:text-base-primary">
            <li>To Call Reception</li>
            <li>To Call HouseKeeping</li>
            <li>And More Services</li>
          </ul>
        </div>
      </div>
      <div className="flex items-center justify-between mt-4">
        <button
          type="button"
          className="h-10 w-[150px] rounded-md flex items-center justify-center shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] bg-[#F04438] hover:bg-[#b62b21] text-sm font-medium text-white duration-300 ease-[0.3s]"
          // onClick={handlePrint}
        >
          Close
        </button>
        <SubmitButton type="button" text={"Print"} sizing={"h-10 w-[150px]"} />
      </div>
    </CustomModal>
  );
};

export default QrPrints;
