import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAIL,
  REGISTER_REQUEST,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
} from "../../actions/types/ownerAuth";
import { CLEAR_ERRORS } from "../../actions/types";

//hotel owner auth reducer
export const hotelOwnerAuthReducer = (state = { owner: null }, action) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        loading: true,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        owner: action.payload,
      };
    case AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//hotel owner login and signup
export const hotelOwnerLoginSignupReducer = (state = { token: null }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case REGISTER_REQUEST:
      return {
        loading: true,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
      };
    case LOGIN_FAIL:
    case REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        token: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
