import { CLEAR_ERRORS } from "../../actions/types";
import {
  ADD_HOTEL_FAIL,
  ADD_HOTEL_REQUEST,
  ADD_HOTEL_RESET,
  ADD_HOTEL_SUCCESS,
  GET_HOTELS_FAIL,
  GET_HOTELS_REQUEST,
  GET_HOTELS_SUCCESS,
  GET_HOTEL_CONFIG_FAIL,
  GET_HOTEL_CONFIG_REQUEST,
  GET_HOTEL_CONFIG_SUCCESS,
  GET_HOTEL_FAIL,
  GET_HOTEL_REQUEST,
  GET_HOTEL_SUCCESS,
  UPDATE_HOTEL_FAIL,
  UPDATE_HOTEL_REQUEST,
  UPDATE_HOTEL_RESET,
  UPDATE_HOTEL_SUCCESS,
} from "../../actions/types/hotel";

//ADD HOTEL - OWNER
export const addHotelReducer = (state = { hotel: {} }, action) => {
  switch (action.type) {
    case ADD_HOTEL_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_HOTEL_SUCCESS:
      return {
        ...state,
        loading: false,
        hotel: action.payload,
        isadded: true,
      };
    case ADD_HOTEL_FAIL:
      return {
        ...state,
        loading: false,
        isadded: false,
        error: action.payload,
      };
    case ADD_HOTEL_RESET:
      return {
        ...state,
        loading: false,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get hotel
export const getHotelReducer = (state = { hotel: {} }, action) => {
  switch (action.type) {
    case GET_HOTEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HOTEL_SUCCESS:
    case UPDATE_HOTEL_SUCCESS:
      return {
        ...state,
        loading: false,
        hotel: action.payload,
      };
    case GET_HOTEL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get all hotels
export const getHotelsReducer = (state = { hotels: null }, action) => {
  switch (action.type) {
    case GET_HOTELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HOTELS_SUCCESS:
      return {
        ...state,
        loading: false,
        hotels: action.payload,
      };
    case GET_HOTELS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//update hotel
export const updateHotelReducer = (state = { hotel: {} }, action) => {
  switch (action.type) {
    case UPDATE_HOTEL_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_HOTEL_SUCCESS:
      return {
        ...state,
        loading: false,
        hotel: action.payload,
        isupdated: true,
      };
    case UPDATE_HOTEL_FAIL:
      return {
        ...state,
        loading: false,
        isupdated: false,
        error: action.payload,
      };
    case UPDATE_HOTEL_RESET:
      return {
        ...state,
        loading: false,
        isupdated: false,
        hotel: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


//get hotel configurations
export const getHotelConfigurationReducer = (state = { configuration: {} }, action) => {
  switch (action.type) {
    case GET_HOTEL_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HOTEL_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        configuration: action.payload,
      };
    case GET_HOTEL_CONFIG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};