import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomeTable } from "../../components";
import QrPrints from "../../components/modals/QrPrints";
import qrcodePrints from "../../images/users/qrcodePrints.png";
import AddButton from "../../components/buttons/AddButton";

const AllQRCode = (props) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    setIsOpen(false);
  };

  const printData = () => {
    return (
      <button
        type="button"
        className="h-8 w-[90px] rounded-md shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] bg-base-primary hover:bg-base-primary_hover text-sm font-medium text-white transition-all duration-300 ease-[0s]"
        onClick={() => setIsOpen(true)}
      >
        Print
      </button>
    );
  };
  const showQRCodeImage = (params) => {
    return (
      // first save qrcode file in svg then fetch svg to display
      <div className="p-[0.5px] border-[0.5px] h-10 w-10 rounded-sm">
        <img src={qrcodePrints} alt="" className="object-cover" />
      </div>
    );
  };

  const rowData = [
    {
      _id: 1255472734423,
      qrCode: "image",
      roomNo: "101",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Active",
    },
    {
      _id: 12423,
      qrCode: "image",
      roomNo: "102",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Inactive",
    },
    {
      _id: 1255423,
      qrCode: "image",
      roomNo: "101",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Active",
    },
    {
      _id: 1255423,
      qrCode: "image",
      roomNo: "104",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Active",
    },
    {
      _id: 1255423,
      qrCode: "image",
      roomNo: "105",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Active",
    },
  ];

  const columnDefs = [
    {
      headerName: "Qr Code ID",
      field: "_id",
      minWidth: 250,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Qr Code",
      field: "qrCode",
      cellRenderer: showQRCodeImage,
    },
    {
      headerName: "Room No",
      field: "roomNo",
      filter: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      filter: true,
    },
    {
      headerName: "No. of Scane For Food Order",
      field: "numberOfScanForFoodOrder",
      filter: true,
    },
    {
      headerName: "Status",
      field: "status",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 150,
      cellRenderer: printData,
    },
  ];

  return (
    <>
      {isOpen ? <QrPrints close={close} /> : null}
      <div className="flex items-end justify-end w-full">
        <AddButton text={"Genrate Code"} />
      </div>
      <div className="w-full mt-6">
        <CustomeTable
          tableName={"QrCode List Table"}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
        />
      </div>
    </>
  );
};
export default AllQRCode;
