import React from "react";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import { useSelector } from "react-redux";
import RoomsHeader from "./RoomsHeader";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/loader/PageLoader";
import FoodBuffetPrice from "./FoodBuffetPrice";
import AllQRCode from "../qrcode";
import PackagesPage from "./packages";
import RoomAmenities from "./room-amenities";
import RoomMinibarList from "./room-minibar/RoomMinibarList";
import RoomMinibarItems from "./room-minibar/RoomMinibarItems";
import AllRooms from "./AllRooms";
import RoomRate from "./RoomRate";
import ServicesPage from "./services";

const RoomConfiguration = () => {
  const { t } = useTranslation();
  const { index } = useParams();
  const { hotel } = useSelector((state) => state.Hotel);

  const headingData = {
    heading: t("Room Configuration"),
    seperator: [
      {
        text: t("Hotel Configuration"),
      },
      {
        text: t("Room Configuration"),
      },
    ],
  };

  return (
    <div className="page-container">
      <Seperator data={headingData} />
      <div className="my-6">
        <RoomsHeader params={index} />
      </div>
      {hotel && hotel?._id ? (
        <>
          {+index === 1 ? <RoomAmenities hotel={hotel} /> : null}
          {+index === 2 ? <RoomMinibarItems hotel={hotel} /> : null}
          {+index === 3 ? <RoomMinibarList hotel={hotel} /> : null}
          {+index === 4 ? <AllRooms hotel={hotel} /> : null}
          {+index === 5 ? <FoodBuffetPrice hotel={hotel} /> : null}
          {+index === 6 ? <RoomRate hotel={hotel} /> : null}
          {+index === 7 ? <AllQRCode hotel={hotel} /> : null}
          {+index === 8 ? <ServicesPage hotel={hotel} /> : null}
          {+index === 9 ? <PackagesPage hotel={hotel} /> : null}
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default RoomConfiguration;
