import React, { useState } from "react";

const ExpenseHeader = ({ index, setIndex }) => {
  const [count, setCount] = useState("29");

  const queuedData = [
    {
      name: "Queued",
      count: 29,
    },
    {
      name: "Paid",
      count: 89,
    },
    {
      name: "Stopped",
      count: 29,
    },
  ];
  const topMenu = [
    { id: 1, name: "All Expenses" },
    { id: 2, name: "Operating Expenses" },
    { id: 3, name: "Cost of Goods Sold" },
    { id: 4, name: "Rental Expense" },
    { id: 5, name: "Capital Expenditures" },
    { id: 6, name: "Administrative Expenses" },
    { id: 7, name: "Software Expense" },
    { id: 8, name: "Depreciation & Intangible" },
    { id: 9, name: "Miscellaneous Expenses" },
    { id: 10, name: "Miscellaneous Expenses" },
  ];

  const handleTopMenu = (id) => {
    setIndex(id);
  };

  const periodicData = [
    { name: "All" },
    { name: "Monthly Fixed" },
    { name: "System Genrated" },
    { name: "One Time" },
  ];

  return (
    <div className="">
      <div className="flex items-center justify-center my-6">
        <div className="flex h-[43px] w-[30vw]">
          {queuedData?.map((q) => (
            <button
              key={q.name}
              type="button"
              className="h-[43px] w-full border-b-[4px] hover:border-base-primary hover:text-base-primary text-[20px] leading-[25.2px] font-semibold duration-300 ease-[0.3s]"
            >
              {`${q.name}(${q.count})`}
            </button>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-5 items-center gap-6">
        {topMenu.map((menu) => {
          const { name, id } = menu;
          return (
            <button
              type="button"
              key={id}
              className={`h-12 border border-[#E5E9EB] rounded-[10px] text-[15px] leading-[18.9px] text-[##000000] font-medium  hover:bg-[#EEF6F2] hover:text-base-primary hover:border-base-primary duration-300 ease-[0.3s] ${
                index === id
                  ? "border-base-primary text-base-primary bg-[#EEF6F2]"
                  : "bg-white"
              }`}
              onClick={() => handleTopMenu(id)}
            >
              {name}
            </button>
          );
        })}
      </div>
      <div className="flex items-center justify-center my-6">
        <div className="h-14 w-[39vw] flex gap-1 items-center justify-between p-[5px] rounded-lg bg-[#00A35E1F] border">
          {periodicData?.map((pd) => (
            <button
              type="button"
              key={pd.name}
              className="w-full min-w-fit px-5 h-full rounded-md bg-transparent text-[18px] leading-[22.68px] text-[#000000] hover:bg-base-primary hover:text-white duration-300 ease-[0.3s]"
            >
              {pd.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExpenseHeader;
