import React from "react";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { FiPercent } from "react-icons/fi";

const StatusCard = ({ name, icon, percantage, totalCounts }) => {
  return (
    <div className="w-full rounded-[12.13px] px-5 py-6 bg-white shadow-[0_4.04px_12.13px_0_rgba(0,0,0,0.04)]">
      <div className="flex items-center justify-between text-base-primary">
        <img src={icon} alt="" className="size-9" />
        <div
          className={`flex items-center font-semibold ${
            percantage >= 0 ? "text-base-primary" : "text-[#EF4444]"
          }`}
        >
          {percantage}
          <FiPercent size={15} />
          {percantage >= 0 ? (
            <BsArrowUpShort size={25} className="text-base-primary" />
          ) : (
            <BsArrowDownShort size={25} className="text-[#EF4444]" />
          )}
        </div>
      </div>
      <div className="w-full md:max-w-[266.26px]  leading-[72.22px] text-[51.28px] text-[#18181B] font-bold">
        {totalCounts}
      </div>
      <div className="text-[#71717A] font-medium text-[13.33px] leading-[22.27px] tracking-[1.24px] uppercase">
        {name}
      </div>
    </div>
  );
};

export default StatusCard;
