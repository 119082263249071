/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AllLeavesCards from "../../components/layouts/AllLeavesCards";
import CustomeTable from "../../components/CustomeTable";
import DelIcon from "../../images/icons/DelIcon.svg";
import EidIcon from "../../images/icons/EidIcon.svg";
import AskForLeave from "../../components/modals/AskForLeave";
import SubmitButton from "../../components/buttons/SubmitButton";
import { DeleteButton, EditButton } from "../../components";

const LeaveManagement = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenAskForLeave, setIsOpenAskForLeave] = useState(false);

  const tableData = [
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Aproved",
    },
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Cancel",
    },
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Pending",
    },
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Aproved",
    },
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Pending",
    },
  ];

  const checkStatusAction = ({ value }) => {
    return (
      <div className="">
        {value === "Aproved" && (
          <div className="text-base-primary">{value}</div>
        )}
        {value === "Pending" && <div className="text-[#444CE7]">{value}</div>}
        {value === "Cancel" && <div className="text-[#F04438]">{value}</div>}
      </div>
    );
  };
  const viewAllLeaveManagementAction = () => {
    return (
      <div className="h-full flex items-center gap-4">
        <EditButton />
        <DeleteButton />
      </div>
    );
  };

  const columnDef = [
    {
      headerName: "Request Date",
      field: "requestdate",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Leave Type",
      field: "leaveType",
      filter: true,
    },
    {
      headerName: "Leave Duration",
      field: "leaveDuration",
      filter: true,
    },
    {
      headerName: "No. Of Days",
      field: "noOfDays",
      filter: true,
    },
    {
      headerName: "Status",
      field: "status",
      filter: true,
      cellRenderer: checkStatusAction,
    },
    {
      headerName: "Actions",
      // field: "",
      minWidth: 200,
      cellRenderer: viewAllLeaveManagementAction,
    },
  ];
  const cardData = [
    {
      name: "Total Leaves",
      value: "05",
    },
    {
      name: "Leave Used",
      value: "02",
    },
    {
      name: "Leave Balence",
      value: "03",
    },
    {
      name: "Declined Leaves",
      value: "01",
    },
  ];
  return (
    <div className="">
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
        {cardData.map((leaveData) => (
          <AllLeavesCards
            text={leaveData.name}
            value={leaveData.value}
            key={leaveData.name}
          />
        ))}
      </div>
      <div className="flex items-center justify-between my-4">
        <div className="text-[16px] leading-[20.16px] tracking-[-0.8px] text-[#6E7C87] font-bold ">
          My Requests
        </div>
        <SubmitButton
          type="button"
          text={"Ask For Leave"}
          onClick={() => setIsOpenAskForLeave(true)}
        />
        {isOpenAskForLeave ? (
          <AskForLeave
            leaveType={""}
            setLeaveType={""}
            note={""}
            setNote={""}
            close={() => setIsOpenAskForLeave(false)}
          />
        ) : null}
      </div>
      <CustomeTable
        size="h-[60vh]"
        tableName={"leave List table"}
        columnDefs={columnDef}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default LeaveManagement;
