import axios from "axios";
import { HOTEL_STAGE, ISADMIN, TOKEN } from "../../constants";
import {
  ADD_HOTEL_FAIL,
  ADD_HOTEL_REQUEST,
  ADD_HOTEL_SUCCESS,
  GET_HOTELS_FAIL,
  GET_HOTELS_REQUEST,
  GET_HOTELS_SUCCESS,
  GET_HOTEL_CONFIG_FAIL,
  GET_HOTEL_CONFIG_REQUEST,
  GET_HOTEL_CONFIG_SUCCESS,
  GET_HOTEL_FAIL,
  GET_HOTEL_REQUEST,
  GET_HOTEL_SUCCESS,
  UPDATE_HOTEL_FAIL,
  UPDATE_HOTEL_REQUEST,
  UPDATE_HOTEL_SUCCESS,
} from "../types/hotel";

// add a hotel
export const addHotelAction = (formData) => async (dispatch) => {
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN));
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/add`;

  try {
    dispatch({ type: ADD_HOTEL_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_HOTEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_HOTEL_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// update hotel
export const updateHotelAction = (formData) => async (dispatch) => {
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN));
  if (!isadmin) return;

  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/update`;

  try {
    dispatch({ type: UPDATE_HOTEL_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_HOTEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_HOTEL_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get hotels
export const getAllHotelAction = () => async (dispatch) => {
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN));
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/all`;

  try {
    dispatch({ type: GET_HOTELS_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_HOTELS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_HOTELS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get hotel
export const getHotelAction = (hotelId) => async (dispatch) => {
  if (!hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels?hotelId=${hotelId}`;

  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN));
  if (!isadmin) return;
  try {
    dispatch({ type: GET_HOTEL_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_HOTEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_HOTEL_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// GET HOTEL CONFIGURATIONS

export const getHotelConfigurationAction = (hotelId) => async (dispatch) => {
  if (!hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/hotel/configurations?hotelId=${hotelId}`;

  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN));
  if (!isadmin) return;
  try {
    dispatch({ type: GET_HOTEL_CONFIG_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_HOTEL_CONFIG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_HOTEL_CONFIG_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};