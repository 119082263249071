/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import { useDispatch, useSelector } from "react-redux";
import { getAllHotelEmployeeAction } from "../../actions/pms/employee";
import { CustomeTable } from "../../components";
import SubmitButton from "../../components/buttons/SubmitButton";
import CreateNewRole from "./CreateNewRole";
import { accessDatas } from "./accessTableData";
import DesignationCard from "./DesignationCard";

const AccessControl = () => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state?.Hotel);
  const { t } = useTranslation();

  const [isCreateRoleModal, setIsCreateRoleModal] = useState(false);

  const headingData = {
    heading: t("Access Control"),
    seperator: [
      {
        text: t("Hotel Configuration"),
      },
      {
        text: t("Access Control"),
      },
    ],
  };

  useEffect(() => {
    if (hotel?._id) dispatch(getAllHotelEmployeeAction(hotel?._id));
  }, [hotel?._id]);

  const createNewRoleModal = () => {
    setIsCreateRoleModal(true);
  };

  const close = () => {
    setIsCreateRoleModal(false);
  };

  return (
    <div className="page-container">
      <div className=" flex justify-between items-end">
        <Seperator data={headingData} />
      </div>
      <div className="flex items-center justify-between mt-6">
        <div className="text-[24px] leading-6 font-medium tracking-[-0.6%] ">
          Designation-Wise Default Access
        </div>
        <SubmitButton text={"Create New Role"} onClick={createNewRoleModal} />
      </div>
      {isCreateRoleModal ? <CreateNewRole close={close} /> : null}

      <div className="flex w-full mt-6">
        <div className="w-[20vw] mr-4">
          <DesignationCard
            roleName={"Role Name"}
            manager={`HR Manager`}
            handleEdit={""}
            handleAddEmployee={""}
          />
        </div>
        <div className="w-full border border-[#D0D5DD] rounded-md bg-white flex gap-4 overflow-x-auto overflow-y-hidden scroll-horizontal p-4 ">
          {accessDatas?.map((d) => {
            return (
              <CustomeTable
                size="h-[60vh] w-[30vw]"
                key={d}
                columnDefs={d.columnDefs}
                rowData={d.items}
                pagination={false}
                showButtons={false}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AccessControl;
