import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IMAGE_SIZE } from "../../constants";
import { notifyError } from "../../components/alert";
import { SlCamera } from "react-icons/sl";
import img from '../../images/users/Salman.png'
import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../actions/pms/ImageUpload";
import { updateOwnerProfileAction } from "../../actions/pms/ownerProfile";

const ProfileImage = ({ profile, fileName }) => {
  const dispatch = useDispatch()
  const { fileId, isuploaded } = useSelector((state) => state.UploadImage)
  const [image, setImage] = useState("");
  const { t } = useTranslation();

  //reading images from local device
  const readImages = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      //check file size
      if (file.size < IMAGE_SIZE) {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader?.readyState === 2) {
            setImage(reader?.result);
          }
        };
        reader.readAsDataURL(file);
        dispatch(uploadImageAction({
          base64Image: image, fileName
        }))
        // {
        //   id: props?.image?.image?.fileId,
        //   url: props?.image?.image?.url,
        // }
      } else {
        notifyError(t(`985`));
      }
    });
  };

  useEffect(() => {
    // dispatch(updateOwnerProfileAction({}))
  }, [isuploaded])

  return (

      <div className="h-[120px] w-[120px] rounded-full flex justify-center items-center absolute top-[112px] ml-8 bg-[#FFFFFF]">
        <div className="h-[106px] w-[106px] rounded-full border bg-gray-100 overflow-hidden relative">
          <img src={image && image?.length ? image : img} alt="" className="w-full absolute top-0 left-0 object-cover h-full" />
        </div>
        <button className="h-7 w-7 rounded-full overflow-hidden flex justify-center items-center bg-[#FFFFFF] absolute right-2 bottom-2">
          <SlCamera
            size={16}
            className="text-base-primary hover:text-base-primary_hover"
          />
          <input type="file"
            onChange={readImages}
            accept="image/png, image/gif, image/jpeg"
            draggable
            className="absolute -top-3 -left-3 pl-10 border-0 outline-none bg-transparent w-full" />
        </button>
      </div>
   
  );
};

export default ProfileImage;
