import React, { useState } from "react";
import AllLaundry from "./AllLaundry";
import LaundryConfig from "./LaundryConfig";
import SubmitButton from "../../components/buttons/SubmitButton";
import Seperator from "../../components/layouts/Seperator";
import OutlineButton from "../../components/buttons/OutlineButton";
import GuestLaundryRequest from "./GuestLaundryRequest";

const Laundry = () => {
  const [isGuestLaundryReq, setIsGuestLaundryReq] = useState(false);
  const [isLaundryConfig, setIsLaundryConfig] = useState(false);
  const track = {
    heading: "Laundry",
    seperator: [
      {
        text: "Front Desk",
      },
      {
        text: "Laundry",
      },
    ],
  };

  const handleIsLaundryCong = () => {
    setIsLaundryConfig(true);
    setIsGuestLaundryReq(false);
  };

  const handleIsGuestLaundryReq = () => {
    setIsGuestLaundryReq(true);
    setIsLaundryConfig(false);
  };

  return (
    <div className="page-container">
      <div className="flex items-end justify-between">
        <Seperator data={track} />
        <div className="flex items-center justify-end gap-4">
          <OutlineButton
            text={"Configure Laundry"}
            onClick={handleIsLaundryCong}
          />
          <SubmitButton
            type="button"
            text={"Guest Laundry Request"}
            onClick={handleIsGuestLaundryReq}
          />
        </div>
      </div>

      {isGuestLaundryReq ? (
        <GuestLaundryRequest close={() => setIsGuestLaundryReq(false)} />
      ) : null}

      {isLaundryConfig ? (
        <LaundryConfig close={() => setIsLaundryConfig(false)} />
      ) : null}

      <AllLaundry />
    </div>
  );
};

export default Laundry;
