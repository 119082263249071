import React from "react";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import CheckOutTomarrow from "./CheckOutTomarrow";
import RoomCleaning from "./RoomCleaning";
import PageLoader from "../../components/loader/PageLoader";
import { useParams } from "react-router-dom";
import HousekeepingHeader from "./HousekeepingHeader";
import RoomServiceRequest from "./RoomServiceRequest";
import IssueReported from "./IssueReported";
import HousekeepingAmenities from "./HousekeepingAmenities";

const HouseKeeping = () => {
  const { t } = useTranslation();
  const { index } = useParams();
  const { hotel } = useSelector((state) => state.Hotel);
  const headingData = {
    heading: t("33"),
    seperator: [
      {
        text: t("Housekeeping & Maintenance"),
      },
      {
        text: t("33"),
      },
    ],
  };
  return (
    <div className="page-container">
      <div className="flex justify-between items-center">
        <Seperator data={headingData} />
      </div>
      <div className="mt-6 mb-8">
        <HousekeepingHeader params={index} />
      </div>
      {hotel && hotel?._id ? (
        <>
          {+index === 1 ? <RoomCleaning hotel={hotel} /> : null}
          {+index === 2 ? <CheckOutTomarrow hotel={hotel} /> : null}
          {+index === 3 ? <RoomServiceRequest hotel={hotel} /> : null}
          {+index === 4 ? <IssueReported hotel={hotel} /> : null}
          {+index === 5 ? <HousekeepingAmenities hotel={hotel} /> : null}
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  rooms: state.AllRoom,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HouseKeeping);
