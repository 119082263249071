import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
function RoomTarrifCalender() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  // Function to get the days in a month
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Generate an array of days for the current month
  const daysInMonth = getDaysInMonth(currentYear, currentMonth);
  const daysArray = Array.from({ length: daysInMonth }, (v, i) => i + 1);

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  return (
    <div className="border rounded-[10px] bg-white text-[#292A34] capitalize p-4">
      <div className="grid grid-cols-2 w-full my-6">
        <div className="flex items-center">
          <button onClick={handlePrevMonth}>
            <IoIosArrowBack size={24} />
          </button>
          <div className="text-center w-full text-[16px] leading-[20.8px] font-bold tracking-[-2%]">
            {monthNames[currentMonth]}
            <span className="font-medium pl-[2px] text-[#292A34]">{currentYear}</span>
          </div>
        </div>
        {/* next */}
        <div className="flex items-center">
          <div className="text-center w-full text-[16px] leading-[20.8px] font-bold tracking-[-2%]">
            {monthNames[currentMonth]}
            <span className="font-medium pl-[2px] text-[#292A34]">{currentYear}</span>
          </div>
          <button onClick={handleNextMonth}>
            <IoIosArrowForward size={24} />
          </button>
        </div>
      </div>
      <div className="w-full grid grid-cols-2 gap-8">
        <div className="w-full">
          <div className="grid grid-cols-7">
            {/* weeks days name */}
            {weekDays.map((dayName) => (
              <div
                key={dayName}
                className="h-8 flex items-center justify-center text-[10px] leading-3 font-medium  uppercase text-[#292A34] mt-2"
              >
                {dayName}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-7 text-[15px] leading-[18.15px] font-bold text-[#292A34] mt-2">
            {daysArray.map((day) => (
              <div
                key={day}
                className="h-14 flex flex-col justify-between border-b border-r border-[#EDEDED] hover:shadow-md hover:scale-95 hover:shadow-[#00A35E] duration-300 ease-[0s] overflow-hidden text-[10px] leading-[12.1px] font-semibold p-1"
              >
                <div className="flex items-start justify-between">
                  <div className="text-[15px] leading-[18.15px] font-bold text-[#292A34]">
                    {day}
                  </div>
                  <div className="text-[#949499]">19/20</div>
                </div>
                <div className="flex items-end justify-between">
                  <div className="text-[7px] leading-[6.05px] font-semibold text-[#949499]">
                    Room
                    Available
                  </div>
                  <div className="text-base-primary">19/20</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full">
          <div className="grid grid-cols-7">
            {/* weeks days name */}
            {weekDays.map((dayName) => (
              <div
                key={dayName}
                className="h-8 flex items-center justify-center text-[10px] leading-3 font-medium text-[#292A34] uppercase mt-2"
              >
                {dayName}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-7 text-[15px] leading-[18.15px] font-semibold text-[#292A34] mt-2">
            {daysArray.map((day) => (
              <div
                key={day}
                className="h-14 flex flex-col justify-between border-b border-r border-[#EDEDED] hover:shadow-md hover:scale-95 hover:shadow-[#00A35E] duration-300 ease-[0s] overflow-hidden text-[10px] leading-[12.1px] font-semibold p-1"
              >
                <div className="flex items-start justify-between">
                  <div className="text-[15px] leading-[18.15px] font-bold text-[#292A34]">
                    {day}
                  </div>
                  <div className="text-[#949499]">$123</div>
                </div>
                <div className="flex justify-between border">
                  <div className="text-[7px] leading-[6.05px] font-semibold text-[#949499]">
                    Room
                    Available
                  </div>
                  <div className="text-base-primary">19/20</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="h-8  flex gap-1 pt-2 mt-1 items-end justify-start text-[14px] leading-[16.94px] tracking-[-0.6%] font-medium">
        <div className="text-[#000000]">8 may | </div>
        <div className="">
          Room Price : <span className="text-base-primary">123€</span> |{" "}
        </div>
        <div className="">
          Available Room : <span className="text-[#0059DF]">19/40</span>
        </div>
      </div>
    </div>
  );
}

export default RoomTarrifCalender;
