/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { FiCamera } from "react-icons/fi";
import { IMAGE_SIZE } from "../../constants";
import { notifyError } from "../alert";
import { clearError } from "../../actions/clearError";
import {
  CLEAR_IMAGE,
  DELETE_IMAGE_RESET,
} from "../../actions/types/ImageUpload";
import BackGroundLoadSpinner from "../loader/BackGroundLoadSpinner";
import { useTranslation } from "react-i18next";
import { registerEvent } from "../../logs";
import {
  deleteImageAction,
  uploadImageAction,
} from "../../actions/pms/ImageUpload";
import PreviewImages from "./PreviewImages";

const MultipleImageUpload = (props) => {
  const { t } = useTranslation();
  // required parameters ->  images, setImages, fileName, thumbnail, required
  const [localImages, setLocalImages] = useState([]);
  const [delImageId, setDelImageId] = useState("");
  const [fileName, setFileName] = useState({});
  const { hotel } = useSelector((state) => state.Hotel);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props?.fileName && props?.fileName?.length)
      setFileName(props?.fileName);
    else if (hotel && hotel?._id) setFileName(hotel?._id);
  }, [props?.fileName]);

  //upload local images
  const uploadLocalImage = async (base64Image) => {
    if (!base64Image?.length && fileName) {
      return;
    }
    const data = { base64Image, fileName };
    await props.uploadImage(data);
    await registerEvent("uploading single images", "upload image", {
      localImages,
    });
  };
  //reading images from local device
  const readImages = async (e) => {
    const files = Array.from(e.target.files);
    registerEvent(
      "selecting images from local device storage",
      "image upload",
      { files }
    );
    //reset local images
    setLocalImages([]);
    files.forEach((file, index) => {
      //check file size
      if (file.size < IMAGE_SIZE) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setLocalImages([...localImages, reader?.result]);
            uploadLocalImage(reader.result);
          }
        };
        reader.readAsDataURL(file);
      } else {
        notifyError(t(`${index + 1} 609`));
      }
    });
  };

  //set props images
  useEffect(async () => {
    if (
      props?.image &&
      props?.image?.image &&
      props?.image?.image?.fileId &&
      localImages &&
      localImages?.length
    ) {
      setLocalImages([]);
      await props?.setImages([
        ...props?.images,
        { id: props?.image?.image?.fileId, url: props?.image?.image?.url },
      ]);
      props?.reset();
    }
  }, [props?.image]);

  //clear image upload error
  useEffect(() => {
    if (
      (props?.image && props?.image?.error) ||
      (props?.delImage && props?.delImage?.error)
    ) {
      props.clear();
    }
  }, [props?.image, props?.delImage]);

  //DELETE IMAGE
  const deleteThisImage = async (fileId) => {
    if (fileId) {
      setDelImageId(fileId);
      await props.deleteImage(fileId);
      registerEvent("delete image", "photos", { fileId });
    }
  };

  useEffect(async () => {
    if (props?.delImage && props?.delImage?.isdeleted && delImageId) {
      const result = await props?.images?.filter((x) => x.id !== delImageId);
      props.setImages(result);
      props.delReset();
    }
  }, [props?.delImage]);

  //see all the uploaded image
  // const handleShow = () => {
  //   setOpen(true);
  // };

  return (
    <>
      <div className={`max-w-full relative h-[168.54px]`}>
        <div className="relative z-10 left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 w-fit">
          <input
            required={props?.required}
            type="file"
            onChange={readImages}
            name={fileName}
            id={fileName}
            accept="image/JPEG/PNG/WebP"
            draggable
            multiple={true}
            className={`outline-none p-0 border-none pl-[50px] mx-auto w-[50.42px] h-[50.42px] overflow-hidden cursor-pointer z-10 bg-[#ffffff4f] rounded-full`}
          />
          <label
            htmlFor={fileName}
            className={`absolute top-[13px] left-[13px] cursor-pointer bg-transparent`}
          >
            <FiCamera
              style={{
                fontSize: "1.5rem",
                color: "white",
              }}
            />
          </label>
        </div>

        {props?.images && props?.images[0]?.url ? (
          <img
            src={props?.images && props?.images[0]?.url}
            alt=""
            className="absolute h-full w-full object-contain top-0 left-0 z-0 rounded overflow-hidden"
          />
        ) : (
          <img
            src={props?.thumbnail}
            alt=""
            className="absolute h-full w-full object-cover top-0 left-0 z-0 rounded overflow-hidden"
          />
        )}
        {/* {props?.images && props?.images[0]?.url ? (
          <button
            type="button"
            onClick={handleShow}
            className="absolute w-fit top-2 right-2 z-10 bg-white p-2 py-1 rounded"
          >
            <AiOutlineEye />
          </button>
        ) : null} */}
      </div>
      <PreviewImages
        open={open}
        handleClose={() => setOpen(false)}
        images={props?.images}
        deleteThisImage={deleteThisImage}
      />
      {(props?.image && props?.image?.loading) ||
      (props?.delImage && props?.delImage?.loading) ? (
        <BackGroundLoadSpinner />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  image: state.UploadImage,
  delImage: state.DeleteImage,
});

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(clearError()),
  uploadImage: (data) => dispatch(uploadImageAction(data)),
  deleteImage: (fileId) => dispatch(deleteImageAction(fileId)),
  reset: () => dispatch({ type: CLEAR_IMAGE }),
  delReset: () => dispatch({ type: DELETE_IMAGE_RESET }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleImageUpload);
