// !In Every object key is for backend!
import { COUNTRY_DETAILS } from "./country-details";
import { TIME_ZONES } from "./timezones";
import { PROPERTY_AMENITIES } from "./property-amenities";
import { ROOM_AMENITIES } from "./room-amenities"
// @local storage data
const TOKEN = "x-auth-token";
const ISADMIN = "isadmin";
const RESET_PASS_TOKEN = "reset-pass-token";
const SETTING_INDEX = "SETTING_INDEX";
const PROPERTY_ID = "PROPERTY_ID";
const HOTEL_ROOM_DETAILS = "hotel-room-details";
const ROOM_TYPES = "hotel-room-types";
const ASSET = "hotel-assets";
const PAGE_DETAIL = "page-details";
const ID_NUMBER = "ID-number";
const IMAGE_SIZE = 350000; // => 350KB
const NOT_APPLICABLE = "NA";
const CONNECTION_STATUS = "device-connected-to-internet";
// total room in hotel = added room in perticular type + remaining room or addable rooms
const ADDABLE_ROOMS_IN_HOTEL = "availableRoomsInThisCategory";

// ================================== ROUTES==============================//
const ROUTES = {
  LANDING_PAGE: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  EMPLOYEE_LOGIN: "/login/employee",
  VERIFY_OTP: "/verify/otp",
  PASSWORD_FORGOT: "/password/forgot",
  PASSWORD_RESET: "/password/reset",
  PASSWORD_CHANGE: "/password/change",
  ONBOARDING: "/onboarding",
  VERIFY_EMAIL_OTP: "/verify/mail-otp",
  MY_PROFILE: "/profile",
  BOOKINGS: "/bookings",
  BOOKING: "/booking",
  ADD_BOOKING: "/add-booking",
  EMPLOYEES: "/employees",
  EQUIPMENT: "/equipment-managements",

  EMPLOYEE_DETAILS: "/employee-details",
  GUEST_SUPPORT: "/guest-support",
  EMPLOYEE_ROASTER: "/employee/roaster",
  VENDORS: "/vendors",
  VENDOR: "/vendor/detail",
  VENDOR_HISTORY: "/vendor/history",
  VENDOR_PURCHASE_ORDER: "/vendor/puchase-order",
  DASHBOARD: "/feed",
  HOTELS: "/hotels",
  CUSTOMERS: "/guests",
  CUSTOMER_DETAILS: "/guest-details",
  GUEST_ISSUE_REPORT: "/guest-issue-report",
  REPORT_ISSUE_TICKET: "/report-issue-ticket",
  RESERVATION_GRID: "/reservations",
  HOUSE_KEEPING: "/housekeeping",
  EXPENSES: "/expenses",
  EXPENSE: "/expense",
  REVIEWS: "/reviews",
  KITCHENS: "/kitchens",
  KiTCHEN_STAFF:"/kitchen-staff",
  KITCHEN_MENU: "/kitchen/menu",
  KITCHEN_BUFFETS: "/kitchen/buffet/All",
  KITCHEN_BUFFET: "/kitchen/buffet",
  KITCHEN_BUFFET_INVENTORY: "/kitchen/buffet/inventory",
  KITCHEN_OPERATIONS: "/kitchen/operations",
  KITCHEN_INVENTORY: "/kitchen/inventory",
  KITCHEN_MENU_INGREDIENTS: "/kitchen/menu/ingredients",
  COMMISSION_SETTLEMENT:"/kitchen/commission-settlement",
  CREATE_BUFFET_MENU: "/buffet/create-buffet-menu",
  COUNTER_CASH_WALLET:"/counter-cash-wallet",
  SPA: "/spa",
  FOOD: "/food",
  // FOOD_ORDER: "/food/food-order",
  FOOD_MENU: "/food/menu",
  HOTEL_CHECKIN: "/hotel/checkin",
  CANCEL_BOOKING: "/booking-cancel",
  ROOMS: "/rooms",
  PACKAGE: "/package",
  ROOM_TARIIF: "/rooms/room-tariff",
  LAUNDRY: "/laundry",
  QR_CODES: "/qr-codes",
  FOOD_ORDER_SUMMARY: "/food/order/cart",
  REPORTS: "/reports",
  ACCESS_CONTROL: "/access-control",
  ALACARTE: "/alacarte",
  ASSETS: "/assets",
  LOGS_CHANGE: "/logs-change",
  INVENTORY: "/inventory",
  FAB_ITEMS: "/fb-items",
  CHECKOUT: "/checkout",
  ROOM_SERVICE_REQUEST: "/room-service/request",
  PURCHASE_ORDER: "/purchase-order",
  PURCHASE_MANAGEMENT: "/purchase-management",
  ISSUE_REPORTED: "/issue-reported",
  HOUSE_KEEPING_AMENITY: "/housekeeping/amenity",
  HOTEL_CONFIG: "/hotel/config",
  ROOM_TARIIF_AUTOMATION: "/room-tariff/automation",
  ASSIGN_SHIFT: "/assign-shift",
  CREATE_RULE: "/create-rule",
  NIGHT_AUDIT: "/night-audit",
  MAINTENANCE: "/maintenance",
  FINANCES: "/finances",
  GUEST_FOLIO: "/guest-folio",
  BOOKING_BILL_DETAILS: "/guest-folio/booking-bill-details",
  FOLIO_CHECKOUT: "/guest-folio/check-out",
  // reports
  ROOM_BOOKING_REPORT: "/room-booking-report",
  ROOM_OCCUPANCY_REPORT: "/room-occupancy-report",
  ASSETS_REPORT: "/assets-report",
  EXPENCE_REPORT: "/expence-report",
  EMPLOYEE_SALARY_REPORT: "/employee-salary-report",
  INVENTORY_REPORT: "/inventory-report",
  PURCHASE_ORDER_REPORT: "/purchase-order-report",
  THIRD_PARTY_SERVICE_REPORT: "/third-party-service-report",
  REPAIR_MAINTENANCE_REPORT: "/repair-maintenance-report",
  SECURITY_REPORT: "/security-report",
  ROASTER_REPORT: "/roaster-report",
  // Equipment Managements
};

// ======================================== EMPLOYEE PERMISSIONS ===============================//
const PERMISSIONS = {
  BOOKING: "BOOKING",
  CHECKIN: "CHECKIN",
  KITCHEN: "KITCHEN",
  ASSETS: "ASSETS",
  INVENTORY: "INVENTORY",
  ORDER: "ORDER",
  STOCK: "STOCK",
  HOUSE_KEEPING: "HOUSE_KEEPING",
  RECEPTION: "RECEPTION",
  EXPENSE: "EXPENSE",
  REVIEW: "REVIEW",
  MANAGER: "MANAGER",
};

const ONBOARDING_STAGES = {
  WELCOME_TO_KUNGGY: "WELCOME_TO_KUNGGY",
  ONBOARDING: "ONBOARDING",
  CONTACT: "CONTACT",
  AMENITY: "AMENITY",
  IDENTITY_SEARCH: "IDENTITY_SEARCH",
  DASHBOARD: "DASHBOARD",
};

const THEMES = {
  GREEN: "theme-green",
  BLUE: "theme-blue",
  PURPLE: "theme-purple",
};

const DISPLAY_SIZE = {
  lg: 1024,
};

const PORTION_SIZE = { HALF: "01", FULL: "02", NA: "03" };

const PORTION_FOR = {
  1: "1",
  2: "2",
  3: "3",
  NA: "0",
};

// KITCHEN
const ITEM_QUANTITY_MEASURMENT = { WEIGHT: "WEIGHT", PIECE: "PIECE" };
const MENU_AVAILABLE_IN = {
  ROOM_SERVICES: "room service",
  RESTOURANT_DINING: "restourant dining",
  BAR_CAFE: "bar & cafe",
};

const MENU_TYPE = {
  REGULAR: "REGULAR",
  DATE: "DATE",
  DAY: "DAY",
  WEEKEND: "WEEKEND",
  WEEKDAYS: "WEEKDAYS",
};

const PAYMENT_METHODS = {
  CREDIT_CARD: "CREDIT_CARD",
  CASH: "CASH",
  UPI: "UPI",
};
const PAYMENT_MODE = {
  CASH: "CASH",
  CHEQUE: "CHEQUE",
  CUSTOM_PAYMENT: "CUSTOM_PAYMENT",
  CORPORATE_WALLET: "CORPORATE_WALLET",
  ONLINE_WALLET: "ONLINE_WALLET",
  ONLINE_PAYMENT: "ONLINE_PAYMENT",
  POS: "POS",
  TRAVEL_AGENT: "TRAVEL_AGENT",
};
const THRESHOLD_CONSUME_IN = {
  ROOM_BOOKING: "ROOM_BOOKING",
  FOOD_ORDERING: "FOOD_ORDERING",
  OTHER_SERVICE: "OTHER_SERVICE",
};

const GENDERS = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  OTHER: "OTHER",
};

const DAYS_NAME = {
  ALL_DAYS: "ALL DAYS",
  SUNDAY: "SUNDAY",
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THRUSDAY: "THRUSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
};

const MONTHS_NAME = {
  JANUARY: "JANUARY",
  FEBRUARY: "FEBRUARY",
  MARCH: "MARCH",
  APRIL: "APRIL",
  MAY: "MAY",
  JUNE: "JUNE",
  JULY: "JULY",
  AUGUST: "AUGUST",
  SEPTEMBER: "SEPTEMBER",
  OCTOBER: "OCTOBER",
  NOVEMBER: "NOVEMBER",
  DECEMBER: "DECEMBER",
};

const BOOKING_STATUS = {
  BOOKED: "BOOKED",
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
  CANCELED: "CANCELED",
};
const AVAILABILITY_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};
const TAX_CATEGORIES = {
  ROOM_TARIFF: "Room Tariff",
  LOCAL_CITIZEN: "Local Citizen",
  FOREIGN_CITIZEN: "Foreign Citizen",
};

const TAX_TYPES = { FLAT_TAX: "flat tax", SLAB_TAX: "slabwise tax" };

const TAX_BY = { TAX_BY_PERSON: "person", TAX_BY_ROOM: "room" };

const TARIFF_PRICE_STATUS = {
  STATUS: ["INCREASE", "DECREASE"],
  SIGN: ["%"],
  MAX: 123333,
};

const ROOM_TARIFF_CONDITION = {
  OCCUPANCY_INCREASE_BY: { label: "occupancy increase by", unit: "PERCENTAGE" },
  OCCUPANCY_DECREASE_BY: { label: "occupancy decrease by", unit: "PERCENTAGE" },
  NO_OF_DAYS_LEFT_TO_BOOKING_IS_MORE_THAN: {
    label: "no of days left to booking is more than",
    unit: null,
  },
  NO_OF_DAYS_LEFT_TO_BOOKING_IS_LESS_THAN: {
    label: "no of days left to booking is less than",
    unit: null,
  },
  OCCUPANCY_IS_MORE_THAN: {
    label: "occupancy is more than",
    unit: "NUM_PER",
  },
  OCCUPANCY_IS_LESS_THAN: {
    label: "occupancy is less than",
    unit: "NUM_PER",
  },
  CALEDER_EVENT: {
    label: "calender event",
    unit: "CALENDER",
  },
};

const REPORTS = {
  ROOM_BOOKING: "Room Booking Report",
  ROOM_OCCUPANCY: "Room Occupancy Report",
  ASSETS: "Assets report",
  EXPENSE: "Expense Report",
  EMPLOYEE_SALARY: "Employee Salary Report",
  INVENTORY: "Inventory Report",
  PURCHASE_ORDER: "Purchase Order Report",
  THIRD_PARTY_SERVICE: "Third Party Service Report",
  REPAIR_MAINTENANCE: "Repair/ Maintenance Report",
  SECURITY: "Security Report",
  ROASTER: "Employee Attandance and weekly/ monthly roster report",
};

const ROOM_STATUS = {
  AVAILABLE: "AVAILABLE",
  BOOKED: "BOOKED",
  DIRTY: "DIRTY",
};

const PROPERTY_TYPE = {
  HOTELS: "HOTELS",
  HOSTELS: "HOSTELS",
  MOTELS: "MOTELS",
  RESORTS: "RESORTS",
  BOUTIQUE_HOTELS: "BOUTIQUE_HOTELS",
  BED_AND_BREAKFASTS: "BED_AND_BREAKFASTS",
  TRANSIT_HOTELS: "TRANSIT_HOTELS",
  HERITAGE_HOTELS: "HERITAGE_HOTELS",
  UNIQUE_CONCEPT_HOTELS: "UNIQUE_CONCEPT_HOTELS",
  VACATION_RENTAL: "VACATION_RENTAL",
};

const BED_TYPES = {
  SINGLE: "Single bed (28 X 52)inch",
  TWIN: "Twin Bed (39 X 76)inch",
  STANDARD: "Standard Double (54 X 76)inch",
  QUEEN: "Queen Bed (60 X 80)inch",
  OLYMPIC: "Olympic Queen (66 X  80)inch",
  KING: "King Bed (76 X 80)inch",
  SUPER_KING: "Super King (76 X 80)inch",
  GRAND_KING: "Grand King (80 X 98)inch",
};

const LANGUAGES = [
  { value: "en", text: "english" },
  { value: "ch", text: "中文" },
  { value: "sp", text: "español" },
  { value: "ar", text: "عربي" },
  { value: "pr", text: "Portuguese" },
  { value: "in", text: "Indonésio" },
  { value: "fr", text: "Français" },
  { value: "ja", text: "やまと" },
  { value: "ru", text: "Русский" },
  { value: "ge", text: "Deutsch" },
  { value: "es", text: "Nederlands" },
  { value: "ta", text: "Italiano" },
  { value: "ko", text: "한국어" },
];
const LEAVE_TYPE = { TYPE_1: "TYPE_1", TYPE_2: "TYPE_2", TYPE_3: "TYPE_3" };

const FOOD_PLANS = {
  AMERICAN_MEAL_PLAN: "American Plan  (AP)",
  MODIFIED_AMERICAN_PLAN: "Modified American Plan (MAP)",
  CONTINENTAL_PLAN: "Continental Plan (CP)",
  EUROPEAN_PLAN: "European Plan (EP)",
};

const BOOKING_ROOM_TYPES = {
  ALL: "ALL",
  DELUXE: "DELUXE",
  SUITE: "SUITE",
  TWIN: "TWIN",
  SINGLE: "SINGLE",
};

const EXPENSE_TYPES = { TYPE1: "TYPE_1", TYPE2: "TYPE_2", TYPE3: "TYPE_3" };

const EXPENSE_FREQUENCY = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};

const POSITIONS = {
  ASSISTANT_HOTEL_MANAGER: "ASSISTANT_HOTEL_MANAGER",
  HOTEL_MANAGER: "HOTEL_MANAGER",
  PROCUREMENT_SPECIALIST: "PROCUREMENT_SPECIALIST",
  SALES_MARKETING_MANAGER: "SALES_MARKETING_MANAGER",
  ROOM_ATTENDANT: "ROOM_ATTENDANT",
  NIGHT_AUDITOR: "NIGHT_AUDITOR",
  HOTEL_RECEPTIONIST: "HOTEL_RECEPTIONIST",
  SPA_MANAGER: "SPA_MANAGER",
  HOUSEKEEPER: "HOUSEKEEPER",
  MAINTENANCE_SUPERVISOR: "MAINTENANCE_SUPERVISOR",
  HOST_OR_HOSTESS: "HOST_OR_HOSTESS",
  SERVER: "SERVER",
  CHEF: "CHEF",
  EVENT_PLANNER: "EVENT_PLANNER",
  CONVENTION_CENTRE_MANAGER: "CONVENTION_CENTRE_MANAGER",
  REVENUE_MANAGER: "REVENUE_MANAGER",
  ACCOUNTING_MANAGER: "ACCOUNTING_MANAGER",
  PURCHASE_MANAGER: "PURCHASE_MANAGER",
  HUMAN_RESOURCE_MANAGER: "HUMAN_RESOURCE_MANAGER",
  IT_MANAGER: "IT_MANAGER",
};

const DEPARTMENTS = {
  GENERAL_MANAGER: "GENERAL_MANAGER",
  HR_MANAGER: "HR_MANAGER",
  ASSISTANT_HOTEL_MANAGER: "ASSISTANT_HOTEL_MANAGER",
  HOTEL_MANAGER: "HOTEL_MANAGER",
  PROCUREMENT_SPECIALIST: "PROCUREMENT_SPECIALIST",
  ROOM_ATTENDANT: "ROOM_ATTENDANT",
  NIGHT_AUDITOR: "NIGHT_AUDITOR",
  HOTEL_RECEPTIONIST: "HOTEL_RECEPTIONIST",
  SPA_MANAGER: "SPA_MANAGER",
  HOUSEKEEPER: "HOUSEKEEPER",
  MAINTENANCE_SUPERVISOR: "MAINTENANCE_SUPERVISOR",
  HOST_OR_HOSTESS: "HOST_OR_HOSTESS",
  SERVER: "SERVER",
  CHEF: "CHEF",
  EVENT_PLANNER: "EVENT_PLANNER",
  CONVENTION_CENTRE_MANAGER: "CONVENTION_CENTRE_MANAGER",
  REVENUE_MANAGER: "REVENUE_MANAGER",
  ACCOUNTING_MANAGER: "ACCOUNTING_MANAGER",
  PURCHASE_MANAGER: "PURCHASE_MANAGER",
  HUMAN_RESOURCE_MANAGER: "HUMAN_RESOURCE_MANAGER",
  IT_MANAGER: "IT_MANAGER",
  SENIOR_CHEF: "SENIOR_CHEF",
  FINANCE_MANGER: "FINANCE_MANGER",
};

const PAYMENT_STATUS = {
  SUCCESS: "TXN_SUCCESS",
  FAILURE: "TXN_FAILURE",
  PENDING: "TXN_PENDING",
};

const BOOKING_CANCEL_REASON = {
  CHANGE_PLANS: "Change of plans",
  PERSONAL_ISSUE: "Personal Reasons",
  HEALTH_ISSUE: "Health Issues",
  TRANSPORTATION_ISSUE: "Transportation issues",
  WEATHER_ISSUE: "Weather issues",
  FOUND_ALTERNATIVE: "Found alternative accommodation",
  DISSATISFACTION: "Dissatisfaction with hotel",
  FINANCIAL: "Financial reason",
};

const BUFFET_PURPOSE = {
  EVENT_BUFFET: "Event Buffet",
  HOTEL_GUEST: "Hotel Guest Buffet",
};

const BUFFET_TYPE = {
  BREAKFAST: "Breakfast",
  LUNCH: "Lunch",
  HI_TEA: "Hi-Tea",
  DINNER: "Dinner",
};

const INGREDIENTS_PORTION_SIZE = {
  INDIVISUAL: "Individual Portion 500gm",
  DOUBLE: "Double Portion 750gm",
};

const INVENTORY_TYPE = {
  STATIONARY: "STATIONARY",
  SECURITY_EQUIPMENT: "SECURITY EQUIPMENT",
  MAINTENANCE_INVENTORY: "MAINTENANCE INVENTORY",
  HOUSEKEEPING_INVENTORY: "HOUSEKEEPING_INVENTORY",
};
const INVENTORY_DEPARTMENT = {
  HOUSEKEEPING: "HOUSEKEEPING",
  KITCHEN: "KITCHEN",
  SECURITY: "SECURITY",
  MAINTENANCE: "MAINTENANCE",
};

const MEAL_TYPE = {
  BREAKFAST: "BREAKFAST",
  LUNCH: "LUNCH",
  DINNER: "DINNER",
};

const STORAGE_TYPE = { DRY: "DRY", REFRIGERATOR: "REFRIGERATOR" };

const INVENTORY_PURCHASE_FREQUENCY = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};

const TRUE_FALSE = {
  YES: true,
  NO: false,
};

const BILLING_TO = {
  GUEST: "GUEST",
  OTHER: "OTHER",
};

const LAUNDRY_CATEGORY = {
  LAUNDRY: "LAUNDRY",
  IRON: "IRON",
  DRY_CLEANING: "DRY_CLEANING",
};

const LENGTH_UNITS = {
  INCH: "INCH",
  CM: "CM",
  METER: "METER",
  FOOT: "FOOT",
};

const ROLE = {
  ADMIN: "ADMIN",
  EMPLOYEE: "EMPLOYEE"
}
const INTERNET_SPEED = {
  1:1,
  2:2
}

export {
  TOKEN,
  ISADMIN,
  RESET_PASS_TOKEN,
  HOTEL_ROOM_DETAILS,
  ROOM_TYPES,
  ASSET,
  PAGE_DETAIL,
  IMAGE_SIZE,
  NOT_APPLICABLE,
  CONNECTION_STATUS,
  TIME_ZONES,
  THEMES,
  DISPLAY_SIZE,
  PORTION_SIZE,
  PORTION_FOR,
  MENU_TYPE,
  ITEM_QUANTITY_MEASURMENT,
  MENU_AVAILABLE_IN,
  PAYMENT_METHODS,
  PAYMENT_MODE,
  THRESHOLD_CONSUME_IN,
  COUNTRY_DETAILS,
  POSITIONS,
  PERMISSIONS,
  EXPENSE_TYPES,
  ROOM_STATUS,
  REPORTS,
  TARIFF_PRICE_STATUS,
  GENDERS,
  ADDABLE_ROOMS_IN_HOTEL,
  TAX_CATEGORIES,
  TAX_TYPES,
  TAX_BY,
  BED_TYPES,
  FOOD_PLANS,
  DEPARTMENTS,
  ROOM_TARIFF_CONDITION,
  SETTING_INDEX,
  LANGUAGES,
  ID_NUMBER,
  PAYMENT_STATUS,
  BUFFET_PURPOSE,
  INVENTORY_TYPE,
  STORAGE_TYPE,
  EXPENSE_FREQUENCY,
  PROPERTY_ID,
  LEAVE_TYPE,
  INGREDIENTS_PORTION_SIZE,
  BOOKING_CANCEL_REASON,
  BOOKING_STATUS,
  MEAL_TYPE,
  ROUTES,
  PROPERTY_AMENITIES,
  ROOM_AMENITIES,
  ONBOARDING_STAGES,
  BOOKING_ROOM_TYPES,
  PROPERTY_TYPE,
  MONTHS_NAME,
  DAYS_NAME,
  INVENTORY_PURCHASE_FREQUENCY,
  BUFFET_TYPE,
  TRUE_FALSE,
  BILLING_TO,
  LAUNDRY_CATEGORY,
  LENGTH_UNITS, ROLE,
  INVENTORY_DEPARTMENT,
  INTERNET_SPEED
};
