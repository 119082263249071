const ViewSelectChecked = () => {
  return (
    <div className="h-full flex items-center">
      <input type="checkbox" name="" id="" className="size-4 accent-current" />
    </div>
  );
};
export const accessDatas = [
  {
    items: [
      { name: "All Hotels" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
    ],
    columnDefs: [
      {
        headerName: "All Hotels",
        minWidth:175,
        field: "name",
      },
      {
        headerName: "Edit",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Add",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Cancel",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Delete",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
    ],
  },
  {
    items: [
      { name: "House Keeping" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
    ],
    columnDefs: [
      {
        headerName: "HouseKeeping",
        minWidth:175,
        field: "name",
      },
      {
        headerName: "Edit",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Add",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Cancel",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Delete",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
    ],
  },
  //   customer
  {
    items: [
      { name: "Customer" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
    ],
    columnDefs: [
      {
        headerName: "Customer",
        minWidth:175,
        field: "name",
      },
      {
        headerName: "Edit",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Add",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Cancel",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Delete",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
    ],
  },
  //   reservation
  {
    items: [
      { name: "Reservation" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
      { name: "micro Acesss" },
    ],
    columnDefs: [
      {
        headerName: "Reservation",
        minWidth:175,
        field: "name",
      },
      {
        headerName: "Edit",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Add",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Cancel",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
      {
        headerName: "Delete",
        field: "id",
        cellRenderer: ViewSelectChecked,
      },
    ],
  },
  //   so on you want to add
];
