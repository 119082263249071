import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import store from "./store";
import { authenticateHotelOwner } from "./actions/pms/ownerAuth";
import { authenticateEmployee } from "./actions/pms/employeeAuth";
import { THEMES, ROUTES } from "./constants";
import routes from "./middlewares/Routes";
import ProtectedRoute from "./middlewares/ProtectedRoute";
import OnBoardingRoutes from "./middlewares/OnBoardingRoutes";
import LandingPage from "./pages";
import LoginPage from "./pages/auth/LoginPage";
import SignupPage from "./pages/auth/SignupPage";
import ForgotPassword from "./pages/user/ForgotPassword";
import ForgotPasswordVerifyOTP from "./pages/user/ForgotPasswordVerifyOTP";
import ResetPassword from "./pages/user/ResetPassword";
import EmployeeLogin from "./pages/employee/EmployeeLogin";
import VerifyEmailOTP from "./pages/user/VerifyEmailOTP";
import GetStarted from "./pages/onBoarding";
import Tutorial from "./pages/tutorial";
import "./App.css";
import NotFound404 from "./components/NotFound404";

function App() {
  const [theme, setTheme] = useState(THEMES.GREEN);

  useEffect(() => {
    store.dispatch(authenticateHotelOwner());
    store.dispatch(authenticateEmployee());
    console.log(routes)
  }, []);

  return (
    <div className={`${theme} bg-[#fafafa] text-[black] duration-75`}>
      <Router>
        <Routes>
          {/* Tutorial route */}
          <Route path="tutorial" element={<Tutorial />} />

          {/* Public routes */}
          <Route path={ROUTES.LANDING_PAGE} element={<LandingPage />} />
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.REGISTER} element={<SignupPage />} />
          <Route path={ROUTES.PASSWORD_FORGOT} element={<ForgotPassword />} />
          <Route path={ROUTES.VERIFY_OTP} element={<ForgotPasswordVerifyOTP />} />
          <Route path={ROUTES.PASSWORD_RESET} element={<ResetPassword />} />
          <Route path={ROUTES.EMPLOYEE_LOGIN} element={<EmployeeLogin />} />
          <Route path={ROUTES.VERIFY_EMAIL_OTP} element={<VerifyEmailOTP />} />

          {/* Onboarding routes */}
          <Route element={<OnBoardingRoutes />}>
            <Route path={ROUTES.ONBOARDING} element={<GetStarted />} />
          </Route>

          {/* Admin and employee shared routes */}
          {routes.map(({ path, element, permissions }) => (
            
              <Route
                key={path}
                path={path}
                element={<ProtectedRoute permissions={permissions}>{element}</ProtectedRoute>}
              />
          ))}

          {/* Catch all - 404 Not Found */}
          <Route path="*" element={<NotFound404 />} />
        </Routes>
        <ToastContainer style={{ marginTop: "50px" }} />
      </Router>
    </div>
  );
}

export default App;
