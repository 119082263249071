/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DelIcon from "../../images/icons/DelIcon.svg";
import EditIcon from "../../images/icons/EidIcon.svg";
import { CustomeTable, DeleteButton, EditButton } from "../../components";

const CustomerMenuDataTable = () => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { menuItems } = useSelector((state) => state.AllMenuItem);

  useEffect(() => {
    if (menuItems && Array.isArray(menuItems)) {
      let newData = [];
      menuItems?.map((item) => {
        newData.push({
          portionSize: item?.portionSize,
          portionFor: item?.portionFor,
          quantity: item?.quantity,
          price: item?.price,
          _id: item?._id,
        });
      });
      if (newData?.length) {
        setDatas(newData);
      }
    }
  }, [menuItems]);

  //view customer menu data table Actions buttons
  const ViewCustomerMenuActions = () => {
    return (
      <div className="h-full flex items-center gap-2">
        <EditButton/>
        <DeleteButton />
      </div>
    );
  };

  const tableData = [
    {
      portionSize: "Individual Portion",
      portionFor: "1 person",
      quantity: "1 Kg",
      price: "200",
    },
    {
      portionSize: "Sharing Portion",
      portionFor: "2 person",
      quantity: "2 Kg",
      price: "500",
    },
    {
      portionSize: "Platter Portion",
      portionFor: "6 person",
      quantity: "6 Kg",
      price: "1200",
    },
    {
      portionSize: "Platter Portion",
      portionFor: "6 person",
      quantity: "6 Kg",
      price: "1200",
    },
  ];

  const columnDef = [
    {
      headerName: "Portion Size",
      field: "portionSize",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Portion For",
      field: "portionFor",
      filter: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Dietary Preference",
      field: "dietaryPreference",
      filter: true,
    },
    {
      headerName: "Portion Size",
      field: "portionSize",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Actions",
      minWidth: 200,
      // maxWidth: 200,
      cellRenderer: ViewCustomerMenuActions,
    },
  ];

  return (
    <div>
      {/* {datas.length ? ( */}
      <CustomeTable
        size="h-[42vh]"
        tableName={"Customer Menu List Table"}
        rowData={tableData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};

export default CustomerMenuDataTable;
