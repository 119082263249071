import React, { useEffect, useState } from "react";
import EditUnderlinePen from "../../images/icons/EditUnderlinePen.svg";
import EditUnderlinePen2 from "../../images/icons/EditUnderlinePen2.svg";
import MessageIcon from "../../images/icons/MessageIcon.svg";
import LocationIcon from "../../images/icons/LocationIcon.svg";
import UserProfileKitchen from "../../images/icons/UserProfileKitchen.svg";
import UserProfileChef from "../../images/icons/UserProfileChef.svg";
import UserProfileDish from "../../images/icons/UserProfileDish.svg";
import ProfileImage from "./ProfileImage";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import { useSelector, useDispatch } from "react-redux";
import { isObjEmpty } from "../../utils";
import { updateOwnerProfileAction } from "../../actions/pms/ownerProfile";

const NewProfile = () => {
  const { profile } = useSelector((state) => state.OwnerProfile);
  const { owner } = useSelector((state) => state.Authenticate);
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const toggleEdit = () => setEdit(true);

  const updateProfile = () => {
    dispatch(
      updateOwnerProfileAction({
        name: firstName + "_" + lastName,
        mobile: phone,
        address,
      })
    );
    setEdit(false);
  };

  const onPhoneChange = (value) => {
    if (value === undefined || value === null) {
      setPhone("");
    } else setPhone(value);
  };

  useEffect(() => {
    if (!isObjEmpty(profile)) {
      setAddress(profile?.address);
      setPhone(profile?.mobile);
    }

    if (!isObjEmpty(owner)) {
      const first = owner?.name?.split("_")[0];

      const last = owner?.name?.replace(first + "_", "");
      setFirstName(first);
      setLastName(last);
      setEmail(owner?.email);
    }
  }, [profile, owner]);

  return (
    <div className="pb-6 w-full border border-[#E5E9EB] rounded-[10px] reletive bg-white overflow-hidden">
      <div className="h-[114px] rounded-t-[10px] bg-gradient-to-r from-[#C9F6D3] to-[#A4B2FF] flex justify-end">
        <button className="h-8 w-8 rounded-full flex justify-center items-center bg-white absolute border hover:border-[#00A35E] m-4">
          <img src={EditUnderlinePen2} alt="" className="" />
        </button>
      </div>
      <ProfileImage />
      <div className="pt-[68px] px-8 flex justify-between items-center">
        {edit ? (
          <div className="flex items-center gap-4">
            <input
              value={firstName}
              onChange={(e) => setFirstName(e?.target?.value)}
              type="text"
              className={`capitalize w-fit text-[22px] text-[#07090D] leading-[22.27px] font-semibold tracking-[-0.8px] max-w-fit ${
                edit ? "" : "border-none none-focus shadow-none"
              }`}
            />
            <input
              value={lastName}
              onChange={(e) => setLastName(e?.target?.value)}
              type="text"
              className={`capitalize w-fit text-[22px] text-[#07090D] leading-[22.27px] font-semibold tracking-[-0.8px] max-w-fit ${
                edit ? "" : "border-none none-focus shadow-none"
              }`}
            />
          </div>
        ) : (
          <p className="capitalize text-[22px] h-12 text-[#07090D] leading-[22.27px] font-semibold tracking-[-0.8px]">
            {firstName + " " + lastName}
          </p>
        )}
        <button
          onClick={edit ? updateProfile : toggleEdit}
          className="h-10 flex justify-between items-center px-4 gap-2 bg-base-primary hover:bg-base-primary_hover rounded-md"
        >
          <img src={EditUnderlinePen} alt="" />
          <div className="text-[20px] leading-[25.2px] font-medium text-[#F6F8F9]">
            {edit ? "Done" : "Edit Profile"}
          </div>
        </button>
      </div>
      {/* profile text */}
      <div className="max-w-[650px] mt-4">
        <div className="grid grid-cols-2 gap-4 pl-8 items-center">
          <PhoneInput
            flags={flags}
            value={phone}
            onChange={onPhoneChange}
            className={`w-[300px] h-12 relative bg-transparent ${
              edit ? "" : "phone-number-input-profile"
            } overflow-hidden`}
            numberInputProps={{
              required: true,
            }}
            readOnly={!edit}
          />
          <div className="flex items-center gap-1">
            <img src={MessageIcon} alt="" />
            <input
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
              type="email"
              required
              className={`text-[20px] text-[#6E7C87] leading-[25.2px] font-normal tracking-[-0.8px] ${
                edit ? "" : "border-none none-focus shadow-none"
              }`}
              readOnly={!edit}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 items-center my-4 ml-8">
          <div className="flex items-center gap-4 h-12 ">
            <img src={UserProfileKitchen} alt="" />
            <div className="text-[20px] leading-[25.2px] text-[#6E7C87] tracking-[-0.8px]">
              Kitchen
            </div>
          </div>
          <div className="flex items-center gap-4 h-12 ">
            <img src={UserProfileChef} alt="" />
            <div className="text-[20px] leading-[25.2px] text-[#6E7C87] tracking-[-0.8px]">
              Chef
            </div>
          </div>
          <div className="flex items-center gap-4 h-12 ">
            <img src={UserProfileDish} alt="" />
            <div className="text-[20px] leading-[25.2px] text-[#6E7C87] tracking-[-0.8px]">
              Chinese
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 ml-8">
          <img src={LocationIcon} alt="" />
          <input
            value={address}
            onChange={(e) => setAddress(e?.target?.value)}
            required
            className={`text-[20px] text-[#6E7C87] leading-[25.2px] font-normal tracking-[-0.8px] ${
              edit ? "" : "border-none none-focus shadow-none"
            }`}
            readOnly={!edit}
          />
        </div>
      </div>
    </div>
  );
};

export default NewProfile;
