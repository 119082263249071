import React, { useEffect, useState } from "react";
import { isObjEmpty } from "../../utils";
import { CustomeTable, DeleteButton, EditButton } from "../../components";
import { useTranslation } from "react-i18next";

const BuffetSummaryDataTable = (props) => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          itemID: props?.kitchen?.kitchen?.itemID,
          itemName: props?.kitchen?.kitchen?.itemName,
          one_hundred_fifty_plates:
            props?.kitchen?.kitchen?.one_hundred_fifty_plates,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);

  const tableData = [
    {
      itemID: "IT01",
      itemName: "Chicken Wings",
      one_hundred_fifty_plates: "$12",
    },
    {
      itemID: "IT02",
      itemName: "Chicken Karahi",
      one_hundred_fifty_plates: "$12",
    },
    {
      itemID: "IT03",
      itemName: "Caesar Salad",
      one_hundred_fifty_plates: "$12",
    },
    {
      itemID: "IT04",
      itemName: "Beef Steak",
      one_hundred_fifty_plates: "$12",
    },
    {
      itemID: "IT05",
      itemName: "Kheer",
      one_hundred_fifty_plates: "$12",
    },
  ];

  const ViewCreateBuffetAction = () => {
    return (
      <div className="h-full flex items-center gap-2">
        <EditButton />
        <DeleteButton />
      </div>
    );
  };
  const getUnitsInputs = () => {
    return (
      <div className="h-full flex items-center gap-2">
        <div className="flex items-center gap-2 ">
          <input type="text" className="h-[30px] w-[70px] rounded-[5px] text-[14px] leading-[17.64px] font-normal text-[#667085]" />
          <select className="h-[30px] w-[70px] rounded-[5px] text-[14px] leading-[17.64px] font-normal text-[#667085] pl-1" >
            <option>kg</option>
            <option>gm</option>
          </select>
        </div>
        <div className="h-[30px] w-[70px] border border-[#E5E9EB] bg-white rounded-[5px] text-[14px] leading-[17.64px] font-normal text-[#667085] flex justify-center items-center shadow-inputFeild ">
          $12
        </div>
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Item ID",
      field: "itemID",
      minWidth: 100,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item Name",
      field: "itemName",
      filter: true,
    },
    {
      headerName: "150 Plates",
      field: "one_hundred_fifty_plates",
      cellRenderer: getUnitsInputs,
    },
    {
      headerName: "Actions",
      field: "_id",
      cellRenderer: ViewCreateBuffetAction,
    },
  ];
  return (
    <div>
      {datas ? (
        <CustomeTable
          size="h-[60vh]"
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
          showButtons={false}
        />
      ) : null}
    </div>
  );
};

export default BuffetSummaryDataTable;
