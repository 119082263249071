/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PageLoader from "../../../components/loader/PageLoader";
import { updateHotelAction } from "../../../actions/pms/hotel";
import { connect } from "react-redux";
import { notifySuccess } from "../../../components/alert";
import BackGroundLoadSpinner from "../../../components/loader/BackGroundLoadSpinner";
import {
  COUNTRY_DETAILS,
  TIME_ZONES,
  ROUTES,
  ONBOARDING_STAGES,
  PROPERTY_TYPE,
} from "../../../constants";
import { registerEvent } from "../../../logs";
import PropertyCurrencySelector from "./PropertyCurrencySelector";
import InputPropertyTimeZone from "./InputPropertyTimeZone";
import SelectFromArray from "../../../components/layouts/SelectFromArray";
import { useTranslation } from "react-i18next";
import Inputfield from "../../../components/layouts";
import { UPDATE_HOTEL_RESET } from "../../../actions/types/hotel";
import { useNavigate } from "react-router-dom";
import SkipButton from "../../../components/buttons/SkipButton";
import AddSubmitButton from "../../../components/buttons/AddSubmitButton";
import CountryNameSelector from "../../../components/layouts/CountryNameSelector";
import StateNameSelector from "../../../components/layouts/StateNameSelector";
import MultipleImageUpload from "../../../components/layouts/MultipleImageUpload";
import img from "../../../images/menuInventoryImage.jpg";
import invoiceTemplate from "../../../images/invoiceTemplate.png";
import { IoSearch } from "react-icons/io5";
import SelectFromObject from "../../../components/layouts/SelectFromObject";

const PropertyDetails = (props) => {
  const { t } = useTranslation();
  const [propertyName, setPropertyName] = useState("");
  const [timeZoneOffset, setTimeZoneOffset] = useState(TIME_ZONES[0]?.offset);
  const [propertyType, setPropertyType] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [tagline, setTagline] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [coperateIdNumber, setCoperateIdNumber] = useState("");
  const [currency, setCurrency] = useState(COUNTRY_DETAILS[0]?.currency.code);
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [isFullData, setIsFullData] = useState(false);
  const [country, setCountry] = useState("");
  const [countryIsoCode, setCountryIsoCode] = useState("");
  const [state, setState] = useState("");
  const [stateIsoCode, setStateIsoCode] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [images, setImages] = useState([]);
  const [searchInvoiceTem, setSearchInvoiceTem] = useState("");
  //set property details from local / hotel data
  useEffect(() => {
    if (props?.hotel && props?.hotel?._id) {
      setPropertyName(props?.hotel?.propertyName);
      setPropertyType(props?.hotel?.propertyType);
      setTimeZoneOffset(props?.hotel?.timeZoneOffset);
      setEmail(props?.hotel?.propertyContact?.email);
      setWebsite(props?.hotel?.website);
      setTaxIdNumber(props?.hotel?.taxIdNumber);
      setHsnCode(props?.hotel?.HsnCode);
      setCoperateIdNumber(props?.hotel?.coperateIdNumber);
      setCurrency(props?.hotel?.currency);
      setCheckInTime(props?.hotel?.checkInTime);
      setCheckOutTime(props?.hotel?.checkOutTime);
      setTagline(props?.hotel?.tagline);
      setIsFullData(true);
    }
  }, [props?.hotel]);

  //submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      hotelId: props?.hotel?._id,
      propertyName,
      propertyType,
      email,
      website,
      taxIdNumber: taxIdNumber ? taxIdNumber : null,
      HsnCode: hsnCode ? hsnCode : null,
      coperateIdNumber: coperateIdNumber ? coperateIdNumber : null,
      currency: currency ? currency : null,
      tagline: tagline ? tagline : null,
      timeZoneOffset,
      checkInTime,
      checkOutTime,
      stage: ONBOARDING_STAGES.DASHBOARD,
    };

    setClicked(true);
    await props.updateHotel(formData);
    registerEvent("UPDATE PROPERTY DETAILS", "PROPERTY DETAILS", formData);
  };

  //show updatation alears
  useEffect(() => {
    if (
      props?.data &&
      props?.data?.isupdated &&
      props?.data?.hotel &&
      clicked
    ) {
      props?.reset();
      notifySuccess(t("688"));
      setClicked(false);
      navigate(`${ROUTES.HOTEL_CONFIG}/2`);
    }
  }, [props?.data]);

  //clear error
  useEffect(() => {
    if (props?.data && props?.data?.error && clicked) {
      setClicked(false);
    }
  }, [props.data]);

  const skipTheTab = () => {
    navigate(`${ROUTES.HOTEL_CONFIG}/2`);
  };

  return (
    <div className="">
      {props?.data && props?.data?.loading && clicked ? (
        <BackGroundLoadSpinner />
      ) : null}

      {isFullData ? (
        <div className="w-full max-w-full border border-[#E4E4E7] bg-white rounded-lg p-6">
          <h2 className="font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
            {t("360")}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 my-4">
              <Inputfield
                label={t("689")}
                message={t("690")}
                value={propertyName}
                onChange={(e) => setPropertyName(e.target.value)}
                required={true}
                minLength={3}
                maxLength={40}
              />
              <SelectFromObject
                value={propertyType}
                setValue={setPropertyType}
                options={PROPERTY_TYPE}
                label={`${t("363")} *`}
                toolTipMessage={t("364")}
                required={true}
              />
              <InputPropertyTimeZone
                value={timeZoneOffset}
                setValue={setTimeZoneOffset}
                options={TIME_ZONES}
                required={true}
              />
              <Inputfield
                label={t("691")}
                message={t("692")}
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                type="email"
                placeholder={"Enter Your Property Email"}
              />
              <Inputfield
                label={t("693")}
                message={t("694")}
                value={website}
                placeholder={"Enter a Value"}
                onChange={(e) => setWebsite(e?.target.value)}
                type="ulr"
              />
              <Inputfield
                label={t("695")}
                message={t("696")}
                value={taxIdNumber}
                placeholder={"Enter a Value"}
                onChange={(e) => setTaxIdNumber(e?.target?.value)}
              />
              <Inputfield
                label={t("697")}
                message={t("698")}
                placeholder={"Enter a Value"}
                value={hsnCode}
                onChange={(e) => setHsnCode(e?.target?.value)}
              />
              <Inputfield
                label={t("699")}
                message={t("700")}
                placeholder={"Enter a Value"}
                value={coperateIdNumber}
                onChange={(e) => setCoperateIdNumber(e?.target?.value)}
              />
              <PropertyCurrencySelector
                label={`${t("377")} *`}
                value={currency}
                onChange={(e) => setCurrency(e?.target?.value)}
                options={COUNTRY_DETAILS}
              />

              <CountryNameSelector
                country={country}
                setCountry={setCountry}
                setCountryIsoCode={setCountryIsoCode}
              />
              <StateNameSelector
                state={state}
                setState={setState}
                stateIsoCode={stateIsoCode}
                setStateIsoCode={setStateIsoCode}
                countryCode={countryIsoCode}
              />
              <Inputfield
                label={`${t("Street Address")} *`}
                placeholder={t("Street Address")}
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
                minLength={10}
                maxLength={100}
              />
              <Inputfield
                label={"Zip Code"}
                placeholder={t("Code")}
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                minLength={5}
                maxLength={100}
              />

              <Inputfield
                label={t("701")}
                message={t("702")}
                value={checkInTime}
                onChange={(e) => setCheckInTime(e?.target?.value)}
                type="time"
                required={true}
              />
              <Inputfield
                label={t("703")}
                message={t("704")}
                value={checkOutTime}
                onChange={(e) => setCheckOutTime(e?.target?.value)}
                type="time"
                required={true}
              />
            </div>
            {/* image */}
            <div className="text-[30px] leading-[37.8px] font-bold tracking-[-1.5%] text-[#07090D] mb-4 mt-2">
              Upload Hotel Logo
            </div>
            <div className="w-[18.8%]">
              <label className="text-sm font-medium text-[#344054]">
                {t("Hotel Logo")}
              </label>
              <MultipleImageUpload
                images={images}
                setImages={setImages}
                thumbnail={img}
              />
            </div>
            {/* Invoice Template  */}
            <h2 className="font-semibold text-[24px] leading-6 text-black mt-6 mb-3">
              Invoice Template
            </h2>

            <div className="grid grid-cols-3">
              <div className="relative flex items-end gap-4">
                <IoSearch
                  size={24}
                  className="text-[#D0D5DD] absolute left-4 top-8 z-20"
                />
                <Inputfield
                  customInputField={"pl-12"}
                  message={"select invoice template"}
                  label={"Invoice Template"}
                  placeholder={"Type To Search"}
                  value={searchInvoiceTem}
                  onChange={() => setSearchInvoiceTem}
                />
              </div>
            </div>
            <div className="mt-8">
              <div className="text-[24px] leading-[30.24px] font-medium mb-3">
                Invoice No 1
              </div>
              <div className="w-[22%] h-[40vh] border border-base-primary rounded-[5px] overflow-hidden">
                <img
                  src={invoiceTemplate}
                  alt=""
                  className="object-fill h-full w-full"
                />
              </div>
            </div>
            <div className="flex justify-end items-center gap-3 mb-6 mt-8">
              <SkipButton text={t("260")} onClick={skipTheTab} />
              <AddSubmitButton
                text={t("65")}
                width={88}
                disabled={props?.data && props?.data?.loading}
              />
            </div>
          </form>
        </div>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  updateHotel: (data) => dispatch(updateHotelAction(data)),
  reset: () => dispatch({ type: UPDATE_HOTEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
