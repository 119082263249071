import React, { useState } from "react";
import InputButton from "../InputButton";
import { OutlineButton } from "../../../components";
import SubmitButton from "../../../components/buttons/SubmitButton";
import SelectPosType from "../../../components/modals/SelectPosType";

const Pos = () => {
  const [isOpenPosModal, setIsOpenPosModal] = useState(false);
  return (
    <div className="px-8 pb-8">
      <div className="text-[24px] leading-6 font-semibold">
        Payment Mode Configuration Of POS
      </div>
      <div className="flex flex-col gap-10 mt-10">
        <div className="w-2/3">
          <InputButton
            label={"Select POS Type"}
            onClick={() => setIsOpenPosModal(true)}
          />
        </div>
        {isOpenPosModal ? (
          <SelectPosType 
          close={() => setIsOpenPosModal(false)} 
          />
        ) : null}
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton label={"Employee Authorize to Swipe Cards"} />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton
              label={"Employee Authorize to verify Payment Received"}
            />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton label={"Default Account to Received payment"} />
          </div>
          <SubmitButton sizing={"h-12 w-[183px]"} text={"Save"} />
        </div>
      </div>
    </div>
  );
};

export default Pos;
