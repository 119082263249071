import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  authenticateHotelOwner,
  hotelOwnerRegisterAction,
} from "../../actions/pms/ownerAuth";
import { clearError } from "../../actions/clearError";
import { notifyError } from "../../components/alert";
import LoadSpinner from "../../components/loader/LoadSpinner";
import MetaData from "../../utils/MetaData";
import { Link } from "react-router-dom";
import { isObjEmpty } from "../../utils";
import {
  ISADMIN,
  TOKEN,
  ROUTES,
} from "../../constants";

import { connect } from "react-redux";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import AuthNameInput from "./AuthNameInput";
import AuthPasswordInput from "./AuthPasswordInput";
import AuthEmailInput from "./AuthEmailInput";
import ContinueWithGoogle from "../../components/layouts/ContinueWithGoogle"; 
import { useTranslation } from "react-i18next";

const SignupPage = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //validation error
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formDataValid, setFormDataValid] = useState(false);

  //submiting the form input data.
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !nameError &&
      !emailError &&
      !passwordError &&
      name &&
      email &&
      password
    ) {
      const formData = {
        name,
        email,
        password,
      };
      props.register(formData);
      localStorage.clear();
      sessionStorage.clear();
    }
  };

  //form is valid or not
  useEffect(() => {
    if (
      !nameError &&
      !emailError &&
      !passwordError &&
      name &&
      email &&
      password
    ) {
      setFormDataValid(true);
    }
  }, [nameError, emailError, passwordError, name, email, password]);

  //redirecting the user after login
  useEffect(() => {
    //redirect owner after login
    if (
      props.registerRes?.token?.message ===
      "Please Verify Your Email To Proceed Further"
    ) {
      //if user is singedup and not verified account than according to error redirect to verify email page!
      localStorage.setItem(TOKEN, email);
      navigate(ROUTES.VERIFY_EMAIL_OTP);
      localStorage.setItem(ISADMIN, true); 
    } else if (
      props?.registerRes &&
      props?.registerRes?.token &&
      props?.registerRes?.token?.length
    ) {
      localStorage.setItem(ISADMIN, true);
      localStorage.setItem(TOKEN, props?.registerRes?.token); 
    }

    if (props?.user && props?.user?.owner && !isObjEmpty(props?.user?.owner)) {
      navigate(ROUTES.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.registerRes, props.user]);

  //clearing error
  useEffect(() => {
    if (props?.registerRes && props?.registerRes?.error) {
      props?.registerRes.error && notifyError(props?.registerRes?.error);

      props.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.registerRes]);

  return (
    <>
      <MetaData title={t("803")} />
      {props?.user && props?.user?.loading ? <BackGroundLoadSpinner /> : null}
      <div className="min-h-screen p-4 relative flex flex-col justify-center">
        <div className="w-[450px] mx-auto max-w-full rounded-lg flex flex-col border my-8 bg-[#FFFFFF]">
          <div className="w-full p-6">
            <h1 className="text-3xl font-medium text-center text-base-primary">
              {t("803")}
            </h1>
          </div>
          <form
            className="w-full px-8 grid grid-cols-1 gap-4"
            onSubmit={handleSubmit}
          >
            <AuthNameInput
              name={name}
              setName={setName}
              nameError={nameError}
              setNameError={setNameError}
            />
            <AuthEmailInput
              email={email}
              setEmail={setEmail}
              setEmailError={setEmailError}
              emailError={emailError}
            />

            <AuthPasswordInput
              password={password}
              setPassword={setPassword}
              passwordError={passwordError}
              setPasswordError={setPasswordError}
            />

            <button
              className="inline-block h-12 w-full bg-base-primary text-[#FFFFFF] rounded-3xl font-medium disabled:bg-base-rgba_primary_8"
              disabled={
                (props?.registerRes && props?.registerRes?.loading) ||
                !formDataValid
              }
            >
              {props?.registerRes && props?.registerRes?.loading ? (
                <LoadSpinner text={t("798")} />
              ) : (
                <>{t("803")}</>
              )}
            </button>
          </form>
          <div className="w-full flex flex-col items-center relative p-8 py-4">
            <div className="flex w-full items-center">
              <span className="border-b flex border-[#D0D5DD] w-full"></span>
              <p className="whitespace-nowrap mx-2">{t("800")}</p>
              <span className="border-b flex border-[#D0D5DD] w-full"></span>
            </div>
            <div className="flex w-full justify-center items-center">
              <div className="m-2">
                <ContinueWithGoogle />
              </div>
            </div>
          </div>
          <div className="w-full text-center pb-6">
            {t("804")}
            <Link to={ROUTES.LOGIN} className="text-blue-500 ml-2">
              {t("805")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.Authenticate,
  registerRes: state.LoginSignup,
});

const mapDispatchToProps = (dispatch) => ({
  register: (data) => dispatch(hotelOwnerRegisterAction(data)),
  clear: () => dispatch(clearError()),
  getOwner: () => dispatch(authenticateHotelOwner()), 
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
