import React from "react";
import SupportUser from "./SupportUser";

//temp array
const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const SupportLeft = () => {
  return (
    <>
      {/* user & message*/}
      <div className="flex flex-col overflow-x-hidden overflow-y-auto scroll-barguest w-[364px]">
        {array.map((data, index) => (
          <SupportUser key={index} user={data} />
        ))}
      </div>
    </>
  );
};

export default SupportLeft;
