import { useState } from "react";

const CLEANNING_STATUS = {
  COMPLETED: "Completed",
  IN_PROGRESS: "In Progress",
  NOT_STARTED: "Not Started",
};
const CheckStatus = () => {
  const [value, setValue] = useState("In Progress");
  console.log("check value :", value);
  return (
    <select
      onChange={(e) => setValue(e?.target?.value)}
      value={value}
      className={`
          h-[29px] rounded-[5px] shadow-none border-none focus:border-none text-[14px] leading-[17.64px] font-normal px-2
           ${value === "Completed" && "bg-[#ECFDF3] text-base-primary"}
            ${value === "In Progress" && "bg-[#EEF4FF] text-[#444CE7]"}
             ${value === "Not Started" && "bg-[#FEF3F2] text-[#F04438]"}
          `}
    >
      {Object.keys(CLEANNING_STATUS)?.map((it) => (
        <option className="capitalize" key={it} value={CLEANNING_STATUS[it]}>
          {CLEANNING_STATUS[it]}
        </option>
      ))}
    </select>
  );
};
export default CheckStatus;
