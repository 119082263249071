import React from 'react'
import AddCorporateAccountDetails from './AddCorporateAccountDetails'
import Billing from './Billing'

const CorporateAccount = () => {
  return (
    <div className='flex flex-col gap-6'>
      <AddCorporateAccountDetails/>
      <Billing/>
    </div>
  )
}

export default CorporateAccount