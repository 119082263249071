import React, { useState, useEffect } from "react";
import PageLoader from "../../../components/loader/PageLoader";
import { updateHotelAction } from "../../../actions/pms/hotel";
import { connect } from "react-redux";
import { notifySuccess } from "../../../components/alert";
import BackGroundLoadSpinner from "../../../components/loader/BackGroundLoadSpinner";
import { registerEvent } from "../../../logs";
import { useTranslation } from "react-i18next";
import InputTextArea from "../../../components/layouts/InputTextArea";
import { UPDATE_HOTEL_RESET } from "../../../actions/types/hotel";
import { useNavigate } from "react-router-dom";
import {  ONBOARDING_STAGES, ROUTES } from "../../../constants";
import SkipButton from "../../../components/buttons/SkipButton";
import AddSubmitButton from "../../../components/buttons/AddSubmitButton";
import Inputfield from "../../../components/layouts";
import SelectFromObject from "../../../components/layouts/SelectFromObject";

const PropertyInfo = (props) => {
  const { t } = useTranslation();
  const [tagline, setTagline] = useState("");
  const [commonAreas, setCommonAreas] = useState([]);
  const [propertyDescription, setPropertyDescription] = useState("");
  const [refundPolicy, setRefundPolicy] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [rulesAndRegulations, setRulesAndRegulations] = useState("");
  const [clicked, setClicked] = useState(false);
  const [isFullData, setIsFullData] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props?.hotel?._id) {
      setTagline(props?.hotel?.tagline);
      setPropertyDescription(props?.hotel?.propertyDescription);
      setRefundPolicy(props?.hotel?.refundPolicy);
      setTermsAndConditions(props?.hotel?.termsAndConditions);
      setRulesAndRegulations(props?.hotel?.rulesAndRegulations);
      setIsFullData(true);
    }
  }, [props?.hotel]);

  //submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      hotelId: props?.hotel?._id,
      tagline,
      propertyDescription,
      termsAndConditions,
      rulesAndRegulations,
      stage: ONBOARDING_STAGES.DASHBOARD,
    };

    setClicked(true);
    await props.updateHotel(formData);
    registerEvent("UPDATE PROPERTY DETAILS", "PROPERTY DETAILS", formData);
  };

  //show updatation alears
  useEffect(() => {
    if (
      props?.data &&
      props?.data?.isupdated &&
      props?.data?.hotel &&
      clicked
    ) {
      props.reset();
      notifySuccess(t("706"));
      setClicked(false);
      navigate(`${ROUTES.HOTEL_CONFIG}/3`);
    }
  }, [props?.data]);

  //clear error
  useEffect(() => {
    if (props?.data && props?.data?.error && clicked) {
      setClicked(false);
    }
  }, [props.data]);

  const skipTheTab = () => navigate(`${ROUTES.HOTEL_CONFIG}/3`);

  return (
    <>
      {props?.data && props?.data?.loading && clicked && (
        <BackGroundLoadSpinner />
      )}
      {isFullData ? (
        <div className="w-full max-w-full border border-[#E4E4E7] rounded-lg bg-white overflow-hidden p-8">
          <h2 className="text-[30px] leading-[37.8px] text-[#07090D] font-bold tracking-[-1.5%]">
            {t("707")}
          </h2>
          <form
            className="flex flex-col gap-4 w-full mt-4"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-2 gap-6 items-center">
              <Inputfield
                value={tagline}
                onChange={(e) => setTagline(e?.target?.value)}
                label={t("708")}
                message={t("709")}
                placeholder={t("Enter Your Tagline here...")}
              />
              <SelectFromObject
                label={t("Common Areas")}
                value={commonAreas}
                setValue={setCommonAreas}
                options={{}}
                toolTipMessage={"select Common areas..."}
              />
            </div>

            <InputTextArea
              value={propertyDescription}
              onChange={(e) => setPropertyDescription(e?.target?.value)}
              label={t("Property Description")}
              message={t("715")}
              placeholder={t("Enter Property Description here...")}
              placeholderColor={true}
              rows={3}
            />
            <InputTextArea
              value={refundPolicy}
              onChange={(e) => setRefundPolicy(e?.target?.value)}
              label={t("Reufund Policy")}
              message={t("718")}
              placeholder={t("Enter Refund Policy here..")}
              placeholderColor={true}
              rows={3}
            />
            <InputTextArea
              value={termsAndConditions}
              onChange={(e) => setTermsAndConditions(e?.target?.value)}
              label={t("723")}
              message={t("724")}
              placeholder={t("725")}
              placeholderColor={true}
              rows={3}
            />
            <InputTextArea
              value={rulesAndRegulations}
              onChange={(e) => setRulesAndRegulations(e?.target?.value)}
              label={t("Property Rules And Regulations here...")}
              message={t("727")}
              placeholder={t("Enter Property Rules And Regulations")}
              placeholderColor={true}
              rows={3}
            />
            <div className="w-full flex justify-end items-center gap-3 mt-4">
              <SkipButton text={t("260")} onClick={skipTheTab} />
              <AddSubmitButton
                text={t("65")}
                width={88}
                disabled={props?.data && props?.data?.loading}
              />
            </div>
          </form>
        </div>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  updateHotel: (data) => dispatch(updateHotelAction(data)),
  reset: () => dispatch({ type: UPDATE_HOTEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyInfo);
