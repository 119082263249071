import React, { useState } from "react";
import SelectFromObject from "../../components/layouts/SelectFromObject"; 
import Inputfield from "../../components/layouts";
import PhoneInput from "react-phone-number-input";
import SubmitButton from "../../components/buttons/SubmitButton";

const WelcomeToKunggy = (props) => {
  const [whoAmI, setWhoAmI] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNo, setContactNo] = useState("");

  function nextStep() {
    props.setStep(2);
  }
  return (
    <div className="flex flex-col items-center mx-auto">
      <div className="text-[40px] leading-[48px] font-bold tracking-[-1.5%] text-[#07090D] mt-7">
        welcome to kunggy
      </div>
      <p className="text-[24px] leading-[38px] text-[#585858] mt-2">
        You have been invited by Rakesh (General Manager)
      </p>
      <div className="w-full px-4 mt-4">
        <SelectFromObject
          label={"Who am i ?"}
          toolTipMessage={"select you are a owner."}
          options={{}}
          value={whoAmI}
          setValue={setWhoAmI}
        />
        <div className="w-full my-4">
          <label htmlFor="firstAndLastName">Details</label>
          <div className="w-full flex items-center gap-4">
            <Inputfield
              placeholder={"First Name"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Inputfield
              placeholder={"Last Name"}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="relative w-full">
          <PhoneInput
            placeholder={"Contact No"}
            value={contactNo}
            onChange={(value) => setContactNo(value)}
            className={`w-full h-12 relative bg-transparent border shadow-inputFeild border-[#D0D5DD] rounded-lg phone-number-input-box overflow-hidden`}
            numberInputProps={{
              required: true,
            }}
          />
          <div className="absolute top-[1.5px] right-[1.5px] h-[45px] rounded-r-lg flex items-center justify-center px-4 bg-white">
            <button
              type="button"
              className="flex items-center justify-center h-[23px] w-[74px] rounded border border-base-primary text-base-primary hover:bg-base-primary duration-300 ease-[0.3s] text-[10px] leading-[20px] font-semibold hover:text-white"
            >
              Send OTP
            </button>
          </div>
          <div className="w-full my-4">
            <Inputfield
              label={"Enter OTP (OTP Valid  for 30 minutes)"}
              placeholder={
                "Enter 6 Digit Number Received on your Mobile Number in SMS"
              }
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="h-12 w-full rounded-md bg-[#E9FBF0] hover:bg-[#c3dbcc] duration-300 ease-[0.3s] text-[16px] leading-5 font-medium text-base-primary shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]"
          >
            Verified
          </button>
          <SubmitButton
            onClick={()=>nextStep()}
            text={"Next"}
            sizing="w-full h-12 my-4"
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeToKunggy;
