import React from "react";

const ShiftTimming = ({
  startTime,
  setStartTime,
  // options,
  startOptionsValue,
  setStartOptionValue,
  endTime,
  setEndTime,
  endOptionsValue,
  setEndOptionValue,
}) => {
  const AM_PM = ["Am","Pm"]
  return (
    <div className="w-full flex items-center gap-4 text-[16px] leading-[20.16px] font-medium text-[#6E7C87]">
      {/* start time */}
      <div className="flex items-center justify-center w-[302px] h-12 border border-[#9BF4E9] bg-[#D1FFF9] rounded-lg">
        <div className="flex items-center justify-center border-r border-[#9BF4E9] w-[100px] h-10">
          Start
        </div>
        <input
          type="text"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          className="px-[30px] w-[100px] h-10 border-r border-[#9BF4E9] border-t-0 border-l-0 border-b-0 bg-[#D1FFF9] focus:border-none shadow-none rounded-none "
        />
        <select
          value={startOptionsValue}
          onChange={(e) => setStartOptionValue(e.target.value)}
          className="border-none bg-[#D1FFF9] border-l-none focus:border-none shadow-none rounded-l-none w-[100px] h-fit pr-3"
        >
          {
            AM_PM?.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
        </select>
      </div>
      {/* end time */}
      <div className="flex items-center justify-center w-[302px] h-12 border border-[#F5C9B1] bg-[#FFE7DA] rounded-lg">
        <div className="flex items-center justify-center border-r border-[#F5C9B1] w-[100px] h-10">
          End
        </div>
        <input
          type="text"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          className="px-[30px] w-[100px] h-10 border-r border-[#F5C9B1] border-t-0 border-l-0 border-b-0 bg-[#FFE7DA] focus:border-none shadow-none rounded-none "
        />
        <select
          value={endOptionsValue}
          onChange={(e) => setEndOptionValue(e.target.value)}
          className="border-none bg-[#FFE7DA] border-l-none focus:border-none shadow-none rounded-l-none w-[100px] h-fit pr-3"
        >
          {
            AM_PM?.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default ShiftTimming;
