import React, { useState } from "react";
import { CustomeTable } from "../../../components";
import { TbSquareRoundedPlus } from "react-icons/tb";
import SelectFromObject from "../../../components/layouts/SelectFromObject";
import { BOOKING_ROOM_TYPES, FOOD_PLANS } from "../../../constants";

const Billing = () => {
  const [roomType, setRoomType] = useState([]);
  const [rateType, setRateType] = useState([]);
  const [serviceName, setServiceName] = useState([]);
  const roomRowData = [
    {
      id: "001",
      roomType: roomType,
      rateType: rateType,
      mealPrice: "$19.00",
      roomTariff: "$1000",
      discount: "10%",
      totalPriceExcludingTax: "$1019",
      cGST: "$100",
      sGST: "$50",
      iGST: "$50",
      vAT: "$600",
      totalPriceInlcudingTax: "$3500",
    },
  ];
  const addRowActions = () => {
    return (
      <button
        type="button"
        className="size-fit rounded-full text-base-primary hover:text-base-primary_hover duration-300 ease-[0.3s]"
      >
        <TbSquareRoundedPlus size={18} />
      </button>
    );
  };
  const roomTypeActions = () => {
    return (
      <div className="h-full flex items-center">
        <SelectFromObject
          options={BOOKING_ROOM_TYPES}
          value={roomType}
          setValue={setRoomType}
          className={"h-8 px-1"}
        />
      </div>
    );
  };

  const rateTypeActions = () => {
    return (
      <div className="h-full flex items-center">
        <SelectFromObject
          options={FOOD_PLANS}
          value={rateType}
          setValue={setRateType}
          className={"h-8 px-1"}
        />
      </div>
    );
  };
  const serviceNameActions = () => {
    return (
      <div className="h-full flex items-center">
        <SelectFromObject
          options={{ airportTransfer: "Airport Transfer" }}
          value={serviceName}
          setValue={setServiceName}
          className={"h-8 px-1"}
        />
      </div>
    );
  };
  const columnDefsRoom = [
    {
      headerName: "",
      field: "id",
      minWidth: 50,
      cellRenderer: addRowActions,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      minWidth: 150,
      filter: true,
      cellRenderer: roomTypeActions,
    },
    {
      headerName: "Rate Type",
      field: "rateType",
      minWidth: 200,
      filter: true,
      cellRenderer: rateTypeActions,
    },
    {
      headerName: "Meal Price",
      field: "mealPrice",
      filter: true,
    },
    {
      headerName: "Room Tariff",
      field: "roomTariff",
      filter: true,
    },
    {
      headerName: "Discount",
      field: "discount",
      filter: true,
    },
    {
      headerName: "Total Price (Excluding Tax)",
      field: "totalPriceExcludingTax",
      filter: true,
    },
    {
      headerName: "CGST 5%",
      field: "cGST",
      filter: true,
    },
    {
      headerName: "SGST 5%",
      field: "sGST",
      filter: true,
    },
    {
      headerName: "IGST 5%",
      field: "iGST",
      filter: true,
    },
    {
      headerName: "Vat 5%",
      field: "vAT",
      filter: true,
    },
    {
      headerName: "Total Price (Inlcuding Tax)",
      field: "totalPriceInlcudingTax",
      filter: true,
    },
  ];
  const serviceRowData = [
    {
      id: "001",
      serviceName: serviceName,
      serviceType: "$100",
      servicePrice: "$19.00",
      cGST: "$100",
      sGST: "$50",
      iGST: "$50",
      vAT: "$600",
      totalPrice: "$3500",
    },
  ];
  const columnDefsService = [
    {
      headerName: "",
      field: "id",
      minWidth: 50,
      cellRenderer: addRowActions,
    },
    {
      headerName: "Service Name",
      field: "serviceName",
      minWidth: 200,
      filter: true,
      cellRenderer: serviceNameActions,
    },
    {
      headerName: "Service Type",
      field: "serviceType",
      filter: true,
    },
    {
      headerName: "Service Price",
      field: "servicePrice",
      filter: true,
    },
    {
      headerName: "CGST 5%",
      field: "cGST",
      filter: true,
    },
    {
      headerName: "SGST 5%",
      field: "sGST",
      filter: true,
    },
    {
      headerName: "IGST 5%",
      field: "iGST",
      filter: true,
    },
    {
      headerName: "Vat 5%",
      field: "vAT",
      filter: true,
    },
    {
      headerName: "Total Price",
      field: "totalPrice",
      filter: true,
    },
  ];
  return (
    <div className="w-full border border-[#D0D5DD] rounded-[10px] bg-white px-4 py-6">
      <div className="text-[20px] leading-5 font-bold text-[#07090D] tracking-[2%] mb-4">
        Billing
      </div>
      <CustomeTable
        size={"h-[20vh]"}
        columnDefs={columnDefsRoom}
        rowData={roomRowData}
        pagination={false}
        showButtons={false}
      />
      <div className="my-4">
        <CustomeTable
          size={"h-[20vh]"}
          columnDefs={columnDefsService}
          rowData={serviceRowData}
          pagination={false}
          showButtons={false}
        />
      </div>
      <div className="flex items-center justify-end w-full">
        <div className="flex justify-between gap-16 text-[16px] leading-[20.16px] font-normal">
            <div className="flex flex-col gap-2">
                <div>Total Room Tariff</div>
                <div>Extra Service</div>
                <div>Total Tax</div>
                <div>Corporate Discount 10%</div>
                <div className="text-[18px] leading-[22px] font-bold">Grand Total</div>
            </div>
            <div className="flex flex-col gap-2">
                <div >$2000</div>
                <div >$854</div>
                <div >$200</div>
                <div >$350</div>
                <div className="text-[18px] leading-[22px] font-bold">$7000</div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
