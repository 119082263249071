import React, { useState } from "react";
import SelectFromObject from "../../../components/layouts/SelectFromObject";

const OrderForRoom = () => {
  const [tableNo, setTableNo] = useState([0]);
  const [orderAssignTo, setOrderAssignTo] = useState([0]);
  const ROOM_NO = {
    table: "Select Room",
    table01: "R101",
    table02: "R102",
    table03: "R103",
    table04: "R104",
    table05: "R105",
  };
  const ORDER_ASSIGN_TO = {
    table: "Order Assign To",
    table01: "Rakesh",
    table02: "sanjay",
    table03: "rahul",
    table04: "manish",
    table05: "ankit",
  };
  return (
    <div className="flex flex-col gap-4">
      <SelectFromObject
        value={tableNo}
        setValue={setTableNo}
        options={ROOM_NO}
      />
      <SelectFromObject
        value={orderAssignTo}
        setValue={setOrderAssignTo}
        options={ORDER_ASSIGN_TO}
      />
    </div>
  );
};

export default OrderForRoom;
