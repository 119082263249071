import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { BiCartDownload } from "react-icons/bi";
const CategoryCard = ({ name, details, price }) => {
  return (
    <div className="border-[0.69px] border-[#E5E9EB] rounded-[10px]  bg-white capitalize p-4">
      <div className="flex items-start justify-between gap-6">
        <div className="text-[16px] leading-6 font-medium text-[#000000]">
          {name}
        </div>
        <div className="text-[24px] leading-6 text-base-primary">{price}</div>
      </div>
      <p className="text-xs text-[#6E7C87] w-[198px] mt-1">
        {details}
      </p>
      <div className="flex items-end justify-end">
        <div className="border border-[#E1E1E1] rounded-[5px] h-[31px] w-[93px] bg-white shadow-[0_0_5.3px_0_rgba(0,0,0,0.18)] text-[16px] leading-[22px] font-semibold text-base-primary  flex items-center justify-between py-1 px-2">
          <button
            type="button"
            className="hover:text-base-primary duration-300 ease-[0.3s] scale-100 hover:scale-150"
          >
            <FaMinus size={15} />
          </button>
          <div className="select-none">2</div>
          <button
            type="button"
            className="hover:text-base-primary duration-300 ease-[0.3s] scale-100 hover:scale-150"
          >
            <FaPlus size={15} />
          </button>
        </div>
        <button
          type="button"
          className="h-[31px] w-[35px] border border-base-primary rounded-[4.4px] flex items-center justify-center text-base-primary hover:bg-base-primary hover:text-white duration-300 ease-[0.3s] scale-100 hover:scale-125 ml-4 "
        >
          <BiCartDownload size={18} />
        </button>
      </div>
    </div>
  );
};

export default CategoryCard;
