import React from "react";
import { useTranslation } from "react-i18next";
import { TbPencil } from "react-icons/tb";

const EditButton = ({
  onClick = () => {},
  disabled = true,
  sizing = "h-[30px] w-fit",
}) => {
  const { t } = useTranslation();
  return (
    <button
      className={`${sizing} pl-[3px] pr-2 border border-[#667085] rounded-md flex items-center justify-center gap-2 duration-[0.3s] ease-[0s] bg-white text-[#667085]  hover:text-white hover:bg-[#667085] group`}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <div className="flex items-center justify-center rounded text-white bg-[#667085] group-hover:bg-white group-hover:text-[#667085] p-[3px]">
        <TbPencil size={16} />
      </div>

      <span className="tracking-[-0.006em] text-[14px] leading-6 font-normal">
        {t("62")}
      </span>
    </button>
  );
};

export default EditButton;
