/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  addHotelAction,
  getHotelAction,
  updateHotelAction,
} from "../../actions/pms/hotel";
import { notifySuccess } from "../../components/alert";
import LoadSpinner from "../../components/loader/LoadSpinner";
import { clearError } from "../../actions/clearError";
import { ADD_HOTEL_RESET } from "../../actions/types/hotel";
import { connect } from "react-redux";
import InputPropertyName from "./InputPropertyName";
import InputNumberOfRooms from "./InputNumberOfRooms";
import {
  PROPERTY_TYPE,
  PROPERTY_ID,
  ONBOARDING_STAGES,
  LANGUAGES,
  TIME_ZONES,
  COUNTRY_DETAILS,
} from "../../constants";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import ToolTip from "../../components/ToolTip";
import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";

const Step1 = (props) => {
  const { t } = useTranslation();
  const [propertyName, setPropertyName] = useState("");
  const [propertyType, setPropertyType] = useState(PROPERTY_TYPE.HOTELS);
  const [totalRooms, setTotalRooms] = useState(1);
  const [isHoteladded, setIsHoteladded] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [country, setCountry] = useState(Country.getAllCountries()[0]?.name);
  const [countryIsoCode, setCountryIsoCode] = useState(
    Country.getAllCountries()[0]?.isoCode
  );
  const [state, setState] = useState("");
  const [stateIsoCode, setStateIsoCode] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [language, setLanguage] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [currency, setCurrency] = useState([]);

  const formSubmit = (e) => {
    e.preventDefault();
    const formData = {
      propertyName,
      propertyType,
      totalRooms,
      propertyContact: {
        phone,
        email,
      },
      // new update field
      language: {
        language,
        timeZone,
        currency,
      },
      propertyAddress: {
        streetAddress,
        country,
        state,
        city,
        pinCode,
      },
      description: t("903"),
      checkInTime: "14:00",
      checkOutTime: "12:00",
    };
    console.log("form data occures:", formData);
    console.log(formData, isHoteladded);

    if (isHoteladded) {
      props.updateHotel({
        ...formData,
        hotelId: props?.hotel?._id,
        stage: ONBOARDING_STAGES.CONTACT,
      });
    } else {
      props.addHotel(formData);
    }
  };

  useEffect(() => {
    if (props?.add && props?.add?.isadded) {
      localStorage.setItem(PROPERTY_ID, props?.add?.hotel?._id);
      props.getHotel(props?.add?.hotel?._id);
      notifySuccess(t("904"));
      props.addreset();
      props.setStep(2);
    }
  }, [props?.add]);

  useEffect(() => {
    if (props?.updatedHotel && props?.updatedHotel?.isupdated) {
      props?.reset();
      props?.setStep(3);
    }
  }, [props?.updatedHotel]);

  useEffect(() => {
    if (props?.hotel && props?.hotel?._id) {
      setIsHoteladded(true);
      setPropertyName(props?.hotel?.propertyName);
      setPropertyType(props?.hotel?.propertyType);
      setTotalRooms(props?.hotel?.totalRooms);
      setPhone(props?.hotel?.propertyContact?.phone);
      setEmail(props?.hotel?.propertyContact?.email);
      setStreetAddress(props?.hotel?.propertyAddress?.streetAddress);
      setCountry(props?.hotel?.propertyAddress?.country);
      setState(props?.hotel?.propertyAddress?.state);
      setCity(props?.hotel?.propertyAddress?.city);
      setPinCode(props?.hotel?.propertyAddress?.pinCode);
      // new feild set
      setLanguage(props?.hotel?.language?.language);
      setTimeZone(props?.hotel?.language?.timeZone);
      setCurrency(props?.hotel?.language?.currency);
      console.log(props?.hotel);
    }
  }, [props?.hotel]);

  useEffect(() => {
    if (countryIsoCode) {
      const stateName = State.getStatesOfCountry(countryIsoCode)[0]?.name;
      const iso = State.getStatesOfCountry(countryIsoCode)[0]?.isoCode;
      setState(stateName);
      setStateIsoCode(iso);
    }
  }, [countryIsoCode]);

  useEffect(() => {
    if (countryIsoCode && stateIsoCode) {
      const cityy = City.getCitiesOfState(countryIsoCode, stateIsoCode)[0]
        ?.name;
      setCity(cityy);
    }
  }, [stateIsoCode]);

  return (
    <div className="flex items-start flex-col justify-center pb-6 w-full">
      <form className="mx-auto" onSubmit={formSubmit}>
        <div className="text-center text-2xl sm:text-3xl md:text-[40px] capitalize mb-8 font-bold leading-[72px] tracking-[-1.5%] mt-12">
          {t("905")}
        </div>
        <div className="lg:w-[540px] md:w-full grid grid-cols-1 gap-4">
          <InputPropertyName
            propertyName={propertyName}
            setPropertyName={setPropertyName}
            message={t("899")}
          />
          {/* sm:w-[410px] */}
          <div className="relative w-full">
            <label>{t("906")}</label>
            <div className="relative flex items-center w-full gap-2">
              <select
                className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12 capitalize"
                onChange={(e) => setPropertyType(e?.target?.value)}
                value={propertyType}
              >
                {Object.keys(PROPERTY_TYPE)?.map((value) => (
                  <option key={value} value={value}>
                    {value?.replace("_", " ")?.toLowerCase()}
                  </option>
                ))}
              </select>
              <ToolTip Message={t("1118")} />
            </div>
          </div>

          <InputNumberOfRooms
            totalRooms={totalRooms}
            setTotalRooms={setTotalRooms}
            message={t("1119")}
          />
          <div className="relative max-w-full w-full">
            <label>{t("908")}*</label>
            <div className="relative flex items-center w-full gap-2">
              <PhoneInput
                placeholder={t("909")}
                value={phone}
                onChange={(value) => setPhone(value)}
                className={`w-full h-12 relative bg-transparent border shadow-inputFeild border-[#D0D5DD] rounded-lg phone-number-input-box overflow-hidden`}
                numberInputProps={{
                  required: true,
                }}
              />
              <ToolTip Message={t("910")} />
            </div>
          </div>

          <div className="max-w-full w-full gap-2">
            <label>{t("911")}</label>
            <div className="flex items-center w-full gap-2">
              <input
                type="email"
                onChange={(e) => setEmail(e?.target?.value)}
                value={email}
                required={true}
                placeholder="Property Email"
                className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12"
              />
              <ToolTip Message={t("912")} />
            </div>
          </div>
          {/* language */}
          <div className="">
            <label>{t("Language")}</label>
            <div className="flex items-center w-full gap-2">
              <select
                className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12 capitalize"
                value={language}
                onChange={(e) => {
                  setLanguage(e.target.value);
                }}
              >
                {LANGUAGES.map((lang) => {
                  return <option key={lang.text}>{lang.text}</option>;
                })}
              </select>
              <ToolTip Message={t("916")} />
            </div>
          </div>
          {/* time zone and currency */}
          <div className="">
            <label htmlFor="timezoneAndCurrency">
              Time Zone & Currency{" "}
              <span className="text-[12px] leading-5 text-[#6E7C87]">
                (Time zone and currency cannot be changed once saved){" "}
              </span>
            </label>
            <div className="flex items-center gap-2">
              {/* timeZone */}
              <div className="flex items-center gap-2 ">
                <select
                  value={timeZone}
                  onChange={(e) => setTimeZone(e.target.value)}
                >
                  {TIME_ZONES.map((timeZones) => {
                    return (
                      <option key={timeZones.text}>{timeZones.text}</option>
                    );
                  })}
                </select>
                <ToolTip Message={t("916")} />
              </div>
              {/* currency */}
              <div className="flex items-center gap-2">
                <select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  {COUNTRY_DETAILS.map((crr, id) => {
                    return (
                      <option key={id}>
                        {crr.currency.name} - {crr.currency.code}
                      </option>
                    );
                  })}
                </select>
                <ToolTip Message={t("916")} />
              </div>
            </div>
          </div>

          <div className="max-w-full w-full gap-2">
            <label>{t("913")}*</label>
            <div className="relative flex items-center w-full gap-2">
              <input
                type="text"
                onChange={(e) => setStreetAddress(e?.target?.value)}
                value={streetAddress}
                required={true}
                minLength={5}
                maxLength={100}
                placeholder={t("914")}
                className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12"
              />
              <ToolTip Message={t("1120")} />
            </div>
            <div className="flex items-center w-full mt-4 gap-6">
              <div className="flex items-center w-full gap-2">
                <select
                  className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12"
                  onChange={(e) => {
                    setCountry(e.target.innerText);
                    setCountryIsoCode(e?.target?.value);
                  }}
                  value={countryIsoCode}
                  required={true}
                >
                  {Country.getAllCountries()?.map((value) => (
                    <option value={value?.isoCode} key={value?.isoCode}>
                      {value?.name}
                    </option>
                  ))}
                </select>
                <ToolTip Message={t("916")} />
              </div>
              <div className="flex items-center w-full gap-2">
                <select
                  className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12"
                  onChange={(e) => {
                    setState(e?.target?.innerText);
                    setStateIsoCode(e?.target?.value);
                  }}
                  value={stateIsoCode}
                  required={true}
                >
                  {State.getStatesOfCountry(countryIsoCode)?.map((value) => (
                    <option value={value?.isoCode} key={value?.isoCode}>
                      {value?.name}
                    </option>
                  ))}
                </select>
                <ToolTip Message={t("917")} />
              </div>
            </div>
            <div className="flex items-center w-full mt-4 gap-6">
              <div className="flex items-center w-full gap-2">
                <select
                  className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  required={true}
                >
                  {City.getCitiesOfState(countryIsoCode, stateIsoCode)?.map(
                    (value) => (
                      <option value={value?.name} key={value?.name}>
                        {value?.name}
                      </option>
                    )
                  )}
                </select>
                <ToolTip Message={t("918")} />
              </div>
              <div className="flex items-center w-full gap-2">
                <input
                  type="text"
                  onChange={(e) => setPinCode(e?.target?.value)}
                  value={pinCode}
                  placeholder="Pincode"
                  required={true}
                  className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12"
                />
                <ToolTip Message={t("919")} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex lg:w-full items-center mt-6 justify-between text-sm font-medium">
          <button
            type="button"
            className="w-[120px] h-12 flex items-center justify-center gap-2 rounded-md hover:bg-gray-300 bg-white border border-[#E5E9EB] text-[#252C32] capitalize duration-300 ease-[0.3s]"
            onClick={() => props.setStep(1)}
          >
            <FiArrowLeft size={18} />
            {t("64")}
          </button>
          <button
            className="w-[120px] h-12 flex justify-center items-center gap-2 bg-base-primary hover:bg-base-primary_hover rounded-md  text-white capitalize duration-300 ease-[0.3s]"
            type="submit"
            disabled={
              (props?.add && props?.add?.loading) ||
              (props?.updatedHotel && props?.updatedHotel?.loading)
            }
          >
            {(props?.add && props?.add?.loading) ||
            (props?.updatedHotel && props?.updatedHotel?.loading) ? (
              <LoadSpinner />
            ) : (
              <>
                {t("65")}
                <FiArrowRight size={18} />
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  add: state.AddHotel,
  updatedHotel: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  addHotel: (data) => dispatch(addHotelAction(data)),
  updateHotel: (data) => dispatch(updateHotelAction(data)),
  addreset: () => dispatch({ type: ADD_HOTEL_RESET }),
  clear: () => dispatch(clearError()),
  getHotel: (hotelId) => dispatch(getHotelAction(hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
