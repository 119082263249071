import React from "react";

const ShiftButton = ({
  name,
  onClick = () => {},
  className="h-[42px] w-[141px]",
  icon,
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${className} flex items-center justify-center gap-2 h-[42px] w-[141px] rounded-md bg-[#E8E8E8] hover:bg-[#DBF2E8] hover:text-base-primary text-[#7D7D7D] text-[17px] leading-[21px] font-medium duration-300 ease-[0.3s]`}
    >
      {icon}
      <div className="">{name}</div>
    </button>
  );
};

export default ShiftButton;
