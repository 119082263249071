import React from "react";
import { useTranslation } from "react-i18next";
import { BiSolidSend } from "react-icons/bi";
import { BsEmojiSmile } from "react-icons/bs";

const SupportMessageSendBox = () => {
  const { t } = useTranslation();
  return (
    <div className="relative flex items-center gap-0 mx-2">
      <textarea
        className="w-full pt-3 pl-10 rounded-tr-lg border-t border-r border-gray-300 bg-[#F6F6F6] overflow-y-auto  srcoll-barguest"
        rows={3}
        placeholder={t("Message . . .")}
      ></textarea>
      <button type="button">
        <BsEmojiSmile
          size={14}
          className="absolute left-4 top-[18px] text-[#707991]"
        />
      </button>
      <button
        type="button"
        className="text-2xl absolute right-3 top-3 h-[50px] w-[50px] rounded-[10px] flex justify-center items-center bg-base-primary text-white"
      >
        <BiSolidSend />
      </button>
    </div>
  );
};

export default SupportMessageSendBox;
