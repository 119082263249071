/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DeleteOrNot from "../../../components/layouts/DeleteOrNot";
import { ONBOARDING_STAGES } from "../../../constants";
import { registerEvent } from "../../../logs";
import { notifyError } from "../../../components/alert";
import { CustomeTable, DeleteButton, EditButton } from "../../../components";
import BackGroundLoadSpinner from "../../../components/loader/BackGroundLoadSpinner";
import { updateHotelAction } from "../../../actions/pms/hotel";

const BookingSourceAll = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (props?.hotel && props?.hotel?.bookingSource) {
      let sources = props?.hotel?.bookingSource?.map((s) => {
        return {
          source: s?.source,
          email: s?.email,
          _id: s?._id,
        };
      });
      setDatas(sources);
    }
  }, [props?.hotel]);

  // const deleteBookingSourceudate = (id) => {
  //   if (id) {
  //     setDeleteId(id);
  //     setOpen(true);
  //   }
  // };

  //delete booking source confirm
  const deleteBookingSourceConfirm = () => {
    const result = datas?.filter((data) => data._id !== deleteId);
    props.updateSource({
      hotelId: props?.hotel?._id,
      bookingSource: result,
      stage: ONBOARDING_STAGES.DASHBOARD,
    });
    registerEvent("confirm delete booking source", "booking source", {
      result,
    });
  };

  useEffect(() => {
    if (
      (props?.data && props?.data?.error) ||
      (props?.data && props?.data?.isupdated)
    ) {
      notifyError(props?.data?.error);
      setDeleteId(null);
    }
  }, [props.data]);

  //confirmation cancel of booking source delete
  const notDeletingBookingSource = () => {
    setOpen(false);
    setDeleteId("");
  };
  const tableData = [
    {
      id:"BS001",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS002",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Not Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS003",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS004",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS005",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS006",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS007",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS008",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS009",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS010",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
    {
      id:"BS011",
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      commissionSettlement:"16%",
      bookingEmailReceived: "02",
      paymentSettlementEmail:"02",
      totalEmails: "01",
    },
  ];
  const bookingSourceAction = ()=>{
    return(
      <div className="flex items-center gap-2 h-full">
        <EditButton />
        <DeleteButton />
      </div>
    )
  }
  const columnDefs = [
    {
      headerName: "Booking Source ID",
      field: "id",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Source",
      field: "source",
      filter: true,
    },
    {
      headerName: "Notification Email",
      field: "notificationEmail",
      filter: true,
    },
    {
      headerName: "Status",
      field: "status",
      filter: true,
    },
    {
      headerName: "Commission Settlement",
      field: "commissionSettlement",
      filter: true,
    },
    {
      headerName: "Booking Email Received",
      field: "bookingEmailReceived",
      filter: true,
    },
    {
      headerName: "Payment Settlement Email",
      field: "paymentSettlementEmail",
      filter: true,
    },
    {
      headerName: "Total Emails",
      field: "totalEmails",
      filter: true,
    },
    {
      headerName: "Action",
      field: "id",
      minWidth:200,
      filter: true,
      cellRenderer:bookingSourceAction
    },
  ];
  return (
    <>
      <div className="my-4">
        <CustomeTable
          tableName={"Booking Source Table"}
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
      </div>
      {open ? (
        <DeleteOrNot
          open={open}
          close={notDeletingBookingSource}
          formSubmit={deleteBookingSourceConfirm}
        />
      ) : null}
      {props?.data && props?.data?.loading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  updateSource: (data) => dispatch(updateHotelAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingSourceAll);
