import React from "react";
import { LEAVE_TYPE } from "../../constants";
import Inputfield from "../layouts";
import InputTextArea from "../layouts/InputTextArea";
import SelectFromObject from "../layouts/SelectFromObject";
import CustomModal from "../CustomModal";
import SubmitButton from "../buttons/SubmitButton";

const AskForLeave = ({
  startDateAndEndDate,
  setStartDateAndEndDate,
  leaveType,
  setLeaveType,
  note,
  setNote,
  close = () => {},
}) => {
  return (
    <CustomModal close={close} heading={"Ask For Leave"}>
      <div className="h-[58vh] w-[25vw]">
        <div className="flex flex-col gap-6 mt-10">
          <SelectFromObject
            value={leaveType}
            setValue={setLeaveType}
            options={LEAVE_TYPE}
          />
          <Inputfield
            label={"Start Date & End Date"}
            type="date"
            value={startDateAndEndDate}
            onChange={(e) => setStartDateAndEndDate(e.target.value)}
            minLength={10}
            maxLength={100}
            required={true}
          />
          <InputTextArea
            rows={3}
            label={"Note"}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="add notes"
          />
          <SubmitButton
            type="button"
            text={"apply leave"}
            className={"h-[50px] w-full"}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default AskForLeave;
