import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Inputfield from "../../../components/layouts";

const TaxSlabMinimum = ({ taxRule, setTaxRule, setChanged }) => {
  const { t } = useTranslation();
  const [price, setPrice] = useState("");
  const [percentage, setPercentage] = useState("");

  //set tax price and percentage
  const maxPrice = (e) => {
    const value = e.target.value;
    if (+value > 100000 - 2) return;
    setPrice(value);

    if (taxRule.length) {
      taxRule[0].minimum = 0;
      taxRule[0].maximum = +value;
      setTaxRule(taxRule);
      setChanged(true);
    }
  };

  //set tax percentage
  const taxPercentageset = (value) => {
    setPercentage(value);
    if (taxRule.length && +value <= 100) {
      taxRule[0].taxPercentage = +value;
      setTaxRule(taxRule);
      setChanged(true);
    }
  };

  useEffect(() => {
    if (taxRule?.length) {
      setPrice(taxRule[0]?.maximum);
      setPercentage(taxRule[0]?.taxPercentage);
    }
  }, [taxRule]);

  return (
      <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 grid-w-full">
        <Inputfield
          type="number"
          label={t("Range")}
          value={0}
          onChange={(e) => {}}
          readOnly={true}
        />
        <Inputfield
          type="number"
          label={t("Range")}
          onChange={maxPrice}
          value={price}
          max={100000}
          required={true}
          min={1}
        />
        <Inputfield
          type="number"
          label={t("Tax Percentage")}
          onChange={(e) => taxPercentageset(e.target.value)}
          value={percentage}
          min={0}
          max={100}
          required={true}
        />
      </div>
  
  );
};

export default TaxSlabMinimum;
