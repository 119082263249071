import React, { useState } from "react";
import InputButton from "../InputButton";
import { OutlineButton } from "../../../components";
import ViewCorporateWallet from "../../../components/modals/ViewCorporateWallet";

const ConfigCorporateWallet = () => {
  const [isViewCorporateWallet, setIsViewCorporateWallet] = useState(false);
  return (
    <div className="px-8 pb-8">
      <div className="text-[24px] leading-6 font-semibold">
        Payment Mode Configuration Of Corporate Wallet
      </div>
      <div className="flex flex-col gap-12 mt-10">
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton
              label={"Employee Authorize to View Corporate Wallet"}
              onClick={() => setIsViewCorporateWallet(true)}
            />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
        {isViewCorporateWallet ? (
          <ViewCorporateWallet
            close={() => setIsViewCorporateWallet(false)}
          />
        ) : null}
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton
              label={
                "Employee Authorize to Verify Payment Received in Bank Account"
              }
            />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
        <div className="w-2/3">
          <InputButton label={"Default Account to Received payment"} />
        </div>
      </div>
    </div>
  );
};

export default ConfigCorporateWallet;
