import React, { useState } from 'react'
import { CustomeTable } from '../../components'
import SubmitButton from '../../components/buttons/SubmitButton';

const AllThirdPartyKitchen = () => {
    const [selectedRows,setSelectedRows] = useState([]);
    const rowData = [
      {
        kitchen_id: "K001",
        kitchenName: "Kladen's Cafe",
        kitchenLocation: "Third Party Kitchen",
        contactPersonName: "Rishi Kumar",
        contactPersonNumber: "+911345678978",
      },
      {
        kitchen_id: "K002",
        kitchenName: "Kladen's Cafe",
        kitchenLocation: "Third Party Kitchen",
        contactPersonName: "Rishi Kumar",
        contactPersonNumber: "+911345678978",
      },
      {
        kitchen_id: "K003",
        kitchenName: "Kladen's Cafe",
        kitchenLocation: "Third Party Kitchen",
        contactPersonName: "Rishi Kumar",
        contactPersonNumber: "+911345678978",
      },
      {
        kitchen_id: "K004",
        kitchenName: "Kladen's Cafe",
        kitchenLocation: "Third Party Kitchen",
        contactPersonName: "Rishi Kumar",
        contactPersonNumber: "+911345678978",
      },
      {
        kitchen_id: "K005",
        kitchenName: "Kladen's Cafe",
        kitchenLocation: "Third Party Kitchen",
        contactPersonName: "Rishi Kumar",
        contactPersonNumber: "+911345678978",
      },
      {
        kitchen_id: "K006",
        kitchenName: "Kladen's Cafe",
        kitchenLocation: "Third Party Kitchen",
        contactPersonName: "Rishi Kumar",
        contactPersonNumber: "+911345678978",
      },
    ];
    const ViewThirdPartyActions = () =>{
        return (
            <div className="flex items-center h-full gap-4">
                <SubmitButton sizing='h-8' text={"Commission Sattlement"} />
                <SubmitButton sizing='h-8' text={"Ala Carte"}/>
            </div>
        )
    }
    const columnDefs = [
        {
          headerName: "Kitchen ID",
          field: "kitchen_id",
          minWidth: 150,
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: "Name",
          field: "kitchenName",
          minWidth: 150,
          filter: true,
        },
        {
          headerName: "Location",
          field: "kitchenLocation",
          minWidth: 150,
          filter: true,
        },
        {
          headerName: "Person Name",
          field: "contactPersonName",
          minWidth: 160,
          filter: true,
        },
        {
          headerName: "Contact",
          field: "contactPersonNumber",
          minWidth: 150,
          filter: true,
        },
        {
          headerName: "Action",
          filter: true,
          minWidth: 495,
        cellRenderer: ViewThirdPartyActions,
        },
      ];
  return (
    <div>
         <CustomeTable
            tableName={"Third Party Kitchen Table"}
            columnDefs={columnDefs}
            rowData={rowData}
            setSelectedRows={setSelectedRows}
          />
    </div>
  )
}

export default AllThirdPartyKitchen