import React from "react";
import SupportMessageBox from "./SupportMessageBox";
import SupportMessageSendBox from "./SupportMessageSendBox";

const SupportRight = () => {
  return (
    <div className="w-full flex flex-col relative justify-between bg-white">
      <SupportMessageBox />
      <SupportMessageSendBox />
    </div>
  );
};

export default SupportRight;
