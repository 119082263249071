import React, { useState } from "react";

const OPTIONS = {
  INSPECTED: "Inspected",
  PENDING: "Pending",
};

const Inspection = () => {
  const [value, setValue] = useState("Inspected");

  return (
    <select
      onChange={(e) => setValue(e?.target?.value)}
      value={value}
      className="inspection-selector h-[29px] rounded-[5px] shadow-none border-none focus:border-none text-[14px] leading-[17.64px] font-normal px-2"
    >
      {Object.keys(OPTIONS)?.map((it) => (
        <option className="capitalize" key={it} value={OPTIONS[it]}>
          {OPTIONS[it]}
        </option>
      ))}
    </select>
  );
};

export default Inspection;
