import React from "react";
import { IoSearchOutline } from "react-icons/io5";

const InputButton = ({ label,onClick= () => {}, handleSelect = () => {},isSelectButton = false }) => {

  return (
    <div className="text-[##738391]">
      {label ? (
        <label className="text-[16px] leading-6 font-normal">{label}</label>
      ) : null}
      <div className="relative">
        <input type="button" onClick={onClick} className="pl-12 pr-24 mt-1 cursor-pointer" />
        <IoSearchOutline size={24} className="absolute top-4 left-4" />
        {isSelectButton ? <button
          type="button"
          onClick={handleSelect}
          className="absolute top-1 right-[1px] h-12 border-l rounded-r-lg px-4 text-[16px] leading-6 font-medium bg-base-primary text-white hover:bg-base-primary_hover duration-300 ease-[0.3s]"
        >
          Select
        </button> :null}
      </div>
    </div>
  );
};

export default InputButton;
