import React, { useState } from "react";
import InputButton from "../InputButton";
import { OutlineButton } from "../../../components";
import TakeCashPayment from "../../../components/modals/TakeCashPayment";

const Cash = () => {
  const [isTakeCash, setIsTakeCash] = useState(false);
  return (
    <div className="px-8 pb-8">
      <div className="text-[24px] leading-6 font-semibold">
        Payment Mode Configuration Of Cash
      </div>
      <div className="flex flex-col gap-12 mt-10">
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton
              label={"Employee Authorize to Take Cash Payment"}
              onClick={() => setIsTakeCash(true)}
            />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
        {isTakeCash ? (
          <TakeCashPayment close={() => setIsTakeCash(false)} />
        ) : null}
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton label={"Employee Authorize to Verify Cash Payment"} />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton label={"Employee Authorize to Deposit Cash in Bank"} />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
      </div>
    </div>
  );
};

export default Cash;
