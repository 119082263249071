import React from "react";
import SupportMessageRecieved from "./SupportMessageRecieved";
import SupportMessageSended from "./SupportMessageSended";
import { useTranslation } from "react-i18next";

const SupportMessageBox = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-center items-center my-5">
        <div className="w-[208px] h-10 flex justify-center items-center bg-[#E9FBF0] rounded-[10px]">
          <div className="text-[16px] leading-5 font-normal text-base-primary">
            Monday, 20 April 2023
          </div>
        </div>
      </div>
      <div className=" overflow-y-auto scroll-barguest">
        <SupportMessageSended message={t("Deli more peyena")} />
        <SupportMessageSended
          message={t("Dwata inner mochi pakha la bu na ")}
        />
        <SupportMessageRecieved />
      </div>
    </>
  );
};

export default SupportMessageBox;
