import React, { useState } from "react";
import SelectFromArray from "../../components/layouts/SelectFromArray";
import Inputfield from "../../components/layouts";
import AddSubmitButton from "../../components/buttons/AddSubmitButton";
import { CloseButton } from "../../components";

const GuestLaundryRequest = (props) => {
  const [roomNo, setRoomNo] = useState([]);
  const [guestName, setGuestName] = useState("");
  const [date, setDate] = useState(new Date());
  const [attributes, setAttributes] = useState([]);

  return (
    <form
      action=""
      className="border border-[#E4E4E7] rounded-[10px] bg-white p-6 mt-6"
    >
      <div className="flex items-center justify-between">
        <h2 className="text-[30px] leading-[37.8px] font-bold text-[#07090D] tracking-[-1.5%]">
          Create Laundry Request
        </h2>
        <CloseButton onClick={props.close} />
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6 items-center my-4">
        <SelectFromArray
          label={"Room No."}
          toolTipMessage={"select room number for service..."}
          options={["Select room number", "01", "02", "03"]}
          value={roomNo}
          onChange={(e) => setRoomNo(e?.target?.value)}
        />
        <Inputfield
          label={"Guest Name"}
          message={"Enter guest name..."}
          placeholder={"Enter Guest Name"}
          value={guestName}
          onChange={(e) => setGuestName(e.target.value)}
          required={true}
        />
        <Inputfield
          label={"Date"}
          message={"Select a date to take service..."}
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required={true}
        />
        <SelectFromArray
          label={"Attributes"}
          toolTipMessage={"select Attributes for service..."}
          options={["Dry Cleanning", "Ironning", "Washing", "Wet Cleanning"]}
          value={attributes}
          onChange={(e) => setAttributes(e?.target?.value)}
        />
      </div>
      <div className="flex items-center justify-end">
        <AddSubmitButton text={"Add Laundry Request"} width={199} />
      </div>
    </form>
  );
};

export default GuestLaundryRequest;
