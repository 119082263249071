import React from "react";
import CustomModal from "../CustomModal";
import ErrorIcon2 from "../../images/icons/ErrorIcon2.svg";
import SubmitButton from "../buttons/SubmitButton";

const GetAccessPermission = ({ close = () => {} }) => {
  return (
    <CustomModal close={close}>
      <div className="w-[50vw] flex flex-col items-center">
        <img src={ErrorIcon2} alt="" />
        <div className="text-center text-[36px] leading-[45px] font-semibold text-[#000000] w-[28vw] mt-8">
          You Need Permission To Access Settings
        </div>
        <div className="text-center text-[20px] leading-[31px] text-[#707070] w-[38vw] mt-4">
          You do not have permission to access this section. Would you like to
          request access from your manager?
        </div>
      </div>
      <div className="flex items-center justify-center mt-16 mb-2">
        <SubmitButton sizing="h-12 w-[235px]" text={"Send Access Request"} />
      </div>
    </CustomModal>
  );
};

export default GetAccessPermission;
