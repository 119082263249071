import React, { useState } from "react";

const AmenityCount = () => {
    const [value,setValue] = useState(Number)
  return (
    <select
    className="amenity-count-select"
    value={value}
    onChange={(e) => setValue(e.target.value)}
  >
    {[0, 1, 2, 3, 4].map((it, index) => (
      <option
        key={index}
        value={it}
        className={`${it < 2 ? "text-[#F04438]" : "text-[#344054]"}`}
      >
        {it}
      </option>
    ))}
  </select>
  );
};

export default AmenityCount;
