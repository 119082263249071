import React, { useState } from "react";
import CustomModal from "../CustomModal";
import CustomeTable from "../CustomeTable";
import Inputfield from "../layouts";
import EditButton from "../buttons/EditButton";
import SubmitButton from "../buttons/SubmitButton";
import { HiOutlineSearch } from "react-icons/hi";

const SelectPackage = (props) => {
  const [searchPackage, setSearchPackage] = useState("");
  const rowData = [
    {
      id: "001",
      packageName: "Explorer's Package",
      roomTypeAdded: "Deluxe Suit Room",
      mealPlanAdded: "Breakfast",
      servicesAdded: "Airport Transfer",
      availbilityFor: "Corporate",
    },
    {
      id: "002",
      packageName: "Explorer's Package",
      roomTypeAdded: "Deluxe Suit Room",
      mealPlanAdded: "Breakfast",
      servicesAdded: "Airport Transfer",
      availbilityFor: "Online Travel Agent",
    },
    {
      id: "003",
      packageName: "Explorer's Package",
      roomTypeAdded: "Deluxe Suit Room",
      mealPlanAdded: "Breakfast",
      servicesAdded: "Airport Transfer",
      availbilityFor: "Corporate",
    },
    {
      id: "004",
      packageName: "Explorer's Package",
      roomTypeAdded: "Deluxe Suit Room",
      mealPlanAdded: "Breakfast",
      servicesAdded: "Airport Transfer",
      availbilityFor: "Corporate",
    },
    {
      id: "005",
      packageName: "Explorer's Package",
      roomTypeAdded: "Deluxe Suit Room",
      mealPlanAdded: "Breakfast",
      servicesAdded: "Airport Transfer",
      availbilityFor: "Corporate",
    },
  ];
  const packageActions = () => {
    return (
      <div className="flex items-center h-full gap-4">
        <EditButton />
        <SubmitButton type="button" className="h-[30px] " text={"Save"} />
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Package Id",
      field: "id",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Package Name",
      field: "packageName",
      filter: true,
    },
    {
      headerName: "Room Type Added",
      field: "roomTypeAdded",
      filter: true,
    },
    {
      headerName: "Meal Plan Added",
      field: "mealPlanAdded",
      filter: true,
    },
    {
      headerName: "Services Added",
      field: "servicesAdded",
      filter: true,
    },
    {
      headerName: "Availbility For",
      field: "availbilityFor",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "id",
      minWidth: 190,
      filter: true,
      cellRenderer: packageActions,
    },
  ];
  return (
    <CustomModal heading={"Selecte Package"} close={props.close}>
      <div className="h-[80vh] w-[70vw]">
        <div className="relative w-5/12 my-6">
          <Inputfield
            label={"Search Package"}
            value={searchPackage}
            onChange={(e) => setSearchPackage(e.target.value)}
            message={"search package"}
            placeholder={"Search"}
            className={"pl-12"}
          />
          <HiOutlineSearch size={24} className="absolute top-8 left-4 text-[#6E7C87]" />
        </div>
        <div className="">
          <CustomeTable
            size={"h-[54vh]"}
            columnDefs={columnDefs}
            rowData={rowData}
            showButtons={false}
          />
        </div>
        <div className="flex items-center justify-end mt-6">
            <SubmitButton type="button" text={"Add"} className={"h-[42px] w-[127px]"}/>
        </div>
      </div>
    </CustomModal>
  );
};

export default SelectPackage;
