import React, { useState } from "react";
import { CustomeTable, DeleteButton, EditButton } from "../../components";

const RoomTariffAutomationRules = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      rulesId: "EA001",
      rulesName: "Lorem Ipsum",
      rules:
        "Single room price increase by 12% when occupancy increases by 42% And Total  Days  Left  to Booking  Date is 90 Days",
    },
    {
      rulesId: "EA001",
      rulesName: "Lorem Ipsum",
      rules:
        "Single room price increase by 12% when occupancy increases by 42% And Total  Days  Left  to Booking  Date is 90 Days",
    },
    {
      rulesId: "EA001",
      rulesName: "Lorem Ipsum",
      rules:
        "Single room price increase by 12% when occupancy increases by 42% And Total  Days  Left  to Booking  Date is 90 Days",
    },
    {
      rulesId: "EA001",
      rulesName: "Lorem Ipsum",
      rules:
        "Single room price increase by 12% when occupancy increases by 42% And Total  Days  Left  to Booking  Date is 90 Days",
    },
    {
      rulesId: "EA001",
      rulesName: "Lorem Ipsum",
      rules:
        "Single room price increase by 12% when occupancy increases by 42% And Total  Days  Left  to Booking  Date is 90 Days",
    },
    {
      rulesId: "EA001",
      rulesName: "Lorem Ipsum",
      rules:
        "Single room price increase by 12% when occupancy increases by 42% And Total  Days  Left  to Booking  Date is 90 Days",
    },
  ];
  const rulesActions = () => {
    return (
      <div className="h-full flex items-center gap-4">
        <EditButton />
        <DeleteButton />
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Rules Id",
      field: "rulesId",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Rules Name",
      field: "rulesName",
      filter: true,
    },
    {
      headerName: "Rules",
      field: "rules",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "",
      filter: true,
      cellRenderer: rulesActions,
    },
  ];
  return (
    <CustomeTable
      size={"h-[60vh]"}
      tableName={"Room Tariff Automation Rules"}
      columnDefs={columnDefs}
      rowData={rowData}
      setSelectedRows={setSelectedRows}
    />
  );
};

export default RoomTariffAutomationRules;
