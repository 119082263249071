import React, { useState } from "react";
import AddButton from "../../../components/buttons/AddButton";
import AddPackage from "./AddPackage";
import AllPackages from "./AllPackages";

const PackagesPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="mt-6">
      <div className="flex items-end justify-end">
        <AddButton text={"Add Package"} onClick={() => setOpen(true)} />
      </div>

      <div className="my-4 overflow-hidden">
        {open ? <AddPackage close={() => setOpen(false)} /> : null}
      </div>
      <AllPackages />
    </div>
  );
};

export default PackagesPage;
