import React, { useState } from "react";
import SelectBox from "../../../components/layouts/SelectBox";
import ShiftButton from "../../../components/buttons/ShiftButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import SubmitButton from "../../../components/buttons/SubmitButton";

const RoomType = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      roomTypeName: "Deluxe Suit Room",
      roomTariffMin: "$12",
      roomTariffMax: "$22",
    },
    {
      roomTypeName: "Deluxe Suit Room",
      roomTariffMin: "$12",
      roomTariffMax: "$22",
    },
    {
      roomTypeName: "Deluxe Suit Room",
      roomTariffMin: "$12",
      roomTariffMax: "$22",
    },
  ];
  const rowDataRight = [
    {
      roomTypeName: "Deluxe Suit Room",
      roomTariffMin: "$12",
      roomTariffMax: "$22",
    },
  ];
  const columnDefs = [
    {
      headerName: "Room Type Name",
      field: "roomTypeName",
      checkboxSelection: true,
    },
    {
      headerName: "Room Tariff (Min)",
      field: "roomTariffMin",
    },
    {
      headerName: "Room Tariff (Max)",
      field: "roomTariffMax",
    },
  ];
  return (
    <div className="text-[#07090D] px-6">
      <div className="text-[30px] leading-[37.8px] font-bold tracking-[-1.5%]">
        Package Details
      </div>
      <p className="text-[20px] leading-5">
        Lorem ipsum amit epic alit thu Lorem ipsum amit epic alit thu{" "}
      </p>
      <div className="grid grid-cols-10 w-full mt-14">
        {/* Left side */}
        <div className="col-span-4">
          <SelectBox
            tableSize={"h-[57vh]"}
            label={"Select Room Type"}
            columnDefs={columnDefs}
            rowData={rowData}
            setSelectedRows={setSelectedRows}
            showBtn={false}
          />
        </div>

        {/* Buttons for add and remove */}
        <div className="col-span-2 flex flex-col items-center justify-center gap-6 px-5">
          <ShiftButton
            className="h-10 w-full"
            name={"Add"}
            icon={<AiOutlineDoubleRight size={18} />}
          />
          <ShiftButton
            className="h-10 w-full"
            name={"Remove"}
            icon={<AiOutlineDoubleLeft size={18} />}
          />
        </div>

        {/* Right side */}
        <div className="col-span-4 w-full">
          <SelectBox
            tableSize={"h-[57vh]"}
            label={"Selected Room Type"}
            optionalText={"(Only one room type can be selected)"}
            showBtn={false}
            columnDefs={columnDefs}
            rowData={rowDataRight}
          />
        </div>
      </div>
      <div className="flex items-center justify-end mt-8">
        <SubmitButton
          type="button"
          className={"h-12 w-[160px] text-[20px] leading-5"}
          text={"Next"}
        />
      </div>
    </div>
  );
};

export default RoomType;
