import React, { useState } from "react";
import InputDate from "../../components/layouts/InputDate";
import SubmitButton from "../../components/buttons/SubmitButton";
import { CustomeTable } from "../../components";
import { AllFoodAction, FoodStatus, YesOrNo } from "./TableFunctions";

const AllFoodOrder = () => {
  const [selectDate, setSelectDate] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      _id: "F001",
      date: "2024-09-26",
      tableAndRoomNo: "T102",
      orderFor: "Room Service",
      orderBy: "Guest-Rahul",
      isBillSign: "Yes",
      isPaid: "No",
      status: "In Queue",
      totalPrice: "₹1212",
    },
    {
      _id: "F002",
      date: "2024-09-26",
      tableAndRoomNo: "105",
      orderFor: "Room Service",
      orderBy: "Guest-Rahul",
      isBillSign: "Yes",
      isPaid: "No",
      status: "Delivered",
      totalPrice: "₹750",
    },
  ];

  const columnDefs = [
    {
      headerName: "ID",
      field: "_id",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Date",
      field: "date",
      filter: true,
    },
    {
      headerName: "Table/Room No",
      field: "tableAndRoomNo",
      filter: true,
    },
    {
      headerName: "Order For",
      field: "orderFor",
      filter: true,
    },
    {
      headerName: "Order By",
      field: "orderBy",
      filter: true,
    },
    {
      headerName: "Is Bill Sign",
      field: "isBillSign",
      filter: true,
      cellRenderer: YesOrNo,
    },
    {
      headerName: "Is Paid",
      field: "isPaid",
      filter: true,
      cellRenderer: YesOrNo,
    },
    {
      headerName: "Total Price",
      field: "totalPrice",
      filter: true,
    },
    {
      headerName: "Status",
      field: "status",
      filter: true,
      cellRenderer: FoodStatus,
    },
    
    {
      headerName: "Actions",
      field: "id",
      minWidth: 380,
      cellRenderer: AllFoodAction,
    },
  ];
  return (
    <>
      <div className="grid grid-cols-3">
        <div className="flex items-end gap-2">
          <div className="w-full">
            <InputDate
              label={"Select Date"}
              value={selectDate}
              setValue={setSelectDate}
            />
          </div>
          <SubmitButton type="button" text={"Today"} />
        </div>
      </div>
      <div className="mt-4">
        <CustomeTable
          tableName={"Food List Table"}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
        />
      </div>

      {}
    </>
  );
};

export default AllFoodOrder;
