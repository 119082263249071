import React from "react";
import { CloseButton, DeleteButton, EditButton } from "../../components";
import Inputfield from "../../components/layouts";
import SelectFromObject from "../../components/layouts/SelectFromObject";

const EpmloyeeIdSeriesSetteng = (props) => {
  return (
    <div className="border border-[#E5E9EB] rounded-[10px] bg-white mt-6 pb-6">
      <div className="flex items-center justify-between px-6 py-4">
        <div className="text-[20px] leading-6 font-medium text-[#000000]">
          Employee Id Series Settings
        </div>
        <CloseButton onClick={props.close} />
      </div>
      <div className="">
        {Array.from({ length: 4 }).map((data) => {
          return (
            <div
              className="flex gap-4 items-end border-t border-[#E5E9EB] p-6"
              key={data}
            >
              <div className="">
                <label htmlFor="series">Enter Your Ongoing Series Number</label>
                <div className="flex items-center gap-2">
                  {Array.from({ length: 7 }).map((series) => {
                    return (
                      <Inputfield
                        key={series}
                        value={"E"}
                        className={
                          "size-9 text-center rounded p-0 text-[20px] leading-6 font-medium text-[#667085] bg-[#FCFCFC]"
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <Inputfield
                className={
                  "h-9 rounded font-medium text-[#667085] bg-[#FCFCFC]"
                }
                label={"Series Title"}
              />
              <SelectFromObject
                className={
                  "h-9 rounded font-medium text-[#667085] bg-[#FCFCFC]"
                }
                label={"Select Category"}
                options={{ select: "Select" }}
              />
              <div className="flex items-center gap-2">
                <EditButton sizing="h-9" />
                <DeleteButton sizing="h-9" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EpmloyeeIdSeriesSetteng;
