import { useState } from "react";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import AddEmployee from "./AddEmployee";
import AllEmployee from "./AllEmployee";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import { useEffect } from "react";
import { getAllHotelEmployeeAction } from "../../actions/pms/employee";
import { OutlineButton } from "../../components";
import EpmloyeeIdSeriesSetteng from "./EpmloyeeIdSeriesSetteng";

const EmployeePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openIdSeries, setOpenIdSeries] = useState(false);
  const [openDataPrivacy, setOpenDataPrivacy] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const { hotel } = useSelector((state) => state.Hotel);

  const addEmployeeModal = () => {
    setOpen(true);
    setOpenIdSeries(false);
    registerEvent("click add employee button", "all employee page");
  };

  //close add employee modal
  const close = () => {
    setOpen(false);
    setUpdateData(null);
    registerEvent("close add employee component", "add employee page");
  };
  const handleIdSeriesSetting = () => {
    setOpenIdSeries(true);
    setOpen(false);
    setOpenDataPrivacy(false);
  };
  useEffect(() => {
    if (hotel?._id) dispatch(getAllHotelEmployeeAction(hotel?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headingData = {
    heading: t("198"),
    seperator: [
      {
        text: t("Employee Management"),
      },
      {
        text: t("198"),
      },
    ],
  };

  return (
    <div className="page-container">
      <div className="w-full flex justify-between items-end mx-auto">
        <Seperator data={headingData} />
        <div className="flex items-center gap-4">
          <OutlineButton
            text={"Employee Id series setting"}
            onClick={handleIdSeriesSetting}
            active={openIdSeries}
          />
          <OutlineButton text={"Data Privacy"} />
          <AddButton text={t("199")} onClick={addEmployeeModal} />
        </div>
      </div>

      {(updateData && !isObjEmpty(updateData)) || open ? (
        <div className="mt-6">
          <AddEmployee close={close} updateData={updateData} />
        </div>
      ) : null}
      {openIdSeries ? (
        <EpmloyeeIdSeriesSetteng close={() => setOpenIdSeries(false)} />
      ) : null}
      <div className="mt-6">
        <AllEmployee setUpdateData={setUpdateData} />
      </div>
    </div>
  );
};

export default EmployeePage;
