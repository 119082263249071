import React from "react";

const ProfileNavigation = ({ text, index, buttonIndex, setIndex }) => {
  return (
    <button
      className={`border-b-2 px-4 h-[41px] flex justify-center items-center text-[20px] leading-[25.2px] font-semibold tracking-[-0.8px] ${
        buttonIndex === index
          ? "border-b-2 border-base-primary text-base-primary"
          : "hover:border-b-2 hover:border-base-primary hover:text-base-primary_hover"
      }`}
      onClick={() => setIndex(buttonIndex)}
    >
      {text}
    </button>
  );
};

export default ProfileNavigation;
