import React, { useState } from "react";
import { CustomeTable } from "../../components";
import Assign from "./Assign";
import RoomCleaningTime from "./RoomCleaningTime";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";
import CheckStatus from "./CheckStatus";

const RoomServiceRequest = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      _id: "100",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "assign",
      assign_time: "10:15 AM",
      status: "completed",
      completion_time: "10:30 AM",
    },
    {
      _id: "101",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "E023 Masud , E024 Rajot",
      assign_time: "10:15 AM",
      status: "Pending",
      completion_time: "10:30 AM",
    },
    {
      _id: "102",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "assign",
      assign_time: "10:15 AM",
      status: "completed",
      completion_time: "10:30 AM",
    },
    {
      _id: "103",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "E023 Masud , E024 Rajot",
      assign_time: "10:15 AM",
      status: "In Progress",
      completion_time: "10:30 AM",
    },
    {
      _id: "104",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "assign",
      assign_time: "10:15 AM",
      status: "Pending",
      completion_time: "10:30 AM",
    },
  ];
  const columnDef = [
    {
      headerName: "Room ID",
      field: "_id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      minWidth: 120,
      filter: true,
    },
    {
      headerName: "request time",
      field: "request_time",
      minWidth: 150,
      filter: true,
    },
    {
      headerName: "request for",
      field: "request_for",
      minWidth: 150,
      filter: true,
    },
    {
      headerName: "assign to",
      field: "assign_to",
      cellRenderer: Assign,
      minWidth: 150,
      filter: true,
    },
    {
      headerName: "assign time",
      field: "assign_time",
      cellRenderer: RoomCleaningTime,
      minWidth: 150,
      filter: true,
    },
    {
      headerName: "status",
      field: "status",
      cellRenderer: CheckStatus,
      minWidth: 150,
      filter: true,
    },
    {
      headerName: "completion time",
      field: "completion_time",
      minWidth: 150,
      filter: true,
    },
  ];

  return (
    <div >
      <CustomeTable
      tableName={"Room Service Request Table"}
        rowData={rowData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default RoomServiceRequest;
