import React, { useState } from "react";
import { CustomeTable, OutlineButton } from "../../components";
import AmenityCount from "./AmenityCount";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import SubmitButton from "../../components/buttons/SubmitButton";
import GetAccessPermission from "../../components/modals/GetAccessPermission";

const HousekeepingAmenities = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [roomNumber, setRoomNumber] = useState([]);
  const [time, setTime] = useState([]);
  const [isAccessPermission, setIsAccessPermission] = useState(false);
  const NUMBERS = {
    ONE: "1",
    TWO: "2",
    THREE: "3",
    FOUR: "4",
    FIVE: "5",
    SIX: "6",
    SEVEN: "7",
    EIGHT: "8",
    NINE: "9",
    TEN: "10",
  };
  const tableData = [
    {
      inv_id: "IN01",
      room_no: "103",
      bedsheets: "0",
      pillows: "1",
      blankets: "1",
      towels: "4",
      tea_bags: "2",
      water_bottle: "2",
      soap: "1",
      shampoo: "0",
      toilet_paper: "2",
    },
    {
      inv_id: "IN01",
      room_no: "101",
      bedsheets: "2",
      pillows: "2",
      blankets: "0",
      towels: "2",
      tea_bags: "1",
      water_bottle: "0",
      soap: "2",
      shampoo: "1",
      toilet_paper: "1",
    },
    {
      inv_id: "IN01",
      room_no: "101",
      bedsheets: "1",
      pillows: "2",
      blankets: "1",
      towels: "1",
      tea_bags: "2",
      water_bottle: "1",
      soap: "0",
      shampoo: "0",
      toilet_paper: "2",
    },
    {
      inv_id: "IN01",
      room_no: "103",
      bedsheets: "2",
      pillows: "0",
      blankets: "1",
      towels: "3",
      tea_bags: "0",
      water_bottle: "2",
      soap: "2",
      shampoo: "1",
      toilet_paper: "0",
    },
  ];

  const columnDefs = [
    {
      headerName: "Room ID",
      field: "inv_id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      filter: true,
      minWidth: 125,
    },
    {
      headerName: "bed sheets",
      field: "bedsheets",
      filter: true,
      minWidth: 130,
      cellRenderer: AmenityCount,
    },
    {
      headerName: "pillows",
      field: "pillows",
      filter: true,
      minWidth: 110,
      cellRenderer: AmenityCount,
    },
    {
      headerName: "blankets",
      field: "blankets",
      filter: true,
      minWidth: 115,
      cellRenderer: AmenityCount,
    },
    {
      headerName: "towels",
      field: "towels",
      filter: true,
      minWidth: 110,
      cellRenderer: AmenityCount,
    },
    {
      headerName: "tea bags",
      field: "tea_bags",
      filter: true,
      minWidth: 115,
      cellRenderer: AmenityCount,
    },
    {
      headerName: "water bottle",
      field: "water_bottle",
      minWidth: 150,
      filter: true,
      cellRenderer: AmenityCount,
    },
    {
      headerName: "soap",
      field: "soap",
      minWidth: 90,
      filter: true,
      cellRenderer: AmenityCount,
    },
    {
      headerName: "shampoo",
      field: "shampoo",
      minWidth: 125,
      filter: true,
      cellRenderer: AmenityCount,
    },
    {
      headerName: "toilet paper",
      field: "toilet_paper",
      minWidth: 135,
      filter: true,
      cellRenderer: AmenityCount,
    },
  ];
  const openAccessPermission = () => {
    setIsAccessPermission(true);
  };
  return (
    <div>
      <div className="w-full flex items-center justify-end mb-4">
        <OutlineButton text={"Configurations"} onClick={openAccessPermission} />
        {isAccessPermission ? (
          <GetAccessPermission close={() => setIsAccessPermission(false)} />
        ) : null}
      </div>
      <div className="w-full h-auto border border-[#E4E4E7] rounded-[10px] bg-white px-4 py-6 mb-4">
        <div className="text-[24px] text-[#000000] leading-[30.24px] font-bold tracking-[-0.8px]">
          Room Details
        </div>
        <div className="grid lg:grid-cols-4 md:gap-4 md:grid-cols-1 lg:gap-8 items-center mt-4">
          <SelectFromObject
            label={"Room Number"}
            values={roomNumber}
            setValues={setRoomNumber}
            options={{ Select: "Select" }}
          />
          <SelectFromObject
            label={"Date"}
            values={roomNumber}
            setValues={setRoomNumber}
            options={{ Select: "Select" }}
          />
          <SelectFromObject
            label={"Time"}
            values={roomNumber}
            setValues={setRoomNumber}
            options={{ Select: "Select" }}
          />
        </div>

        {/* Add Amenities*/}
        <div className="text-[24px] text-[#000000] leading-[30.24px] font-bold tracking-[-0.8px] mt-8 mb-4">
          Housekeeping Room Cleaning Add Ons
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-3 items-center gap-6">
          <SelectFromObject
            label={"Bed Sheets"}
            values={time}
            setValues={setTime}
            options={NUMBERS}
          />
          <SelectFromObject
            label={"Pillows"}
            values={time}
            setValues={setTime}
            options={NUMBERS}
          />
          <SelectFromObject
            label={"Blankets"}
            values={time}
            setValues={setTime}
            options={NUMBERS}
          />
          <SelectFromObject
            label={"Towels"}
            values={time}
            setValues={setTime}
            options={NUMBERS}
          />
          <SelectFromObject
            label={"Tea Bags"}
            values={time}
            setValues={setTime}
            options={{ Select: "Select" }}
          />
          <SelectFromObject
            label={"Water Bottles"}
            values={time}
            setValues={setTime}
            options={NUMBERS}
          />
          <SelectFromObject
            label={"Tissue Box"}
            values={time}
            setValues={setTime}
            options={NUMBERS}
          />
          <SelectFromObject
            label={"Shampoo"}
            values={time}
            setValues={setTime}
            options={NUMBERS}
          />
          <SelectFromObject
            label={"Soap"}
            values={time}
            setValues={setTime}
            options={NUMBERS}
          />
          <SelectFromObject
            label={"Toilet Paper"}
            values={time}
            setValues={setTime}
            options={NUMBERS}
          />
        </div>
        <div className="flex items-center justify-end mt-6">
          <SubmitButton sizing="h-10 w-28" type="button" text={"Add"} />
        </div>
      </div>
      {/* data table */}
      <div className="relative mt-4">
        <CustomeTable
          tableName={"Amenities Table"}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
};

export default HousekeepingAmenities;
