import React from "react";
import { FiPercent } from "react-icons/fi";
import { BOOKING_ROOM_TYPES } from "../../constants";

const AddAutomationRules = ({
  roomTypeRule,
  setRoomTypeRule,
  priceIncAndDecByRule,
  setPriceIncAndDecByRule,
  pricePercentage,
  setPricePercentage,
  logicalOperation,
  setLogicalOperation,
  occupencyPercentage,
  setOccupencyPercentage,
  daysLeftToBooking,
  setDaysLeftToBooking,
}) => {
  const PRICE_INC_AND_DEC = {
    PRICE_INCREASE_BY: "Price Increase By",
    PRICE_DECREASE_BY: "Price Decrease By",
  };
  const LOGICAL_OPERATIONS = {
    LESS_THAN: "<",
    GREATER_THAN: ">",
    LESS_THAN_OR_EQUAL: "<=",
    GREATER_THAN_OR_EQUAL: ">=",
    EQUAL: "==",
  };
  const COUNT_DAYS = {
    DAY_1: "1 days",
    DAY_2: "2 days",
    DAY_3: "3 days",
    DAY_4: "4 days",
    DAY_5: "5 days",
    DAY_6: "6 days",
    DAY_7: "7 days",
    DAY_8: "8 days",
    DAY_9: "9 days",
    DAY_10: "10 days",
    DAY_11: "11 days",
    DAY_12: "12 days",
    DAY_13: "13 days",
    DAY_14: "14 days",
    DAY_15: "15 days",
    DAY_16: "16 days",
    DAY_17: "17 days",
    DAY_18: "18 days",
    DAY_19: "19 days",
    DAY_20: "20 days",
    DAY_21: "21 days",
    DAY_22: "22 days",
    DAY_23: "23 days",
    DAY_24: "24 days",
    DAY_25: "25 days",
    DAY_26: "26 days",
    DAY_27: "27 days",
    DAY_28: "28 days",
    DAY_29: "29 days",
    DAY_30: "30 days",
    DAY_31: "31 days",
    DAY_32: "32 days",
    DAY_33: "33 days",
    DAY_34: "34 days",
    DAY_35: "35 days",
    DAY_36: "36 days",
    DAY_37: "37 days",
    DAY_38: "38 days",
    DAY_39: "39 days",
    DAY_40: "40 days",
    DAY_41: "41 days",
    DAY_42: "42 days",
    DAY_43: "43 days",
    DAY_44: "44 days",
    DAY_45: "45 days",
    DAY_46: "46 days",
    DAY_47: "47 days",
    DAY_48: "48 days",
    DAY_49: "49 days",
    DAY_50: "50 days",
    DAY_51: "51 days",
    DAY_52: "52 days",
    DAY_53: "53 days",
    DAY_54: "54 days",
    DAY_55: "55 days",
    DAY_56: "56 days",
    DAY_57: "57 days",
    DAY_58: "58 days",
    DAY_59: "59 days",
    DAY_60: "60 days",
    DAY_61: "61 days",
    DAY_62: "62 days",
    DAY_63: "63 days",
    DAY_64: "64 days",
    DAY_65: "65 days",
    DAY_66: "66 days",
    DAY_67: "67 days",
    DAY_68: "68 days",
    DAY_69: "69 days",
    DAY_70: "70 days",
    DAY_71: "71 days",
    DAY_72: "72 days",
    DAY_73: "73 days",
    DAY_74: "74 days",
    DAY_75: "75 days",
    DAY_76: "76 days",
    DAY_77: "77 days",
    DAY_78: "78 days",
    DAY_79: "79 days",
    DAY_80: "80 days",
    DAY_81: "81 days",
    DAY_82: "82 days",
    DAY_83: "83 days",
    DAY_84: "84 days",
    DAY_85: "85 days",
    DAY_86: "86 days",
    DAY_87: "87 days",
    DAY_88: "88 days",
    DAY_89: "89 days",
    DAY_90: "90 days",
    DAY_91: "91 days",
    DAY_92: "92 days",
    DAY_93: "93 days",
    DAY_94: "94 days",
    DAY_95: "95 days",
    DAY_96: "96 days",
    DAY_97: "97 days",
    DAY_98: "98 days",
    DAY_99: "99 days",
    DAY_100: "100 days",
  };
  return (
      <div className="w-full flex flex-wrap items-center gap-4 text-sm font-medium text-[#344054] mb-4">
        {/* select room type */}
        <div className="flex items-center gap-2">
          <label htmlFor="select-room-type">Select Room Type</label>
          <select
            name="select-room-type"
            value={roomTypeRule}
            onChange={(e) => setRoomTypeRule(e.target.value)}
            className="w-fit text-sm font-medium text-[#344054]"
          >
            {Object.keys(BOOKING_ROOM_TYPES).map((opt) => {
              return (
                <option value={opt} key={opt}>
                  {BOOKING_ROOM_TYPES[opt].replace("", " ")}
                </option>
              );
            })}
          </select>
        </div>
        {/* price inc/dec by */}
        <div className="flex items-center gap-2">
          <select
            value={priceIncAndDecByRule}
            onChange={(e) => setPriceIncAndDecByRule(e.target.value)}
            className="border-none focus:border-none shadow-none w-fit px-0 text-sm font-medium text-[#344054]"
          >
            {Object.keys(PRICE_INC_AND_DEC).map((opt) => {
              return (
                <option value={opt} key={opt}>
                  {PRICE_INC_AND_DEC[opt].replace("", " ")}
                </option>
              );
            })}
          </select>
          <input
            type="number"
            value={pricePercentage}
            onChange={(e) => setPricePercentage(e.target.value)}
            className="w-28"
          />
          <span>
            <FiPercent size={15} />
          </span>
        </div>
        {/* occupency */}
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-[1px]">
            when<span>:</span>
          </div>
          <label htmlFor="occupency">Occupency</label>
          <select
            name="occupency"
            value={logicalOperation}
            onChange={(e) => setLogicalOperation(e.target.value)}
            className="border-none focus:border-none shadow-none pl-0 pr-0 w-12 text-sm font-medium text-[#344054]"
          >
            {Object.keys(LOGICAL_OPERATIONS).map((opt) => {
              return (
                <option value={opt} key={opt}>
                  {LOGICAL_OPERATIONS[opt]}
                </option>
              );
            })}
          </select>
          <input
            type="number"
            value={occupencyPercentage}
            onChange={(e) => setOccupencyPercentage(e.target.value)}
            className="w-28"
          />
          <span>
            <FiPercent size={15} />
          </span>
        </div>
        {/*total day left to booking */}
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-[1px]">
            And<span>:</span>
          </div>
          <label htmlFor="total-days-left">
            Total Days Left to Booking Date is
          </label>
          <select
            name="total-days-left"
            value={daysLeftToBooking}
            onChange={(e) => setDaysLeftToBooking(e.target.value)}
            className="px-2 w-fit text-sm font-medium text-[#344054]"
          >
            {Object.keys(COUNT_DAYS).map((opt) => {
              return (
                <option value={opt} key={opt} className="overflow-y-auto overflow-x-hidden dropdown-scroll">
                  {COUNT_DAYS[opt]}
                </option>
              );
            })}
          </select>
        </div>
      </div>
  );
};

export default AddAutomationRules;
