import React, { useState } from "react";
import { Link } from "react-router-dom";
import SubmitButton from "../../../components/buttons/SubmitButton";
import { CustomeTable } from "../../../components";

const Publish = () => {
  const [values, setValues] = useState("");
  const packageDetails = [
    {
      id: "1",
      name: "Package Name",
      value: "Family Package",
    },
    { id: "2", name: "Minimum No of Nights", value: "06" },
    { id: "3", name: "Minimum No of Rooms Booked Together", value: "04" },
    { id: "4", name: "Room Type", value: "Deluxe Room" },
  ];
  const getValueActions = ({ value }) => {
    return (
      <div className="h-full flex items-center">
        {value ? (
          <input
            type="text"
            value={value}
            onChange={(e) => setValues(e.target.value)}
            className="h-[30px] rounded px-2 text-[14px] leading-6 tracking-[-0.6%] text-[#07090D]"
          />
        ) : null}
      </div>
    );
  };
  const roomRowData = [
    {
      roomTypeAndServiceName: "Deluxe Room",
      rateType: "American Plan (AP)",
      totalNights: "2",
      roomTariffAndServicePrice: "$1000",
      discountPercentage: "10%",
      discountAmount: "$10",
      priceAfterDiscount: "$90",
      cGST: "$100",
      sGST: "$50",
      iGST: "$50",
      vAT: "$600",
      totalPriceIncludingTax: "$100",
      totalPrice: "$1200.00",
    },
    {
      roomTypeAndServiceName: "Deluxe Room",
      rateType: "American Plan (AP)",
      totalNights: "2",
      roomTariffAndServicePrice: "$1000",
    },
  ];
  const roomColumnDefs = [
    {
      headerName: "Room Type / Service Name",
      field: "roomTypeAndServiceName",
      minWidth: 200,
    },
    {
      headerName: "Rate Type",
      field: "rateType",
      filter: true,
    },
    {
      headerName: "Total Nights",
      field: "totalNights",
      filter: true,
    },
    {
      headerName: "Room Tariff / Service Price",
      field: "roomTariffAndServicePrice",
      filter: true,
      cellRenderer: getValueActions,
    },
    {
      headerName: "Discount Percentage",
      field: "discountPercentage",
      filter: true,
      cellRenderer: getValueActions,
    },
    {
      headerName: "Discount Amount",
      field: "discountAmount",
      filter: true,
      cellRenderer: getValueActions,
    },
    {
      headerName: "Price After Discount",
      field: "priceAfterDiscount",
      filter: true,
      cellRenderer: getValueActions,
    },
    {
      headerName: "CGST",
      field: "cGST",
      filter: true,
    },
    {
      headerName: "SGST",
      field: "sGST",
      filter: true,
    },
    {
      headerName: "IGST",
      field: "iGST",
      filter: true,
    },
    {
      headerName: "VAT",
      field: "vAT",
      filter: true,
    },
    {
      headerName: "Total Price / Including Tax",
      field: "totalPriceIncludingTax",
      filter: true,
    },
  ];
  // service
  const serviceRowData = [
    {
      id:"1",
      serviceName: "Airport Transfer",
      servicePrice: "$100",
      discountPercentage: "10%",
      discountAmount: "$10",
      priceAfterDiscount: "$90",
      cGST: "$100",
      sGST: "$50",
      iGST: "$50",
      vAT: "$600",
      totalPrice: "$1200.00",
    },
    {
      id:"2",
      serviceName: "Pool Service",
      servicePrice: "$100",
      discountPercentage: "10%",
      discountAmount: "$10",
      priceAfterDiscount: "$90",
      cGST: "$100",
      sGST: "$50",
      iGST: "$50",
      vAT: "$600",
      totalPrice: "$1200.00",
    },
    {
      id:"3",
      serviceName: "Golf Transfer",
      servicePrice: "$100",
      discountPercentage: "10%",
      discountAmount: "$10",
      priceAfterDiscount: "$90",
      cGST: "$100",
      sGST: "$50",
      iGST: "$50",
      vAT: "$600",
      totalPrice: "$1200.00",
    },
    
  ];
  const serviceColumnDefs = [
    {
      headerName: "",
      field: "id",
      minWidth: 30,
    },
    {
      headerName: "Service Name",
      field: "serviceName",
      minWidth: 200,
    },
    {
      headerName: "Service Price",
      field: "servicePrice",
      filter: true,
    },
    {
      headerName: "Discount Percentage",
      field: "discountPercentage",
      filter: true,
      cellRenderer: getValueActions,
    },
    {
      headerName: "Discount Amount",
      field: "discountAmount",
      filter: true,
      cellRenderer: getValueActions,
    },
    {
      headerName: "Price After Discount",
      field: "priceAfterDiscount",
      filter: true,
      cellRenderer: getValueActions,
    },
    {
      headerName: "CGST",
      field: "cGST",
      filter: true,
    },
    {
      headerName: "SGST",
      field: "sGST",
      filter: true,
    },
    {
      headerName: "IGST",
      field: "iGST",
      filter: true,
    },
    {
      headerName: "VAT",
      field: "vAT",
      filter: true,
    },
    {
      headerName: "Total Price",
      field: "totalPrice",
      filter: true,
    },
  ];
  return (
    <div className="px-6">
      <div className="text-[30px] leading-[37.8px] font-bold tracking-[-1.5%]">
        Package Details
      </div>
      <div className="text-[20px] leading-[30.24px] font-medium text-[#07090D] mt-2">
        Packages Detail:
      </div>
      <div className="grid grid-cols-3 text-[14px] leading-5 gap-1">
        {packageDetails?.map((pd) => (
          <div className="flex items-center gap-1" key={pd.id}>
            <div className="font-medium text-[#07090D]">{pd.name} : </div>
            <div className="text-[#6E7C87]">{pd.value}</div>
          </div>
        ))}
        <div className="flex items-center gap-1">
          <div className="font-medium text-[#07090D]">Available For : </div>
          <Link
            to={"#"}
            className="text-base-primary hover:text-base-primary_hover underline"
          >
            View List
          </Link>
        </div>
      </div>
      <div className="mt-6">
        <div className="flex items-center text-[#07090D]">
          <div className="text-[14px] leading-5 font-bold ">Billing</div>
          <div className="text-[11px] leading-3 ">{`(By default, the price is calculating on the basis of minimum number of nights in the package + minimum number of room booked together)`}</div>
        </div>
        {/* table */}
        <div className="w-full h-[56vh] overflow-x-auto overflow-y-auto sidebar-scroll scroll-horizontal py-4 pr-2">
          <div className="">
            <CustomeTable
              size={"h-[25vh]"}
              columnDefs={roomColumnDefs}
              rowData={roomRowData}
              pagination={false}
              showButtons={false}
            />
          </div>
          <div className="mt-4">
            <CustomeTable
              size={"h-[25vh]"}
              columnDefs={serviceColumnDefs}
              rowData={serviceRowData}
              pagination={false}
              showButtons={false}
            />
          </div>
        </div>
        <div className="flex items-center justify-end gap-6 mt-5">
          <SubmitButton
            type="button"
            className={"h-12"}
            text={"Publish Package"}
          />
        </div>
      </div>
    </div>
  );
};

export default Publish;
