import React from "react";
import { BiEdit } from "react-icons/bi";
import SubmitButton from "../../components/buttons/SubmitButton";

const DesignationCard = ({
  roleName,
  manager,
  handleEdit = () => {},
  handleAddEmployee = () => {},
}) => {
  return (
    <div className="h-[35vh] border border-[#D0D5DD] rounded-[10px] bg-white flex flex-col justify-between p-4">
      <div className="flex items-center justify-end">
        <button
          type="button"
          onClick={handleEdit}
          className="border border-[#D0D5DD] rounded-full hover:bg-base-primary text-[#344054] hover:text-white hover:border-base-primary duration-300 ease-[0.3s] p-[5px]"
        >
          <BiEdit size={22} />
        </button>
      </div>
      <div className="w-full flex flex-col items-center justify-center leading-6 text-[#344054]">
        <div className="text-[20px] font-semibold uppercase">{roleName}</div>
        <div className="text-[16px]">{manager}</div>
      </div>
      <SubmitButton
        type="button"
        onClick={handleAddEmployee}
        className="h-10 w-full mb-1"
        text={"Add Emoloyee"}
      />
    </div>
  );
};

export default DesignationCard;
