import React, { useEffect, useState } from "react";
import { isObjEmpty } from "../../utils";
import { CustomeTable, DeleteButton, EditButton } from "../../components";

const AllCreateBuffet = (props) => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          //   Kitchen_id: "1214",
          itemId: props?.kitchen?.kitchen?.itemId,
          itemName: props?.kitchen?.kitchen?.itemName,
          itemType: props?.kitchen?.kitchen?.itemType,
          non_veg: props?.kitchen?.kitchen?.non_veg,
          ave_cost_per_kg: props?.kitchen?.kitchen?.ave_cost_per_kg,
          ave_cost_per_gm: props?.kitchen?.kitchen?.ave_cost_per_gm,
          quantity_per_10: props?.kitchen?.kitchen?.quantity_per_10,
          available: props?.kitchen?.kitchen?.available,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);
  //set kitchen data and redirect to another page
  // const setKitchenAndRedirect = (link) => {
  //     if (link) {
  //       navigate(link);
  //     }
  //   };
  const tableData = [
    {
      itemId: "IT01",
      itemName: "Chicken Wings",
      itemType: "Appetizer",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 100",
      ave_cost_per_gm: "$ 0.01",
      quantity_per_10: "4/10",
      available: "Regular",
    },
    {
      itemId: "IT02",
      itemName: "Chicken Wings",
      itemType: "Main Course",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 200",
      ave_cost_per_gm: "$ 0.05",
      quantity_per_10: "10/10",
      available: "Regular",
    },
    {
      itemId: "IT03",
      itemName: "Caesar Salad",
      itemType: "Salad",
      non_veg: "No",
      ave_cost_per_kg: "$ 200",
      ave_cost_per_gm: "$ 0.05",
      quantity_per_10: "6/10",
      available: "Weekdays",
    },
    {
      itemId: "IT04",
      itemName: "Beef Steak",
      itemType: "Main Dish",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 300",
      ave_cost_per_gm: "$ 0.15",
      quantity_per_10: "1/10",
      available: "Weekend",
    },
    {
      itemId: "IT05",
      itemName: "Kheer",
      itemType: "Dessert",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 600",
      ave_cost_per_gm: "$ 0.18",
      quantity_per_10: "5/10",
      available: "Regular",
    },
  ];
  const ViewCreateBuffetAction = () => {
    return (
      <div className="h-full flex items-center gap-2">
        <EditButton />
        <DeleteButton />
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Item ID",
      field: "itemId",
      minWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item Name",
      field: "itemName",
      filter: true,
    },
    {
      headerName: "Item Type",
      field: "itemType",
      filter: true,
    },
    {
      headerName: "Non-Veg",
      field: "non_veg",
      filter: true,
    },
    {
      headerName: "Ave-Cost/Kg",
      field: "ave_cost_per_kg",
      filter: true,
    },
    {
      headerName: "Ave-Cost/Gm",
      field: "ave_cost_per_gm",
      filter: true,
    },
    {
      headerName: "Quantity/10",
      field: "quantity_per_10",
      filter: true,
    },
    {
      headerName: "Available",
      field: "available",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 180,
      cellRenderer: ViewCreateBuffetAction,
    },
  ];

  return (
    <div className="">
      {datas ? (
        <CustomeTable
          size="h-[50vh]"
          tableName={"Create Buffet List Table"}
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
      ) : null}
    </div>
  );
};

export default AllCreateBuffet;
