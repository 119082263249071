import React from "react";
import ToolTip from "../ToolTip";

const CheckBoxInput = ({
  value,
  onChange,
  label,
  readOnly = false,
  message,
  label2,
  utility,
  adjustment,
}) => {
  return (

      <div className={`${adjustment}`}>
        <div className={`flex items-center justify-between ${utility}`}>
          <label>{label}</label>
          <ToolTip Message={message} />
        </div>
        <div
          className={`w-fit relative flex justify-between items-center py-1 h-10`}
        >
          <input
            type={"checkbox"}
            checked={value}
            id={label}
            onChange={onChange}
            value={value}
            className={"checkBoxControl"}
            readOnly={readOnly}
          />
          <label
            htmlFor={label}
            className={`text-sm font-normal text-[#344054] ml-3 capitalize`}
          >
            {label2}
          </label>
        </div>
      </div>
  );
};

export default CheckBoxInput;
