import React, { useRef } from "react";
import { useOutsideClick } from "../utils";
import CloseButton from "./buttons/CloseButton";

const CustomModal = ({ close, children, isCloseButton = true, heading }) => {
  const closeModalRef = useRef(null);
  useOutsideClick(closeModalRef, close);
  return (
    <div className="fixed flex items-center justify-center top-0 right-0 left-0 bottom-0 bg-[#7a7a7a61] border h-full z-[10000] select-none">
      <div
        className={`relative rounded-[10px] w-fit overflow-hidden bg-white px-8 py-6`}
        ref={closeModalRef}
      >
        <div className="flex items-center justify-between">
          <div className="text-[22px] leading-[27.72px] tracking-[-0.8px] font-medium text-[#07090D]">
            {heading}
          </div>
          {isCloseButton ? <CloseButton onClick={close} /> : null}
        </div>
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
