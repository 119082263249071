import React, { useEffect, useState } from "react";
import { TAX_TYPES, TAX_CATEGORIES, TAX_BY, ROUTES } from "../../../constants";
import TaxCitizenTariff from "./TaxCitizenTariff";
import { notifyError, notifySuccess } from "../../../components/alert";
import { updateHotelAction } from "../../../actions/pms/hotel";
import { connect } from "react-redux";
// import TaxesAll from "./TaxesAll";
import { registerEvent } from "../../../logs";
import SaveOrNot from "../../../components/layouts/SaveOrNot";
import { isObjEmpty } from "../../../utils";
import { useTranslation } from "react-i18next";
import Inputfield from "../../../components/layouts";
import SelectFromObject from "../../../components/layouts/SelectFromObject";
import TaxRoomRules from "./TaxRoomRules";
import { UPDATE_HOTEL_RESET } from "../../../actions/types/hotel";
import AddSubmitButton from "../../../components/buttons/AddSubmitButton";
import AllTaxesDataTable from "./AllTaxesDataTable";
import { RxCross2 } from "react-icons/rx";
import PercentageIcon from "../../../images/icons/PercentageIcon.svg";
import AddButton from "../../../components/buttons/AddButton";
import CloseButton from "../../../components/buttons/CloseButton";

const TaxCategories = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [taxName, setTaxName] = useState("");
  const [country, setCountry] = useState("");
  const [taxType, setTaxType] = useState(Object.keys(TAX_TYPES)[0]);
  const [citizenTaxType, setCitizenTaxType] = useState(Object.keys(TAX_BY)[0]);

  const [taxCategory, setTaxCategory] = useState(
    Object.keys(TAX_CATEGORIES)[0]
  );
  const [taxOnItem, setTaxOnItem] = useState("");
  //if tax is on room (room tariff)
  const [roomTaxPercentage, setRoomTaxPercentage] = useState("");
  const [taxRule, setTaxRule] = useState([
    { minimum: 0, maximum: "", taxPercentage: 0 },
    { minimum: "", maximum: 100000, taxPercentage: 0 },
  ]);

  //if tax is on local/foreign citizen
  const [adultTax, setAdultTax] = useState("");
  const [childTax, setChildTax] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [isupdating, setIsupdating] = useState(false);

  //open tax input fields components
  const openTaxBox = () => {
    setOpen(true);
  };

  //close modal
  const closeTaxBox = () => {
    if (data) setData(null);
    setOpen(false);
  };

  // confirm to add tax
  const confirmAddTaxes = (e) => {
    e.preventDefault();

    let isExist = false;
    props?.hotel &&
      !data &&
      // eslint-disable-next-line array-callback-return
      props?.hotel?.taxes?.map((tax) => {
        if (tax.taxName === taxName) {
          notifyError(t("729"));
          isExist = true;
        }
      });
    if (isExist) return;
    setOpenModal(true);
  };

  // not confirm add tax
  const closeModal = () => setOpenModal(false);

  //set tax category
  const handleSubmit = () => {
    let taxData = {
      taxName,
      country,
      taxCategory,
      // add new feild
      taxOnItem,
    };
    // 1st option -> room tariff
    if (taxCategory === "ROOM_TARIFF") {
      if (taxType === "FLAT_TAX") {
        taxData = {
          ...taxData,
          taxType,
          taxPercentageOnFlatTax: roomTaxPercentage,
        };
      } else {
        taxData = { ...taxData, taxType, taxDivisionOnSlabWiseTax: taxRule };
      }
    }
    // 2nd option -> local citizen
    if (taxCategory === "LOCAL_CITIZEN") {
      taxData = {
        ...taxData,
        taxType: citizenTaxType,
        adultTax,
        childTax,
      };
    }
    // 3rd option -> foreign citizen
    if (taxCategory === "FOREIGN_CITIZEN") {
      taxData = {
        ...taxData,
        taxType: citizenTaxType,
        adultTax,
        childTax,
      };
    }

    if (data && data?._id) {
      const result = props?.hotel?.taxes?.filter((d) => d?._id !== data?._id);
      props.update({
        taxes: [...result, taxData],
        hotelId: props?.hotel?._id,
        stage: ROUTES.ONBOARDING_STAGES.DASHBOARD,
      });
    } else {
      props?.update({
        taxes: [...props?.hotel?.taxes, taxData],
        hotelId: props?.hotel?._id,
        stage: ROUTES.ONBOARDING_STAGES.DASHBOARD,
      });
    }
    registerEvent("adding tax", "tax", taxData);
  };

  useEffect(() => {
    if (props?.updated && props?.updated?.isupdated) notifySuccess(t("730"));
    console.log("hi");
    props?.reset();
  }, [props?.updated?.isupdated]);

  useEffect(() => {
    if (data && !isObjEmpty(data)) {
      console.log(data);
      setIsupdating(true);
      openTaxBox();
      setTaxName(data?.taxName);
      setCountry(data?.country);
      setTaxCategory(data?.taxCategory);
      setTaxType(data?.taxType);
      // add new feild
      setTaxOnItem(data?.taxOnItem);
      setAdultTax(data?.adultTax);
      setChildTax(data?.childTax);
      setTaxRule(data?.taxDivisionOnSlabWiseTax);
    }
  }, [data]);

  return (
    <>
      <div className="flex justify-end gap-2">
        <AddButton onClick={openTaxBox} text={t("731")} />
      </div>
      <form
        className={`relative w-full mx-auto border border-[#D0D5DD] rounded-lg bg-white p-6 mt-4 ${
          !open ? "hidden" : ""
        }`}
        onSubmit={confirmAddTaxes}
      >
        <div className="flex items-center justify-between">
          <h2 className="font-bold text-xl sm:text-2xl md:text-3xl leading-[38px] tracking-[-0.015em]">
            {t("732")}
          </h2>
          {open ? <CloseButton onClick={closeTaxBox} /> : null}
        </div>
        <div className="grid gap-6 w-full sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-4">
          <Inputfield
            label={t("733")}
            message={t("734")}
            placeholder={t("Enter Name")}
            value={taxName}
            onChange={(e) => setTaxName(e.target.value)}
            required={true}
          />
          {/* country auto select when we select tax type */}
          <SelectFromObject
            label={`${t("738")} *`}
            toolTipMessage={t("739")}
            options={TAX_TYPES}
            value={taxType}
            setValue={setTaxType}
            required={true}
          />
          <SelectFromObject
            label={`${t("Tax Category")} *`}
            toolTipMessage={t("737")}
            options={TAX_CATEGORIES}
            value={taxCategory}
            setValue={setTaxCategory}
            required={true}
          />
          <SelectFromObject
            label={t("Tax on Item")}
            options={{}}
            value={taxOnItem}
            setValue={setTaxOnItem}
          />
          {/* on first option select -> room tariff */}
          {taxCategory === "ROOM_TARIFF" ? (
            <div className="grid sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-1 gap-6">
              {taxCategory === "ROOM_TARIFF" && taxType === "FLAT_TAX" ? (
                <Inputfield
                  label={t("740")}
                  message={t("741")}
                  placeholder={"Enter a value"}
                  value={roomTaxPercentage}
                  onChange={(e) => setRoomTaxPercentage(e?.target?.value)}
                  type="number"
                  required={true}
                  max={100}
                  min={0}
                  icon={<img src={PercentageIcon} alt="" />}
                />
              ) : null}
            </div>
          ) : null}
        </div>
        {taxCategory === "ROOM_TARIFF" && taxType === "SLAB_TAX" ? (
          <TaxRoomRules
            taxRule={taxRule}
            setTaxRule={setTaxRule}
            isupdate={isupdating}
          />
        ) : null}
        {/* on second option select -> local citizen */}
        {taxCategory === "LOCAL_CITIZEN" ||
        taxCategory === "FOREIGN_CITIZEN" ? (
          <div className="grid gap-6">
            <TaxCitizenTariff
              setRoomTaxType={setCitizenTaxType}
              roomTaxType={citizenTaxType}
              adultPrice={adultTax}
              setAdultPrice={setAdultTax}
              childPrice={childTax}
              setChildPrice={setChildTax}
              options={TAX_BY}
            />
          </div>
        ) : null}
        <div className="flex justify-end mt-6 items-center">
          <AddSubmitButton loading={props?.updated?.loading} text={t("450")} />
        </div>
      </form>
      {/* <TaxesAll hotel={props?.hotel} update={props?.update} setData={setData} /> */}
      <AllTaxesDataTable />
      <SaveOrNot
        isopen={openModal}
        close={closeModal}
        formSubmit={handleSubmit}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  updated: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateHotelAction(data)),
  reset: () => dispatch({ type: UPDATE_HOTEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaxCategories);
