import React, { useState } from "react";
import AddButton from "../../../components/buttons/AddButton";
import AddServices from "./AddServices";
import AllServices from "./AllServices";

const ServicesPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="mt-6">
      <div className="flex items-end justify-end">
        <AddButton text={"Add Extra Service"} onClick={() => setOpen(true)} />
      </div>

      <div className="my-4">
        {open ? <AddServices close={() => setOpen(false)} /> : null}
      </div>
      <AllServices />
    </div>
  );
};

export default ServicesPage;
