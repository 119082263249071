import React from "react";
import { SlQuestion } from "react-icons/sl";
import ReactTooltip from "react-tooltip";
//check
const ToolTip = ({ Message,size }) => {
  return (
    <>
      {Message && Message.length ? (
        <>
          <div className="w-fit h-fit relative rounded-lg text-[#344054] text-sm z-50 border-[#E5E9EB]">
            <SlQuestion size={size} data-tip={Message}  />
          </div>
          <ReactTooltip
            type="light"
            border={true}
            borderColor="#E5E9EB"
            className="py-2 px-6 max-w-full sm:max-w-sm text-center text-[12px] leading-5 font-normal text-[#6E7C87] rounded-lg shadow-[0_0_31px_0_rgba(238, 238, 238,1)]"
          />
        </>
      ) : null}
    </>
  );
};

export default ToolTip;
