import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomeTable from "../../../components/CustomeTable";
import { DeleteButton, EditButton } from "../../../components";

const WifiAll = () => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { wifi } = useSelector((state) => state.AllWifi);

  useEffect(() => {
    if (wifi && wifi?.length) {
      let wifies = [];
      wifi?.map((w) => {
        wifies.push({
          _id: w?._id,
          ssid: w?.ssid,
          password: w?.password,
          dailyUsesQuota: w?.dailyUsesQuota,
          speedRestriction: w?.speedRestriction,
        });
      });
      setDatas(wifies);
    }
  }, [wifi]);
 
const wifiActions = () => {
  return (
    <div className="h-full flex gap-4 items-center">
        <EditButton />
        <DeleteButton />
    </div>
  )
}

  const columnDefs = [
    {
      headerName: "Wifi ID",
      field: "_id",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "SSID",
      field: "ssid",
      filter: true,
    },
    {
      headerName: "Password",
      field: "password",
      filter: true,
    },
    {
      headerName: "Daily Uses Quota",
      field: "dailyUsesQuota",
      filter: true,
    },
    {
      headerName: "Speed Restriction",
      field: "speedRestriction",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "id",
      filter: true,
      cellRenderer:wifiActions
    },
  ];

  return (
    <div className="my-6">
      {/* {datas?.length ? ( */}
      <CustomeTable
        tableName={"Wifi List Table"}
        columnDefs={columnDefs}
        rowData={datas}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};

export default WifiAll;
