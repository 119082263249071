import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./RoomBookingChart.css";

// importing temporary data
import data from "./temp/tempDrb";
import { useTranslation } from "react-i18next";

// include after all imports
ChartJS.register(BarElement, CategoryScale, LinearScale, ArcElement);

const RoomBookingChart = () => {
  const [lastDays, setLastDays] = useState(30);
  const { t } = useTranslation()

  const [showflop, setShowflop] = useState(false);
  const shofloptions = () => {
    setShowflop(!showflop);
  };

  return (
    <div className="kn-roombooking-chart-container">
      <div className="kn-top-selected-head flex items-center">
      <h3 className="font-bold capitalize">{t("Total Booked Rooms")}</h3>
          <div className="kn-top-selected-p-actions border border-[#E4E4E7] rounded-md">
            <div
              className="kn-top-selected-p-op z-30 border border-[#E4E4E7]"
              onClick={shofloptions}
            >
              {lastDays} {t("26")}
              <BsChevronDown className={`transition-all duration-300 ${showflop?"rotate-[180deg]":""}`}/>
              <div
                className={
                  showflop
                    ? "kn-top-selected-p-childs open border border-[#E4E4E7] bg-white rounded-b-md z-20 "
                    : "kn-top-selected-p-childs hover:text-base-primary"
                }
                onClick={(e) => setLastDays(e.target.value)}
              >
                <input
                  type="radio"
                  id="7daysppackage"
                  name="lastdays"
                  value={7}
                />
                <label htmlFor="7daysppackage">7 {t("26")}</label>
                <input
                  type="radio"
                  id="15daysppackage"
                  name="lastdays"
                  value={15}
                />
                <label htmlFor="15daysppackage">15 {t("26")}</label>
                <input
                  type="radio"
                  id="30dayspackage"
                  name="lastdays"
                  value={30}
                />
                <label htmlFor="30dayspackage">30 {t("26")}</label>
              </div>
            </div>
          </div>
        </div>
      <div className="rb-doughnut-chart">
        <Doughnut
          data={data}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default RoomBookingChart;
