import React, { useState } from "react";
import CustomModal from "../CustomModal";
import Inputfield from "../layouts";
import { HiOutlineSearch } from "react-icons/hi";
import SelectBox from "../layouts/SelectBox";
import ShiftButton from "../buttons/ShiftButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import SubmitButton from "../buttons/SubmitButton";

const AddServices = (props) => {
  const [searchServices, setSearchServices] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const row = [
    {
      name: "Airport Transfer",
      type: "HR Manager",
      price: "$12",
    },
    {
      name: "Airport Transfer",
      type: "HR Manager",
      price: "$12",
    },
    {
      name: "Airport Transfer",
      type: "HR Manager",
      price: "$12",
    },
  ];
  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      checkboxSelection: true,
    },
    {
      headerName: "Type",
      field: "type",
    },
    {
      headerName: "Price",
      field: "price",
    },
  ];
  return (
    <CustomModal heading={"Selecte Package"} close={props.close}>
      <div className="h-[80vh] w-[70vw]">
        <div className="relative w-5/12 my-4">
          <Inputfield
            label={"Search Package"}
            value={searchServices}
            onChange={(e) => setSearchServices(e.target.value)}
            message={"search package"}
            placeholder={"Search"}
            className={"pl-12"}
          />
          <HiOutlineSearch
            size={24}
            className="absolute top-8 left-4 text-[#6E7C87]"
          />
        </div>
        <div className="grid grid-cols-10 w-full">
          {/* Left side */}
          <div className="col-span-4">
            <SelectBox
              tableSize={"h-[44vh]"}
              label={"All Services List"}
              heading={"Select Services"}
              btnName={"Add Service"}
              columnDefs={columnDefs}
              rowData={row}
              setSelectedRows={setSelectedRows}
            />
          </div>

          {/* Buttons for add and remove */}
          <div className="col-span-2 flex flex-col items-center justify-center gap-6">
            <ShiftButton
              name={"Add"}
              icon={<AiOutlineDoubleRight size={18} />}
            />
            <ShiftButton
              name={"Remove"}
              icon={<AiOutlineDoubleLeft size={18} />}
            />
          </div>

          {/* Right side */}
          <div className="col-span-4 w-full">
            <SelectBox
              tableSize={"h-[47.7vh]"}
              heading={"Selected Services"}
              showBtn={false}
              columnDefs={columnDefs}
              rowData={row}
            />
          </div>
        </div>
        <div className="flex items-center justify-end mt-4">
            <SubmitButton type="button" className="h-[43px] w-[165px]" text={"Save"}/>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddServices;
