import React from "react";
import CustomModal from "../CustomModal";
import CustomeTable from "../CustomeTable";
import SubmitButton from "../buttons/SubmitButton";

const CheckTotalCost = (props) => {
  const rowData = [
    {
      nameOfDishes: "Salad",
      noOfDishes: "08",
      price: "$120",
    },
    {
      nameOfDishes: "Buffet Items",
      noOfDishes: "02",
      price: "$120",
    },
    {
      nameOfDishes: "Appetizer",
      noOfDishes: "02",
      price: "$120",
    },
    {
      nameOfDishes: "Soups",
      noOfDishes: "00",
      price: "$120",
    },
    {
      nameOfDishes: "Side Dishes",
      noOfDishes: "12",
      price: "$120",
    },
    {
      nameOfDishes: "Main Course",
      noOfDishes: "03",
      price: "$120",
    },
    {
      nameOfDishes: "Deserts",
      noOfDishes: "03",
      price: "$120",
    },
  ];
  const columnDefs = [
    {
      headerName: "Name Of Dishes",
      field: "nameOfDishes",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "No Of Dishes",
      field: "noOfDishes",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
  ];
  return (
    <CustomModal close={props.close}>
      <div className="h-[61vh] w-[50vw]">
       <div className="my-6">
       <CustomeTable
          size="h-[50vh]"
          columnDefs={columnDefs}
          rowData={rowData}
          pagination={false}
          showButtons={false}
        />
       </div>
        <div className="flex items-center justify-between">
          <div className="text-[16px] leading-[22px] text-[#1A1B1C]">
            Total Production Cost: $840
          </div>
          <SubmitButton type="button" text={"Done"} />
        </div>
      </div>
    </CustomModal>
  );
};

export default CheckTotalCost;
