import React from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants";
import TabConfigButton from "../../components/buttons/TabConfigButton";

const HousekeepingHeader = ({ params }) => {
  const { t } = useTranslation();
  const headerData = [
    {
      index: 1,
      name: `${t("76")}`,
    },
    {
      index: 2,
      name: `${t("Checkout")}`,
    },
    {
      index: 3,
      name: `${t("78")}`,
    },
    {
      index: 4,
      name: `${t("Issue Reported")}`,
    },
    {
      index: 5,
      name: `${t("Amenities")}`,
    },
  ];
  return (
    <div className="flex items-center justify-between gap-4">
      {headerData?.map((header) => {
        const { index, name } = header;
        return (
          <TabConfigButton
            key={index}
            name={name}
            index={index}
            params={params}
            routes={ROUTES.HOUSE_KEEPING}
          />
        );
      })}
    </div>
  );
};

export default HousekeepingHeader;
