import {
  applyMiddleware,
  legacy_createStore as createStore,
} from "redux";

import thunk from "redux-thunk";

// external import for development purpose
import { composeWithDevTools } from "redux-devtools-extension";
import { USER_LOGOUT } from "./actions/types/ownerAuth";
import { CLEAR_REDUX } from "./actions/types";
import { appReducer } from "./reducers";

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT || action.type === CLEAR_REDUX) {
    state = undefined;
  }
  return appReducer(state, action);
};

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
  // applyMiddleware(...middleware)
);

export default store;
