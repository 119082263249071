import React, { useEffect, useState } from "react";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants";
import BuffetSummaryCard from "./BuffetSummaryCard";
import AllCreateBuffet from "./AllCreateBuffet";
import { registerEvent } from "../../logs";
import { isObjEmpty } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import AddItemToMenu from "../kitchen-menu/AddItemToMenu";
import { getKitchenMenuAction } from "../../actions/kitchen/menu";
import BuffetSummaryDataTable from "./BuffetSummaryDataTable";
import SubmitButton from "../../components/buttons/SubmitButton";
import CheckTotalCost from "../../components/modals/CheckTotalCost";

const CreateBuffetMenu = () => {
  const { t } = useTranslation();
  const { hotel } = useSelector((state) => state.Kitchen);
  const { kitchen } = useSelector((state) => state.Kitchen);
  const dispatch = useDispatch();
  const [addFAndBButton, setAddFAndBButton] = useState(false);
  const [menu, setMenu] = useState({});
  const [isOpenTotalCost,setIsOpenTotalCost] = useState(false)
  //register event
  const registerEventAndOpen = () => {
    setAddFAndBButton(true);
    registerEvent(
      "add food and beverage item button open",
      "add food and beverage menu",
      {
        addFAndBButton,
      }
    );
  };

  //get kitchen data from session Storage
  useEffect(() => {
    if (kitchen?._id) dispatch(getKitchenMenuAction(kitchen?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitchen?._id]);

  // register evnet on close add asset component
  const close = () => {
    if (!isObjEmpty(menu)) setMenu(null);
    setAddFAndBButton(false);
    registerEvent(
      "add food and beverage item button close",
      "add food and beverage menu",
      {
        addFAndBButton,
      }
    );
  };
  const data = {
    heading: t("Kladee's Cafe  - Royal Buffet"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      {
        link: ROUTES.KITCHEN_BUFFETS,
        text: t("1001"),
      },
      { text: t("Create Buffet Menu") },
    ],
  };
  const productionCostData = [
    { id: 1, name: "IT 0012" },
    { id: 2, name: "Royal Buffet" },
    { id: 3, name: "Party Buffet" },
    { id: 4, name: "Friends Party" },
    { id: 5, name: "20 Person" },
    { id: 6, name: "$20/Person" },
  ];
  const buffetSummary = [
    {
      id: 1,
      count: 8,
      name: "No. of items in salads",
    },
    {
      id: 2,
      count: 12,
      name: "Total No. of buffet items",
    },
    {
      id: 3,
      count: 2,
      name: "No. of items in appitizer",
    },
    {
      id: 4,
      count: 0,
      name: "No. of items in soups",
    },
    {
      id: 5,
      count: 12,
      name: "No. of items in side dish",
    },
    {
      id: 6,
      count: 3,
      name: "No. of items in main course",
    },
    {
      id: 7,
      count: 3,
      name: "No. of items in dessert",
    },
  ];
  return (
    <div className="page-container">
      <div className="flex justify-between items-end">
        <Seperator data={data} />
        <AddButton text={t("Add F&B")} onClick={registerEventAndOpen} />
      </div>

      {!isObjEmpty(menu) || addFAndBButton ? (
        <AddItemToMenu
          close={close}
          hotel={hotel}
          kitchen={kitchen}
          menu={menu}
        />
      ) : null}
      {/* data table for Create buffet menu */}

      <div className="my-6">
        <AllCreateBuffet />
      </div>
      <div className="text-[30px] leading-[37.8px] font-bold my-4">
        {t("Production Cost Table")}
      </div>
      <div className="flex items-center gap-2 my-4">
        {productionCostData?.map((p) => {
          const { id, name } = p;
          return (
            <div
              className="h-[34px] flex items-center rounded-[5px] px-1 text-[16px] leading-[20.16px] text-[#07090D] border border-[#E5E9EB] bg-white"
              key={id}
            >
              {name}
            </div>
          );
        })}
      </div>

      <div className="w-full">
        <BuffetSummaryDataTable />
      </div>
     <div className="flex items-center justify-between my-6">
     <div className="text-[30px] leading-[37.8px] font-bold tracking-[-0.8px] text-[#000000] ml-4">
        Total Menu
      </div>
      <SubmitButton type="button" text={"Check Total Cost"} onClick={()=>setIsOpenTotalCost(true)}/>
     </div>
      {isOpenTotalCost?<CheckTotalCost close={()=>setIsOpenTotalCost(false)}/>:null}
      <div className="grid grid-cols-4 gap-8 w-full mt-4">
        {buffetSummary?.map((s) => {
          const { id, count, name } = s;
          return <BuffetSummaryCard key={id} counts={count} name={name} />;
        })}
      </div>
    </div>
  );
};

export default CreateBuffetMenu;
