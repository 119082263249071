import React, { useState } from "react";
import { CustomeTable } from "../../../components";

const AllEmployeePermission = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const rowDatas = [
    {
      _id: "01",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "02",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "03",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "04",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "05",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "06",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "07",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
  ];

  const ViewSelectChecked = ({ value }) => {
    const handleValue = (e) => {
      setIsChecked(e.target.checked?._id);
      if (isChecked && e._id) {
        return value;
      }
    };
    return (
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={isChecked?._id}
            onChange={handleValue}
            className="h-[15.63px] w-4 border border-[#D0D5DD] rounded accent-current"
          />
          <h2>{value}</h2>
        </div>
    );
  };

  const columnDefs = [
    {
      headerName: "All Hotels",
      field: "allHotels",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Reservations",
      field: "reservations",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Customers",
      field: "customers",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "House Keeping",
      field: "houseKeeping",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Rooms",
      field: "rooms",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Booking",
      field: "booking",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Kitchen",
      field: "kitchen",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Employee",
      field: "employee",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Expense",
      field: "expense",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Food Order",
      field: "foodOrder",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Reports",
      field: "reports",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
  ];

  return (
    <div>
      <CustomeTable
        columnDefs={columnDefs}
        rowData={rowDatas}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default AllEmployeePermission;
