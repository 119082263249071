import React from "react";
import { FaPlus } from "react-icons/fa6";
import CustomeTable from "../CustomeTable";

const SelectBox = ({
  label,
  heading = false,
  btnName,
  showBtn = true,
  handleTopBtn = () => {},
  className,
  columnDefs,
  rowData,
  setSelectedRows,
  optionalText,
  tableSize="h-[46vh]"
}) => {
  return (
    <div>
      {label ? (
        <label>
          {label}
          <spane className={"text-[11px] leading-5 font-normal"}>
            {" "}
            {optionalText}
          </spane>
        </label>
      ) : null}
      <div className={`pb-2 ${className}`}>
        <div
          className={`${
            heading && "h-[37px] mb-4"
          } flex items-end justify-between `}
        >
          {heading ? (
            <div className="text-[16px] leading-[21px] font-medium text-[#000000] ">
              {heading}
            </div>
          ) : null}
          {showBtn && (
            <button
              type="button"
              onClick={handleTopBtn}
              className="flex items-center justify-center gap-2 h-[37px] px-4 border border-base-primary rounded-md text-base-primary hover:bg-base-primary hover:text-white duration-300 ease-[0.3s] text-sm font-semibold"
            >
              {btnName}
              <FaPlus />
            </button>
          )}
        </div>
        <CustomeTable
          size={tableSize}
          columnDefs={columnDefs}
          rowData={rowData}
          pagination={false}
          showButtons={false}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default SelectBox;
