import React, { useState } from "react";
import { CustomeTable } from "../../../components";
import Inputfield from "../../../components/layouts"; 
import AllEmployeePermission from "./AllEmployeePermission";
import FormSubmitButton from "../../../components/buttons";

const EmployeePermissions = () => {
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [employeeID, setEmployeeID] = useState("");
  const [employeeName, setEmployeeName] = useState("");

  const rowDatas = [
    {
      designation: "General Manager",
      addHotels: "General Manager",
      reservations: "General Manager",
      customers: "General Manager",
      houseKeeping: "General Manager",
      rooms: "General Manager",
      booking: "General Manager",
      kitchen: "General Manager",
      employee: "General Manager",
      expense: "General Manager",
      foodOrder: "General Manager",
      reports: "General Manager",
    },
    {
      designation: "Hr Manager",
      addHotels: "Hr Manager",
      reservations: "Hr Manager",
      customers: "Hr Manager",
      houseKeeping: "Hr Manager",
      rooms: "Hr Manager",
      booking: "Hr Manager",
      kitchen: "Hr Manager",
      employee: "Hr Manager",
      expense: "Hr Manager",
      foodOrder: "Hr Manager",
      reports: "Hr Manager",
    },
    {
      designation: "Senior Chef",
      addHotels: "Senior Chef",
      reservations: "Senior Chef",
      customers: "Senior Chef",
      houseKeeping: "Senior Chef",
      rooms: "Senior Chef",
      booking: "Senior Chef",
      kitchen: "Senior Chef",
      employee: "Senior Chef",
      expense: "Senior Chef",
      foodOrder: "Senior Chef",
      reports: "Senior Chef",
    },
    {
      designation: "Finance Manger",
      addHotels: "Finance Manger",
      reservations: "Finance Manger",
      customers: "Finance Manger",
      houseKeeping: "Finance Manger",
      rooms: "Finance Manger",
      booking: "Finance Manger",
      kitchen: "Finance Manger",
      employee: "Finance Manger",
      expense: "Finance Manger",
      foodOrder: "Finance Manger",
      reports: "Finance Manger",
    },
  ];
  const ViewSelectChecked = ({ value }) => {
    const handleValue = (e) => {
      setIsChecked(e.target.checked?._id);
      if (isChecked && e._id) {
        return value;
      }
    };
    return (
        <div className="pt-1">
          <input
            type="checkbox"
            checked={isChecked?._id}
            onChange={handleValue}
            className="h-[15.63px] w-4 border border-[#D0D5DD] rounded accent-current"
          />
          {/* <h2>{value}</h2> */}
        </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Designation",
      field: "designation",
      maxWidth: 250,
      minWidth: 150,
    },
    {
      headerName: "Add Hotels",
      field: "addHotels",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Reservations",
      field: "reservations",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Customers",
      field: "customers",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "House Keeping",
      field: "houseKeeping",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Rooms",
      field: "rooms",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Booking",
      field: "booking",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Kitchen",
      field: "kitchen",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Employee",
      field: "employee",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Expense",
      field: "expense",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Food Order",
      field: "foodOrder",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Reports",
      field: "reports",
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
  ];
  return (
    <div className="page-container max-w-7xl">
      <div className="text-[24px] leading-6 font-medium tracking-[-0.6%]">
        Designation-Wise Default Access
      </div>

      <div className="mt-4">
        <CustomeTable
          columnDefs={columnDefs}
          rowData={rowDatas}
          setSelectedRows={setSelectedRows}
        />
      </div>
      <div className="my-6 text-[24px] leading-6 font-medium tracking-[-0.6%]">
        Individual Access
      </div>
      <div className="flex items-center justify-between">
        <div className="grid grid-cols-3 items-center gap-4">
          <Inputfield
            label="Employee ID"
            placeholder="Enter Employee ID"
            value={employeeID}
            onChange={(e) => setEmployeeID(e.target.value)}
            minLength={5}
            maxLength={50}
          />
          <Inputfield
            label="Employee Name"
            placeholder="Enter Employee Name"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            minLength={5}
            maxLength={50}
          />
          <Inputfield
            label="Employee ID"
            placeholder="Enter Employee ID"
            value={employeeID}
            onChange={(e) => setEmployeeID(e.target.value)}
            minLength={5}
            maxLength={50}
          />
        </div>
        <div className="pt-5">
          <FormSubmitButton text="Add to Table" />
        </div>
      </div>
      <div className="">
        <h2 className="text-[20px] leading-6 text-[#344054] mt-8 mb-4">
          EMP125 - Rahul Kumar - HR Manager
        </h2>
        <AllEmployeePermission />
      </div>
    </div>
  );
}; 

export default EmployeePermissions;
