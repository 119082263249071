export const subCategory = [
  {
    id: "1",
    headingName: "American Cuisine",
    items: [
      {
        id: "1",
        name: "Pizza",
        itemsCard: [
          {
            id: "1",
            itemsName: "Margherita",
            itemsDetails: "Tomato, mozzarella, basil",
            price: "₹300",
          },
          {
            id: "2",
            itemsName: "Pepperoni",
            itemsDetails: "Pepperoni, mozzarella, tomato sauce",
            price: "₹350",
          },
          {
            id: "3",
            itemsName: "BBQ Chicken",
            itemsDetails: "Chicken, BBQ sauce, mozzarella, onion",
            price: "₹400",
          },
          {
            id: "4",
            itemsName: "Veggie",
            itemsDetails: "Bell peppers, olives, mushrooms, mozzarella",
            price: "₹320",
          },
          {
            id: "5",
            itemsName: "Hawaiian",
            itemsDetails: "Ham, pineapple, mozzarella",
            price: "₹370",
          },
          {
            id: "6",
            itemsName: "Meat Lover's",
            itemsDetails: "Pepperoni, sausage, ham, bacon",
            price: "₹450",
          },
          {
            id: "7",
            itemsName: "Four Cheese",
            itemsDetails: "Mozzarella, cheddar, parmesan, blue cheese",
            price: "₹400",
          },
          {
            id: "8",
            itemsName: "Supreme",
            itemsDetails:
              "Pepperoni, sausage, bell peppers, onions, mushrooms, olives",
            price: "₹450",
          },
          {
            id: "9",
            itemsName: "Buffalo Chicken",
            itemsDetails: "Chicken, buffalo sauce, mozzarella, blue cheese",
            price: "₹420",
          },
          {
            id: "10",
            itemsName: "Mushroom",
            itemsDetails: "Mushrooms, garlic, mozzarella, parsley",
            price: "₹350",
          },
        ],
      },
      {
        id: "2",
        name: "Burgers",
        itemsCard: [
          {
            id: "1",
            itemsName: "Cheeseburger",
            itemsDetails: "Beef patty, cheese, lettuce, tomato, onion",
            price: "₹150",
          },
          {
            id: "2",
            itemsName: "Bacon Burger",
            itemsDetails: "Beef patty, bacon, cheese, lettuce, tomato",
            price: "₹200",
          },
          {
            id: "3",
            itemsName: "Veggie Burger",
            itemsDetails: "Veggie patty, lettuce, tomato, onion",
            price: "₹120",
          },
          {
            id: "4",
            itemsName: "Chicken Burger",
            itemsDetails: "Chicken patty, lettuce, tomato, mayo",
            price: "₹180",
          },
          {
            id: "5",
            itemsName: "Fish Burger",
            itemsDetails: "Fish fillet, lettuce, tartar sauce",
            price: "₹190",
          },
          {
            id: "6",
            itemsName: "Double Cheeseburger",
            itemsDetails:
              "Double beef patty, double cheese, lettuce, tomato, onion",
            price: "₹250",
          },
          {
            id: "7",
            itemsName: "BBQ Burger",
            itemsDetails: "Beef patty, BBQ sauce, onion rings, cheese",
            price: "₹220",
          },
          {
            id: "8",
            itemsName: "Mushroom Swiss Burger",
            itemsDetails:
              "Beef patty, mushrooms, Swiss cheese, lettuce, tomato",
            price: "₹240",
          },
          {
            id: "9",
            itemsName: "Spicy Chicken Burger",
            itemsDetails: "Spicy chicken patty, lettuce, tomato, mayo",
            price: "₹200",
          },
          {
            id: "10",
            itemsName: "Turkey Burger",
            itemsDetails: "Turkey patty, lettuce, tomato, onion",
            price: "₹170",
          },
        ],
      },
      {
        id: "3",
        name: "Pasta",
        itemsCard: [
          {
            id: "1",
            itemsName: "Spaghetti Carbonara",
            itemsDetails: "Spaghetti, pancetta, egg, parmesan, black pepper",
            price: "₹250",
          },
          {
            id: "2",
            itemsName: "Fettuccine Alfredo",
            itemsDetails: "Fettuccine, cream, parmesan, butter",
            price: "₹280",
          },
          {
            id: "3",
            itemsName: "Penne Arrabbiata",
            itemsDetails: "Penne, tomato sauce, garlic, chili flakes, parsley",
            price: "₹230",
          },
          {
            id: "4",
            itemsName: "Lasagna",
            itemsDetails:
              "Lasagna noodles, ricotta, mozzarella, ground beef, tomato sauce",
            price: "₹350",
          },
          {
            id: "5",
            itemsName: "Pesto Pasta",
            itemsDetails: "Pasta, basil pesto, parmesan, pine nuts",
            price: "₹260",
          },
          {
            id: "6",
            itemsName: "Bolognese",
            itemsDetails: "Spaghetti, ground beef, tomato sauce, parmesan",
            price: "₹300",
          },
          {
            id: "7",
            itemsName: "Mac and Cheese",
            itemsDetails: "Macaroni, cheddar cheese, cream, breadcrumbs",
            price: "₹220",
          },
          {
            id: "8",
            itemsName: "Seafood Pasta",
            itemsDetails: "Pasta, shrimp, scallops, garlic, white wine sauce",
            price: "₹400",
          },
          {
            id: "9",
            itemsName: "Primavera",
            itemsDetails:
              "Pasta, mixed vegetables, olive oil, garlic, parmesan",
            price: "₹240",
          },
          {
            id: "10",
            itemsName: "Chicken Alfredo",
            itemsDetails:
              "Fettuccine, grilled chicken, cream, parmesan, butter",
            price: "₹300",
          },
        ],
      },
      {
        id: "4",
        name: "Salads",
        itemsCard: [
          {
            id: "1",
            itemsName: "Caesar Salad",
            itemsDetails: "Romaine, croutons, parmesan, Caesar dressing",
            price: "₹150",
          },
          {
            id: "2",
            itemsName: "Greek Salad",
            itemsDetails: "Tomato, cucumber, olives, feta, onion, olive oil",
            price: "₹160",
          },
          {
            id: "3",
            itemsName: "Caprese Salad",
            itemsDetails: "Tomato, mozzarella, basil, balsamic reduction",
            price: "₹180",
          },
          {
            id: "4",
            itemsName: "Cobb Salad",
            itemsDetails: "Chicken, bacon, avocado, egg, blue cheese, lettuce",
            price: "₹200",
          },
          {
            id: "5",
            itemsName: "Quinoa Salad",
            itemsDetails: "Quinoa, cucumber, tomato, feta, lemon vinaigrette",
            price: "₹180",
          },
          {
            id: "6",
            itemsName: "Spinach Salad",
            itemsDetails:
              "Spinach, strawberries, goat cheese, walnuts, balsamic vinaigrette",
            price: "₹170",
          },
          {
            id: "7",
            itemsName: "Nicoise Salad",
            itemsDetails: "Tuna, green beans, potatoes, egg, olives, lettuce",
            price: "₹220",
          },
          {
            id: "8",
            itemsName: "Waldorf Salad",
            itemsDetails: "Apples, celery, walnuts, grapes, mayo, lettuce",
            price: "₹150",
          },
          {
            id: "9",
            itemsName: "Asian Salad",
            itemsDetails:
              "Mixed greens, mandarin oranges, almonds, sesame dressing",
            price: "₹190",
          },
          {
            id: "10",
            itemsName: "Pasta Salad",
            itemsDetails: "Pasta, bell peppers, olives, feta, Italian dressing",
            price: "₹160",
          },
        ],
      },
      {
        id: "5",
        name: "Sandwiches",
        itemsCard: [
          {
            id: "1",
            itemsName: "Club Sandwich",
            itemsDetails: "Turkey, bacon, lettuce, tomato, mayo, toast",
            price: "₹180",
          },
          {
            id: "2",
            itemsName: "BLT",
            itemsDetails: "Bacon, lettuce, tomato, mayo, toast",
            price: "₹150",
          },
          {
            id: "3",
            itemsName: "Grilled Cheese",
            itemsDetails: "Cheddar cheese, bread, butter",
            price: "₹120",
          },
          {
            id: "4",
            itemsName: "Chicken Salad Sandwich",
            itemsDetails: "Chicken salad, lettuce, tomato, bread",
            price: "₹160",
          },
          {
            id: "5",
            itemsName: "Tuna Sandwich",
            itemsDetails: "Tuna salad, lettuce, tomato, bread",
            price: "₹170",
          },
          {
            id: "6",
            itemsName: "Ham and Cheese",
            itemsDetails: "Ham, cheese, lettuce, tomato, mustard, bread",
            price: "₹150",
          },
          {
            id: "7",
            itemsName: "Roast Beef Sandwich",
            itemsDetails: "Roast beef, cheese, lettuce, tomato, bread",
            price: "₹200",
          },
          {
            id: "8",
            itemsName: "Veggie Sandwich",
            itemsDetails:
              "Cucumber, bell pepper, tomato, lettuce, hummus, bread",
            price: "₹140",
          },
          {
            id: "9",
            itemsName: "Egg Salad Sandwich",
            itemsDetails: "Egg salad, lettuce, bread",
            price: "₹130",
          },
          {
            id: "10",
            itemsName: "Pulled Pork Sandwich",
            itemsDetails: "Pulled pork, BBQ sauce, coleslaw, bun",
            price: "₹190",
          },
        ],
      },
      {
        id: "6",
        name: "Desserts",
        itemsCard: [
          {
            id: "1",
            itemsName: "Chocolate Cake",
            itemsDetails: "Rich chocolate cake with chocolate frosting",
            price: "₹120",
          },
          {
            id: "2",
            itemsName: "Cheesecake",
            itemsDetails: "Creamy cheesecake with a graham cracker crust",
            price: "₹150",
          },
          {
            id: "3",
            itemsName: "Tiramisu",
            itemsDetails: "Coffee-soaked ladyfingers, mascarpone, cocoa",
            price: "₹180",
          },
          {
            id: "4",
            itemsName: "Apple Pie",
            itemsDetails: "Classic apple pie with a flaky crust",
            price: "₹130",
          },
          {
            id: "5",
            itemsName: "Brownie",
            itemsDetails: "Rich and fudgy chocolate brownie",
            price: "₹100",
          },
          {
            id: "6",
            itemsName: "Ice Cream",
            itemsDetails: "Vanilla, chocolate, or strawberry ice cream",
            price: "₹80",
          },
          {
            id: "7",
            itemsName: "Panna Cotta",
            itemsDetails: "Creamy panna cotta with a berry coulis",
            price: "₹160",
          },
          {
            id: "8",
            itemsName: "Fruit Tart",
            itemsDetails:
              "Shortcrust pastry filled with custard and topped with fresh fruits",
            price: "₹180",
          },
          {
            id: "9",
            itemsName: "Lemon Meringue Pie",
            itemsDetails: "Tart lemon filling with a fluffy meringue topping",
            price: "₹140",
          },
          {
            id: "10",
            itemsName: "Macarons",
            itemsDetails: "Assorted flavors of French macarons",
            price: "₹200",
          },
        ],
      },
      {
        id: "7",
        name: "Soups",
        itemsCard: [
          {
            id: "1",
            itemsName: "Chicken Soup",
            itemsDetails: "Chicken, vegetables, broth",
            price: "₹100",
          },
          {
            id: "2",
            itemsName: "Tomato Soup",
            itemsDetails: "Tomatoes, basil, cream",
            price: "₹80",
          },
          {
            id: "3",
            itemsName: "Minestrone",
            itemsDetails: "Vegetable soup with pasta and beans",
            price: "₹120",
          },
          {
            id: "4",
            itemsName: "French Onion Soup",
            itemsDetails: "Caramelized onions, beef broth, cheese, croutons",
            price: "₹150",
          },
          {
            id: "5",
            itemsName: "Clam Chowder",
            itemsDetails: "Clams, potatoes, cream, bacon",
            price: "₹180",
          },
          {
            id: "6",
            itemsName: "Miso Soup",
            itemsDetails: "Miso, tofu, seaweed, green onions",
            price: "₹90",
          },
          {
            id: "7",
            itemsName: "Lentil Soup",
            itemsDetails: "Lentils, vegetables, spices",
            price: "₹100",
          },
          {
            id: "8",
            itemsName: "Broccoli Cheddar Soup",
            itemsDetails: "Broccoli, cheddar cheese, cream",
            price: "₹130",
          },
          {
            id: "9",
            itemsName: "Gazpacho",
            itemsDetails: "Cold tomato and vegetable soup",
            price: "₹110",
          },
          {
            id: "10",
            itemsName: "Butternut Squash Soup",
            itemsDetails: "Butternut squash, cream, spices",
            price: "₹120",
          },
        ],
      },
      {
        id: "8",
        name: "Drinks",
        itemsCard: [
          {
            id: "1",
            itemsName: "Coca Cola",
            itemsDetails: "330ml can of Coca Cola",
            price: "₹40",
          },
          {
            id: "2",
            itemsName: "Pepsi",
            itemsDetails: "330ml can of Pepsi",
            price: "₹40",
          },
          {
            id: "3",
            itemsName: "Lemonade",
            itemsDetails: "Freshly squeezed lemonade",
            price: "₹60",
          },
          {
            id: "4",
            itemsName: "Iced Tea",
            itemsDetails: "Chilled tea with lemon",
            price: "₹50",
          },
          {
            id: "5",
            itemsName: "Orange Juice",
            itemsDetails: "Freshly squeezed orange juice",
            price: "₹70",
          },
          {
            id: "6",
            itemsName: "Apple Juice",
            itemsDetails: "Freshly squeezed apple juice",
            price: "₹70",
          },
          {
            id: "7",
            itemsName: "Mineral Water",
            itemsDetails: "500ml bottle of mineral water",
            price: "₹30",
          },
          {
            id: "8",
            itemsName: "Milkshake",
            itemsDetails: "Vanilla, chocolate, or strawberry milkshake",
            price: "₹90",
          },
          {
            id: "9",
            itemsName: "Smoothie",
            itemsDetails: "Mixed berry or tropical fruit smoothie",
            price: "₹100",
          },
          {
            id: "10",
            itemsName: "Coffee",
            itemsDetails: "Hot or iced coffee",
            price: "₹80",
          },
        ],
      },
      {
        id: "9",
        name: "Breakfast",
        itemsCard: [
          {
            id: "1",
            itemsName: "Pancakes",
            itemsDetails: "Stack of pancakes with syrup",
            price: "₹150",
          },
          {
            id: "2",
            itemsName: "Waffles",
            itemsDetails: "Belgian waffles with fruit and whipped cream",
            price: "₹180",
          },
          {
            id: "3",
            itemsName: "Omelette",
            itemsDetails: "Three-egg omelette with choice of fillings",
            price: "₹120",
          },
          {
            id: "4",
            itemsName: "French Toast",
            itemsDetails: "French toast with powdered sugar and syrup",
            price: "₹140",
          },
          {
            id: "5",
            itemsName: "Breakfast Burrito",
            itemsDetails:
              "Scrambled eggs, cheese, bacon, wrapped in a tortilla",
            price: "₹160",
          },
          {
            id: "6",
            itemsName: "Avocado Toast",
            itemsDetails:
              "Toast with mashed avocado, cherry tomatoes, and poached egg",
            price: "₹170",
          },
          {
            id: "7",
            itemsName: "Bagel with Cream Cheese",
            itemsDetails: "Bagel with a generous spread of cream cheese",
            price: "₹80",
          },
          {
            id: "8",
            itemsName: "Smoothie Bowl",
            itemsDetails: "Smoothie bowl with granola and fresh fruit",
            price: "₹200",
          },
          {
            id: "9",
            itemsName: "Breakfast Sandwich",
            itemsDetails: "Egg, cheese, and bacon on an English muffin",
            price: "₹150",
          },
          {
            id: "10",
            itemsName: "Yogurt Parfait",
            itemsDetails: "Yogurt layered with granola and fresh berries",
            price: "₹130",
          },
        ],
      },
      {
        id: "10",
        name: "Appetizers",
        itemsCard: [
          {
            id: "1",
            itemsName: "Spring Rolls",
            itemsDetails: "Crispy spring rolls with dipping sauce",
            price: "₹120",
          },
          {
            id: "2",
            itemsName: "Mozzarella Sticks",
            itemsDetails: "Fried mozzarella sticks with marinara sauce",
            price: "₹150",
          },
          {
            id: "3",
            itemsName: "Nachos",
            itemsDetails:
              "Tortilla chips with cheese, jalapenos, salsa, sour cream",
            price: "₹200",
          },
          {
            id: "4",
            itemsName: "Chicken Wings",
            itemsDetails: "Spicy chicken wings with ranch dip",
            price: "₹250",
          },
          {
            id: "5",
            itemsName: "Stuffed Mushrooms",
            itemsDetails: "Mushrooms stuffed with cheese and herbs",
            price: "₹180",
          },
          {
            id: "6",
            itemsName: "Bruschetta",
            itemsDetails:
              "Toasted bread topped with tomatoes, basil, and olive oil",
            price: "₹130",
          },
          {
            id: "7",
            itemsName: "Potato Skins",
            itemsDetails: "Crispy potato skins with cheese and bacon",
            price: "₹140",
          },
          {
            id: "8",
            itemsName: "Garlic Bread",
            itemsDetails: "Toasted bread with garlic butter",
            price: "₹90",
          },
          {
            id: "9",
            itemsName: "Hummus with Pita",
            itemsDetails: "Creamy hummus served with warm pita bread",
            price: "₹110",
          },
          {
            id: "10",
            itemsName: "Onion Rings",
            itemsDetails: "Crispy fried onion rings with dipping sauce",
            price: "₹120",
          },
        ],
      },
      {
        id: "11",
        name: "Seafood",
        itemsCard: [
          {
            id: "1",
            itemsName: "Grilled Salmon",
            itemsDetails: "Salmon fillet with lemon butter sauce",
            price: "₹400",
          },
          {
            id: "2",
            itemsName: "Fish and Chips",
            itemsDetails: "Battered fish with fries and tartar sauce",
            price: "₹300",
          },
          {
            id: "3",
            itemsName: "Shrimp Scampi",
            itemsDetails:
              "Shrimp cooked in garlic butter sauce, served with pasta",
            price: "₹350",
          },
          {
            id: "4",
            itemsName: "Lobster Tail",
            itemsDetails: "Grilled lobster tail with drawn butter",
            price: "₹600",
          },
          {
            id: "5",
            itemsName: "Crab Cakes",
            itemsDetails: "Pan-fried crab cakes with remoulade sauce",
            price: "₹450",
          },
          {
            id: "6",
            itemsName: "Clam Bake",
            itemsDetails: "Clams steamed with corn and potatoes",
            price: "₹500",
          },
          {
            id: "7",
            itemsName: "Tuna Steak",
            itemsDetails: "Seared tuna steak with sesame crust",
            price: "₹550",
          },
          {
            id: "8",
            itemsName: "Calamari",
            itemsDetails: "Fried calamari with marinara sauce",
            price: "₹300",
          },
          {
            id: "9",
            itemsName: "Oysters",
            itemsDetails: "Fresh oysters on the half shell",
            price: "₹400",
          },
          {
            id: "10",
            itemsName: "Paella",
            itemsDetails:
              "Spanish rice dish with seafood, saffron, and vegetables",
            price: "₹500",
          },
        ],
      },
      {
        id: "12",
        name: "Grilled",
        itemsCard: [
          {
            id: "1",
            itemsName: "Grilled Chicken",
            itemsDetails: "Marinated grilled chicken breast",
            price: "₹250",
          },
          {
            id: "2",
            itemsName: "BBQ Ribs",
            itemsDetails: "Smoky BBQ ribs with sauce",
            price: "₹400",
          },
          {
            id: "3",
            itemsName: "Grilled Vegetables",
            itemsDetails:
              "Assorted grilled vegetables with olive oil and herbs",
            price: "₹200",
          },
          {
            id: "4",
            itemsName: "Steak",
            itemsDetails: "Grilled ribeye steak with garlic butter",
            price: "₹600",
          },
          {
            id: "5",
            itemsName: "Grilled Shrimp",
            itemsDetails: "Marinated grilled shrimp skewers",
            price: "₹350",
          },
          {
            id: "6",
            itemsName: "Pork Chops",
            itemsDetails: "Grilled pork chops with apple sauce",
            price: "₹300",
          },
          {
            id: "7",
            itemsName: "Grilled Lamb Chops",
            itemsDetails: "Herb-marinated grilled lamb chops",
            price: "₹500",
          },
          {
            id: "8",
            itemsName: "Grilled Tofu",
            itemsDetails: "Marinated grilled tofu with vegetables",
            price: "₹220",
          },
          {
            id: "9",
            itemsName: "Grilled Sausages",
            itemsDetails: "Assorted grilled sausages with mustard",
            price: "₹250",
          },
          {
            id: "10",
            itemsName: "Grilled Cheese Sandwich",
            itemsDetails: "Grilled sandwich with melted cheese",
            price: "₹120",
          },
        ],
      },
      {
        id: "13",
        name: "Tacos",
        itemsCard: [
          {
            id: "1",
            itemsName: "Chicken Tacos",
            itemsDetails: "Grilled chicken, lettuce, cheese, salsa",
            price: "₹150",
          },
          {
            id: "2",
            itemsName: "Beef Tacos",
            itemsDetails: "Ground beef, lettuce, cheese, salsa",
            price: "₹160",
          },
          {
            id: "3",
            itemsName: "Fish Tacos",
            itemsDetails: "Grilled fish, cabbage, salsa, lime",
            price: "₹170",
          },
          {
            id: "4",
            itemsName: "Veggie Tacos",
            itemsDetails: "Grilled vegetables, lettuce, cheese, salsa",
            price: "₹140",
          },
          {
            id: "5",
            itemsName: "Shrimp Tacos",
            itemsDetails: "Grilled shrimp, cabbage, salsa, lime",
            price: "₹180",
          },
          {
            id: "6",
            itemsName: "Carnitas Tacos",
            itemsDetails: "Slow-cooked pork, lettuce, cheese, salsa",
            price: "₹170",
          },
          {
            id: "7",
            itemsName: "Barbacoa Tacos",
            itemsDetails: "Slow-cooked beef, lettuce, cheese, salsa",
            price: "₹180",
          },
          {
            id: "8",
            itemsName: "Breakfast Tacos",
            itemsDetails: "Scrambled eggs, bacon, cheese, salsa",
            price: "₹160",
          },
          {
            id: "9",
            itemsName: "Chorizo Tacos",
            itemsDetails: "Spicy chorizo, lettuce, cheese, salsa",
            price: "₹170",
          },
          {
            id: "10",
            itemsName: "Baja Tacos",
            itemsDetails: "Grilled fish, cabbage, salsa, lime, avocado",
            price: "₹190",
          },
        ],
      },
      {
        id: "14",
        name: "Sushi",
        itemsCard: [
          {
            id: "1",
            itemsName: "California Roll",
            itemsDetails: "Crab, avocado, cucumber, rice, seaweed",
            price: "₹200",
          },
          {
            id: "2",
            itemsName: "Spicy Tuna Roll",
            itemsDetails: "Spicy tuna, rice, seaweed",
            price: "₹220",
          },
          {
            id: "3",
            itemsName: "Salmon Nigiri",
            itemsDetails: "Salmon on sushi rice",
            price: "₹240",
          },
          {
            id: "4",
            itemsName: "Eel Roll",
            itemsDetails: "Eel, avocado, cucumber, rice, seaweed",
            price: "₹230",
          },
          {
            id: "5",
            itemsName: "Tempura Roll",
            itemsDetails: "Tempura shrimp, avocado, cucumber, rice, seaweed",
            price: "₹250",
          },
          {
            id: "6",
            itemsName: "Dragon Roll",
            itemsDetails: "Eel, cucumber, avocado, rice, seaweed",
            price: "₹280",
          },
          {
            id: "7",
            itemsName: "Rainbow Roll",
            itemsDetails: "Assorted fish, avocado, cucumber, rice, seaweed",
            price: "₹300",
          },
          {
            id: "8",
            itemsName: "Vegetable Roll",
            itemsDetails: "Cucumber, avocado, carrot, rice, seaweed",
            price: "₹180",
          },
          {
            id: "9",
            itemsName: "Tuna Nigiri",
            itemsDetails: "Tuna on sushi rice",
            price: "₹240",
          },
          {
            id: "10",
            itemsName: "Sashimi Platter",
            itemsDetails: "Assorted sashimi slices",
            price: "₹400",
          },
        ],
      },
      {
        id: "15",
        name: "BBQ",
        itemsCard: [
          {
            id: "1",
            itemsName: "BBQ Chicken",
            itemsDetails: "Grilled chicken with BBQ sauce",
            price: "₹250",
          },
          {
            id: "2",
            itemsName: "BBQ Ribs",
            itemsDetails: "Slow-cooked ribs with BBQ sauce",
            price: "₹400",
          },
          {
            id: "3",
            itemsName: "Pulled Pork",
            itemsDetails: "Slow-cooked pulled pork with BBQ sauce",
            price: "₹300",
          },
          {
            id: "4",
            itemsName: "BBQ Brisket",
            itemsDetails: "Slow-cooked beef brisket with BBQ sauce",
            price: "₹350",
          },
          {
            id: "5",
            itemsName: "BBQ Sausages",
            itemsDetails: "Grilled sausages with BBQ sauce",
            price: "₹200",
          },
          {
            id: "6",
            itemsName: "BBQ Wings",
            itemsDetails: "Chicken wings with BBQ sauce",
            price: "₹180",
          },
          {
            id: "7",
            itemsName: "BBQ Shrimp",
            itemsDetails: "Grilled shrimp with BBQ sauce",
            price: "₹300",
          },
          {
            id: "8",
            itemsName: "BBQ Tofu",
            itemsDetails: "Grilled tofu with BBQ sauce",
            price: "₹220",
          },
          {
            id: "9",
            itemsName: "BBQ Pork Chops",
            itemsDetails: "Grilled pork chops with BBQ sauce",
            price: "₹280",
          },
          {
            id: "10",
            itemsName: "BBQ Salmon",
            itemsDetails: "Grilled salmon with BBQ sauce",
            price: "₹350",
          },
        ],
      },
    ],
  },
 
];
