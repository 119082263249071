import React, { useState } from 'react'
import { subCategory } from '../FoodData';
import CategoryButton from './CategoryButton';
import CategoryCard from './CategoryCard';

const ThaiCuisine = () => {
 
    const [selectedCategory, setSelectedCategory] = useState(subCategory[0].items[0].id);
  
    const handleShowItemsCard = (id) => {
      setSelectedCategory(id);
    };
  
  
    return (
      <div className="">
        {subCategory?.map((data) => {
          const { id, headingName, items } = data;
          return (
            <>
              <div
                className="text-xl font-medium text-[#07090D] mt-2 mb-4"
                key={id}
              >
                {headingName}
              </div>
              <div className="w-full flex mt-2">
                <div className="w-[30vw] h-[calc(100vh-(-1.3rem))] flex flex-col space-y-2 pr-2  max-h-full overflow-x-hidden overflow-y-auto scroll-bar2">
                  {items?.map((item) => {
                    const { id, name } = item;
                    return (
                      <CategoryButton
                        name={name}
                        key={id}
                        active={selectedCategory === id}
                        onClick={() => handleShowItemsCard(id)}
                      />
                    );
                  })}
                </div>
                <div className="w-[70vw] h-[calc(100vh-(-1.3rem))] flex flex-col space-y-2 overflow-y-auto overflow-x-hidden scroll-bar2 px-2">
                  {items?.map((item) =>
                    item.id === selectedCategory
                      ? item.itemsCard.map((cardData) => (
                          <CategoryCard
                            key={cardData?.id}
                            name={cardData?.itemsName}
                            price={cardData?.price}
                            details={cardData?.itemsDetails}
                          />
                        ))
                      : null
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  };

export default ThaiCuisine