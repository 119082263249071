import React from "react";
const TopButtons = ({ name, Icon, onClick = () => {}, active }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`py-4 w-full flex flex-col gap-2 items-center justify-center bg-[#F6F6F6] rounded-[5px] duration-300 ease-[0.3s] text-[16px] leading-[20.16px] font-semibold  ${
        active ? "text-white bg-base-primary" : ""
      } `}
    >
      {Icon}
      <div>{name}</div>
    </button>
  );
};

export default TopButtons;
