import React from "react";

const LinksButton = ({ name, onClick = () => {} }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-base-primary hover:text-base-primary_hover duration-300 ease-[0.3s] pr-2 border-r last:border-r-0"
    >
      {name}
    </button>
  );
};

export default LinksButton;
