/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import BasicInformation from "./BasicInformation";
import OccupationalInformation from "./OccupationalInformation";

const EmployeeInformation = () => {
  return (
    <div>
      <BasicInformation />
      <div className="mt-4">
        <OccupationalInformation />
      </div>
    </div>
  );
};

export default EmployeeInformation;
