import React from "react"; 
import { useTranslation } from "react-i18next";

const AvarageRatings = ({ value }) => {
  const { t } = useTranslation();
  return (
      <div className="w-full flex flex-col items-center  sm:border-l border-gray-200 md:border-r font-pjs h-56 pb-3">
        <div className="font-semibold text-xl sm:text-2xl md:text-[30px] leading-[37px] tracking-[-0.8 px] text-[#667085] capitalize pt-[75px]">
          {t("321")}
        </div>
        <div className="flex items-center">
          <div className="font-medium text-xl sm:text-2xl md:text-[30px] leading-[37px] tracking-[-0.8 px] text-[#00A35E] mr-2">
            {value}
          </div>
          {/* <Stack spacing={1}>
            <Rating
              max={5}
              name="half-rating-read"
              defaultValue={value}
              precision={value}
              onChange={() => {
                return null;
              }}
              readOnly
            />
          </Stack> */}
        </div>
      </div>
  );
};

export default AvarageRatings;
