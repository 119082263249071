import React from 'react'
import AddTravelAgentDetails from './AddTravelAgentDetails'
import Billing from '../bank-account/Billing'

const LocalTravelAgent = () => {
  return (
    <div className='flex flex-col gap-6 '>
        <AddTravelAgentDetails/>
        <Billing/>
    </div>
  )
}

export default LocalTravelAgent
