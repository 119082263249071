/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { clearError } from "../../actions/clearError";
import { connect, useSelector } from "react-redux";
import { isObjEmpty } from "../../utils";
import RatingPercentage from "./RatingPercentage";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import TotalReviews from "./TotalReviews";
import { getAllReviewAction } from "../../actions/pms/reviews";
import AvarageRatings from "./AvarageRatings";
import PageLoader from "../../components/loader/PageLoader";
import CustomeTable from "../../components/CustomeTable";
import ReplyReview from "./ReplyReview";

const AllReviews = (props) => {
  const { t } = useTranslation();
  const { reviews, loading } = useSelector((state) => state.AllReview);
  const [averageRating, setAverageRating] = useState(0);
  const [ratings, setRatings] = useState(null);
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (
      props?.hotel &&
      props?.hotel?.hotel &&
      !isObjEmpty(props?.hotel?.hotel)
    ) {
      props?.getAllReviews(props.hotel?.hotel?._id);
    }
  }, []);

  const modifyTheData = async (data) => {
    let arr = [];
    const ratings = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };
    await data?.map((element) => {
      const rating = element?.review?.rating;
      arr.push({
        reviewId: element?.review?.reviewId,
        reviewerName: element?.review?.reviewerName,
        rating: element?.review?.rating,
        reviews: element?.review?.reviews,
        category: element?.review?.category,
        bookingId: element?.review?.bookingId,
        reviewDate: element?.reviewedAt?.slice(0, 10),
        reply: element?.replies?.length ? element?.replies[0] : element?._id,
        action: element?.review?.action,
        //  usernName: element?.userName,
        // reviewDate: element?.reviewedAt?.slice(0, 10),
        // rating: element?.review?.rating,
        // description: element?.review?.review,
        // replayDate: element?.replies[0]?.date?.slice(0, 10),
        // _id: element?.replies?.length ? element?.replies[0] : element?._id,
      });
      ratings[rating] = ratings[rating] + 1;
    });
    if (arr?.length) {
      setDatas(arr);
      setRatings(ratings);
      let ratingCount = 0;
      let totalRating = 0;
      Object.keys(ratings)?.map((rat) => {
        ratingCount = ratingCount + ratings[rat] * rat;
        totalRating = totalRating + ratings[rat];
      });

      const avgRating = Math.ceil(ratingCount / totalRating);
      if (avgRating) setAverageRating(avgRating);
      else setAverageRating(1);
    } else {
      setRatings(ratings);
      setAverageRating(1);
    }
  };
  useEffect(() => {
    if (reviews && Array.isArray(reviews)) {
      modifyTheData(reviews);
    }
  }, [reviews]);

  const customerReply = ({ value }) => {
    return (
      <div className="h-full flex items-center">
        <ReplyReview reviewId={value} />
      </div>
    );
  };

  const headingData = {
    heading: t("319"),
    seperator: [
      {
        text: t("Guest Management"),
      },
      {
        text: t("320"),
      },
    ],
  };

  const columnDefs = [
    {
      headerName: "Review ID",
      field: "reviewId",
      minWidth: 160,
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Reviewer Name",
      field: "reviewerName",
      filter: true,
    },
    {
      headerName: "Rating",
      field: "rating",
      filter: true,
    },
    {
      headerName: "Reviews",
      field: "reviews",
      filter: true,
    },
    {
      headerName: "Category",
      field: "category",
      filter: true,
    },
    {
      headerName: "Booking Id",
      field: "bookingId",
      filter: true,
    },
    {
      headerName: "Review Date",
      field: "reviewDate",
      filter: true,
      // cellRenderer: checkIsRefundable,
    },
    {
      headerName: "Reply",
      field: "reply",
      filter: true,
      cellRenderer: customerReply,
    },
  ];
  return (
    <>
      {loading || !averageRating || !ratings ? (
        <PageLoader />
      ) : (
        <div className="page-container">
          <div className=" flex justify-between items-center">
            <Seperator data={headingData} />
          </div>
          <div className="my-6 border grid grid-cols-1 sm:grid-cols-3 items-center bg-white rounded-lg shadow-sm mt-4 py-8 sm:py-0">
            <TotalReviews count={reviews ? reviews?.length : 0} />
            <AvarageRatings value={averageRating} />
            <RatingPercentage ratings={ratings} />
          </div>
          <div>
            {/* <ReviewsTable datas={datas} /> */}
            <CustomeTable
              tableName={"Reviews Table"}
              columnDefs={columnDefs}
              rowData={datas}
              setSelectedRows={setSelectedRows}
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.Hotel,
});

const mapDispatchToProps = (dispatch) => ({
  getAllReviews: (hotelId) => dispatch(getAllReviewAction(hotelId)),
  clearError: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllReviews);
