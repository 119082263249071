import React, { useState } from "react";
import SubmitButton from "../buttons/SubmitButton";
import SelectBox from "../layouts/SelectBox";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import ShiftButton from "../buttons/ShiftButton";
import { IoSearchOutline } from "react-icons/io5";
import Inputfield from "../layouts";
import CustomModal from "../CustomModal";

const ViewCorporateWallet = ({ close = () => {} }) => {
  const [selectedRows, setSelectedRows] = useState([]);
 
  const row = [
    {
      name: "Karmilla",
      designation: "HR Manager",
      department: "Human Resource",
    },
  ];
  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      checkboxSelection: true,
    },
    {
      headerName: "Designation",
      field: "designation",
    },
    {
      headerName: "Department",
      field: "department",
    },
  ];
  return (
    <CustomModal
      close={close}
      heading={"Payment mode Configuration: Corporate Wallet"}
    >
      <div className="h-[85vh] w-[80vw] capitalize">
        <div className="w-[32vw]">
          <div className="text-[20px] leading-[33.1px] font-medium tracking-[-1.5%] text-[#07090D]">
            Employee Authorize to View Corporate Wallet
          </div>
          <div className="relative my-2">
            <Inputfield
              type="search"
              placeholder={"Add Employee"}
              className={"pl-12"}
            />
            <IoSearchOutline size={24} className="absolute top-3 left-4" />
          </div>
        </div>
        <div className="grid grid-cols-10 w-full">
          {/* Left side */}
          <div className="col-span-4">
            <SelectBox
              label={"All Employees List"}
              heading={"Select Employees"}
              btnName={"Add Employee"}
              columnDefs={columnDefs}
              rowData={row}
              setSelectedRows={setSelectedRows}
            />
  
          </div>

          {/* Buttons for add and remove */}
          <div className="col-span-2 flex flex-col items-center justify-center gap-6">
            <ShiftButton
              name={"Add"}
              icon={<AiOutlineDoubleRight size={18} />}
            />
            <ShiftButton
              name={"Remove"}
              icon={<AiOutlineDoubleLeft size={18} />}
            />
          </div>

          {/* Right side */}
          <div className="col-span-4 w-full">
            <SelectBox
              label={"Selected Employees Authorise To View Corporate"}
              heading={"Selected Employees"}
              showBtn={false}
              columnDefs={columnDefs}
              rowData={row}
            />
            
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <SubmitButton sizing={"h-12 w-[183px]"} text={"Save"} />
        </div>
      </div>
    </CustomModal>
  );
};

export default ViewCorporateWallet;
