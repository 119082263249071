import React from 'react'
import BookingSourceAdd from './BookingSourceAdd'
import BookingSourceAll from './BookingSourceAll'

const AutoSyncBookingConfig = () => {
  return (
    
      <div className="flex flex-col">
        <BookingSourceAdd />
        <BookingSourceAll/>
      </div>
   
  )
}

export default AutoSyncBookingConfig