import React, { useState } from "react";
// import { GrClose } from "react-icons/gr";

const PreviewImages = ({
  images,
  open,
  handleClose,
  deleteThisImage = () => {},
}) => {
  const [index, setIndex] = useState(0);
  //view previous image
  const gotoBackword = () => {
    if (images?.length) {
      if (index === 0) {
        setIndex(images?.length - 1);
      } else setIndex(index - 1);
    }
  };

  //view next image
  const gotoForword = () => {
    if (images?.length) {
      setIndex((index + 1) % images?.length);
    }
  };

  //delete the image
  const deleteImage = (fileId) => {
    if (images?.length > 1) {
      gotoForword();
    } else handleClose();
    deleteThisImage(fileId);
  };

  //close image view
  const closeModal = () => {
    handleClose();
    setIndex(0);
  };

  return (
    <>
      {images?.length && images[index]?.url ? (
        <></>
      ) : // <Dialog
      //   open={open}
      //   onClose={closeModal}
      //   aria-labelledby="alert-dialog-title"
      //   aria-describedby="alert-dialog-description"
      //   sx={{
      //     ".MuiPaper-elevation": {
      //       maxWidth: "90vw !important",
      //       maxHeight: "90vh !important",
      //       height: "fit-content !important",
      //       div: {
      //         padding: "1rem !important",
      //       },
      //     },
      //   }}
      // >
      //   {/* <button className="absolute top-1 right-1" onClick={closeModal}>
      //     <GrClose />
      //   </button> */}
      //   <DialogContent>
      //     <img src={images[index]?.url} alt="" />
      //   </DialogContent>
      //   {images?.length > 1 ? (
      //     <>
      //       <button
      //         className="absolute top-1/2 left-7 text-xl p-0 border-none w-10 h-10 overflow-hidden z-10 bg-[#ffffff4f] rounded-full flex items-center justify-center font-bold -translate-y-1/2"
      //         onClick={gotoBackword}
      //       >
      //         <IoIosArrowBack />
      //       </button>
      //       <button
      //         className="absolute top-1/2 right-7 text-xl p-0 border-none w-10 h-10 overflow-hidden z-10 bg-[#ffffff4f] rounded-full flex items-center justify-center font-bold -translate-y-1/2"
      //         onClick={gotoForword}
      //       >
      //         <IoIosArrowForward />
      //       </button>

      //       <button
      //         className="absolute top-7 right-7 text-xl p-0 border-none w-10 h-10 overflow-hidden z-10 bg-[#ffffff4f] rounded-full flex items-center justify-center font-bold"
      //         onClick={() => deleteImage(images[index]?.id)}
      //       >
      //         <AiOutlineDelete />
      //       </button>
      //     </>
      //   ) : null}
      // </Dialog>
      null}
    </>
  );
};

export default PreviewImages;
