import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

const FoodTopButton = ({ Icon, name, params, index }) => {
  const navigate = useNavigate();
  const setOptionTabIndex = () => {
    navigate(`${ROUTES.FOOD}/${index}`);
  };
  return (
    <button
      type="button"
      className={`min-w-[118px] min-h-[115px] bg-white border rounded-[10px] flex items-center justify-center 
        ${
          +params !== index ? "border-[#E5E9EB]" : "border border-base-primary"
        }`}
      onClick={() => setOptionTabIndex()}
    >
      <div className="flex flex-col gap-2">
        <div className={`h-12 w-[50px]  rounded-full flex items-center justify-center mx-auto bg-[#F6F6F6]`}>
          {/* <Icon style={{ fontSize: "2rem", fontWeight: "300" }} /> */}
          <img src={Icon} alt="" />
        </div>
        <div className="mx-auto w-14 text-[14px] text-[#667085] leading-[15px] font-medium tracking-[-1.5%]">
          {name}
        </div>
      </div>
    </button>
  );
};

export default FoodTopButton;
