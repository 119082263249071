/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../constants";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import AddItemToMenu from "./AddItemToMenu";
import AllMenuItem from "./AllMenuItem";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/buttons/AddButton";
import Seperator from "../../components/layouts/Seperator";
import { getKitchenMenuAction } from "../../actions/kitchen/menu";
import DeleteButton from "../../components/buttons/DeleteButton";
import EditButton from "../../components/buttons/EditButton";

const MenuItemPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addButton, setAddButton] = useState(false);
  const { hotel } = useSelector((state) => state.Kitchen);
  const { kitchen } = useSelector((state) => state.Kitchen);

  const [menu, setMenu] = useState({});

  //register event
  const registerEventAndOpen = () => {
    setAddButton(true);
    registerEvent("add menu item button open", "add kitchen menu", {
      addButton,
    });
  };

  //get kitchen data from session Storage
  useEffect(() => {
    if (kitchen?._id) dispatch(getKitchenMenuAction(kitchen?._id));
  }, [kitchen?._id]);

  // register evnet on close add asset component
  const close = () => {
    if (!isObjEmpty(menu)) setMenu(null);
    setAddButton(false);
    registerEvent("add menu item button close", "add kitchen menu", {
      addButton,
    });
  };

  const data = {
    heading: t("36"),
    seperator: [
      {
        text: "Kitchen Management",
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      { text: t("Menu") },
    ],
  };
  return (
    <div className="page-container">
      <div className="flex justify-between items-end">
        <Seperator data={data} />
        <AddButton text={t("1090")} onClick={registerEventAndOpen} />
      </div>
      {!isObjEmpty(menu) || addButton ? (
        <AddItemToMenu
          close={close}
          hotel={hotel}
          kitchen={kitchen}
          menu={menu}
        />
      ) : null}
      <AllMenuItem kitchen={kitchen} hotel={hotel} setMenu={setMenu} />
    </div>
  );
};

export default MenuItemPage;
