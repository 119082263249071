import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BOOKING_ROOM_TYPES } from "../../constants";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import { FaPlus } from "react-icons/fa6";
import AddAutomationRules from "./AddAutomationRules";
import CancelButton from "../../components/buttons/CancelButton";
import RoomTarrifCalender from "./RoomTariffCalender";
import SelectFromArray from "../../components/layouts/SelectFromArray";
import RoomTariffAutomationRules from "./RoomTariffAutomationRules";

const RoomTariff = () => {
  const { t } = useTranslation();
  const [roomType, setRoomType] = useState([]);
  const [isAutomate, setIsAutomate] = useState(false);
  const [selectSeasonalRate, setSelectSeasonalRate] = useState([]);
  const [selectRoomType, setSelectRoomType] = useState([]);
  const [selectNoOfPerson, setSelectNoOfPerson] = useState([]);
  const [roomTypeRule, setRoomTypeRule] = useState([]);
  const [priceIncAndDecByRule, setPriceIncAndDecByRule] = useState([]);
  const [pricePercentage, setPricePercentage] = useState("");
  const [logicalOperation, setLogicalOperation] = useState([]);
  const [occupencyPercentage, setOccupencyPercentage] = useState("");
  const [daysLeftToBooking, setDaysLeftToBooking] = useState([]);
  const [addMoreRule, setAddMoreRule] = useState([]);
  const [showRule, setShowRule] = useState(false);
  console.log(
    "add rules entity : ",
    roomTypeRule,
    priceIncAndDecByRule,
    pricePercentage,
    occupencyPercentage,
    daysLeftToBooking
  );
  const ROOM_TARIFF_SEASONAL_RATE = {
    SEASONAL_RATE: "Seasonal Rate",
    RACK_RATE: "Rack Rate",
    HONEYMOON_PACKAGES: "Honeymoon Packages",
    BED_AND_BREAKFAST: "Bed & Breakfast",
    HALF_BOARD: "Half Board (B,Dinner)",
    FULL_BOARD: "Full Board (B,L,D)",
  };

  const NUMBER_OF_PERSONS = {
    FOR_01_PERSON: "For 01 Person",
    FOR_02_PERSONS: "For 02 Persons",
    FOR_03_PERSONS: "For 03 Persons",
    FOR_05_PERSONS: "For 05 Persons",
    FOR_10_PERSONS: "For 10 Persons",
    FOR_15_PERSONS: "For 15 Persons",
    FOR_20_PERSONS: "For 20 Persons",
    FOR_30_PERSONS: "For 30 Persons",
    FOR_40_PERSONS: "For 40 Persons",
    FOR_50_PERSONS: "For 50 Persons",
  };

  // const headingData = {
  //   heading: t("Room Tariff Automation"),
  //   seperator: [
  //     {
  //       text: t("Front Desk"),
  //     },
  //     {
  //       text: t("Room Tariff"),
  //     },
  //   ],
  // };

  const roomData = [
    { type: "Single", tariff: "2000", maxTariff: "4000" },
    { type: "Double", tariff: "3500", maxTariff: "7000" },
    { type: "Triple", tariff: "5000", maxTariff: "10000" },
    { type: "Quad", tariff: "6000", maxTariff: "12000" },
    { type: "King", tariff: "7500", maxTariff: "15000" },
  ];

  // const redirectToPreviousePage = (link) => {
  //   if (link) {
  //     navigate(link);
  //   }
  // };

  const handleAddMoreRule = () => {
    setAddMoreRule([...addMoreRule, {}]);
    setShowRule(true);
  };
  return (
    <div className="mt-4">
      {/* <div className="flex justify-between items-end">
        <Seperator data={headingData} />
        <button
          type="button"
          onClick={() => redirectToPreviousePage(ROUTES.ROOMS)}
          className="h-10 border border-[#D0D5DD] rounded-md text-[14px] leading-[17.64px] font-semibold tracking-[-0.6%] bg-white hover:bg-[#f5f7f2] transition-all duration-[0.3s] ease-[0s] flex items-center justify-center gap-3 px-4"
        >
          <MdOutlineKeyboardBackspace size={18} />
          <div className="">Back</div>
        </button>
      </div> */}
      <div className="flex items-end justify-between ">
        <div className="font-medium text-base text-[#344054]">
          {t("Automation On / Off")}
          <input
            type={"checkbox"}
            className="ml-4 checkBoxControl"
            value={isAutomate}
            onChange={() => setIsAutomate(!isAutomate)}
          />
        </div>
        <div className="flex items-end gap-4">
          <SelectFromObject
            value={selectSeasonalRate}
            setValue={setSelectSeasonalRate}
            options={ROOM_TARIFF_SEASONAL_RATE}
          />
          <SelectFromObject
            value={selectRoomType}
            setValue={setSelectRoomType}
            options={BOOKING_ROOM_TYPES}
          />
          <SelectFromObject
            value={selectNoOfPerson}
            setValue={setSelectNoOfPerson}
            options={NUMBER_OF_PERSONS}
          />
        </div>
      </div>

      <div className="w-full bg-white rounded-[10px] border-[#E4E4E7] mt-4">
        <div className=" text-[14px] leading-6  border  grid grid-cols-6 rounded-t-[10px]">
          <div className="col-span-1 border-r font-medium tracking-[-0.8px]">
            <div className="h-12 flex items-center justify-center border-b">
              Room Type
            </div>
            <div className="h-12 flex items-center justify-center border-b">
              Room Tariff
            </div>
            <div className="h-12 flex items-center justify-center">
              Maximum Room Tariff
            </div>
          </div>
          {roomData.map((room) => (
            <div
              key={room}
              className="col-span-1 border-r last:border-r-0  text-[#000000]"
            >
              <div className="h-12 flex items-center justify-center border-b">
                {room.type}
              </div>
              <div className="h-12 flex items-center justify-center border-b text-base">
                <div className="border border-[#D0D5DD] rounded-[3px] px-4">
                  {room.tariff}
                </div>
              </div>
              <div className="h-12 flex items-center justify-center text-base">
                <div className="border border-[#D0D5DD] rounded-[3px] px-4">
                  {room.maxTariff}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="h-20 border border-t-0 rounded-b-[10px] grid grid-cols-6 items-center justify-end">
          <div className="col-span-5"></div>
          <div className="flex items-center justify-center col-span-1 gap-4">
            <button
              type="button"
              className="h-9 w-[55px] rounded-md border border-[] text-sm font-medium text-[#252C32] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] hover:bg-slate-300 duration-300 ease-[0s]"
            >
              edit
            </button>
            <button
              type="button"
              className="h-9 w-[61px] rounded-md bg-base-primary hover:bg-base-primary_hover text-sm font-medium text-white duration-300 ease-[0s] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]"
            >
              save
            </button>
          </div>
        </div>
      </div>
      {/*  Add rules*/}
      <div className="w-full border border-[#E4E4E7] rounded-[10px] bg-white capitalize my-6 p-4">
        <div className="text-[18px] leading-[22.68px] font-semibold tracking-[-0.8px] mb-4">
          Add Automation Rule
        </div>
        <AddAutomationRules
          roomTypeRule={roomTypeRule}
          setRoomTypeRule={setRoomTypeRule}
          priceIncAndDecByRule={priceIncAndDecByRule}
          setPriceIncAndDecByRule={setPriceIncAndDecByRule}
          pricePercentage={pricePercentage}
          setPricePercentage={setPricePercentage}
          logicalOperation={logicalOperation}
          setLogicalOperation={setLogicalOperation}
          occupencyPercentage={occupencyPercentage}
          setOccupencyPercentage={setOccupencyPercentage}
          daysLeftToBooking={daysLeftToBooking}
          setDaysLeftToBooking={setDaysLeftToBooking}
        />
        {showRule &&
          addMoreRule.map((data, index) => {
            return (
              <AddAutomationRules
                key={index}
                roomTypeRule={roomTypeRule}
                setRoomTypeRule={setRoomTypeRule}
                priceIncAndDecByRule={priceIncAndDecByRule}
                setPriceIncAndDecByRule={setPriceIncAndDecByRule}
                pricePercentage={pricePercentage}
                setPricePercentage={setPricePercentage}
                logicalOperation={logicalOperation}
                setLogicalOperation={setLogicalOperation}
                occupencyPercentage={occupencyPercentage}
                setOccupencyPercentage={setOccupencyPercentage}
                daysLeftToBooking={daysLeftToBooking}
                setDaysLeftToBooking={setDaysLeftToBooking}
              />
            );
          })}

        <button
          type="button"
          onClick={handleAddMoreRule}
          className="size-[31px] border border-[#D0D5DD] rounded-full bg-white flex items-center justify-center hover:bg-gray-300 duration-300 ease-[0s] mt-2"
        >
          <FaPlus size={15} />
        </button>
        <div className="grid grid-cols-6 items-center justify-end mt-4">
          <div className="col-span-5"></div>
          <div className="flex items-center justify-center gap-4 col-span-1">
            <CancelButton />
            <button
              type="button"
              className="h-10 w-[61px] rounded-md bg-base-primary hover:bg-base-primary_hover text-sm font-medium text-white duration-300 ease-[0s] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]"
            >
              save
            </button>
          </div>
        </div>
      </div>
      <RoomTariffAutomationRules />
      {/* calender*/}
      <div className="mt-6">
        <div className="grid grid-cols-5 mb-6">
          <SelectFromArray
            value={roomType}
            setValue={setRoomType}
            options={["Room Type"]}
          />
        </div>
        <RoomTarrifCalender />
      </div>
    </div>
  );
};

export default RoomTariff;
