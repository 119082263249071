import React from "react";
import AddThirdPartyKitchen from "./AddThirdPartyKitchen";
import AllThirdPartyKitchen from "./AllThirdPartyKitchen";

const ThirdPartyKitchen = (props) => {
  return (
    <div className="">
      <AddThirdPartyKitchen close = {props.closeThirdPartyKitchenModal} />
      <div className="my-4">
        <AllThirdPartyKitchen />
      </div>
    </div>
  );
};

export default ThirdPartyKitchen;
