import React from "react";

const BuffetSummaryCard = ({ counts, name }) => {
  return (
    <div className="w-full h-[18vh] flex items-center justify-center rounded-[10.12px] bg-white shadow drop-shadow-[0_4042162px_12.126486778259277px_rgba(0,0,0,0.04)] px-3">
      <div>
        <div className="text-[41.73px] text-[#07090D] leading-[60.27px] font-bold">
          {counts}
        </div>
        <div className="text-[11.68px] text-[#71717A] leading-[18.58px] tracking-[1.24px] uppercase">
          {name}
        </div>
      </div>
    </div>
  );
};

export default BuffetSummaryCard;
