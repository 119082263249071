import React, { useState } from "react";

const OPTIONS = {
  DIRTY: "Dirty",
  CLEANED: "Cleaned",
  CLEANING: "Cleaning",
};

const RoomCleaningStatus = () => {
  const [value, setValue] = useState("Dirty");
  return (
    <select
      onChange={(e) => setValue(e?.target?.value)}
      value={value}
      className={`
          h-[29px] rounded-[5px] shadow-none border-none focus:border-none text-[14px] leading-[17.64px] font-normal px-2
          ${value === "Dirty" && "bg-[#FEF3F2] text-[#F04438]"}
           ${value === "Cleaned" && "bg-[#ECFDF3] text-base-primary"}
            ${value === "Cleaning" && "bg-[#EEF4FF] text-[#444CE7]"}
          `}
    >
      {Object.keys(OPTIONS)?.map((it) => (
        <option className="capitalize" key={it} value={OPTIONS[it]}>
          {OPTIONS[it]}
        </option>
      ))}
    </select>
  );
};

export default RoomCleaningStatus;
