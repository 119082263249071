import React from "react";
import { useNavigate } from "react-router-dom";

const TabConfigButton = ({ name, params, index, routes}) => {
  const navigate = useNavigate();
  const setOptionTabIndex = () => {
    navigate(`${routes}/${index}`);
  };

  return (
    <button
      className={`our-button w-[21vw] h-12 last:mr-0 capitalize rounded-lg whitespace-nowrap min-w-fit overflow-auto text-[15px] border ${
        +params !== index
          ? "border-[#E8E8E8] text-black bg-white"
          : " border-base-primary text-base-primary bg-[#EEF6F2]"
      }`}
      onClick={() => setOptionTabIndex()}
    >
      {name}
    </button>
  );
};

export default TabConfigButton;
