import React, { useState } from "react";
import TopButtons from "./TopButtons";
import { MdOutlineMeetingRoom, MdOutlineDinnerDining } from "react-icons/md";
import OrderForTable from "./OrderForTable";
import OrderForRoom from "./OrderForRoom";
import FoodOrderCard from "./FoodOrderCard";
import SubmitButton from "../../../components/buttons/SubmitButton";

const CreateFoodOrder = () => {
  const [isOrderForTable, setIsOrderForTable] = useState(true);
  const [isOrderForRoom, setIsOrderForRoom] = useState(false);
  const handleOrderForTable = () => {
    setIsOrderForTable(true);
    setIsOrderForRoom(false);
  };
  const handleOrderForRoom = () => {
    setIsOrderForRoom(true);
    setIsOrderForTable(false);
  };
  const FOOD_ORDER_CARD = [
    {
      id: "1",
      orderName: "A2 Size Rice Cooker",
      price: "₹250",
    },
    { id: "2", orderName: "Rice Cooker", price: "₹250" },
    {
      id: "3",
      orderName: "A2 Size Rice Cooker",
      price: "₹250",
    },
    {
      id: "4",
      orderName: "A2 Size Rice Cooker",
      price: "₹250",
    },
    {
      id: "5",
      orderName: "Rice Cooker",
      price: "₹250",
    },
    {
      id: "6",
      orderName: "A2 Size Rice Cooker",
      price: "₹250",
    },
    {
      id: "7",
      orderName: "A2 Size Rice Cooker",
      price: "₹250",
    },
    { id: "8", orderName: "Rice Cooker", price: "₹250" },
    {
      id: "9",
      orderName: "A2 Size Rice Cooker",
      price: "₹250",
    },
    {
      id: "10",
      orderName: "A2 Size Rice Cooker",
      price: "₹250",
    },
    {
      id: "11",
      orderName: "Rice Cooker",
      price: "₹250",
    },
    {
      id: "12",
      orderName: "A2 Size Rice Cooker",
      price: "₹250",
    },
  ];
  return (
    <div className="w-full border border-[#E5E9EB] rounded-r-[10px] bg-white">
      <div className="flex flex-col p-4">
        <div className="flex gap-2">
          <TopButtons
            name={"Table"}
            Icon={<MdOutlineDinnerDining size={34} />}
            onClick={handleOrderForTable}
            active={isOrderForTable}
          />
          <TopButtons
            name={"Room"}
            Icon={<MdOutlineMeetingRoom size={34} />}
            onClick={handleOrderForRoom}
            active={isOrderForRoom}
          />
        </div>
        <div className="mt-4">
          {isOrderForTable ? <OrderForTable /> : null}
          {isOrderForRoom ? <OrderForRoom /> : null}
        </div>
        {/* border-[#E1E1E1] */}
      </div>
      <div className="h-[calc(100vh-21.9rem)] border-t border-[#E1E1E1]  overflow-x-hidden overflow-y-auto scroll-bar2 px-4">
        {FOOD_ORDER_CARD?.map((food) => {
          const { id, price, orderName } = food;
          return <FoodOrderCard key={id} Price={price} orderName={orderName} />;
        })}
      </div>
      <div className="flex flex-col gap-2 text-[16px] leading-7 tracking-[-1.5%] text-[#6E7C87] shadow p-4">
        <div className="grid grid-cols-3">
          <div>Total</div>
          <div className="text-center">:</div>
          <div className="text-right text-[#07090D] font-medium">1579.00</div>
        </div>
        <div className="grid grid-cols-3">
          <div>Discount</div>
          <div className="text-center">:</div>
          <div className="text-right text-[#07090D] font-medium">00.00</div>
        </div>
        <div className="grid grid-cols-3">
          <div>Tax</div>
          <div className="text-center">:</div>
          <div className="text-right text-[#07090D] font-medium">147</div>
        </div>
        <div className="border-t-2 border-dashed border-spacing-10 border-[#6E7C87]">
          <div className="flex items-center justify-between text-[#07020D] font-medium my-3">
            <div>Net Payable</div>
            <div>1600</div>
          </div>
          <SubmitButton
            type="button"
            sizing="w-full h-12"
            text={"Create Order"}
          />
          <button
            type="button"
            className="w-full h-12 mt-4 rounded-lg bg-[#FFE5E3] text-[#F04438] text-[16px] leading-[20.16px] font-semibold duration-300 ease-[0.3s] hover:bg-[#F04438] hover:text-white"
          >
            Discard Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateFoodOrder;
