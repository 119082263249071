import React, { useState } from "react";
import CloseButton from "../../../components/buttons/CloseButton";
import Inputfield from "../../../components/layouts";
import SubmitButton from "../../../components/buttons/SubmitButton";
import InputButton from "../InputButton";
import ConfigPaymentReceivedType from "../../../components/modals/ConfigPaymentReceivedType";

const AddBankAccount = (props) => {
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNickName, setAccountNickName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [isConfigPaymentType, setIsConfigPaymentType] = useState(false);
  // const [paymentMode, setPaymentMode] = useState([]);

  return (
    <div className="relative w-full border border-[#E4E4E7] rounded-[10px] bg-white mt-6">
      <div className="w-1/2 mx-auto ">
        <h2 className="font-bold text-xl sm:text-2xl md:text-3xl leading-9 text-black tracking-tighter my-6">
          Add Bank Account
        </h2>
        <div className="absolute right-6 top-6">
          <CloseButton onClick={props.close} />
        </div>
        <div className="flex flex-col gap-6">
          <div className="">
            <label htmlFor="bankAndBranchName">Bank And Branch Name</label>
            <div className="flex items-center w-full">
              <input
                name="bankAndBranchName"
                type="text"
                className="w-3/5 border-r-0 rounded-r-none"
                placeholder="Enter Bank Name"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
              <input
                name="bankAndBranchName"
                type="text"
                className="w-2/5 rounded-l-none"
                placeholder="Enter Branch Name"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
              />
            </div>
          </div>
          <Inputfield
            label={"Account Nick Name"}
            value={accountNickName}
            onChange={(e) => setAccountNickName(e.target.value)}
            placeholder={"Enter Account Nick Name"}
          />
          <Inputfield
            label={"Account Number(last 4 digits)"}
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            placeholder={"Enter Account Number"}
          />
        </div>
        <h2 className="font-semibold text-[20px] leading-6 text-black my-6">
          Payment Received Type
        </h2>
        <InputButton
          label={"Select Incoming Payment in This Account"}
          onClick={() => setIsConfigPaymentType(true)}
        />
        {isConfigPaymentType ? (
          <ConfigPaymentReceivedType
            close={() => setIsConfigPaymentType(false)}
          />
        ) : null}
        {/* <h2 className="font-semibold text-[20px] leading-6 text-black my-6">
          Payment Received Type
        </h2>
        <AddButton
          text={"Add Payment Mode"}
          onClick={() => setIsShowPaymentMode(!isShowPaymentMode)}
        />
        {isShowPaymentMode ? (
          <div className="my-6">
            <MultipleSelectTabView
              label={"Select Incoming Payment in This Account"}
              options={PAYMENT_MODE}
              value={paymentMode}
              setValue={setPaymentMode}
            />
          </div>
        ) : null} */}
        <div className="flex items-center justify-end mt-6 mb-8">
          <SubmitButton sizing="h-12 w-[183px]" text={"Save"} />
        </div>
      </div>
    </div>
  );
};

export default AddBankAccount;
