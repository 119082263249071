import React, { useState, useEffect } from "react";
import { getToday } from "../../utils";

const SelectDate = (props) => {
  const [startDate, setStartDate] = useState(getToday());
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (startDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(startDateObj.setMonth(startDateObj.getMonth() + 1));
      const endDateString = endDateObj.toISOString().split("T")[0];
      setEndDate(endDateString);
    }
  }, [startDate]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    const startDateObj = new Date(startDate);
    const newEndDateObj = new Date(newEndDate);

    // Check if the new end date is at least 1 month after the start date
    const minEndDateObj = new Date(startDateObj.setMonth(startDateObj.getMonth() + 1));
    if (newEndDateObj >= minEndDateObj) {
      setEndDate(newEndDate);
    } else {
      // If not, set the end date to be exactly 1 month after the start date
      setEndDate(minEndDateObj.toISOString().split("T")[0]);
    }
  };

  return (
    <div className="relative w-[308px] h-10 flex flex-row justify-center items-center gap-3 border border-[#E4E4E7] rounded-md box-border bg-white  text-[#18181B] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] p-3">
      <input
        value={startDate}
        onChange={handleStartDateChange}
        type="date"
        className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 cursor-pointer select-none text-[14px] font-medium leading-[17.64pxpx] tracking-[-0.8px] focus:outline-none"
      />
      <input
        value={endDate}
        onChange={handleEndDateChange}
        type="date"
        className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-medium cursor-pointer select-none text-[14px] leading-[17.64pxpx] tracking-[-0.8px]"
      />
    </div>
  );
};

export default SelectDate;
