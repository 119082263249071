import React, { useState } from "react";
import CustomModal from "../CustomModal";
import Inputfield from "../layouts";
import { IoSearchOutline } from "react-icons/io5";
import SelectBox from "../layouts/SelectBox";
import ShiftButton from "../buttons/ShiftButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import SubmitButton from "../buttons/SubmitButton";

const ConfigPaymentReceivedType = ({ close = () => {} }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const leftSideRow = [
    {
      name: "Cheque",
    },
    {
      name: "POS",
    },
    {
      name: "Corporate Wallet",
    },
    {
      name: "Travel Agent Wallet",
    },
    {
      name: "Cash Payment",
    },
    {
      name: "Online Payment",
    },
  ];
  const rightSideRow = [
    {
      name: "Cheque",
    },
  ];
  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      checkboxSelection: true,
    },
  ];
  return (
    <CustomModal close={close} heading={"Configure Payment Received Type"}>
      <div className="h-[80vh] w-[80vw] capitalize">
        <div className="w-[32vw]">
          <div className="relative my-4">
            <Inputfield
              type="search"
              placeholder={"Add Employee"}
              className={"pl-12"}
            />
            <IoSearchOutline size={24} className="absolute top-3 left-4" />
          </div>
        </div>
        <div className="grid grid-cols-10 w-full">
          {/* Left side */}
          <div className="col-span-4">
            <SelectBox
              label={"Select Incoming Payment in This Account"}
              heading={"Select Payment Types"}
              showBtn={false}
              columnDefs={columnDefs}
              rowData={leftSideRow}
              setSelectedRows={setSelectedRows}
            />
          </div>

          {/* Buttons for add and remove */}
          <div className="col-span-2 flex flex-col items-center justify-center gap-6">
            <ShiftButton
              name={"Add"}
              icon={<AiOutlineDoubleRight size={18} />}
            />
            <ShiftButton
              name={"Remove"}
              icon={<AiOutlineDoubleLeft size={18} />}
            />
          </div>

          {/* Right side */}
          <div className="col-span-4 w-full">
            <SelectBox
              label={"Selected Incoming Payment in This Account"}
              heading={"Selected Payment Types"}
              showBtn={false}
              columnDefs={columnDefs}
              rowData={rightSideRow}
            />
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <SubmitButton sizing={"h-12 w-[183px]"} text={"Save"} />
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfigPaymentReceivedType;
