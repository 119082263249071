import React, { Fragment, useState } from "react";
import "./Packages.css";
import { BsChevronDown } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Packages = () => {
  const [lastDays, setLastDays] = useState(30);
  const [showflop, setShowflop] = useState(false);
  const shofloptions = () => {
    setShowflop(!showflop);
  };
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="kn-top-selected-p ">
        <div className="kn-top-selected-head flex items-center">
          <div className="kn-top-sp-head-fr font-bold">{t("25")}</div>
          <div className="kn-top-selected-p-actions border border-[#E4E4E7] rounded-md">
            <div
              className="kn-top-selected-p-op z-30 border border-[#E4E4E7]"
              onClick={shofloptions}
            >
              {lastDays} {t("26")}
              <BsChevronDown className={`transition-all duration-300 ${showflop?"rotate-[180deg]":""}`}/>
              <div
                className={
                  showflop
                    ? "kn-top-selected-p-childs open border border-[#E4E4E7] bg-white rounded-b-md z-20 "
                    : "kn-top-selected-p-childs hover:text-base-primary"
                }
                onClick={(e) => setLastDays(e.target.value)}
              >
                <input
                  type="radio"
                  id="7daysppackage"
                  name="lastdays"
                  value={7}
                />
                <label htmlFor="7daysppackage">7 {t("26")}</label>
                <input
                  type="radio"
                  id="15daysppackage"
                  name="lastdays"
                  value={15}
                />
                <label htmlFor="15daysppackage">15 {t("26")}</label>
                <input
                  type="radio"
                  id="30dayspackage"
                  name="lastdays"
                  value={30}
                />
                <label htmlFor="30dayspackage">30 {t("26")}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="kn-top-selected-packs">
          <div className="kn-top-selected-pack">
            <div className="kn-top-s-p__info">
              <span>{t("27")}</span>
              <span>70%</span>
            </div>
            <div className="w-full md:w-72 pt-1">
              <div className=" input-renge-outer">
                <div className="bg-base-primary absolute w-[70%] md:w-[223.51px] h-[4.54px] rounded-[64.97px]"></div>
              </div>
            </div>
            <div className="kn-top-sp-bar">
              <span></span>
            </div>
          </div>
          <div className="kn-top-selected-pack">
            <div className="kn-top-s-p__info">
              <span>{t("27")}</span>
              <span>60%</span>
            </div>
            <div className="w-full md:w-72 pt-1">
              <div className=" input-renge-outer">
                <div className="bg-base-primary absolute w-[60%] md:w-[195.75px] h-[4.54px] rounded-[64.97px]"></div>
              </div>
            </div>
            <div className="kn-top-sp-bar">
              <span></span>
            </div>
          </div>
          <div className="kn-top-selected-pack">
            <div className="kn-top-s-p__info">
              <span>{t("27")}</span>
              <span>30%</span>
            </div>
            <div className="w-full md:w-72 pt-1">
              <div className=" input-renge-outer">
                <div className="bg-base-primary absolute w-[30%] md:w-[99.95px] h-[4.54px] rounded-[64.97px]"></div>
              </div>
            </div>
            <div className="kn-top-sp-bar">
              <span></span>
            </div>
          </div>
          <div className="kn-top-selected-pack">
            <div className="kn-top-s-p__info">
              <span>{t("27")}</span>
              <span>10%</span>
            </div>
            <div className="w-full md:w-72 pt-1">
              <div className=" input-renge-outer">
                <div className="bg-base-primary absolute w-[10%] md:w-[38.16px] h-[4.54px] rounded-[64.97px]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Packages;
