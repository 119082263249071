import React from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";

const Step0 = ({ setStep, owner }) => {
  const { t } = useTranslation();
  const completeStep = () => {
    setStep(1);
  };

  return (
    <div className="flex flex-col justify-center items-start">
      <div className="capitalize text-3xl sm:text-5xl md:text-[52px] md:leading-[72px] font-bold break-words">
        <div className="">{t("900")}</div>
        <span className="text-base-primary pr-2">{t("929")}</span>
        {t("901")}
      </div>
      <div className="flex items-center text-[14px] text-[#252C32] leading-5 font-medium gap-4 mt-6">
        <button
          type="button"
          className="flex items-center gap-3 px-4 h-10 rounded-md bg-white hover:bg-gray-300  border border-[#E5E9EB] duration-300 ease-[0.3s]"
          // onClick={completeStep}
        >
          <FaArrowLeft />
          {t("Go Back")}
        </button>
        <button
          type="button"
          className="bg-base-primary hover:bg-base-primary_hover flex items-center gap-3 px-4 h-10 rounded-md text-white duration-300 ease-[0.3s]"
          onClick={completeStep}
        >
          {t("902")}
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Step0;
