import React, { useState } from "react";
import { CloseButton } from "../../../components";
import SelectFromObject from "../../../components/layouts/SelectFromObject";
import SubmitButton from "../../../components/buttons/SubmitButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import ShiftButton from "../../../components/buttons/ShiftButton";
import SelectBox from "../../../components/layouts/SelectBox";

const CreateMinibarList = (props) => {
  const [addQuantity, setAddQuantity] = useState("");
  const [minibarConfigName, setMinibarConfigName] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  
  const rowData = [
    {
      product: "Red Bull (250ml)",
      price: "$12",
    },
    {
      product: "kingfisher soda (250ml)",
      price: "$8",
    },
  ];
  const handleAddQuantity = () => {
    return (
      <div className="h-full flex items-center">
        <input
          type="text"
          className="h-8 w-16 text-sm text-[#000000] font-medium"
          value={addQuantity}
          onChange={(e) => setAddQuantity(e.target.value)}
        />
      </div>
    );
  };
  const rowData1 = [
    {
      product: "Red Bull (250ml)",
      price: "$12",
      addQuantity: "2",
    },
    {
      product: "kingfisher soda (250ml)",
      price: "$8",
      addQuantity: "4",
    },
  ];
  const columnDefs = [
    {
      headerName: "Product",
      field: "product",
      checkboxSelection: true,
      minWidth: 100,
      // headerCheckboxSelection: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Add Quantity",
      field: "id",
      filter: true,
      cellRenderer: handleAddQuantity,
    },
  ];
  const columnDefs1 = [
    {
      headerName: "Product",
      field: "product",
      checkboxSelection: true,
      minWidth: 100,
      // headerCheckboxSelection: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Add Quantity",
      field: "addQuantity",
      filter: true,
    },
  ];
  return (
    <div className="w-full bg-white border border-[#D0D5DD] rounded-[10px] mt-4 p-6">
      <div className="flex items-center justify-between">
        <div className="text-[26px] leading-[32.76px] font-bold text-[#07090D]">
          Configuration of Minibar By Room Type
        </div>
        <CloseButton onClick={props.close} />
      </div>
      <div className="w-2/5 mt-6">
        <SelectFromObject
          label={"Minibar Configuration Name"}
          options={{
            minibar1: "minibar 1",
            minibar2: "minibar 2",
            minibar3: "minibar 3",
          }}
          value={minibarConfigName}
          setValue={setMinibarConfigName}
        />
      </div>
      <div className="grid grid-cols-10 w-full mt-6">
        {/* Left side */}
        <div className="col-span-4">
        <SelectBox 
            label={"Available Items"}
            heading={"Select  items to add"}
            btnName={"Add item"}
            handleTopBtn={""}
            rowData={rowData}
            columnDefs={columnDefs}
            setSelectedRows={setSelectedRows}
          />
        </div>

        {/* Buttons for add and remove */}
        <div className="col-span-2 flex flex-col items-center justify-center gap-6">
          <ShiftButton name={"Add"} icon={<AiOutlineDoubleRight size={18} />} />
          <ShiftButton
            name={"Remove"}
            icon={<AiOutlineDoubleLeft size={18} />}
          />
        </div>

        {/* Right side */}
        <div className="col-span-4 w-full">
        <SelectBox 
            label={"selected Item"}
            heading={"Add items to List"}
            showBtn={false}
            rowData={rowData1}
            columnDefs={columnDefs1}
            setSelectedRows={setSelectedRows}
          />
        </div>
      </div>
      <div className="flex items-center justify-end mt-6">
        <SubmitButton text={"Configure Minibar"} />
      </div>
    </div>
  );
};

export default CreateMinibarList;
