import React, { useState } from "react";
import { CustomeTable } from "../../components";
import Assign from "./Assign";

const ISSUE_STATUS = {
  RESOLVE: "Resolve",
  IN_PROGRESS: "In Progress",
  PENDING: "Pending",
};

function IssueStatus() {
  const [value, setValue] = useState("In Progress");
  return (
    <select
      onChange={(e) => setValue(e?.target?.value)}
      value={value}
      className={`
          h-[29px] rounded-[5px] shadow-none border-none focus:border-none text-[14px] leading-[17.64px] font-normal px-2
           ${value === "Resolve" && "bg-[#ECFDF3] text-base-primary"}
            ${value === "In Progress" && "bg-[#EEF4FF] text-[#444CE7]"}
             ${value === "Pending" && "bg-[#FEF3F2] text-[#F04438]"}
          `}
    >
      {Object.keys(ISSUE_STATUS)?.map((it) => (
        <option className="capitalize" key={it} value={ISSUE_STATUS[it]}>
          {ISSUE_STATUS[it]}
        </option>
      ))}
    </select>
  );
}

const IssueReported = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      _id: "100",
      room_no: "103",
      report_type: "House Keeping",
      issue: "Dirty Towel",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "assign",
      status: "Resolved",
    },
    {
      _id: "101",
      room_no: "103",
      report_type: "Maintenance",
      issue: "Broken Lamp",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "E023 Masud , E024 Rajot",
      status: "In Progress",
    },
    {
      _id: "102",
      room_no: "103",
      report_type: "Security",
      issue: "Lost Key Card",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "assign",
      status: "Pending",
    },
    {
      _id: "103",
      room_no: "103",
      report_type: "House Keeping",
      issue: "Room Service",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "E023 Masud , E024 Rajot",
      status: "Resolved",
    },
    {
      _id: "104",
      room_no: "103",
      report_type: "Maintenance",
      issue: "Room Service",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "assign",
      status: "In Progress",
    },
  ];

  const columnDef = [
    {
      headerName: "Room ID",
      field: "_id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      minWidth: 120,
      filter: true,
    },
    {
      headerName: "report type",
      field: "report_type",
      minWidth: 140,
      filter: true,
    },
    {
      headerName: "issue",
      field: "issue",
      minWidth: 100,
      filter: true,
    },
    {
      headerName: "reporting_time",
      field: "reporting_time",
      minWidth: 155,
      filter: true,
    },
    {
      headerName: "date",
      field: "date",
      minWidth: 100,
      filter: true,
    },
    {
      headerName: "assign to",
      field: "assign_to",
      cellRenderer: Assign,
      minWidth: 120,
      filter: true,
    },
    {
      headerName: "status",
      field: "status",
      cellRenderer: IssueStatus,
      minWidth: 150,
      filter: true,
    },
  ];

  return (
    <div>
      <CustomeTable
        tableName={"Issue Reported Table"}
        rowData={rowData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default IssueReported;
