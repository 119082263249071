import React, { useEffect } from "react";
import { useState } from "react";
import { registerEvent } from "../../logs";
import AllAssets from "./AllAssets";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllAssetAction } from "../../actions/pms/assetget";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import AddAssets from "./AddAssets";
import DelIcon from "../../images/icons/DelIcon.svg";
import UpdateAssets from "./UpdateAssets";
import { DeleteButton, EditButton } from "../../components";

const AssetsPage = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { hotel } = useSelector((state) => state.Hotel);
  const [asset, setAsset] = useState(null);

  const toggleAddAssetsModal = () => {
    if (asset) setAsset(null);
    setOpen(!open);
    registerEvent("add asset button open", "add asset", { open });
  };

  useEffect(() => {
    if (hotel?._id) {
      props.getAssets(hotel?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel?._id]);

  const headingData = {
    heading: t("325"),
    seperator: [
      {
        text: t("Inventory Management"),
      },
      {
        text: t("325"),
      },
    ],
  };

  return (
    <div className="page-container">
      <div className="flex justify-between items-end">
        <Seperator data={headingData} />
        <div className="flex items-center gap-2">
          <EditButton onClick={toggleAddAssetsModal} disabled={!asset} />
          <DeleteButton />
          <AddButton
            text={t("326")}
            onClick={toggleAddAssetsModal}
            disabled={open}
          />
        </div>
      </div>
      {open ? <AddAssets hotel={hotel} close={toggleAddAssetsModal} /> : null}
      {open && asset ? (
        <UpdateAssets close={toggleAddAssetsModal} hotel={hotel} />
      ) : null}
      <AllAssets hotel={hotel} setAsset={setAsset} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  assets: state.AllAsset,
});

const mapDispatchToProps = (dispatch) => ({
  getAssets: (hotelId) => dispatch(getAllAssetAction(hotelId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AssetsPage);
