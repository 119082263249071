import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import NewCustomer from "./NewCustomer";
import { IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import img1 from "../../images/users/Image (1).png";
import img2 from "../../images/users/Image (2).png";
import img3 from "../../images/users/Image (3).png";

const NewCustomers = ({ bookings }) => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState([img1, img2, img3]);

  useEffect(() => {}, []);

  return (
    <>
      <div >
        <div className="text-base font-bold text-[#18181B]">{t("17")}</div>
        <div className="text-[13px] leading-[22px] font-normal text-gray-500">
          {t("18")}
        </div>
        <div className="max-w-[251px] my-3">
          {customers?.map((customer, index) => (
            <NewCustomer image={customer} key={index} />
          ))}
        </div>
        <div>
          <Link
            to={ROUTES.CUSTOMERS}
            className="font-bold text-[12px] tracking-[1px] text-normal leading-[18px] uppercase opacity-50 flex items-center"
          >
            {t("19")}
            <IoIosArrowForward size={14} style={{ marginLeft: ".5rem" }} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default NewCustomers;
