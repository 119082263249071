import React, { useState } from "react";
import AddButton from "../../../components/buttons/AddButton";
import AddBankAccount from "./AddBankAccount";
import { registerEvent } from "../../../logs";
import AllBankAccounts from "./AllBankAccounts";

const BankAccountConfig = () => {
  const [openModal, setOpenModal] = useState(false);

  const openBankAccountModal = () => {
    setOpenModal(true);
    registerEvent("Add bank account button open", "payment mode");
  };

  const closeBankAccountModal = () => {
    setOpenModal(false);
    registerEvent("Add bank account button close", "payment roaster");
  };

  return (
    <div className="">
      <div className="flex items-center justify-end">
        <AddButton text={"Add Bank Account"} onClick={openBankAccountModal} />
      </div>
      {openModal ? <AddBankAccount close={closeBankAccountModal} /> : null}
      <div className="my-4">
        <AllBankAccounts />
      </div>
    </div>
  );
};

export default BankAccountConfig;
